import firebase from "firebase"
import moment from "moment"
import React, { useState } from "react"
import { Button, Col, Collapse, Form, Row } from "react-bootstrap"
import { useCompany } from "./hooks/ConectoHooks"
import { encrypt, randomAESKey } from "./encryption/AES"
import { AnnualOffer, MonthlyOffer, SpotOffer } from "./model/Bid"
import { Id, Product } from "./model/Model"
import Timestamp = firebase.firestore.Timestamp
import { matchEnumPartial, matchEnumPartialLazy, matchPartial } from "functional/lib/match"
import { GasBuyRequest, GasBuyTypeAnnual, GasBuyTypeMonthly, GasBuyTypeSpot } from "./model/buyRequest/GasBuyRequest"
import { ElectricityBuyRequest } from "./model/buyRequest/ElectricityBuyRequest"
import { AppCall } from "./context/AppCall"
import { Unit, none, unit } from "functional/lib/core"
import { Maybe } from "functional/lib/Maybe"
import { BuyRequestStatusAssigned } from "./model/buyRequest/BuyRequestStatus"
import { List } from "functional/lib/List"
import { BuyRequest } from "./model/buyRequest/BuyRequest"
import { Process } from "./model/Process"
import { IO } from "functional/lib/IO"
import { FlexCol, FlexRow } from "./components/Flexbox"
import { BuyRequestFeeType, FeeConditions } from "./model/buyRequest/BuyRequestBase"
import { User } from "./model/User"
import { Draft } from "./model/utils"
import { defaultFeeConditions } from "./config"


export const PasswordRecover = (
  props: {
    style?: React.CSSProperties
    email?: string
  }
) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [sentWithSuccess, setSentWithSuccess] = useState(false)
  const [email, setEmail] = useState(props.email ? props.email : '')

  const auth = firebase.auth()

  const onSendPasswordReset = 
    async () => {
      try {
        await auth.sendPasswordResetEmail(email)
        setShowPasswordReset(false)
        setSentWithSuccess(true)
      } catch (error) {
        console.log(error)
      }
    }

  return <FlexCol
    style={props.style}
    alignItems="stretch"
  >
    <FlexRow 
      justifyContent="center"
    >
      <small>
        ¿Olvidaste tu Contraseña? <a href='#' onClick={() => setShowPasswordReset(it => !it)}>Restaurar</a>
      </small>
    </FlexRow>
    <Collapse in={showPasswordReset}>
      <div>
        <hr />
        <FlexCol
          gap={8}
        >
          <Row className="justify-content-center">
            <small>Ingresá tu email, te enviamos un link para que restaures tu contraseña</small>
          </Row>
          <FlexRow
            style={{
              width: '100%',
            }}
            gap={8}
          >
            <Form.Group
              style={{
                flexGrow: 1
              }}
              controlId="formBasicEmail"
            >
              <Form.Control
                type="email"
                value={email}
                size={'sm'}
                onChange={event => setEmail(event.target.value)}
                placeholder="Email"
              />
            </Form.Group>
            <Button
              variant="primary" 
              size='sm' 
              onClick={onSendPasswordReset}
            >
              Enviar Mail
            </Button>
          </FlexRow>
        </FlexCol>
      </div>
    </Collapse>
    {sentWithSuccess && <Row className="justify-content-center"><small className='text-success'>Enviado con Éxito.</small></Row>}
  </FlexCol>

}
export const bootstrapColorForStatus = (
  request?: Draft<BuyRequest>,
  user?: User
) => 
  matchPartial(request?.status ?? { type: "pending" })({
    open: () => "success",
    pending: () => "warning",
    waitingAnswer: () => "primary",
    preAssigned: () => 'secondary',
    assigned: status => {
      const assigned = status as BuyRequestStatusAssigned
      return user && 
      (
        (assigned.assignedCompanyId === user?.companyIdList?.[0]) || 
        user.companyIdList?.includes(request?.buyerCompanyId ?? "")
      ) ? 'info' : 'secondary'
    },
    deserted: () => "danger",
  }) ?? "dark"

export const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]

export const NoCapitalMONTHS = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre"
]
export const WEEKDAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado"
]

export const DAYSPERMONTH = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
]



export const processDefinitionDate = (process: Process): Date => 
  moment(process.closeDate).add(process.definitionTermDays, "days").toDate()


export const shortMonthName = (index: number): string => 
  MONTHS[index].slice(0, 3)


export const dateToShortFormat = (
  dateInTimeStamps: Maybe<Date | Timestamp>
): string => {

  if (dateInTimeStamps === none) return "-"

  if (dateInTimeStamps instanceof Timestamp) return dateToShortFormat(dateInTimeStamps.toDate())
  const hora = dateInTimeStamps.toString().substring(16, 21)
  const month = MONTHS[dateInTimeStamps.getMonth()].substring(0, 3)
  const day = dateInTimeStamps.getDate()
  const year = dateInTimeStamps.getFullYear()
  return `${day} ${month} ${hora} hs ${year}`
}

export const dateToNiceFormat = (date: Maybe<Date>): string => {

  if (date === none) return "-"

  const hora = date.toString().substring(16, 21)
  const month = MONTHS[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()
  return `${day} de ${month} ${year} a las ${hora} hs`
}

export const dateToMailFormat = (
  dateInTimeStamps: Date
) => {
  const hora = dateInTimeStamps.toString().substring(16, 21)
  const month = NoCapitalMONTHS[dateInTimeStamps.getMonth()]
  const day = dateInTimeStamps.getDate()
  const weekDay = WEEKDAYS[dateInTimeStamps.getDay()]
  return `${weekDay} ${day} de ${month} ${hora} hs`
}

export const dateToBidFormat = (date: Date) => {
  const hora = date.toString().substring(16, 24)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear().toString().slice(-2)
  return `${day}/${month} ${hora} hs`
}

export const validaDateForBid = (
  dateInTimeStamps: Date, days: Maybe<number>
) => {
  if (days === none) return ""
  const result = new Date(dateInTimeStamps);
  result.setDate(result.getDate() + days)
  const month = result.getMonth() + 1
  const day = result.getDate()
  return day + '/' + month
}

export const validaDateForCheck = (
  dateInTimeStamps: Date, days: Maybe<number>
) => {
  if (days === none) return false
  const date = new Date()
  const result = new Date(dateInTimeStamps);
  result.setDate(result.getDate() + days)
  return new Date() > result
}

export const dateToStartSupplyFormat = (
  dateInTimeStamps: Date
) => {
  const hora = "06:00hs"
  const month = MONTHS[dateInTimeStamps.getMonth()]
  const day = dateInTimeStamps.getDate()
  return day + " de " + month + " " + hora
}

export const dateToMonthStartSupplyFormat = (
  dateInTimeStamps: Date
) => {
  const hora = "06:00hs"
  const month = MONTHS[dateInTimeStamps.getMonth()]
  const day = '1'
  return day + " de " + month + " " + hora
}

export const monthYearFormat = (
  date: Maybe<Date>
) => {

  if (date === none) return "-"

  const month = date.getMonth()
  const year = date.getFullYear()

  const monthText = MONTHS[month]?.substring(0, 3) ?? "-"
  const yearText = year.toString().substring(0, 4)
  return `${monthText} ${yearText}`
}

const addZero = (num: number): string => 
  (num >= 0 && num < 10) ? "0" + num : num + ""

export const formatTimestamp = (timestamp: Timestamp): string => {
  const date = new Date(timestamp.seconds * 1000)
  return date.getDate() + ' ' + shortMonthName(date.getMonth()) + ' ' + date.getFullYear() + " " + date.getHours() + ":" + addZero(date.getMinutes()) + "hs";
}

export const sum = (array: List<number>) => 
  array?.reduce((sum, next) => sum + (next ? next : 0), 0)


export const isWinterMonth = (monthIndex: number): boolean => 
  4 <= monthIndex && monthIndex < 9


export const computeAnnualWeightedAveragePrice = (
  buyRequest: BuyRequest,
  offer?: Draft<AnnualOffer>
): number => {

  if (offer == none) return 0

  if (buyRequest.type !== "gas") return 0
  if (buyRequest.buyType?.type !== "annual") return 0

  const buyType = buyRequest.buyType

  const startMonthIndex = buyRequest.supplyStartDate.getMonth()

  const curve = (buyRequest.acceptsLessVolume ? (offer.monthlyCurve ?? [0]) : buyType.demandCurve).map(it => it ?? 0) 
  const priceByVolume = buyType.demandCurve.map((value, position) =>
    curve[position] * (isWinterMonth((startMonthIndex + position) % 12) ? (offer.winterPrice ?? 0) : (offer.summerPrice ?? 0))
  )
  return sum(priceByVolume) / sum(curve)
}

export const computeMonthlyWeightedAveragePrice = (
  buyRequest: BuyRequest,
  offer: Maybe<Draft<MonthlyOffer>>
): number => {
  const buyType = buyRequest.buyType as GasBuyTypeMonthly
  const useCurve = (buyRequest.type === "gas" && buyRequest.acceptsLessVolume ? offer?.monthlyCurve : buyType.demandCurve)?.map(it => it ?? 0) ?? [0]
  const priceByVolume = buyType.demandCurve.filter(value => value !== null && value !== 0).map((value, index) => (useCurve[index] ?? 0) * (offer?.priceCurve?.[index] ?? 0))
  return sum(priceByVolume) / sum(useCurve)

}
export const computeMonthlyCmdAverage = (
  cmdCurve: List<number>
): string => 
  (sum(cmdCurve) / cmdCurve?.length).toFixed(2)

export const mapMonthlyCurveWithName = <T,>(
  supplyStartDate: Maybe<Date>,
  demandCurve: List<number>,
  transform: (name: string, value: number, index: number) => T
): T[] => {

  if (supplyStartDate === none) return []

  const startMonthIndex = supplyStartDate.getMonth()
  const startYear = supplyStartDate.getFullYear()

  return demandCurve.map((value, index) => {
    const name = `${MONTHS[(startMonthIndex + index) % 12]} ${startYear + Math.floor((startMonthIndex + index) / 12)}`
    return transform(name, value, index)
  })
}

export const buildPathForStorage = (
  training: boolean,
  product: string,
  buyRequestId: string,
  encryptionStatus: string,
  fileName: Maybe<string>
): IO<string> => 
  () => {
    const uuidv4New = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        c => {
          const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        }
      )
    }
    const time = new Date()
    const trainingStatus = training ? 'Training' : 'Productivos'
    return [
      "OfferFiles",
      product,
      trainingStatus,
      buyRequestId,
      encryptionStatus,
      fileName ? `${time.toISOString()}-${fileName}` : uuidv4New()
    ].join("/")
  }



export const useCompanyName = (companyId: string) => {
  const [company, loading, error] = useCompany(companyId)
  return company && company.businessName
}

/*
export function encrypt(data: Uint8Array, key: Uint8Array) {
    const aesjs = require('aes-js')
    const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter())
    return aesCtr.encrypt(data)
}
*/

// (file, fileName, position, rsaKey, fileType, product, buyRequestId, processId, docId)
// (offer.encryptedXls.link, offer.encryptedXls.name,null,'xls')


export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
};


export const changeRoundTime = () => alert('Cambio de horario')
