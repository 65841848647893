import { IO } from 'functional/lib/IO'
import { Unit } from 'functional/lib/core'
import { matchEnumPartial } from 'functional/lib/match'
import { Button, Col, Form, FormSelect, Row } from "react-bootstrap"
import { BooleanCheckEditor } from "../components/editors/boolean"
import { FlexCol, FlexRow } from '../components/Flexbox'
import { BuyRequestDraft, Product } from "../model/Model"
import { useFormState } from '../functional/react/form/state'
import { Validator } from '../functional/react/form/Validated'
import { FormReactContext } from '../functional/react/form/FormContext'
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { GasBuyType } from '../model/buyRequest/GasBuyRequest'

export const BuyRequestCreateTermsStep = (
  props: {
    request: BuyRequestDraft
    accepted?: boolean
    onBack: IO<Unit>
    onNext: IO<Unit>
  }
) => {

  const form = useFormState({
    initialDraft: () => props.accepted ?? false,
    validator: Validator.predicate(it => it, "terms", "Debes aceptar los términos y condiciones")
  })

  const draft = props.request

  const isGas = props.request.type == 'gas'
  const isElectricity = props.request.type == 'electricity'

  return <FormReactContext.Provider
    value={form}
  > 
    <FlexCol
      alignItems='stretch'
    >

      {draft.type === "electricity" && draft.buyType?.type && <><Row className="justify-content-center">
        <ElectricityTermsAndConditions 
          subProduct={draft.buyType.type} 
        />
      </Row>
        <br />
        <Row className="justify-content-center">
          <Col xs='10'>
            <Form>
              <Form.Group>
                <BooleanCheckEditor
                  fieldId='terms'
                  type="checkbox"
                  label="En mi carácter de Usuario Comprador Autorizado declaro conocer y aceptar
              la totalidad de los Términos y Condiciones Particulares de este Pedido los cuales
              serán comunicados a los Usuarios Vendedores Autorizados para su aceptación como
              condición previa y necesaria al envío de su Oferta."
                  state={form.state}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row></>}

      {isElectricity && !props.request?.buyType?.type && <>
      <br />
      <br />
      <br />
      <br />
      <Row className="justify-content-center text-warning">
        <h6>Seleccioná un <b>Tipo de Compra</b> en los "Parámetros del Pedido"</h6>
      </Row>
      <br />
      <br />
      <br />
      <br />
      </>}
      
      {draft.type === "gas" && <>
      <Row className="justify-content-center">
        <GasTermsAndConditions 
          type={draft.buyType.type}
        />
      </Row>
        <br />
        <Row className="justify-content-center">
          <Col xs='10'>
            <Form>
              <Form.Group>
                <BooleanCheckEditor
                  fieldId='terms'
                  type="checkbox"
                  label="En mi carácter de Usuario Comprador Autorizado declaro conocer y aceptar
                        la totalidad de los Términos y Condiciones Particulares de este Pedido los cuales
                        serán comunicados a los Usuarios Vendedores Autorizados para su aceptación como
                        condición previa y necesaria al envío de su Oferta."
                  state={form.state}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr />
        {/*<Row className="justify-content-center">*/}
        {/*    <CommissionPDF/>*/}
        {/*    <br/>*/}
        {/*</Row>*/}
        <br />
        {/*<Row className="justify-content-center">*/}
        {/*    {company ?*/}
        {/*        <Row*/}
        {/*            //@ts-ignore*/}
        {/*            xs='10'*/}
        {/*        >Empresa Compradora: {company.businessName}</Row> :*/}
        {/*        <Row className='text-danger'>Seleccioná una Empresa en la pantalla de Condiciones de Pedido para ver la Comisón aplicable</Row>}*/}
        {/*</Row>*/}
        {/*{company && company.discount && !(company.discount.factor === 1) && <Row className="justify-content-center">*/}
        {/*        <Col sm={12} className='text-center'><br/>*/}
        {/*        Comisión Base: {company.discount ? ((1 - company.discount.factor) * 1).toFixed(2) : 1} % <br/>*/}
        {/*        Comisión Mínima: {company.discount ? ((1 - company.discount.min) * 600).toFixed(0) : 600} USD <br/>*/}
        {/*        Comisión Máxima: {company.discount ? ((1 - company.discount.max) * 40000).toFixed(0) : 40000} USD <br/>*/}
        {/*        </Col>*/}
        {/*    </Row>}*/}
        {/*{hasDiscount && !(company.discount.factor === 1)&& !(company.discount.factor === 0) &&*/}
        {/*    <Row className="justify-content-center">*/}
        {/*        <h6><p className='text-success'>Estas comisiones son netas de los descuentos vigentes</p></h6>*/}
        {/*    </Row>}*/}

        {/*<Row className='justify-content-center'>*/}
        {/*    {hasDiscount && company.discount.factor === 1 ? <p className='text-success'>Pedido de Compra sin comisión</p> :*/}
        {/*        <Form>*/}
        {/*            <Form.Group className="text-center">*/}
        {/*                <br/>*/}
        {/*                <Form.Control*/}
        {/*                    value={props.draft.feeType ? props.draft.feeType : ""} as="select"*/}
        {/*                    onChange={(event) => props.modifyDraft({*/}
        {/*                        feeType: (event.target as any).value,*/}
        {/*                        discount: hasDiscount ? company.discount : null*/}
        {/*                    })}*/}
        {/*                >*/}
        {/*                    <option value="">Definir el responsable del pago de la Comisión</option>*/}
        {/*                    <option value="seller">100% a cargo del Vendedor</option>*/}
        {/*                    <option value="buyer">100% a cargo del Comprador</option>*/}
        {/*                    <option value="split">50% a cargo del Comprador y 50% a cargo del Vendedor</option>*/}
        {/*                </Form.Control>*/}
        {/*            </Form.Group>*/}
        {/*        </Form>}*/}
        {/*</Row>*/}
        {/*<Row className="justify-content-center">*/}
        {/*    <Col xs='10'>*/}
        {/*        <Form>*/}
        {/*            <Form.Group>*/}
        {/*                <CheckInput*/}
        {/*                    type="checkbox"*/}
        {/*                    label="En mi carácter de Usuario Comprador Autorizado declaro conocer y aceptar en su totalidad*/}
        {/*                                la Política General de Comisión para Pedidos de Compra de Gas Natural de Contecto Energía y, en caso de aplicar,*/}
        {/*                                los acuerdos particulares alcanzados entre la Empresa y Conecto Energía en relación a este Pedido."*/}
        {/*                    value={props.acceptedCommission}*/}
        {/*                    onValueChanged={value => props.setAcceptedCommission(value)}*/}
        {/*                />*/}
        {/*            </Form.Group>*/}
        {/*        </Form>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
      </>
      }


      <FlexRow
        justifyContent='space-between'
        padding={8}
      >

        <Button 
          size='lg'
          variant="light"
          onClick={props.onBack}
        >
          <FlexRow
            gap={8}
          >
            <ArrowBack/>
            Atras
          </FlexRow>
          
        </Button>

        <Button 
          size='lg'
          onClick={
            form.isValid ? 
              props.onNext : 
              form.showErrorsState.apply(() => true)
          }
        >
          <FlexRow
            gap={8}
          >
            Siguiente
            <ArrowForward/>
          </FlexRow>
        </Button>
      </FlexRow>

    </FlexCol>
  </FormReactContext.Provider>
}
{/*En caso de corresponder,*/ }
{/* autorizo la comunicación a los Usuarios Vendedores Autorizados de la Comisión neta aplicable a este Pedido para su aceptación*/ }
{/* como condición previa y necesaria al envío de su Oferta.*/ }

const GasTermsAndConditions = (
  props: {
    type: GasBuyType["type"]
  }
) => {

  const link =
    matchEnumPartial(props.type)({
      spot: "https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/TYC%20Particulares%2FTyC%20Particulares%20-%20Pedido%20de%20Compra%20de%20Gas%20Natural%20SPOT%20(v1-05-2024).docx.pdf?alt=media&token=5656a022-8c61-4635-8686-ae44f0c8e3c7"
    }) ?? 
    "https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/TYC%20Particulares%2FTyC%20Particulares%20-%20Pedido%20de%20Compra%20de%20Gas%20Natural%20(v1-10-2023).docx.pdf?alt=media&token=1f451eb0-141b-49e8-9295-18d438b93c31&_gl=1*jhwffw*_ga*Mjk0NjQ0MTA3LjE2NzY2NDg4NTM.*_ga_CW55HF8NVT*MTY5NzAyODI1MS42NC4xLjE2OTcwMjgzNjcuNDEuMC4w"

  return <iframe
    src={link}
    width="70%"
    height="500px"
    frameBorder={0}
    marginWidth={0}
    marginHeight={0}
    scrolling="yes"
    style={{ 'border': '1px solid #CCC', 'borderWidth': '1px', 'marginBottom': '5px', 'maxWidth': '100%' }}
    allowFullScreen>
  </iframe>
}

// function CommissionPDF() {
//
//     return <iframe
//         src="https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/Poli%CC%81tica%20General%20de%20Comisio%CC%81n%20(Vigente%20al%2028%20de%20febrero%20de%202020).pdf?alt=media&token=a751207e-dcae-4bd5-8da9-665703514c31"
//         width="70%"
//         height="400px"
//         frameBorder={0}
//         marginWidth={0}
//         marginHeight={0}
//         scrolling="yes"
//         style={{'border': '1px solid #CCC', 'borderWidth': '1px', 'marginBottom': '5px', 'maxWidth': '100%'}}
//         allowFullScreen>
//     </iframe>
// }

const ElectricityTermsAndConditions = (
  props: {
    subProduct: Product
  }
) => {

  const link =
    matchEnumPartial(props.subProduct)({
      mater: "https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/TyC%20Particulares%20-%20Pedido%20de%20Compra%20MATER%20(v2024-04-04).pdf?alt=media&token=aaa6de61-c35f-4e8a-91d7-b07c03360ac2",
      thermal: "https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/Te%CC%81rminos%20y%20Condiciones%20Particulares%20-%20Servicio%20de%20Generacio%CC%81n%20%5By%20Vapor%5D%20Onsite%20-%20VF%2005-11-2020.pdf?alt=media&token=cfee2da4-e280-41ac-8a5e-3f80eefeb859",
      onsite: "https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/TYC%20Particulares%2F%20Proyecto%20Solar%20FV%20Onsite.pdf?alt=media&token=14afec94-40f7-41a3-92df-520d7db0ac10"
    }) ?? ""

  return <iframe
    src={link}
    width="70%"
    height="600px"
    frameBorder={0}
    marginWidth={0}
    marginHeight={0}
    scrolling="yes"
    style={{ 'border': '1px solid #CCC', 'borderWidth': '1px', 'marginBottom': '5px', 'maxWidth': '100%' }}
    allowFullScreen>
  </iframe>
}
