import { Unit, none, pipe } from "functional/lib/core"
import { AppCall, appCallUserCollection } from "../context/AppCall"
import { dbUserCollection } from "../hooks/ConectoHooks"
import { Id, Product } from "../model/Model"
import { User } from "../model/User"
import { matchEnumLazy } from "functional/lib/match"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"


export const userGet = 
  (id: Id): AppCall<Maybe<User>> =>
  AppCall.do(async _ => {
    const collection = await _(appCallUserCollection)
    const data = await collection.doc(id).get()
    return data.exists ? data.data() : none
  })

export const userList = 
  (
    filters: {
      companyIds?: List<Id>
      products?: List<string>
    }
  ): AppCall<List<User>> =>
  AppCall.do(async _ => {
    const collection = await _(appCallUserCollection)
    const data = await pipe(collection)(
      it => filters.companyIds !== none ? it.where("companyIdList", "array-contains-any", filters.companyIds) : it,
      it => filters.products !== none ? it.where("productList", "array-contains-any", filters.products) : it
    ).get()
    return pipe(data.docs)(
      List.lift(it => it.data()),
      List.filterNotNone
    )
  })

export const userDataUpdate = (
  args: {
    userId: Id
    newData: Partial<User>
  }
): AppCall<Unit> =>
  AppCall.do(async _ => {
    try {
      await dbUserCollection()
      .doc(args.userId)
      .update(args.newData)
    } catch (error) {
      console.log("Data could not be saved." + error)
    }
  })
  

export const userProductsUpdate = (
  args: {
    user: User
    operation: "add" | "remove"
    authorize: boolean
    product: Product
    desired: boolean
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {
    const productList = (
      args.desired ? args.user?.desiredProductsList : 
      args.authorize ? args.user?.authorizedProductList : 
      args.user?.productList
    ) ?? []

    const newProductList = matchEnumLazy(args.operation)({
      add: () => [...productList, args.product],
      remove: () => productList.filter(it => it !== args.product)
    })

    const modify = {
      ...args.user,
      ...(
        args.desired ? { desiredProductsList: newProductList } : 
        args.authorize ? { authorizedProductList: newProductList } : 
        { productList: newProductList }
      )
    }
    
    console.log(modify)

    await dbUserCollection()
      .doc(args.user.id)
      .update(modify)
  })