import { Maybe } from "functional/lib/Maybe"
import { match, matchEnumPartial, matchPartial } from "functional/lib/match"
import { BuyRequestFeeType, FeeConditions } from "../model/buyRequest/BuyRequestBase"
import { defaultFeeConditions } from "../config"
import { GasBuyRequest, GasBuyTypeAnnual, GasBuyTypeSpot } from "../model/buyRequest/GasBuyRequest"
import { AnnualOffer, MonthlyOffer, Offer, SpotOffer } from "../model/Bid"
import { Draft } from "../model/utils"
import { isWinterMonth, sum } from "../Utilities"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { none } from "functional/lib/core"

export const coerce = (x: number, min: number, max: number): number =>
  Math.min(Math.max(x, min), max)


export const computeSellerFeeFactor = (feetype: Maybe<BuyRequestFeeType>): number =>
  matchEnumPartial(feetype ?? "_")({
    seller: 1.0,
    split: 0.5,
  }) ?? 0.0

export const computeContractValueAndFee = (
  buyRequest: Maybe<BuyRequest>,
  offer: Maybe<Offer>
) => {
  const contractValue = computeContractValue(buyRequest, offer)
  const fee = Maybe.map(contractValue)(it => computeFee(buyRequest?.feeConditions, it))
  return {
    contractValue: contractValue,
    fee: fee
  }
}

export const computeContractValue = (
  buyRequest: Maybe<BuyRequest>,
  offer: Maybe<Offer>
) =>
  match(buyRequest ?? { type: "_" } as const)({
    gas: request =>
      matchPartial(offer ?? { type: "_" } as const)({
        annual: offer => computeAnnualContractValue(request, offer),
        monthly: offer => computeMonthlyContractValue(request, offer),
        spot: offer => computeSpotContractValue(request, offer),
      }),
    electricity: request => none,
    _: () => none
  })

export const computeFee = (
  feeConditions: Maybe<FeeConditions>,
  cost: number // USD
): number => {

  const conditions = feeConditions ?? defaultFeeConditions

  return coerce(
    cost * conditions.factor,
    conditions.min,
    conditions.max
  )
}

const btuOverKjkcal = 252.164

export const computeAnnualFee = (
  buyRequest: GasBuyRequest,
  offer: Draft<AnnualOffer>
): Maybe<number> =>
  computeFee(buyRequest.feeConditions, computeAnnualContractValue(buyRequest, offer))



export const computeAnnualContractValue = ( //ANUAL REVISAR
  buyRequest: GasBuyRequest,
  offer: Draft<AnnualOffer>
): number => {

  const winterPrice = offer.winterPrice ?? 0
  const summerPrice = offer.summerPrice ?? 0

  const buyType = buyRequest.buyType

  if (buyType.type !== "annual") return 0

  const startMonthIndex = buyRequest.supplyStartDate.getMonth() // 5

  const demandCurve = buyType.demandCurve?.map(it => it ?? 0) ?? [0]

  const monthlyCosts = demandCurve.map((demand, position) => {
    const monthOfYear = (startMonthIndex + position) % 12
    return demand * buyRequest.quality / btuOverKjkcal * (isWinterMonth(monthOfYear) ? winterPrice : summerPrice)
  })

  return sum(monthlyCosts)
}

export const computeMonthlyFee = (
  buyRequest: GasBuyRequest,
  offer: Draft<MonthlyOffer>
): number =>
  computeFee(buyRequest.feeConditions, computeMonthlyContractValue(buyRequest, offer))


export const computeMonthlyContractValue = ( //MENSUAL REVISAR
  buyRequest: GasBuyRequest,
  offer: Draft<MonthlyOffer>
): number => {

  const monthlyCost =
    offer.monthlyCurve?.map((demand, index) =>
      (demand ?? 0) * buyRequest.quality / btuOverKjkcal * (offer.priceCurve[index] ?? 0)
    ) ?? [0]

  return sum(monthlyCost)
}

export const computeSpotFee = (
  buyRequest: GasBuyRequest,
  offer: Draft<SpotOffer>
) =>
  computeFee(buyRequest.feeConditions, computeSpotContractValue(buyRequest, offer))

export const computeSpotContractValue = (//SPOT REVISAR
  buyRequest: GasBuyRequest,
  offer: Draft<SpotOffer>
): number => {

  const buyType = buyRequest.buyType as GasBuyTypeSpot

  const totalCost = buyType.amount * buyRequest.quality / btuOverKjkcal * (offer.price ?? 0)

  return totalCost
}

