import MaterialTable, { MaterialTableProps } from "material-table"
import { Bid } from "../../../model/Bid"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { useNavigate } from "react-router"
import { none } from "functional/lib/core"
import { shallowCopy } from "../../../utils/utils"
import { IdView, buildColumns } from "../components"
import { CompanyView } from "../detail-components"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { Id } from "../../../model/Model"
import { appPaths } from "../../navigation"



export const BidTable = (
  props: Omit<MaterialTableProps<Bid>, "data"> & {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    data: Maybe<List<Bid>>
  }
) => {

  const navigate = useNavigate()

  return <MaterialTable
    title={`Total: ${props.data?.length ?? "-"}`}
    style={{
      borderRadius: 16,
      overflow: "hidden"
    }}
    onRowClick={(event, data) => {
      const id = data?.id
      if (id !== none) {
        navigate(
          appPaths
              .buyRequest(props.buyRequest.type, props.buyRequest.id)
              .process(props.processId)
              .bid(id)
        )
      }
    }}
    {...props}
    data={(props.data ?? []) as Bid[]}
    options={{ 
      exportButton: true, 
      exportAllData: true,
      columnResizable: true,
      sorting: true,
      ...props.options,
      //grouping: true,
      //filtering: true,
    }}
    columns={props.columns.map(shallowCopy)}
  />
}

export const bidColumns = buildColumns<Bid>()({
  id: { 
    title: "Id", 
    customFilterAndSearch: (term, data) => data.id?.includes(term) ?? false,
    render: data => 
      <IdView
        id={data.id} 
      />,
  },

  date: {
    title: "Fecha",
    field: "date",
    type: "datetime",
    defaultSort: "asc",
  },

  encrypted: {
    title: "Encriptado",
    render: data => data.encrypted ? "Sí" : "No",
  },

  company: {
    title: "Empresa",
    render: data => 
      data.encrypted ? "Encriptado" :
      <CompanyView id={data.sellerCompanyId} />
  },

})

const columns = bidColumns
