import { Button, Spinner } from "react-bootstrap"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { RState, useRState } from "../../../functional/react/RState"
import { Company } from "../../../model/Company"
import { CallError, ConectoDialog, ConectoGrid, DeleteIcon, EditIcon, ExtraActions, Field, FieldText, NewIcon, SubmitButton, SupplyPointIcon } from "../detail-components"
import { useSupplyPointList } from "../../../hooks/ConectoHooks"
import MaterialTable from "material-table"
import { Address, Id, SupplyPoint } from "../../../model/Model"
import { IdView, ProductListView } from "../components"
import { IO } from "functional/lib/IO"
import { Unit } from "functional/lib/core"
import { Draft } from "../../../model/utils"
import { StringEditor } from "../../../components/editors/string"
import { BooleanSelectEditor } from "../../../components/editors/boolean"
import { ProductEditor, ProductListEditor } from "../../../components/editors/select"
import { AppCall, useAppAsynchronism } from "../../../context/AppCall"
import { supplyPointCreate, supplyPointDelete, supplyPointUpdate } from "../../../client/company"
import { useEffect } from "react"
import { matchEnum } from "functional/lib/match"
import { showIf } from "functional/lib/Maybe"
import { IconButton } from "@material-ui/core"


export const SupplyPointNewButton = (
  props: {
    companyId: Id
  }
) => {

  const openDialog = useRState(() => false)

  return <FlexRow>
    <Button
      variant="success"
      onClick={openDialog.apply(() => true)}
    >
      <FlexRow
        gap={8}
        alignItems="center"
      >
        <NewIcon />
        Nuevo Punto de Suministro
      </FlexRow>

    </Button>
    <ConectoDialog
      style={{
        maxWidth: 1000
      }}
      open={openDialog}
      title="Nuevo Punto de Suministro"
    >
      <hr/>
      <SupplyPointForm  
        companyId={props.companyId}
        onBack={openDialog.apply(() => false)}
      />
    </ConectoDialog>
  </FlexRow>
}


const defaultSupplyPoint: SupplyPoint = {
  id: "",
  name: "",
  shortName: "",
  address: {
    street: "",
    locality: "",
    province: ""
  },
  category: "",
  distributor: {
    name: "",
    identifier: ""
  },
  multiple: false,
  nemo: "",
  type: "",
  product: "gas",
}

const SupplyPointForm = (
  props: {
    companyId: string
    initial?: SupplyPoint
    onBack?: IO<Unit>
  }
) => {

  const mode = (props.initial?.id ?? "") === "" ? "create" : "edit"

  const call = useAppAsynchronism(
    (
      args: {
        companyId: Id
        data: SupplyPoint
      }
    ) => 
      AppCall.do(async _ => {
        const supplyPoint = await _(
          args.data.id === "" ?
            supplyPointCreate(args) :
            supplyPointUpdate(args)
        )
        props.onBack?.()
      })
  )

  const draft = useRState<SupplyPoint>(
    () => ({
      ...defaultSupplyPoint,
      ...props.initial
    })
  )

  const fields = RState.destructureAll(draft)

  return <FlexCol
    style={{
      alignSelf: "stretch"
    }}
    alignItems="stretch"
    gap={8}
  >

    <SupplyPointEditor
      state={draft}
    />

    <FlexRow
      gap={8}
      justifyContent="flex-end"
    >

      <Button
        onClick={props.onBack}
        variant="light"
      >
        Volver
      </Button>

      <SubmitButton
        call={call}
        value={{
          companyId: props.companyId,
          data: draft.value
        }}
        label={
          matchEnum(mode)({
            create: "Crear",
            edit: "Editar"
          })
        }
      />

    </FlexRow>

    <CallError
      call={call}
    />

  </FlexCol>
}

const SupplyPointEditor = (
  props: {
    state: RState<SupplyPoint>
  }
) => {

  const fields = RState.destructureAll(props.state)

  return <FlexCol
    alignItems="stretch"
  >
    <h4>Datos</h4>

    <ConectoGrid
      style={{
        width: "100%"
      }}
      defaults={{
        xs: 3
      }}
      cells={[
        <Field
          label="Nombre"
        >
          <StringEditor
            state={fields.name}
          />
        </Field>,
        <Field
          label="Nombre Corto"
        >
          <StringEditor
            state={fields.shortName}
          />
        </Field>,
        <Field
          label="Producto"
        >
          <ProductEditor
            state={fields.product}
          />
        </Field>,
        <Field
          label="Categoría"
        >
          <StringEditor
            state={fields.category}
          />
        </Field>,
        <Field
          label="Múltiple"
        >
          <BooleanSelectEditor
            state={fields.multiple}
          />
        </Field>,
        <Field
          label="Tipo"
        >
          <StringEditor
            state={RState.nonNone(fields.type)}
          />
        </Field>,
        <Field
          label="NEMO"
        >
          <StringEditor
            state={RState.nonNone(fields.nemo)}
          />
        </Field>,
      ]}
    />

    <hr/>

    <h4>Dirección</h4>

    <AddressEditor
      state={fields.address}
    />

    <hr/>

    <h4>Distribuidora</h4>

    <DistributorEditor
      state={fields.distributor}
    />

  </FlexCol>

}


const AddressEditor = (
  props: {
    state: RState<Address>
  }
) => {

  const fields = RState.destructureAll(props.state)

  return <ConectoGrid
    defaults={{ xs: 3 }}
    cells={[
      <Field
        label="Calle"
      >
        <StringEditor
          state={fields.street}
        />
      </Field>,
      <Field
        label="Localidad"
      >
        <StringEditor
          state={fields.locality}
        />
      </Field>,
      <Field
        label="Provincia"
      >
        <StringEditor
          state={fields.province}
        />
      </Field>
    ]}
  />
}


const DistributorEditor = (
  props: {
    state: RState<{ name: string, identifier: string }>
  }
) => {

  const fields = RState.destructureAll(props.state)
  return <ConectoGrid
    defaults={{ xs: 3 }}
    cells={[
      <Field
        label="Nombre"
      >
        <StringEditor
          state={fields.name}
        />
      </Field>,
      <Field
        label="Nro. Cliente"
      >
        <StringEditor
          state={fields.identifier}
        />
      </Field>
    ]}
  />
}


export const SupplyPointTable = (
  props: {
    data: Company
  }
) => {

  const [list, loading, error] = useSupplyPointList(props.data.id)

  return <MaterialTable
    style={{
      borderRadius: 16,
      overflow: "hidden",
      flexGrow: 1
    }}
    title={`Total ${list?.length ?? ""}`}
    options={{
      exportButton: true,
      exportAllData: true,
      columnResizable: true,
      sorting: true,
      //grouping: true,
      //filtering: true,
    }}
    data={(list ?? []) as SupplyPoint[]}
    columns={[
      { 
        title: "Id",
        render: data => <IdView id={data.id}/>
      },
      { title: "Nombre", field: "name" },
      {
        title: "Producto",
        render: data => <ProductListView products={[data.product]} />
      },
      { title: "Nombre Corto", field: "shortName" },

      { 
        title: "Dirección", 
        render: data => [data.address.street, data.address.locality, data.address.province].join(", ")
      },

      { title: "Categoría", field: "category" },
      { title: "Distribuidora", field: "distributor.name" },
      { title: "Nro. Cliente", field: "distributor.identifier" },
      { title: "NEMO", field: "nemo" },
      {
        title: "Acciones",
        render: data =>
          <FlexRow
            justifyContent="flex-start"
          >
            <EditButton
                companyId={props.data.id}
                supplyPoint={data}
              />
            <DeleteButton
              companyId={props.data.id}
              supplyPoint={data}
            />
          </FlexRow>
      }
    ]}
  />
}

const EditButton = (
  props: {
    companyId: Id
    supplyPoint: SupplyPoint
  }
) => {

  const open = useRState(() => false)

  return <FlexRow>
    <IconButton
      onClick={open.apply(() => true)}
    >
      <EditIcon/>
    </IconButton>
    <ConectoDialog
      style={{
        maxWidth: 1000
      }}
      open={open}
      title="Editar Punto de Suministro"
    >
      <SupplyPointForm
        companyId={props.companyId}
        initial={props.supplyPoint}
        onBack={open.apply(() => false)}
      />
    </ConectoDialog>
  </FlexRow>
}


const DeleteButton = (
  props: {
    companyId: Id
    supplyPoint: SupplyPoint
  }
) => {

  const open = useRState(() => false)

  const call = useAppAsynchronism(supplyPointDelete)

  useEffect(
    call.state.type === "success" ?
      open.apply(() => false) :
      IO.noOp
  )

  return <FlexRow>

    <IconButton
      onClick={open.apply(() => true)}
    >
      <DeleteIcon/>
    </IconButton>

    <ConectoDialog
      open={open}
      title="Eliminar Punto de Suministro"
    >
      <FlexCol
        paddingY={8}
        alignItems="stretch"
        gap={16}
      >
        <FlexRow>
          <FieldText
            label="Id"
            value={props.supplyPoint.id}
          />
          <FieldText
            label="Nombre"
            value={props.supplyPoint.name}
          />
        </FlexRow>

        <FlexRow>¿Está seguro que desea eliminar este punto de suministro?</FlexRow>

        <FlexRow>Esta acción no se puede deshacer.</FlexRow>

        <FlexRow
          padding={16}
          gap={8}
        >
          <Button
            variant="light"
            onClick={open.apply(() => false)}
          >
            Volver
          </Button>
          <SubmitButton
            call={call}
            value={{
              companyId: props.companyId,
              id: props.supplyPoint.id
            }}
            variant="danger"
            label="Eliminar"
          />
        </FlexRow>
        <CallError call={call}/>

      </FlexCol>

      
    </ConectoDialog>

  </FlexRow>
}
