import React from "react"
// import CardStyle from "../styles/CardStyle";
import TableauReport from 'tableau-react'


const options = {
  width: $(window).width(),
  height: $(window).height(),
  hideTabs: false,
  // All other vizCreate options are supported here, too
  // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
}

const filters = {
  Colors: ['Blue', 'Red'],
  Sizes: ['Small', 'Medium']
}

const parameters = {
  Param1: 'Value',
  Param2: 'Other Value'
}

export const GraphCard = () => {
  return <TableauReport
    url={'https://public.tableau.com/views/SntesisdepreciosdegasnaturalparaproductoresdomsticosenArgentina/Sntesisdeprecios'}
    filters={filters}
    parameters={parameters}
    options={options} // vizCreate options
    // Overwrite default query params
    // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
    query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes"
  />
}

//
// export default function GraphCard({ dashboard }) {
//     const ref = useRef(null)
//
//     useEffect(() => {
//         const viz = new tableau.Viz(ref.current, `${dashboard}`, {
//             hideTabs: true,
//             hideToolbar: true,
//             width: $(window).width(),
//             height: $(window).height()
//         })
//
//         return viz;
//     })
//
//     return <div ref={ref} />
// }

//
// export default function GraphCard({ dashboard }) {
//     const ref = useRef(null)
//
//     useEffect(() => {
//         const viz = new tableau.Viz(ref.current, `${dashboard}`, {
//             hideTabs: true,
//             hideToolbar: true,
//             height: "862px",
//         })
//         return viz;
//     })
//
//     return (<CardStyle>
//             <div ref={ref} />
//     </CardStyle>
//
//     )
// }