import { List } from "functional/lib/List"
import { RState, useRState } from "../../functional/react/RState"
import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { FlexCol, FlexRow } from "../Flexbox"
import { Menu, MenuItem } from "@material-ui/core"
import { Id, Product } from "../../model/Model"
import { ProductView } from "../../pages/admin/components"
import { Company } from "../../model/Company"
import { dbCompanyCollection, useCompany, useEntityList, useRealCompanyList } from "../../hooks/ConectoHooks"
import { Skeleton } from "@material-ui/lab"
import { ArrowDropDown } from "@material-ui/icons"


export const SelectEditor = <T,>(
  props: {
    state?: RState<T>
    options: List<T>
    render: (item: T) => React.ReactNode
  }
) => {

  const anchorElement = useRState<Maybe<Element>>(() => none)

  const open = anchorElement.value !== none

  return <FlexCol>

    <FlexRow
      style={{
        cursor: "pointer",
        borderRadius: 6,
        background: "white",
        padding: 8,
        border: "1px solid lightgray"
      }}
      gap={4}
      onClickWithEvent={event =>
        anchorElement.apply(() => event.currentTarget)
      }
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="center"
    >
      {Maybe.map(props.state?.value)(it => props.render(it))}
      <ArrowDropDown/>
    </FlexRow>
    
    <Menu
      open={open}
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorElement.value}
      onClose={anchorElement.apply(() => none)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      MenuListProps={{
        style: { 
          padding: 0,
          maxHeight: 400, 
        }
      }}
    > 
      {
        props.options.map((it, i) =>
          <MenuItem
            style={{
              background: props.state?.value == it ? "lightgray" : "white"
            }}
            key={i}
            onClick={() => {
              props.state?.apply(() => it)?.()
              anchorElement.apply(() => none)()
            }}
          >
            {props.render(it)}
          </MenuItem>
        )
      }
    </Menu>
    
  </FlexCol>
}

export const MultiSelectEditor = <T,>(
  props: {
    state?: RState<List<T>>
    options: List<T>
    render: (item: T) => React.ReactNode
    emptyLabel?: string
  }
) => {

  const anchorElement = useRState<Maybe<Element>>(() => none)

  const open = anchorElement.value !== none

  const toggle = (element: T) => (list: List<T>) =>
      list.includes(element) ?
        list.filter(it => it !== element) :
        props.options.filter(it => list.includes(it) || it == element)

  return <FlexCol>

    <FlexRow
      style={{
        cursor: "pointer",
        borderRadius: 6,
        background: "white",
        padding: 8,
        border: "1px solid lightgray"
      }}
      gap={4}
      onClickWithEvent={event =>
        anchorElement.apply(() => event.currentTarget)
      }
      
    >
      <FlexRow
        flexWrap="wrap"
        justifyContent="flex-start"
        gap={4}
      >
        {
          showIf(props.state?.value?.length === 0)(
            <div
              style={{
                color: "gray"
              }}
            >
              {props.emptyLabel ?? "Vacío"}
            </div>
          )
        }
        {
          props.state?.value?.map((it, i) => 
            <FlexCol
              key={i}
            >
              {props.render(it)}
            </FlexCol>
          )
        }
      </FlexRow>
      <ArrowDropDown/>
    </FlexRow>
    
    <Menu
      open={open}
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorElement.value}
      onClose={anchorElement.apply(() => none)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      MenuListProps={{
        style: { 
          padding: 0,
          maxHeight: 400, 
        }
      }}
    > 
      {
        props.options.map((it, i) =>
          <MenuItem
            style={{
              background: props.state?.value?.includes(it) ? "lightgray" : "white"
            }}
            key={i}
            onClick={props.state?.apply(toggle(it))}
          >
            {props.render(it)}
          </MenuItem>
        )
      }
    </Menu>
    
  </FlexCol>
}


export const ProductEditor = (
  props: {
    state?: RState<Product>
  }
) =>
  <SelectEditor<Product>
    state={props.state}
    options={["gas", "electricity", "liquid", "mater", "onsite", "surplus", "thermal"]}
    render={product => 
      <ProductView product={product}/>
    }
  />

export const ProductListEditor = (
  props: {
    state?: RState<List<Product>>
  }
) =>
  <MultiSelectEditor<Product>
    state={props.state}
    emptyLabel="Seleccionar Productos"
    options={["gas", "electricity", "liquid", "mater", "onsite", "surplus", "thermal"]}
    render={product => 
      <ProductView product={product}/>
    }
  />

export const CompanyListEditor = (
  props: {
    state: RState<List<Id>>
  }
) => {

  const [companyList, loading, error] = useEntityList<Company>(dbCompanyCollection().orderBy("displayName"))

  return (
    Maybe.match(companyList)({
      none: () => <Skeleton width={60}/>,
      some: list => <CompanyListEditorStatic state={props.state} options={list.map(it => it.id)}/>
    })
  )
}

export const CompanyListEditorStatic = (
  props: {
    state: RState<List<Id>>
    options: List<Id>
  }
) => {
  return <MultiSelectEditor<Id>
    state={props.state}
    emptyLabel="Seleccionar Empresas"
    options={props.options}
    render={id => 
      <CompanyItemView id={id}/>
    }
  />
}

const CompanyItemView = (
  props: {
    id: Id
  }
) => {
  
    const [company, loading, error] = useCompany(props.id)
  
    return <FlexRow
      style={{
        borderRadius: 8,
        background: "#EEEEEE",
        padding: 8
      }}
      gap={4}
    >
      {
        loading ? <Skeleton width={100}/> :
        <>
          <img
            style={{
              height: 24,
            }}
            src={company?.imageUrl}
          />        
          {company?.displayName ?? company?.businessName ?? "-"}
        </>
      }
      
    </FlexRow>
}
  