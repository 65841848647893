import { Async } from "./Async"
import { List } from "./List"

export type ContextAsync<C, T> = (ctx: C) => Async<T>

export const ContextAsync = <C>() => ({

  pure: <T>(value: T): ContextAsync<C, T> => () => Async.pure(value),

  map: <I>(value: ContextAsync<C, I>) => <O>(f: (value: I) => O): ContextAsync<C, O> => 
    ctx => Async.map(value(ctx))(f),

  lift: <I, O>(f: (value: I) => O) => (value: ContextAsync<C, I>): ContextAsync<C, O> =>
    ContextAsync<C>().map(value)(f),

  bind: <I>(value: ContextAsync<C, I>) => <O>(f: (value: I) => ContextAsync<C, O>): ContextAsync<C, O> => 
    ctx => 
      async () => {
        const result = await value(ctx)()
        return await f(result)(ctx)()
      },

  forEach: 
    <I>(values: List<I>) => 
    <O>(f: (value: I, position: number) => ContextAsync<C, O>): ContextAsync<C, List<O>> =>
    ctx => Async.forEach(values)((value, position) => f(value, position)(ctx)),
    

  do: <R>(
    body: (_: <T>(value: ContextAsync<C, T>) => Promise<T>) => Promise<R>
  ): ContextAsync<C, R> => 
    ctx => 
      async () => {
        return await body(value => value(ctx)())
      }

})
