import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/WarningRounded'
import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { Alert, Card, Col, Form, OverlayTrigger, Row } from "react-bootstrap"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { CellCol, FlexCol, FlexRow } from "../../components/Flexbox"
import { RState } from "../../functional/react/RState"
import { FormReactContext } from "../../functional/react/form/FormContext"
import { FormErrors } from "../../functional/react/form/FormErrors"
import { Validator } from "../../functional/react/form/Validated"
import { SpotOffer } from "../../model/Bid"
import { Process } from "../../model/Process"
import { GasBuyRequest } from "../../model/buyRequest/GasBuyRequest"
import { Draft } from "../../model/utils"
import { BidFormFooter, bidFormIds, useBidFormFeatures } from "../GenericBidForm"
import {
  DifferentPayTerm,
  RenderTooltipPriceOutOfRangeSpotAuction,
  RenderTooltipPriceOutOfRangeSpotTender
} from "../Utilities"
import { BidSuccess } from "../BidComponents"
import { IO } from "functional/lib/IO"
import { NumberEditor } from "../../components/editors/number"
import { computeSpotFee } from '../../utils/fee'

const fieldIds = {
  price: "price",
  amount: "amount",
  payTermDays: "payTermDays",
  tac: bidFormIds.tac
}

export const SpotBidForm = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
  }
) => {

  const {
    form,
    draft,
    acceptedTac,
    onSubmit,
    enableOffer,
    call
  } = useBidFormFeatures<SpotOffer>({
    buyRequest: props.buyRequest,
    process: props.process,

    validator: validateOffer(props),

    initialDraft: () => ({
      type: "spot",
      amount: none,
      payTermDays: props.process?.minimumPayTermDays ?? props.buyRequest.referencePayTermDays,
      price: none
    })
  })

  if (call.state.type === "success") {
    return <BidSuccess
      onNewOffer={
        IO.sequence([
          acceptedTac.apply(() => false),
          form.showErrorsState.apply(() => false),
          call.reset
        ]) 
      }
    />
  }

  return <Card>
      <Card.Header>
        <FlexRow>
          Ingresá tu Oferta
        </FlexRow>
      </Card.Header>

      <FormReactContext.Provider value={form}>

        <FlexCol
          alignItems="stretch"
          padding={16}
        >

          <SpotOfferEditor
            buyRequest={props.buyRequest}
            process={props.process}
            draft={draft}
            errors={form.errors}
          />

          <Row className='justify-content-center'>
            <Col xs={1} />
            <BooleanCheckEditor
              fieldId={fieldIds.tac}
              label="Acepto los Términos y Condiciones Particulares del Pedio de Compra"
              state={acceptedTac}
            />
            <Col xs={1} />
          </Row>

          <BidFormFooter
            process={props.process}
            call={call}
            enableOffer={enableOffer}
            errors={form.errors}
            onSubmit={onSubmit}
            showErrors={form.showErrors}
          />

        </FlexCol>

      </FormReactContext.Provider>

      
    </Card>
}

const validateOffer =
  (
    args: {
      buyRequest: GasBuyRequest
      process: Process
    }
  ) =>
  Validator.record<Draft<SpotOffer>, SpotOffer>({

    amount: 
      (args.buyRequest.acceptsLessVolume ?? false) ? 
        Validator.compose(
          Validator.notNone<number>(fieldIds.amount, "Por favor ingresá el volumen ofertado"),
          Validator.predicate(it => it > 0, fieldIds.amount, "El volumen ofertado debe ser mayor a 0")
        ) :
        Validator.id(),

    price: Validator.compose(
      Validator.notNone<number>(fieldIds.price, "Por favor ingresá el precio"),
      Validator.predicate(
        it => it > 0, 
        fieldIds.price, "El precio ofertado debe ser mayor a 0"
      ),
      Validator.predicate(
        it => it < (args.process.currentBestPrice ?? Number.MAX_VALUE), 
        fieldIds.price, "El precio ofertado debe ser menor al precio actual"
      ),
      Validator.predicate(
        it => it < (args.process.maximumPrice ?? Number.MAX_VALUE), 
        fieldIds.price, "El precio ofertado debe ser menor al precio máximo"
      ),
    ),

    payTermDays: Validator.compose(
      Validator.notNone<number>(fieldIds.payTermDays, "Por favor ingresá los días de plazo"),
      Validator.predicate(
        it => it > 0, 
        fieldIds.payTermDays, "El plazo debe ser mayor a 0"
      ),
      Validator.predicate(
        it => it >= (args.process.minimumPayTermDays ?? 0), 
        fieldIds.payTermDays, "El plazo debe ser mayor o igual al mínimo"
      )
    )

  })


const SpotOfferEditor = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    draft: RState<Draft<SpotOffer>>
    errors: FormErrors
  }
) => {

  const fields = RState.destructureAll(props.draft)

  const commission = computeSpotFee(props.buyRequest, props.draft.value)

  return <FlexCol
    alignItems="stretch"
  >

    <p><b>Precio</b></p>

    <PriceEditor
      buyRequest={props.buyRequest}
      process={props.process}
      price={fields.price}
      isValidPrice={(props.errors[fieldIds.price] ?? []).length === 0}
    />

    {props.process.type == "tender" && props.buyRequest.acceptsLessVolume ?
      <FlexRow>
        <Form.Label
          style={{ width: 0, flexGrow: 1 }}
        >
          Volumen Ofertado
        </Form.Label>
        <NumberEditor
          fieldId={fieldIds.amount}
          style={{ width: 0, flexGrow: 1 }}
          placeholder="0.00 dam3"
          state={fields.amount}
          min={0}
        />
      </FlexRow> : null
    }

    <Alert variant="info">
      <InfoIcon/> &nbsp; Comisión a pagar por el Vendedor adjudicado: <b>{commission?.toFixed(0) ?? "--"} USD </b>
    </Alert>

    <hr/>

    {
      showIf(props.process.type == "tender" && (props.process.acceptsDifferentPayTerm ?? false))(
        <>
          <DifferentPayTerm
            fieldId={fieldIds.payTermDays}
            buyRequest={props.buyRequest}
            process={props.process}
            payTermDays={fields.payTermDays}
          />
          <hr/>
        </>
      )
    }

    <br />


  </FlexCol>
}


const PriceWithUnits = (
  props: {
    usdByMmbtu: Maybe<number>
    usdByDam3: Maybe<number>
    processType: Process["type"]
    error?: boolean
  }
) =>
  <FlexCol
    alignItems='flex-start'
    style={{ paddingTop: 8 }}
  >

    <h6
      className={(props.error ?? false) ? "text-danger" : 'text-secondary'}
    >
      {props.usdByMmbtu?.toFixed(2) ?? "--"} USD/MMBTU
      {
        showIf(props.error ?? false)(
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={props.processType == 'auction' ? RenderTooltipPriceOutOfRangeSpotAuction : RenderTooltipPriceOutOfRangeSpotTender}
          >

              <InfoIcon
                fontSize="small"
                className={"text-danger"}
                style={{
                  marginLeft: 4,
                  cursor: "pointer"
                }}
              />
           
          </OverlayTrigger>
        )
      }
    </h6>
    
    <small>
      {props.usdByDam3?.toFixed(2) ?? "--"} USD/dam3
    </small>
  </FlexCol>



const PriceEditor = (
  props: {
    process: Process
    buyRequest: GasBuyRequest
    price: RState<Maybe<number>>
    isValidPrice: boolean
  }
) => {

  const price = props.price.value ?? 0

  const usdByMmbtuToUsdByDam = (usdByMmbtu: number) => usdByMmbtu * props.buyRequest.quality / 252

  const priceInUSDByDam = isNaN(props.buyRequest.quality) ? none :  price * props.buyRequest.quality / 252

  const priceColor = price ? (props.isValidPrice ? 'text-success' : "text-danger") : 'text-secondary'

  return <FlexRow
    justifyContent='center'
    paddingY={16}
    gap={16}
  >

    <CellCol/>

    <CellCol
      alignItems='flex-start'
      gap={8}
    >
      <FlexRow>Precio</FlexRow>
      <NumberEditor
        fieldId={fieldIds.price}
        min={0}
        step={0.01}
        decimalPlaces={2}
        placeholder="0.00 USD/MMBTU"
        state={props.price}
      />
      <small style={{ paddingLeft: 12 }}>
        {priceInUSDByDam?.toFixed(2) ?? "--"} USD/dam3
      </small>
    </CellCol>

    {
      Maybe.map(props.process.currentBestPrice)(best =>

        <CellCol
          alignItems='flex-start'
          gap={8}
        >
          <FlexRow>Mejor Oferta</FlexRow>
          <PriceWithUnits
            processType={props.process.type}
            usdByMmbtu={best}
            usdByDam3={usdByMmbtuToUsdByDam(best)}
          />
        </CellCol>
      ) ??
      Maybe.map(props.process.maximumPrice)(max =>
        <CellCol
          alignItems='flex-start'
          gap={8}
        >
          <FlexRow>Precio Máximo</FlexRow>
          <PriceWithUnits
            processType={props.process.type}
            usdByMmbtu={max}
            usdByDam3={usdByMmbtuToUsdByDam(max)}
          />
        </CellCol>
      )
    }

    <CellCol/>

  </FlexRow>

  return <FlexCol
    alignItems="stretch"
    style={{ padding: 16 }}
  >

    <FlexRow>
      <Form.Label
        style={{ width: 0, flexGrow: 1 }}
      >
        Precio
      </Form.Label>
      <NumberEditor
        fieldId={fieldIds.price}
        style={{ width: 0, flexGrow: 1 }}
        min={0}
        max={10}
        step={0.01}
        decimalPlaces={2}
        placeholder="0.00 USD/MMBTU"
        state={props.price}
      />
    </FlexRow>

    <FlexRow>
      <FlexCol 
        style={{ width: 0, flexGrow: 1 }}
      >
        {!price ? <small className={priceColor}>Por favor ingresá tu precio</small> : 
        (!props.isValidPrice ?
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={props.process.type == 'auction' ? RenderTooltipPriceOutOfRangeSpotAuction : RenderTooltipPriceOutOfRangeSpotTender}
          >
            <small className={priceColor}>Tu Oferta está fuera de rango. <InfoIcon /></small>
          </OverlayTrigger> : 
        <small className={priceColor}>Tu oferta está dentro del rango aceptado. ¡Podés enviarla!</small>)
        }
      </FlexCol>

      <small 
        style={{ width: 0, flexGrow: 1 }}
        className='text-secondary'
      >{`${priceInUSDByDam?.toFixed(2) ?? "--"} USD/dam3`}
      </small>

    </FlexRow>

  </FlexCol>
}
