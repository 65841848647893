import { Maybe } from "functional/lib/Maybe"
import { FlexCol } from "../../components/Flexbox"
import { BuyRequestDetail } from "../../requestdetail/BuyRequestDetail"
import { PageLoading, useScrollToTop } from "../admin/components"
import { useBuyRequest } from "../../hooks/ConectoHooks"
import { BuyRequestType } from "../../model/buyRequest/BuyRequest"
import { useParams } from "react-router"

export const MarketplaceBuyRequest = () => {

  const params = useParams()

  const type = params.type as BuyRequestType
  const id = params.id as string

  const [data, loading, error] = useBuyRequest(id, type)

  useScrollToTop()

  return Maybe.map(data)(it => 
    <BuyRequestDetail
      buyRequest={it}
    />
  ) ?? <PageLoading/>
}
