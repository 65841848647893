import { List } from "./List"
import { Unit } from "./core"

export type Async<T> = () => Promise<T>

export const Async = {

  noOp: (): Async<Unit> => async () => {},

  delayMillis: (millis: number): Async<Unit> => 
    () => new Promise(resolve => setTimeout(resolve, millis)),

  pure: <T>(value: T): Async<T> => async () => value,

  map: <I>(value: Async<I>) => <O>(f: (value: I) => O): Async<O> => 
    async () => f(await value()),

  lift: <I, O>(f: (value: I) => O) => (value: Async<I>): Async<O> => 
    Async.map(value)(f),

  bind: <I>(value: Async<I>) => <O>(f: (value: I) => Async<O>): Async<O> => 
    async () => await f(await value())(),

  sequence: <T>(values: List<Async<unknown>>): Async<Unit> => 
    async () => {
      for (const value of values) {
        await value()
      }
    },

  forEach: 
    <T>(values: List<T>) => 
    <O>(f: (value: T, position: number) => Async<O>): Async<List<O>> =>
    async () => {
      const result: O[] = []
      for (let i = 0; i < values.length; i++) {
        result.push(await f(values[i], i)())
      }
      return result
    },

  parallel: <T>(values: List<T>) => <O>(f: (value: T) => Async<O>): Async<List<O>> =>
    async () => {
      return await Promise.all(values.map(value => f(value)()))
    },

}
