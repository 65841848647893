import { StaticDecode, Type } from "@sinclair/typebox"
import { ListT, MaybeT } from "./utils"
import { TimestampT } from "./common"
import { ProductT } from "./Model"


export const UserRoleT = Type.Union([
  Type.Literal("buyer"),
  Type.Literal("seller"),
  Type.Literal("admin"),
  Type.Literal("consultant"),
  Type.Literal("waiting"),
])
export type UserRole = StaticDecode<typeof UserRoleT>

export const NotificationSettingsT = Type.Object({
  newRound: MaybeT(Type.Boolean()),
  newBuyRequest: MaybeT(Type.Boolean()),
  newConsult: MaybeT(Type.Boolean()),
  assigned: MaybeT(Type.Boolean())
})
export type NotificationSettings = StaticDecode<typeof NotificationSettingsT>

export const UserT = Type.Object({
  id: Type.String(),

  roleList: MaybeT(Type.Array(UserRoleT)),

  email: MaybeT(Type.String()),
  firstName: MaybeT(Type.String()),
  lastName: MaybeT(Type.String()),
  
  dni: MaybeT(Type.String()),
  phone: MaybeT(Type.String()),
  cellPhone: MaybeT(Type.String()),

  creationDate: MaybeT(TimestampT),

  companyIdList: MaybeT(ListT(Type.String())),
  
  validated: MaybeT(Type.Boolean()),
  training: MaybeT(Type.Boolean()),
  // authorized: Type.Boolean(),
  authorizedProductList: MaybeT(Type.Array(Type.String())),

  productList: MaybeT(ListT(ProductT)),
  standBy: MaybeT(Type.Boolean()),
  mockUser: MaybeT(Type.Boolean()),
  token: Type.Optional(Type.String()),

  desiredRole: MaybeT(Type.String()),
  desiredCompany: MaybeT(Type.String()),
  desiredProductsList: MaybeT(Type.Array(Type.String())),

  desiredBusinessName: MaybeT(Type.String()),

  desiredCompanyData: MaybeT(
    Type.Object({

      desiredProductsList: MaybeT(Type.Array(Type.String())),

      logo: MaybeT(
        Type.Object({
          link: Type.String(),
          name: Type.String()
        })
      ),

      cuitFile: MaybeT(
        Type.Object({
          link: Type.String(),
          name: Type.String()
        })
      ),

      businessName: MaybeT(Type.String()),
      cuit: MaybeT(Type.Optional(Type.String())),
      role: MaybeT(Type.Optional(Type.String()))
    })
  ),

  desiredCompaniesId: MaybeT(ListT(Type.String())),


  notificationSettings: MaybeT(NotificationSettingsT),
  mailSettings: MaybeT(NotificationSettingsT),

})

export type User = StaticDecode<typeof UserT>

