import { Unit } from "functional/lib/core"
import { AppCall } from "../context/AppCall"
import { User, NotificationSettings } from "../model/User"
import { dbUserCollection } from "../hooks/ConectoHooks"



export const notificationMailSet = (
  args: {
    user: User
    value: boolean
    notification: string
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {

    const changes: Partial<NotificationSettings> = 
      args.notification === 'newRound' ? { newRound: args.value } :
      args.notification === 'newBuyRequest' ? { newBuyRequest: args.value } :
      args.notification === 'newConsult' ? { newConsult: args.value } :
      args.notification === 'assigned' ? { assigned: args.value } : {}

    const modify = { ...args.user, mailSettings: { ...args.user.mailSettings, ...changes } }

    await dbUserCollection()
      .doc(args.user.id)
      .update(modify)
  })

export const notificationSet = (
  args: {
    user: User
    value: boolean
    notification: string
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {

    const changes: Partial<NotificationSettings> =
      args.notification === 'newRound' ? { newRound: args.value } :
      args.notification === 'newBuyRequest' ? { newBuyRequest: args.value } :
      args.notification === 'newConsult' ? { newConsult: args.value } :
      args.notification === 'assigned' ? { assigned: args.value } : {}

    await dbUserCollection()
      .doc(args.user.id)
      .update({ 
        ...args.user, 
        notificationSettings: { 
          ...args.user.notificationSettings, 
          ...changes 
        } 
      })
  })
