import { Maybe } from "functional/lib/Maybe"
import { RState } from "../../functional/react/RState"
import { Form } from "react-bootstrap"
import { none } from "functional/lib/core"
import { List } from "functional/lib/List"
import { useFieldErrors, useFormErrors } from "../../functional/react/form/FormContext"

export const StringChoiceEditor = <T extends string | number | readonly string[] | undefined,>(
  props: {
    fieldId?: string
    state?: RState<T>
    options: List<Readonly<[T, React.ReactNode]>>
  }
) => {

  const errors = useFieldErrors(props.fieldId)

  return <Form.Control 
   as="select" 
   value={String(props.state?.value ?? "")} 
   isInvalid={errors.length > 0}
   onChange={event => {
    const value = event.target.value
    props.state?.apply(() => value as T)?.()
   }}
  >

    {
      props.options.map(([value, label]) =>
        <option value={value}>{label}</option>
      )    
    }
  </Form.Control>
}

export const StringEditor = (
  props: {
    style?: React.CSSProperties
    state?: RState<string>
  }
) => {

  return <Form.Control
    style={props.style}
    value={props.state?.value ?? ""}
    onChange={event => {
      const value = event.target.value
      props.state?.apply(() => value)?.()
    }}
  />
}


export const StringEditorMultiline = (
  props: {
    style?: React.CSSProperties
    state?: RState<string>
    rows?: number
  }
) => {

  return <Form.Control
    style={props.style}
    as="textarea"
    rows={props.rows ?? 3}
    value={props.state?.value ?? ""}
    onChange={event => {
      const value = event.target.value
      props.state?.apply(() => value)?.()
    }}
  />
}
