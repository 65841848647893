import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/WarningRounded'
import { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Table } from "react-bootstrap"
import ReactFileReader from 'react-file-reader'
import { NumericInput } from "../../InputViews"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { buildPathForStorage, computeAnnualWeightedAveragePrice } from "../../Utilities"
import { useAsyncFunction } from "../../client/client"
import { AnnualOffer } from "../../model/Bid"
import { FileData } from "../../model/common"
import {
  DifferentPayTerm,
  MonthInput,
  RenderTooltipPPP,
  RenderTooltipPPPLessVolume,
  RenderTooltipPriceOutOfRange
} from "../Utilities"
import { GasBuyRequest } from '../../model/buyRequest/GasBuyRequest'
import { none } from 'functional/lib/core'
import { Process } from '../../model/Process'
import { Async } from 'functional/lib/Async'
import { fileEncryptAndUpload } from '../../client/file'
import { CellCol, FlexCol, FlexRow } from '../../components/Flexbox'
import { BidFormFooter, bidFormIds, useBidFormFeatures } from '../GenericBidForm'
import { Validator } from '../../functional/react/form/Validated'
import { Draft } from '../../model/utils'
import { RState, useRState } from '../../functional/react/RState'
import { Maybe, showIf } from 'functional/lib/Maybe'
import { List } from 'functional/lib/List'
import { BuyRequest } from '../../model/buyRequest/BuyRequest'
import { IO } from 'functional/lib/IO'
import { FormReactContext } from '../../functional/react/form/FormContext'
import { BidSuccess } from '../BidComponents'
import { NumberEditor } from '../../components/editors/number'
import { computeAnnualFee } from '../../utils/fee'

const fieldIds = {
  summerPrice: "summerPrice",
  winterPrice: "winterPrice",
  payTermDays: "payTermDays",
  validTermDays: "validTermDays",
  tac: bidFormIds.tac,
  price: "price"
}

export const AnnualBidForm = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
  }
) => {

  const {
    form,
    draft,
    acceptedTac,
    onSubmit,
    enableOffer,
    call
  } = useBidFormFeatures<AnnualOffer>({
    buyRequest: props.buyRequest,
    process: props.process,

    validator: validateOffer(props),

    initialDraft: () => ({
      type: "annual",
      payTermDays: props.buyRequest.referencePayTermDays ?? props.process.minimumPayTermDays,
      summerPrice: none,
      winterPrice: none,
      validTermDays: props.process.validTermDays ?? 7,
      encryptedPdfFiles: [],
      decryptedPdfFiles: []
    })
  })

  const fields = RState.destructureAll(draft)


  if (call.state.type === "success") {
    return <BidSuccess
      onNewOffer={
        IO.sequence([
          acceptedTac.apply(() => false),
          form.showErrorsState.apply(() => false),
          call.reset
        ])
      }
    />
  }

  return <Card>
    <Card.Header>
      <FlexRow>
        Ingresá tu Oferta
      </FlexRow>
    </Card.Header>

    <FormReactContext.Provider
      value={form}
    >

      <FlexCol
        alignItems='stretch'
        padding={16}
      >

        <PriceSection
          buyRequest={props.buyRequest}
          process={props.process}
          bid={draft}
        />

        <hr />

        {showIf(props.process.type == "tender" && (props.process.acceptsDifferentPayTerm ?? false))(
          <>
            <DifferentPayTerm
              fieldId={fieldIds.payTermDays}
              buyRequest={props.buyRequest}
              process={props.process}
              payTermDays={fields.payTermDays}
            />
            <hr />
          </>
        )}

        {showIf((props.process.acceptsFileUpload ?? false) && props.process.type === "tender")(
          <>
            <EncryptedPdfFilesEditor
              buyRequest={props.buyRequest}
              state={fields.encryptedPdfFiles}
            />
            <hr />
          </>
        )}

        <FlexCol
          padding={16}
          alignItems="flex-start"
        >
          <ValidTermDaysEditor
            fieldId={fieldIds.validTermDays}
            process={props.process}
            validTermDays={fields.validTermDays}
          />
          <BooleanCheckEditor
            style={{
              alignSelf: "flex-start"
            }}
            fieldId={fieldIds.tac}
            label="Acepto los Términos y Condiciones Particulares del Pedio de Compra"
            state={acceptedTac}
          />
        </FlexCol>

        <BidFormFooter
          process={props.process}
          call={call}
          enableOffer={enableOffer}
          errors={form.errors}
          onSubmit={onSubmit}
          showErrors={form.showErrors}
        />

      </FlexCol>
    </FormReactContext.Provider>
  </Card>
}

const PriceWithUnits = (
  props: {
    usdByMmbtu: Maybe<number>
    usdByDam3: Maybe<number>
    error?: boolean
  }
) =>
  <FlexCol
    alignItems='flex-start'
    style={{ paddingTop: 8 }}
  >

    <h6
      className={(props.error ?? false) ? "text-danger" : 'text-secondary'}
    >
      {props.usdByMmbtu?.toFixed(2) ?? "--"} USD/MMBTU
      {
        showIf(props.error ?? false)(
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={RenderTooltipPriceOutOfRange}
          >

              <InfoIcon
                fontSize="small"
                className={"text-danger"}
                style={{
                  marginLeft: 4,
                  cursor: "pointer"
                }}
              />
           
          </OverlayTrigger>
        )
      }
    </h6>
    
    <small>
      {props.usdByDam3?.toFixed(2) ?? "--"} USD/dam3
    </small>
  </FlexCol>

const PriceSection = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bid: RState<Draft<AnnualOffer>>
  }
) => {

  const {
    summerPrice,
    winterPrice
  } = RState.destructure(props.bid)("summerPrice", "winterPrice")

  const currentBid = props.bid.value

  const weightedAveragePrice = computeAnnualWeightedAveragePrice(props.buyRequest, currentBid)

  const usdByMmbtuToUsdByDam = (usdbyMmbtu: number) => usdbyMmbtu * props.buyRequest.quality / 252

  const averageInUSDByDam = usdByMmbtuToUsdByDam(weightedAveragePrice)

  const commission = computeAnnualFee(props.buyRequest, currentBid)

  const priceHasSense = 
    props.process.type == 'auction' ? (
      props.process.currentBestPrice ? weightedAveragePrice > props.process.currentBestPrice * .9 : true) : 
      true

  const isValidPrice =
    ((props.process.currentBestPrice ? weightedAveragePrice < props.process.currentBestPrice : weightedAveragePrice < 10)
      && weightedAveragePrice < (props.process.maximumPrice ? props.process.maximumPrice : 10)) && priceHasSense

  const unfinishedOffer = !currentBid.winterPrice || !currentBid.summerPrice


  return <FlexCol
    alignItems='stretch'
    gap={8}
  >

    <h6><b>Precio</b></h6>

    <FlexRow
      gap={16}
      alignItems='flex-start'
    >
      <CellCol
        alignItems='flex-start'
      >
        <p>Precio de Verano</p>
        <NumberEditor
          fieldId={fieldIds.summerPrice}
          min={0}
          placeholder="0.00 USD/MMBTU"
          step={0.01}
          decimalPlaces={2}
          state={summerPrice}
        />
      </CellCol>

      <CellCol
        alignItems='flex-start'
      >
        <p>Precio de Invierno</p>
        <NumberEditor
          fieldId={fieldIds.winterPrice}
          min={0}
          placeholder="0.00 USD/MMBTU"
          step={0.01}
          decimalPlaces={2}
          state={winterPrice}
        />
      </CellCol>

      <CellCol
        alignItems='flex-start'
      >

        <p>Precio Promedio</p>

        <PriceWithUnits
          usdByMmbtu={weightedAveragePrice}
          usdByDam3={averageInUSDByDam}
          error={!isValidPrice}
        />

      </CellCol>

      <CellCol
        alignItems='flex-start'
      >
        {
          Maybe.map(props.process.currentBestPrice)(bestPrice =>
            <>
              <p>Mejor Oferta</p>
              <PriceWithUnits
                usdByMmbtu={bestPrice}
                usdByDam3={usdByMmbtuToUsdByDam(bestPrice)}
              />
            </>
          ) ??
          Maybe.map(props.process.maximumPrice)(maxPrice =>
            <>
              <p>Precio Máximo</p>
              <PriceWithUnits
                usdByMmbtu={maxPrice}
                usdByDam3={usdByMmbtuToUsdByDam(maxPrice)}
              />
            </>
          )
        }

      </CellCol>

    </FlexRow>


    {/* <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={props.buyRequest.acceptsLessVolume ? RenderTooltipPPPLessVolume : RenderTooltipPPP}
      >
        <div/>
      </OverlayTrigger> */}

    <div/>

    <Alert variant="info">
      <InfoIcon/> &nbsp; Comisión a pagar por el Vendedor adjudicado: <b>{commission?.toFixed(0) ?? "--"} USD </b>
    </Alert>

    {
      showIf(!unfinishedOffer && (currentBid.winterPrice ?? 0) < (currentBid.summerPrice ?? 0))(
        <Alert 
          variant='warning'
        >
          <WarningIcon/> &nbsp; El Precio de Verano es mayor al Precio de Invierno
        </Alert>
      )
    }

  </FlexCol>
}



export const ValidTermDaysEditor = (
  props: {
    fieldId?: string
    process: Process
    validTermDays: RState<Maybe<number>>
  }
) => {

  const acceptedValidDays = useRState(() => true)

  useEffect(
    acceptedValidDays.value ?
      props.validTermDays.apply(() => props.process.validTermDays ?? 7) :
      IO.noOp,
    [acceptedValidDays.value]
  )

  return <FlexCol
    alignItems='flex-start'
  >

    <BooleanCheckEditor
      label={`Acepto el plazo de validez de oferta de ${props.process.validTermDays} días desde la fecha y hora de cierre de esta Ronda.`}
      state={acceptedValidDays}
    />

    <BooleanCheckEditor
      label={'Modificar el Plazo de validez de Oferta'}
      value={!acceptedValidDays.value}
      onValueChanged={modifyValidDays => {
        acceptedValidDays.apply(it => !modifyValidDays)()
      }}
    />

    {
      showIf(!acceptedValidDays.value)(
        <NumericInput
          fieldId={props.fieldId}
          name={'Plazo de validez de Oferta'}
          state={props.validTermDays}
          placeholder={'días'}
        />
      )
    }

  </FlexCol>
}

export const EncryptedPdfFilesEditor = (
  props: {
    buyRequest: BuyRequest
    state: RState<List<FileData>>
  }
) => {

  const [uploadedStatus, setUploadedStatus] = useState(
    new Map<string, { encryptionProgress: number, uploadProgress: number, done: boolean }>()
  )

  const handleFileEncryption = (fileList: FileList): Async<FileData> =>
    async () => {

      console.log("handleFileEncryption")

      const file = fileList[0]
      if (uploadedStatus.has(file.name)) {
        throw "file already uploaded"
      }

      const path = buildPathForStorage(false, "Gas", props.buyRequest.id ?? "", 'Encrypted', none)()

      const result = await fileEncryptAndUpload(
        file,
        path,
        (encryption, upload) => {
          setUploadedStatus(
            uploadedStatus.set(
              file.name,
              {
                encryptionProgress: encryption,
                uploadProgress: upload,
                done: false
              }
            )
          )
        }
      )()

      setUploadedStatus(
        uploadedStatus.set(
          file.name,
          {
            encryptionProgress: 1.0,
            uploadProgress: 1.0,
            done: true
          }
        )
      )

      return result
    }

  const removePdf = (filename: string) => {

    props.state.apply(list => list.filter(it => it.name !== filename))()

    const uploaded = uploadedStatus
    uploaded.delete(filename)
    setUploadedStatus(uploaded)
  }

  const handlePdfUpload = async (file: FileList): Promise<void> => {
    try {

      console.log("handlePdfUpload")

      const fileData = await handleFileEncryption(file)()

      props.state.apply(list => [...list, fileData])()
    } catch (reason) {
      if (reason === "file already uploaded") alert("El archivo ya fue subido")
      throw reason
    }
  }

  const [pdfEncryptionState, startPdfEncryption] = useAsyncFunction(handlePdfUpload)

  return <FlexCol
    alignItems='center'
    padding={8}
    gap={16}
  >

    <h6><b>Documentos Adjuntos</b></h6>

    <FlexRow
      style={{
        alignSelf: "stretch"
      }}
      justifyContent='space-evenly'
    >

      <FlexCol
        spacing={8}
      >

        <ReactFileReader
          elementId='pdf'
          fileTypes={".pdf"}
          handleFiles={startPdfEncryption}
        >
          <Button size='sm'>Subir Archivos</Button>
        </ReactFileReader>

        <small className='text-secondary'>Los archivo deben ser <b>.pdf</b></small>
      </FlexCol>

      <FlexCol
        gap={8}
      >

        <small className='text-secondary'><b>Opcional:</b> Cargá tu modelo de contrato</small>

        {props.state.value.length > 0 && <Table size="sm">
          <tbody>
            {props.state.value.map((fileData) =>
              <tr>
                <td>{fileData.name}</td>
                <td className='text-danger'>
                  <Button
                    size='sm'
                    variant='outline-danger'
                    onClick={() => {
                      removePdf(fileData.name)
                    }}>
                    Borrar
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>}

        {
          showIf(pdfEncryptionState.isLoading)(
            <Row className='text-secondary justify-content-center'>
              <h5><Spinner animation="grow" /> Encriptando Archivo</h5>
            </Row>
          )
        }

        <small>
          Podés subir la cantidad de PDF's que consideres necesarios.<br />
          Se deben cargar de a uno.
        </small>

      </FlexCol>

    </FlexRow>

  </FlexCol>
}


const validateOffer =
  (
    args: {
      buyRequest: GasBuyRequest
      process: Process
    }
  ): Validator<Draft<AnnualOffer>, AnnualOffer> =>
    Validator.compose(

      Validator.predicate(
        draft => {
          const weightedAveragePrice = computeAnnualWeightedAveragePrice(args.buyRequest, draft)

          const priceHasSense = args.process.type == 'auction' ?
            (args.process.currentBestPrice ? weightedAveragePrice > args.process.currentBestPrice * .9 : true) : true

          const isValidPrice =
            ((args.process.currentBestPrice ? weightedAveragePrice < args.process.currentBestPrice : weightedAveragePrice < 10)
              && weightedAveragePrice < (args.process.maximumPrice ? args.process.maximumPrice : 10)) && priceHasSense
          return isValidPrice
        },
        fieldIds.price, "El precio es muy alto"
      ),

      Validator.record<Draft<AnnualOffer>, AnnualOffer>({

        summerPrice: Validator.compose(
          Validator.notNone<number>(fieldIds.summerPrice, "Por favor ingresá un precio de verano"),
          Validator.predicate(
            it => it > 0,
            fieldIds.payTermDays, "El precio de verano debe ser mayor a 0"
          ),
        ),

        winterPrice: Validator.compose(
          Validator.notNone<number>(fieldIds.winterPrice, "Por favor ingresá un precio de invierno"),
          Validator.predicate(
            it => it > 0,
            fieldIds.winterPrice, "El precio de invierno debe ser mayor a 0"
          ),
        ),

        payTermDays:
          args.process.acceptsDifferentPayTerm ?
            Validator.compose(
              Validator.notNone<number>(fieldIds.payTermDays, "Por favor ingresá los días de plazo"),
              Validator.predicate(
                it => it > 0,
                fieldIds.payTermDays, "El plazo debe ser mayor a 0"
              ),
              Validator.predicate(
                it => it >= (args.process.minimumPayTermDays ?? 0),
                fieldIds.payTermDays, "El plazo debe ser mayor o igual al mínimo"
              )
            ) :
            Validator.id(),

        validTermDays:
          Validator.compose(
            Validator.notNone<number>(fieldIds.validTermDays, "Por favor un plazo de validez de oferta"),
            Validator.predicate(
              it => it > 0,
              fieldIds.validTermDays, "El plazo de validez de oferta debe ser mayor a 0"
            )
          )

      })
    )

const SummerLessVolume = (
  props: {
    buyRequest: GasBuyRequest
  }
) => {
  if (props.buyRequest.acceptsLessVolume === false) {
    return null
  } else {
    return (
      <FlexCol
        alignItems='stretch'
      >
        <h6>Volumen Mensual Ofertado</h6>
        <MonthInput name="Enero" id="enero" />
        <MonthInput name="Febrero" id="febrero" />
        <MonthInput name="Marzo" id="marzo" />
        <MonthInput name="Abril" id="abril" />
        <MonthInput name="Mayo" id="mayo" />
        <MonthInput name="Junio" id="junio" />
      </FlexCol>)
  }
}

const WinterLessVolume = (
  props: {
    buyRequest: GasBuyRequest
  }
) => {
  if (props.buyRequest.acceptsLessVolume === false) {
    return null
  } else {
    return (
      <FlexCol
        alignItems='stretch'
      >
        <h6>Volumen Mensual Ofertado</h6>
        <MonthInput name="Julio" id="julio" />
        <MonthInput name="Agosto" id="agosto" />
        <MonthInput name="Septiembre" id="septiembre" />
        <MonthInput name="Octubre" id="octubre" />
        <MonthInput name="Noviembre" id="noviembre" />
        <MonthInput name="Diciembre" id="diciembre" />
      </FlexCol>
    )
  }
}

