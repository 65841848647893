import GavelIcon from "@material-ui/icons/Gavel"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import React from "react"

export const RequestIcon = (
  props: {
    processType: string,
    color: string,
    size: number
  }
) => {
  const size = props.size

  return props.processType === 'auction' ?
    <GavelIcon
      className={props.color}
      style={{ fontSize: size }} /> :
    <MailOutlineIcon
      className={props.color}
      style={{ fontSize: size }} />
}
