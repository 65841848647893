import { StaticDecode, StaticEncode, TProperties, TSchema, Type } from "@sinclair/typebox"
import { List } from "functional/lib/List"
import { Maybe } from "functional/lib/Maybe"
import { FileData } from "./common"

export type Draft<T, WithMaybe = number | Date | FileData> =
  T extends WithMaybe ? Maybe<T> :
  T extends Record<string, unknown> ? { [K in keyof T]: Draft<T[K]> } :
  T extends List<FileData> ? T :
  T extends List<infer U> ? List<Draft<U>> :
  T

export const OptionalTypeTag = 
  <T extends string, P extends TProperties>(value: T, body: P) =>
    Type.Transform(
      Type.Object({
        type: Type.Optional(Type.Literal(value)),
        ...body,
      })
    ).Decode(it => ({ ...it, type: value }))
    .Encode(it => ({ ...it, type: value }))

export const MaybeT = <T extends TSchema>(type: T) =>
  Type.Optional(
    Type.Transform(
      Type.Union([
        type,
        Type.Null(),
        Type.Undefined()
      ])
    ).Decode(it => it === null ? undefined : it)
    .Encode(it => it === undefined ? null : it)
  )

export const ListT = <T extends TSchema>(type: T) =>
  Type.Transform(Type.Array(type))
      .Decode(it => it as List<StaticDecode<T>>)
      .Encode(it => it as StaticEncode<T>[])
