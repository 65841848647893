import { None, none } from "./core"

export type Maybe<T> = T | None

export const someIf = (condition: boolean) => <T>(value: T): Maybe<T> =>
  condition ? value : undefined

export const showIf = someIf

export const Maybe = {

  match: <T>(value: Maybe<T>) => <R>(
    cases: {
      some: (value: T) => R
      none: () => R
    }
  ): R =>
    value === none ? cases.none() : cases.some(value),

  map: <I>(value: Maybe<I>) => <O>(f: (value: I) => O): Maybe<O> =>
    value === none ? none : f(value),

  lift: <O, I>(f: (value: I) => O) => (value: Maybe<I>): Maybe<O> =>
    Maybe.map(value)(f),

  bind: <I>(value: Maybe<I>) => <O>(f: (value: I) => Maybe<O>): Maybe<O> =>
    value === none ? none : f(value),

  compose: <A, B, C>(
    lhs: (a: A) => Maybe<B>,
    rhs: (b: B) => Maybe<C>,
  ) => (a: A): Maybe<C> => {
    const b = lhs(a)
    return b === none ? none : rhs(b)
  },

  do: <R>(
    body: (_: <T>(value: Maybe<T>) => T) => R
  ): Maybe<R> => {

    const symbol = Symbol()

    try {
      return body(value => {
        if (value === none) {
          throw symbol
        }
        return value
      })
    } catch (error) {
      if (error === symbol) {
        return none
      }
      throw error
    }
  }

}
