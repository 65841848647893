import DateFnsUtils from "@date-io/date-fns"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import deLocale from "date-fns/locale/es"
import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { Lens } from "functional/lib/optics/Lens"
import { Button, ButtonGroup, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { MONTHS } from "../../Utilities"
import { CellCol, FlexCol, FlexRow, Flexbox } from "../../components/Flexbox"
import { DateEditor, YearMonthEditor } from "../../components/editors/date"
import { StringChoiceEditor } from "../../components/editors/string"
import { RState, useRState } from "../../functional/react/RState"
import { GasBuyRequest, GasBuyTypeAnnual, GasBuyTypeMonthly, GasBuyTypeSpot } from "../../model/buyRequest/GasBuyRequest"
import { Draft } from "../../model/utils"
import { ConsumptionChart } from "./BuyRequestCreateFormStep"
import { gasFormFieldIds } from "./validation"
import { NumberEditor } from "../../components/editors/number"
import { useNow } from "../../utils/interval"
import { FileListEditor } from "./electricity"

export const GasDemandForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
  }
) => {

  const fields = RState.destructureAll(props.request)

  const defaultSpot: Draft<GasBuyTypeSpot> = {
    type: "spot",
    amount: 0,
    termDays: 0
  }

  const defaultMonthly: Draft<GasBuyTypeMonthly> = {
    type: "monthly",
    demandCurve: List.new(3)(() => none),
    cmdCurve: List.new(3)(() => none),
  }

  const defaultAnnual: Draft<GasBuyTypeAnnual> = {
    type: "annual",
    demandCurve: List.new(12)(() => none),
    cmdCurve: List.new(12)(() => none),
  }

  const buyType = fields.buyType

  const body = 
    RState.match(buyType)({

      annual: annual =>
        <AnnualForm
          request={props.request}
          state={annual}
        />,

      monthly: monthly =>
        <MonthlyForm
          request={props.request}
          state={monthly}
        />,

      spot: spot => 
        <SpotForm
          request={props.request}
          state={spot}
        />,

    })  
    

  return <FlexCol
    alignItems='stretch'
  > 
    <FlexRow 
      justifyContent='center'
    >
      <b>Demanda</b>
    </FlexRow>

    <FlexRow>
      <CellCol
        alignItems='flex-start'
        weight={1}
        padding={8}
      >
        <p>Tipo de Compra</p>

        <ButtonGroup>

          <Button
            style={{ width: 200 }}
            variant={fields.buyType.value.type === "annual" ? "primary" : "secondary"}
            onClick={fields.buyType.apply(() => defaultAnnual)}
          >
            Anual (Estacional)
          </Button>

          <Button
            style={{ width: 200 }}
            variant={fields.buyType.value.type === "monthly" ? "primary" : "secondary"}
            onClick={fields.buyType.apply(() => defaultMonthly)}
          >
            Mensual
          </Button>

          <Button
            style={{ width: 200 }}
            variant={fields.buyType.value.type === "spot" ? "primary" : "secondary"}
            onClick={fields.buyType.apply(() => defaultSpot)}
          >
            Spot
          </Button>

        </ButtonGroup>

      </CellCol>

      <CellCol
        weight={3}
        padding={8}
      >
      </CellCol>
    </FlexRow>
    {body}
  </FlexCol>
}


const AnnualForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
    state: RState<Draft<GasBuyTypeAnnual>>
  }
) => {

  const { supplyStartDate } = RState.destructure(props.request)("supplyStartDate")
  const {
    demandCurve,
    cmdCurve
  } = RState.destructure(props.state)("demandCurve", "cmdCurve")

  return <CommonMonthlyForm
    supplyStartDate={supplyStartDate}
    demandCurve={demandCurve}
    cmdCurve={RState.nonNone(cmdCurve)}
    variableLength={false}
  />
}

const MonthlyForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
    state: RState<Draft<GasBuyTypeMonthly>>
  }
) => {

  const { supplyStartDate } = RState.destructure(props.request)("supplyStartDate")
  const {
    demandCurve,
    cmdCurve
  } = RState.destructure(props.state)("demandCurve", "cmdCurve")

  return <CommonMonthlyForm
    supplyStartDate={supplyStartDate}
    demandCurve={demandCurve}
    cmdCurve={RState.nonNone(cmdCurve)}
    variableLength={true}
  />
}


const CommonMonthlyForm = (
  props: {
    supplyStartDate: RState<Maybe<Date>>
    demandCurve: RState<List<Maybe<number>>>
    cmdCurve?: RState<List<Maybe<number>>>
    variableLength: boolean
  }
) => {

  const supplyStartDate = props.supplyStartDate
  const demandCurve = props.demandCurve
  const cmdCurve = props.cmdCurve

  const monthCount = useRState<Maybe<number>>(() => demandCurve.value.length)

  const modifyListLength = (length: number) => <T,>(list: List<Maybe<T>>): List<Maybe<T>> =>
    [
      ...list.slice(0, length),
      ...List.new(length - list.length)(() => none)
    ]

  const setNewLength =  IO.sequence([
    demandCurve.apply(modifyListLength(monthCount.value ?? 0)),
    cmdCurve?.apply(modifyListLength(monthCount.value ?? 0)) ?? IO.noOp
  ])

  const dayAvg = (demandCurve.value.reduce((acc, next) => (acc ?? 0) + (next ?? 0), 0) ?? 0) / 365

  const startMonthIndex = supplyStartDate.value?.getMonth() ?? 4
  const startYear = supplyStartDate.value?.getFullYear() ?? 2020

  const monthNames = List.new(demandCurve.value.length)(value => 
    MONTHS[(startMonthIndex + value) % 12] + ' ' + (startYear + Math.floor((startMonthIndex + value) / 12))
  )

  const monthCellWeight = 4
  const numberCellWeight = 3

  const planeDemand = useRState<Maybe<number>>(() => none)
  const setPlaneDemand = demandCurve?.apply(it => it?.map(() => planeDemand.value))

  const planeCmd = useRState<Maybe<number>>(() => none)
  const setPlaneCmd = cmdCurve?.apply(it => it?.map(() => planeCmd.value))

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow>

      <CellCol
        alignItems="stretch"
        padding={8}
      >
        <p className={supplyStartDate.value ? none : 'text-danger'}>
          Mes de Inicio de Suministro
        </p>
        <YearMonthEditor
          state={supplyStartDate}  
        />
      </CellCol>

      {
        showIf(props.variableLength)(
          <CellCol
            alignItems="flex-start"
          >
            <p className={supplyStartDate.value ? none : 'text-danger'}>
              Plazo de Contrato [Meses]
            </p>
            <FlexRow
              justifyContent="flex-start"
            >
              <NumberEditor
                fieldId={gasFormFieldIds.demandCurve}
                min={1}
                max={30}
                state={monthCount}
                onEnter={setNewLength}
              />
              <Button
                style={{
                  minWidth: "max-content"
                }}
                onClick={setNewLength}
              >
                Cambiar
              </Button>
            </FlexRow>
          </CellCol>
        )
      }
    
      <CellCol
        alignItems="stretch"
        padding={8}
      >
        <p>Cargar Volumen a todos</p>
        <FlexRow>
          <NumberEditor
            placeholder="dam3/mes"
            decimalPlaces={2}
            min={0}
            state={planeDemand}
            onEnter={setPlaneDemand}
          />
          <Button
            style={{
              minWidth: "max-content"
            }}
            onClick={setPlaneDemand}
          >
            Cargar
          </Button>
        </FlexRow>

      </CellCol>

      <CellCol
        alignItems="stretch"
        padding={8}
      >
        <p>Cargar CMD a todos</p>
        <FlexRow>
          <NumberEditor
            placeholder="dam3/día"
            decimalPlaces={2}
            min={0}
            state={planeCmd}
            onEnter={setPlaneCmd}
          />
          <Button
            style={{
              minWidth: "max-content"
            }}
            onClick={setPlaneCmd}
          >
            Cargar
          </Button>
        </FlexRow>

      </CellCol>

    </FlexRow>
    {
      Maybe.map(supplyStartDate.value)(supplyStartDate => 
        <FlexRow
          alignItems="flex-start"
          gap={12}
          padding={16}
        >

          <CellCol
            alignItems="stretch"
            weight={2}
          >

            <FlexRow>
              <CellCol weight={monthCellWeight}/>

              <CellCol weight={numberCellWeight}>
                <p className='text-primary'>Volumen<br />[dam3/mes]</p>
              </CellCol>

              <CellCol weight={numberCellWeight}>
                <p className='text-warning'>CMD<br />[dam3/día]</p>
              </CellCol>
            </FlexRow>

            {monthNames.map((month, index) =>
              <FlexRow key={month}>
                <CellCol alignItems="flex-start" weight={monthCellWeight}>
                  <p>{month}</p>
                </CellCol>
                <CellCol weight={numberCellWeight}>
                  <NumberEditor
                    fieldId={gasFormFieldIds.demandCurveValue(index)}
                    placeholder='dam3/mes'
                    decimalPlaces={2}
                    state={RState.applyLens(demandCurve, Lens.listIndex(index))}
                  />
                </CellCol>
                <CellCol weight={numberCellWeight}>
                  <NumberEditor
                    fieldId={gasFormFieldIds.cmdCurveValue(index)}
                    placeholder='dam3/día'
                    decimalPlaces={2}
                    state={
                      Maybe.map(cmdCurve)(it => 
                        RState.applyLens(it, Lens.listIndex(index))
                      )
                    }
                  />
                </CellCol>
              </FlexRow>
            )}
          </CellCol>

          <CellCol
            weight={4}
            alignItems="stretch"
          >
            <Flexbox
              style={{ height: 60 }}
            />
            <ConsumptionChart
              style={{
                minHeight: 400
              }}
              supplyStartDate={supplyStartDate}
              demandCurve={demandCurve.value}
              cmdCurve={cmdCurve?.value}
            />
          </CellCol>

          

        </FlexRow>
      )
    }
    <br />
  </FlexCol>
}

const SpotForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
    state: RState<Draft<GasBuyTypeSpot>>
  }
) => {

  const { supplyStartDate } = RState.destructure(props.request)("supplyStartDate")

  const {
    termDays,
    amount
  } = RState.destructure(props.state)("termDays", "amount")

  const now = useNow()

  return <FlexRow>
    <CellCol
      padding={8}
      alignItems="flex-start"
    >
      <p>Plazo de Contrato [días]</p>
      <NumberEditor
        fieldId={gasFormFieldIds.termDays}
        state={termDays}
      />
    </CellCol>
    <CellCol
      padding={8}
      alignItems="flex-start"
    >
      <p>Fecha de Inicio de Suministro</p>
      <DateEditor
        minDate={now}
        fieldId={gasFormFieldIds.supplyStartDate}
        state={supplyStartDate}
      />
    </CellCol>
    <CellCol
      padding={8}
      alignItems="flex-start"
    >
      <p>Cantidad Total [dam3]</p>
      <NumberEditor
        fieldId={gasFormFieldIds.amount}
        min={0}
        state={amount}
      />
    </CellCol>
  </FlexRow>
}



export const GasSupplyConditionsForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
  }
) => {

  const fields = RState.destructureAll(props.request)

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Condiciones de Suministro</b>
    </FlexRow>

    <FlexRow>

      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Calidad [kcal/m3]</p>
        <NumberEditor
          fieldId={gasFormFieldIds.quality}
          state={fields.quality}
          min={8400}
          max={10000}
        />
      </CellCol>

      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Tipo de Suministro</p>

        <StringChoiceEditor
          state={fields.supplyType}
          options={[
            ["firm", "Firme"],
            ["interruptible", "Interrumpible"]
          ]}
        />

      </CellCol>

      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Take or Pay [%]</p>
        <NumberEditor
          fieldId={gasFormFieldIds.takeOrPay}
          min={0}
          state={fields.takeOrPay}
        />
      </CellCol>

      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Deliver or Pay [%]</p>
        <NumberEditor
          fieldId={gasFormFieldIds.deliverOrPay}
          min={0}
          state={fields.deliverOrPay}
        />
      </CellCol>

      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Exclusividad 
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip show={true}>
                Exclusividad en el suministro a favor del Vendedor por el plazo y hasta los volúmenes de este Pedido de Compra
              </Tooltip>
            }
          >
        <InfoOutlinedIcon />
      </OverlayTrigger>

      </p>
        <Form>
          <Form.Group>
            <BooleanCheckEditor
              type="checkbox"
              label="Otorgar Exclusividad"
              state={
                Maybe.do(_ => RState.withDefault(_(fields.exclusivity), false))
              }
            />
          </Form.Group>
        </Form>
      </CellCol>

    </FlexRow>
  </FlexCol>
}



export const GasPayConditionsForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
  }
) => {

  const fields = RState.destructureAll(props.request)

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Condiciones de Pago</b><br />
    </FlexRow>

    <FlexRow>
      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Tasa de Cambio</p>
        <StringChoiceEditor
          state={fields.exchangeRate}
          options={[
            ["BNA divisa", "BNA Vendedor Divisa"],
            ["BNA billete", "BNA Vendedor Billete"],
            ["CAMMESA", "Condición CAMMESA"]
          ]}
        />
      </CellCol>
      <CellCol
        padding={8}
        alignItems='flex-start'
      >
        <p>Plazo de Pago de Factura Sugerido[días corridos]</p>
        <NumberEditor
          fieldId={gasFormFieldIds.referencePayTermDays}
          min={0}
          state={fields.referencePayTermDays}
        />
      </CellCol>
    </FlexRow>
  </FlexCol>
}

export const GasFilesSection = (
  props: {
    request: RState<Draft<GasBuyRequest>>
  }
) => {

  const buyerCompanyId = props.request.value.buyerCompanyId

  const fields = RState.destructure(props.request)("fileList")

  return <FlexCol
    alignItems="stretch"
  >

    <FlexRow 
      justifyContent="center"  
    >
      <b>Archivos Asociados al Pedido de Compra</b>
    </FlexRow>

      {!buyerCompanyId &&
        <FlexRow 
          justifyContent="center"
        >
          <small className='text-warning'>
            Debes seleccionar una Empresa para cargar los Archivos
          </small>
        </FlexRow>
      }
      <br />
      
      <FileListEditor
        title='Documentación Adicional'
        companyId={buyerCompanyId}
        description={
          <small>
            Cargá en esta sección los archivos que contengan la información necesaria para la preparación de las Ofertas. <br /> 
            Por ejemplo: termsheet, anexos técnicos, planos etc. <br />
            <br />
            Podés cargar la cantidad de archivos que consideres necesarios.<br />Se deben cargar de a uno.
          </small>
        }
        fileTypes={['.pdf', '.dwg', '.xls', '.xlsx']}
        state={RState.nonNone(fields?.fileList)}
      />

    </FlexCol>
}
