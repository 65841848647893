import { StaticDecode, Type } from "@sinclair/typebox"
import firebase from "firebase"
import { Id } from "./Model"
import { FileDataT, TimestampT } from "./common"
import { ListT, MaybeT } from "./utils"
import Timestamp = firebase.firestore.Timestamp

export const SpotOfferT = Type.Object({
  type: Type.Literal("spot"),
  amount: MaybeT(Type.Number()),
  payTermDays: MaybeT(Type.Number()),
  /**
   * USD/MMBTU
   */
  price: Type.Number()
})
export type SpotOffer = StaticDecode<typeof SpotOfferT>

export const MonthlyOfferT = Type.Object({
  type: Type.Literal("monthly"),
  monthlyCurve: MaybeT(ListT(Type.Number())),
  payTermDays: MaybeT(Type.Number()),

  /**
   * USD/MMBTU
   */
  priceCurve: ListT(Type.Number()),

  validTermDays: Type.Number(),

  encryptedPdfFiles: ListT(FileDataT),
  decryptedPdfFiles: ListT(FileDataT),

  encryptedXls: MaybeT(FileDataT),
  decryptedXls: MaybeT(FileDataT)
})
export type MonthlyOffer = StaticDecode<typeof MonthlyOfferT>

export const AnnualOfferT = Type.Object({
  type: Type.Literal("annual"),
  monthlyCurve: MaybeT(ListT(Type.Number())),
  payTermDays: MaybeT(Type.Number()),

  /**
   * USD/MMBTU
   */
  summerPrice: Type.Number(),
  /**
   * USD/MMBTU
   */
  winterPrice: Type.Number(),

  validTermDays: Type.Number(),

  encryptedPdfFiles: ListT(FileDataT),
  decryptedPdfFiles: ListT(FileDataT),

  encryptedXls: MaybeT(FileDataT),
  decryptedXls: MaybeT(FileDataT)
})
export type AnnualOffer = StaticDecode<typeof AnnualOfferT>


export const ThermalOfferT = Type.Object({
  type: Type.Literal("thermal"),
  fixedCost: Type.Number(),
  variableCost: Type.Number(),
  term: Type.Number(),
  startSupplyMonth: Type.Number(),
  startSupplyDays: MaybeT(Type.Number()),
  validPayTermDays: Type.Number(),
  validTermDays: Type.Number(),

  encryptedPdfFiles: ListT(FileDataT),
  decryptedPdfFiles: ListT(FileDataT),

  encryptedXls: FileDataT,
  decryptedXls: FileDataT,

  discount: Type.Number(),
  percentage: Type.Number()
})
export type ThermalOffer = StaticDecode<typeof ThermalOfferT>

export const MaterOfferT = Type.Object({
  type: Type.Literal("mater"),
  validTermDays: MaybeT(Type.Number()),
  variableCost: MaybeT(Type.Number()),
  term: MaybeT(Type.Number()),

  /**
   * @deprecated
   */
  startSupplyMonths: MaybeT(Type.Number()),

  /**
   * @deprecated
   */
  startSupplyDays: MaybeT(Type.Number()),
  
  startSupplyDate: MaybeT(TimestampT),

  encryptedPdfFiles: ListT(FileDataT),
  decryptedPdfFiles: MaybeT(ListT(FileDataT)),

  encryptedXls: MaybeT(FileDataT),
  decryptedXls: MaybeT(FileDataT)
})
export type MaterOffer = StaticDecode<typeof MaterOfferT>

export const OnSiteOfferT = Type.Object({
  type: Type.Literal("onsite"),
  variableCost: Type.Number(),
  term: Type.Number(),
  startSupplyDays: Type.Number(),

  encryptedPdfFiles: ListT(FileDataT),
  decryptedPdfFiles: ListT(FileDataT),

  encryptedXls: FileDataT,
  decryptedXls: FileDataT
})
export type OnSiteOffer = StaticDecode<typeof OnSiteOfferT>


export const OfferT = Type.Union([
  SpotOfferT,
  MonthlyOfferT,
  AnnualOfferT,
  ThermalOfferT,
  MaterOfferT,
  OnSiteOfferT
])
export type Offer = StaticDecode<typeof OfferT>

export const EncryptedTenderBidT = Type.Object({
  type: Type.Literal("tender"),
  id: Type.String(),
  date: TimestampT,
  encrypted: Type.Literal(true),

  encryptedSellerCompanyId: Type.String(),
  encryptedOffer: Type.String(),
  offer: Type.Optional(Type.Never())
})
export type EncryptedTenderBid = StaticDecode<typeof EncryptedTenderBidT>

export const EncryptedAuctionBidT = Type.Object({
  type: Type.Literal("auction"),
  id: Type.String(),
  date: TimestampT,
  encrypted: Type.Literal(true),

  encryptedSellerCompanyId: Type.String(),
  offer: OfferT
})
export type EncryptedAuctionBid = StaticDecode<typeof EncryptedAuctionBidT>

export const EncryptedBidT = Type.Union([
  EncryptedTenderBidT,
  EncryptedAuctionBidT
])
export type EncryptedBid = StaticDecode<typeof EncryptedBidT>

export const DecryptedBidTenderT = Type.Object({
  type: Type.Literal("tender"),
  id: Type.String(),
  date: TimestampT,
  encrypted: Type.Literal(false),

  sellerCompanyId: Type.String(),
  offer: OfferT
})
export type DecryptedBidTender = StaticDecode<typeof DecryptedBidTenderT>

export const DecryptedBidAuctionT = Type.Object({
  type: Type.Literal("auction"),
  id: Type.String(),
  date: TimestampT,
  encrypted: Type.Literal(false),

  sellerCompanyId: Type.String(),
  offer: OfferT
})
export type DecryptedBidAuction = StaticDecode<typeof DecryptedBidAuctionT>

export const DecryptedBidT = Type.Union([
  DecryptedBidTenderT,
  DecryptedBidAuctionT
])
export type DecryptedBid = StaticDecode<typeof DecryptedBidT>

export const BidT = Type.Union([
  EncryptedBidT,
  DecryptedBidT
])
export type Bid = StaticDecode<typeof BidT>

export const matterOfferToJson = (offer: MaterOffer) => ({ 
  ...offer, 
  startSupplyDate: offer.startSupplyDate?.toString() 
})

export const offerToJson = (offer: Offer) =>
  offer.type === "mater" ? matterOfferToJson(offer) : offer

export const offerFromJson = (offer: any) => 
  offer.type === "mater" ? matterOfferFromJson(offer) : offer

export const matterOfferFromJson = (offer: any) => ({
  ...offer,
  startSupplyDate: new Date(offer.startSupplyDate)
})
