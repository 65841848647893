import { IO } from "functional/lib/IO"
import { Unit } from "functional/lib/core"
import { useEffect, useRef } from "react"
import { valueEquals } from "../utils/utils"

export const useIO = (
  io: IO<Unit>,
  dependencies?: unknown
) => {

  const changes = useRef({
    previous: dependencies,
    changes: 0
  })

  if (!valueEquals(changes.current.previous, dependencies)) {
    changes.current = {
      previous: dependencies,
      changes: changes.current.changes + 1
    }
  }

  useEffect(
    io,
    [changes.current.changes]
  )

}
