import { Async } from "functional/lib/Async"
import { IO } from "functional/lib/IO"
import { Unit } from "functional/lib/core"
import { useRState } from "./RState"
import { useEffect } from "react"


export type AsynchronismState<I, O> =
  | {
    type: "idle"
    input?: never
    output?: never
  }
  | {
    type: "running",
    input: I
    output?: never
  }
  | {
    type: "error"
    input: I
    output?: never
    error: unknown
  }
  | {
    type: "success"
    input: I
    output: O
  }

export type Asynchronism<I, O> = {
  state: AsynchronismState<I, O>
  run: (params: I) => IO<Unit>
  reset: IO<Unit>
}

export const useAsynchronism = <I, O>(
  call: (params: I) => Async<O>
): Asynchronism<I, O> => {

  const state = useRState<AsynchronismState<I, O>>(() => ({
    type: "idle"
  }))

  useEffect(
    () => {
      if (state.value.type === "error") {
        console.error(state.value.error)
      }
    },
    [state.value.type]
  )

  const run =
    (input: I) => 
    async () => {
      state.apply(() => ({ 
        type: "running", 
        input: input 
      }))()
      try {
        const output = await call(input)()
        state.apply(() => ({ 
          type: "success",
          input: input, 
          output: output 
        }))()
      } catch (error) {
        console.error(error)
        state.apply(() => ({ 
          type: "error",
          input: input,
          error: error 
        }))()
      }
    }

  return {
    state: state.value,
    run: run,
    reset: state.apply(() => ({ type: "idle" }))
  }

}

