import { StaticDecode, Type } from "@sinclair/typebox"
import { ProductT } from "./Model"
import { ListT, MaybeT } from "./utils"
import { TimestampT } from "./common"
import { FeeConditionsT } from "./buyRequest/BuyRequestBase"


export const CompanyRoleT = Type.Union([
  Type.Literal("buyer"),
  Type.Literal("seller"),
  Type.Literal("consultant"),
])
export type CompanyRole = StaticDecode<typeof CompanyRoleT>

export const CompanyDiscountT = Type.Object({
  factor: Type.Number(),
  max: Type.Number(),
  min: Type.Number(),
  validUntil: MaybeT(TimestampT)
})
export type CompanyDiscount = StaticDecode<typeof CompanyDiscountT>


export const CompanyT = Type.Object({
  id: Type.String(),

  producer: MaybeT(Type.Boolean()),

  displayName: MaybeT(Type.String()),
  shortName: MaybeT(Type.String()),

  businessName: Type.String(),
  imageUrl: MaybeT(Type.String()),

  role: CompanyRoleT,
  productList: MaybeT(ListT(ProductT)),
  discount: MaybeT(CompanyDiscountT),

  mockCompany: MaybeT(Type.Boolean()),

  cuit: MaybeT(Type.String()),

  feeConditions: MaybeT(FeeConditionsT),
})
export type Company = StaticDecode<typeof CompanyT>

