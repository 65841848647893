import { StaticDecode, Type } from "@sinclair/typebox"
// firebase timestamp
import { firestore } from "firebase"

type Timestamp = firestore.Timestamp

export const TimestampT = 
  Type.Transform(
    Type.Object({
      seconds: Type.Number(),
      nanoseconds: Type.Number()
    })
  ).Decode(it => new Date(it.seconds * 1000))
  .Encode(it => firestore.Timestamp.fromDate(it))

export const FileDataT = Type.Object({
  name: Type.String(),
  link: Type.String(),
  image: Type.Optional(Type.String()),
  aesEncryptionKey: Type.Optional(
    Type.Union([Type.Uint8Array(), Type.String()])
  ),
  sha256: Type.Optional(Type.String()),
})
export type FileData = StaticDecode<typeof FileDataT>
