import { Maybe } from "functional/lib/Maybe"
import { Company } from "../model/Company"
import { Id, SupplyPoint } from "../model/Model"
import { AppCall, appCallCompanyCollection } from "../context/AppCall"
import { Unit, none, throws } from "functional/lib/core"
import { normalizeForFirebase } from "./client"
import { dbSupplyPointCollection } from "../hooks/ConectoHooks"


export const companyGet = 
  (id: Id): AppCall<Maybe<Company>> =>
  AppCall.do(async _ => {
    const collection = await _(appCallCompanyCollection)
    const data = await collection.doc(id).get()
    return data.exists ? data.data() : none
  })

export const companyUpdate =
  (
    args: {
      id: Id
      data: Partial<Company>
    }
  ): AppCall<Company> => 
    AppCall.do(async _ => {
      const collection = await _(appCallCompanyCollection)
      await collection.doc(args.id).update(
        normalizeForFirebase(args.data)
      )
      const result = await collection.doc(args.id).get()
      return result.data() ?? throws("Company not found")
    })

export const companyCreate =
  (
    args: {
      data: Company
    }
  ): AppCall<Company> => 
    AppCall.do(async _ => {
      const collection = await _(appCallCompanyCollection)
      const result = await collection.add(
        normalizeForFirebase(args.data)
      )
      return {
        ...args.data,
        id: result.id
      }
    })

export const supplyPointCreate =
  (
    args: {
      companyId: Id
      data: SupplyPoint
    }
  ): AppCall<SupplyPoint> => 
    AppCall.do(async _ => {
      const collection = dbSupplyPointCollection(args.companyId)
      const result = await collection.add(
        normalizeForFirebase(args.data)
      )
      return {
        ...args.data,
        id: result.id
      }
    })

export const supplyPointUpdate =
  (
    args: {
      companyId: Id
      data: SupplyPoint
    }
  ): AppCall<SupplyPoint> => 
    AppCall.do(async _ => {
      const collection = dbSupplyPointCollection(args.companyId)
      await collection.doc(args.data.id).update(
        normalizeForFirebase(args.data)
      )
      return args.data
    })


export const supplyPointDelete =
  (
    args: {
      companyId: Id
      id: Id
    }
  ): AppCall<Unit> => 
    AppCall.do(async _ => {
      const collection = dbSupplyPointCollection(args.companyId)
      await collection.doc(args.id).delete()
    })
  