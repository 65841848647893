import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/WarningRounded'
import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { Affine } from "functional/lib/optics/Affine"
import React, { useMemo } from "react"
import { Alert, Card, Col, Form, OverlayTrigger, Row } from "react-bootstrap"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { MONTHS, computeMonthlyWeightedAveragePrice } from "../../Utilities"
import { CellCol, FlexCol, FlexRow } from "../../components/Flexbox"
import { RState } from "../../functional/react/RState"
import { FormReactContext } from "../../functional/react/form/FormContext"
import { Validator } from "../../functional/react/form/Validated"
import { MonthlyOffer } from "../../model/Bid"
import { Process } from "../../model/Process"
import { BuyRequest } from "../../model/buyRequest/BuyRequest"
import { GasBuyRequest, GasBuyTypeMonthly } from "../../model/buyRequest/GasBuyRequest"
import { Draft } from "../../model/utils"
import { BidSuccess } from "../BidComponents"
import { BidFormFooter, bidFormIds, useBidFormFeatures } from "../GenericBidForm"
import {
  DifferentPayTerm,
  RenderTooltipPPP,
  RenderTooltipPPPLessVolume,
  RenderTooltipPriceOutOfRange
} from "../Utilities"
import { EncryptedPdfFilesEditor, ValidTermDaysEditor } from "./AnnualBidForm"
import { NumberEditor } from "../../components/editors/number"
import { computeMonthlyFee } from '../../utils/fee'

const fieldIds = {
  summerPrice: "summerPrice",
  winterPrice: "winterPrice",
  payTermDays: "payTermDays",
  priceCurve: (i: number) => `priceCurve_${i}`,
  validTermDays: "validTermDays",
  tac: bidFormIds.tac,
  price: "price"
}

export const MonthlyBidForm = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
  }
) => {

  const buyType = props.buyRequest.buyType as GasBuyTypeMonthly

  const {
    form,
    draft,
    acceptedTac,
    onSubmit,
    enableOffer,
    call
  } = useBidFormFeatures<MonthlyOffer>({
    buyRequest: props.buyRequest,
    process: props.process,

    validator: validateOffer(props),

    initialDraft: () => ({
      type: "monthly",
      monthlyCurve: buyType.demandCurve,
      priceCurve: Array(buyType.demandCurve.length).fill(none),
      payTermDays: props.process.minimumPayTermDays ? props.process.minimumPayTermDays : props.buyRequest.referencePayTermDays,
      validTermDays: props.process.validTermDays ?? 0,
      encryptedPdfFiles: [],
      decryptedPdfFiles: []
    })
  })

  const fields = RState.destructure(draft)("encryptedPdfFiles", "payTermDays", "validTermDays")

  const processType = props.process.type

  if (call.state.type === "success") {
    return <BidSuccess
      onNewOffer={
        IO.sequence([
          acceptedTac.apply(() => false),
          form.showErrorsState.apply(() => false),
          call.reset
        ]) 
      }
    />
  }

  return <Card>
    <Card.Header>
      <FlexRow>
        Ingresá tu Oferta
      </FlexRow>
    </Card.Header>
    
    <FormReactContext.Provider
      value={form}
    >

      <FlexCol
        alignItems="stretch"
        padding={16}
      >

        <PriceSection
          buyRequest={props.buyRequest}
          process={props.process}
          bid={draft}
        />

        <hr/>

        {showIf(processType == "tender" && (props.process.acceptsDifferentPayTerm ?? false))(
          <>
            <DifferentPayTerm
              fieldId={fieldIds.payTermDays}
              buyRequest={props.buyRequest}
              process={props.process}
              payTermDays={fields.payTermDays}
            />
            <hr/>
          </>
        )}

        {showIf((props.process.acceptsFileUpload ?? false) && props.process.type === "tender")(
          <>
            <EncryptedPdfFilesEditor
              buyRequest={props.buyRequest}
              state={fields.encryptedPdfFiles}
            />
            <hr/>
          </>
        )}

        <FlexCol
          padding={16}
          alignItems="flex-start"
        >
          <ValidTermDaysEditor
            fieldId={fieldIds.validTermDays}
            process={props.process}
            validTermDays={fields.validTermDays}
          />
          <BooleanCheckEditor
            style={{
              alignSelf: "flex-start"
            }}
            fieldId={fieldIds.tac}
            label="Acepto los Términos y Condiciones Particulares del Pedio de Compra"
            state={acceptedTac}
          />
        </FlexCol>

        <BidFormFooter
          process={props.process}
          call={call}
          enableOffer={enableOffer}
          errors={form.errors}
          onSubmit={onSubmit}
          showErrors={form.showErrors}
        />

      </FlexCol>

    </FormReactContext.Provider>

  </Card>
}


const PriceSection = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bid: RState<Draft<MonthlyOffer>>
  }
) => {

  const { 
    priceCurve, 
    monthlyCurve 
  } = RState.destructure(props.bid)("priceCurve", "monthlyCurve")

  const usdByMmbtuToUsdByDam = (usdByMmbtu: number) => usdByMmbtu * props.buyRequest.quality / 252

  const weightedAveragePrice = computeMonthlyWeightedAveragePrice(props.buyRequest, props.bid.value)

  const priceInUSDByDam = usdByMmbtuToUsdByDam(weightedAveragePrice)

  const commission = computeMonthlyFee(props.buyRequest, props.bid.value)

  // const isValidPrice =  weightedAveragePrice < (props.process.currentBestPrice ? props.process.currentBestPrice : props.process.maximumPrice) && priceHasSense
  const priceHasSense = props.process.type == 'auction' ? (props.process.currentBestPrice ? weightedAveragePrice > props.process.currentBestPrice * .9 : true) : true
  const isValidPrice = ((props.process.currentBestPrice ? weightedAveragePrice < props.process.currentBestPrice : weightedAveragePrice < 10)
    && weightedAveragePrice < (props.process.maximumPrice ? props.process.maximumPrice : 10)) && priceHasSense



  return <FlexCol
    alignItems='stretch'
    gap={8}
  >

    <p><b>Precio</b></p>

    <MonthPriceCurveEditor
      buyRequest={props.buyRequest}
      priceCurve={priceCurve}
      monthlyCurve={monthlyCurve}
    />

    <FlexRow
      alignItems='flex-start'
      paddingY={16}
    >

      <CellCol>
        Precio Promedio
      </CellCol>

      <CellCol
        alignItems='flex-start'
      >
        <PriceWithUnits
          usdByMmbtu={weightedAveragePrice}
          usdByDam3={priceInUSDByDam}
          error={!isValidPrice}
        />
      </CellCol>

      {
        Maybe.map(props.process.currentBestPrice)(best =>
          <>
            <CellCol>Mejor Oferta</CellCol>
            <CellCol
              alignItems='flex-start'
            >
              <PriceWithUnits
                usdByMmbtu={best}
                usdByDam3={usdByMmbtuToUsdByDam(best)}
              />
            </CellCol>
          </>  
        ) ??
        Maybe.map(props.process.maximumPrice)(max =>
          <>
            <CellCol>Precio Máximo</CellCol>
            <CellCol
              alignItems='flex-start'
            >
              <PriceWithUnits
                usdByMmbtu={max}
                usdByDam3={usdByMmbtuToUsdByDam(max)}
              />
            </CellCol>
          </>  
        ) ??
        <>
          <CellCol
            weight={2}
          />
        </>
      }


    </FlexRow>

    <Alert variant="info">
      <InfoIcon/> &nbsp; Comisión a pagar por el Vendedor adjudicado: <b>{commission?.toFixed(0) ?? "--"} USD </b>
    </Alert>

  </FlexCol>
}



const PriceWithUnits = (
  props: {
    usdByMmbtu: Maybe<number>
    usdByDam3: Maybe<number>
    error?: boolean
  }
) =>
  <FlexCol
    alignItems='flex-start'
    style={{ paddingLeft: 8 }}
  >

    <h6
      className={(props.error ?? false) ? "text-danger" : 'text-secondary'}
    >
      {props.usdByMmbtu?.toFixed(2) ?? "--"} USD/MMBTU
      {
        showIf(props.error ?? false)(
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={RenderTooltipPriceOutOfRange}
          >

              <InfoIcon
                fontSize="small"
                className={"text-danger"}
                style={{
                  marginLeft: 4,
                  cursor: "pointer"
                }}
              />
           
          </OverlayTrigger>
        )
      }
    </h6>
    
    <small>
      {props.usdByDam3?.toFixed(2) ?? "--"} USD/dam3
    </small>
  </FlexCol>


const validateOffer =
  (
    args: {
      buyRequest: BuyRequest
      process: Process
    }
  ) => 
  Validator.compose(

    Validator.predicate(
      draft => {
        const weightedAveragePrice = computeMonthlyWeightedAveragePrice(args.buyRequest, draft)

        const priceHasSense = args.process.type == 'auction' ? (args.process.currentBestPrice ? weightedAveragePrice > args.process.currentBestPrice * .9 : true) : true
        const isValidPrice = ((args.process.currentBestPrice ? weightedAveragePrice < args.process.currentBestPrice : weightedAveragePrice < 10)
          && weightedAveragePrice < (args.process.maximumPrice ? args.process.maximumPrice : 10)) && priceHasSense

        return isValidPrice
      },
      fieldIds.price, "El precio es inválido"
    ),

    Validator.record<Draft<MonthlyOffer>, MonthlyOffer>({

      priceCurve: Validator.list(i => Validator.notNone<number>(fieldIds.priceCurve(i), `Error en el precio del mes ${i + 1}`)),

      payTermDays: 
        (args.process.acceptsDifferentPayTerm ?? false) ?
          Validator.compose(
            Validator.notNone<number>(fieldIds.payTermDays, "Por favor ingresá los días de plazo"),
            Validator.predicate(
              it => it > 0, 
              fieldIds.payTermDays, "El plazo debe ser mayor a 0"
            ),
            Validator.predicate(
              it => it >= (args.process.minimumPayTermDays ?? 0), 
              fieldIds.payTermDays, "El plazo debe ser mayor o igual al mínimo"
            )
          ) :
          Validator.id(),

      validTermDays:
        Validator.compose(
          Validator.notNone<number>(fieldIds.validTermDays, "Por favor un plazo de validez de oferta"),
          Validator.predicate(
            it => it > 0, 
            fieldIds.validTermDays, "El plazo de validez de oferta debe ser mayor a 0"
          )
        )

    })
  )


const MonthPriceCurveEditor = (
  props: {
    buyRequest: GasBuyRequest
    priceCurve: RState<List<Maybe<number>>>
    monthlyCurve?: RState<Maybe<List<Maybe<number>>>>
  }
) => {

  const buyType = props.buyRequest.buyType as GasBuyTypeMonthly

  const startMonthIndex = props.buyRequest.supplyStartDate.getMonth()
  const startYear = props.buyRequest.supplyStartDate.getFullYear()

  const showMonthlyVolume = props.buyRequest.acceptsLessVolume ?? false

  const Cell = (
    props: {
      header?: boolean
      children?: React.ReactNode
    }
  ) =>
    <FlexCol
      style={{ 
        width: 0,
        flexGrow: 1, 
        borderBottom: props.header ? "solid 1px lightgray" : none,
      }}
      children={props.children}
    />

  const cards = useMemo(
    () => buyType.demandCurve.map((value, index) => 
      <FlexRow key={index}>
        <Cell>
          <Form.Label>
            {MONTHS[(startMonthIndex + index) % 12]} {startYear + Math.floor((startMonthIndex + index) / 12)}
          </Form.Label>
        </Cell>
        <Cell>
          <NumberEditor
            fieldId={fieldIds.priceCurve(index)}
            min={0}
            max={10}
            step={0.01}
            decimalPlaces={2}
            placeholder="0.00 USD/MMBTU"
            state={RState.applyAffine(props.priceCurve, Affine.listIndex(index))}
          />
        </Cell>
        {showMonthlyVolume && props.monthlyCurve !== none ?
          <Cell>
            <NumberEditor
              placeholder="dam3/mes"
              state={
                RState.applyAffine(
                  props.monthlyCurve, 
                  Affine.compose(
                    Affine.notNone(),
                    Affine.listIndex(index)
                  )
                )
            }
            />
          </Cell> :
          null
        }
        <Cell>
          <p className='text-secondary'>{buyType.demandCurve[index]}</p>
        </Cell>
        {(buyType.cmdCurve?.length ?? 0) > 0 && <Cell>
          <p className='text-secondary'>{buyType.cmdCurve?.[index]}</p>
        </Cell>}
      </FlexRow>
    ),
    []
  )

  return <FlexCol
    alignItems="stretch"
  >

    <FlexRow
      alignItems="stretch"
    >
      <Cell />
      <Cell header>
        <p>Precio Mensual Ofertado<br />[USD/MMBTU]</p>
      </Cell>
      {showIf(showMonthlyVolume)( 
        <Cell header>
          <h6>Volumen Mensual Ofertado</h6>
        </Cell>
      )}
      <Cell header>
        <p className='text-secondary'>Volumen Mensual Pedido<br />[dam3/mes]</p>
      </Cell>
      <Cell header>
        <p className='text-secondary'>CMD Mensual Pedido<br />[dam3/día]</p>
      </Cell>
    </FlexRow>

    {cards}


  </FlexCol>
}

