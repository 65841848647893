import { BuyRequestDraft } from "../../model/Model"
import { useTermsAndConditions } from "../../hooks/ConectoHooks"
import { none } from "functional/lib/core"
import NewTermsAndConditions from "../../components/NewTermsAndConditions"
import { MarketPlace } from "./MarketPlace"
import { NewWaitingValidation } from "../../NewWaitingValidation"
import { showIf } from "functional/lib/Maybe"
import { useNavigate } from "react-router"
import { FlexCol } from "../../components/Flexbox"
import { SandBoxMessage } from "./MarketPlaceComponents"
import { User } from "../../model/User"
import { appPaths } from "../navigation"


export const MarketPlacePage = (
  props: {
    currentUser: User
    setRequestDraft: (draft: BuyRequestDraft) => void
  }
) => {

  const currentUser = props.currentUser
  const validated = currentUser ? currentUser.validated : false

  const [acceptedUsersList, loading, error] = useTermsAndConditions()
  const lastTerms = acceptedUsersList?.[0]
  const acceptedTerms = lastTerms?.acceptedUsersList.includes(props.currentUser.id) ?? false

  const navigate = useNavigate()

  const onNewBuyRequest =
    (draft: BuyRequestDraft) => 
    () => {
      props.setRequestDraft(draft)
      navigate(appPaths.buyRequestForm)
    }

  return <FlexCol
    style={{
      width: "100%",
    }}
    alignItems="stretch"
  >
    {
      validated && !acceptedTerms && lastTerms !== none ?
        <NewTermsAndConditions user={currentUser} lastTerms={lastTerms} /> :
        none
    }

    <SandBoxMessage/>

    {
      !validated ?
        <NewWaitingValidation /> :
        <MarketPlace
          onNewBuyRequest={onNewBuyRequest}
        />
    }

    {showIf(currentUser.productList?.length == 0)("Sin producto valido")}

  </FlexCol>

}
