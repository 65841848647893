import { Alert } from "react-bootstrap"
import { FormErrors } from "../functional/react/form/FormErrors"
import { Record } from "functional/lib/Record"
import { none } from "functional/lib/core"
import { CSSProperties } from "react"



export const FormErrorsViewer = (
  props: {
    style?: CSSProperties
    form: {
      showErrors: boolean
      isValid: boolean
      errors: FormErrors
    }
  }
) => {

  const form = props.form

  return form.showErrors && !form.isValid ?
    <Alert 
      style={{
        textAlign: "left",
        ...props.style
      }}
      variant="danger"
    >
      Por favor corrige estos datos antes de continuar:
      <ul>
        {
          Record.entries(form.errors).map(([key, errors]) =>
            <li key={key}>{errors.join(", ")}</li>
          )
        }
      </ul>
    </Alert> :
    none
}
