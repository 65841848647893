import { IO } from "functional/lib/IO"
import { useEffect, useState } from "react"
import { useRState } from "../functional/react/RState"

export const deviceSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const getDeviceSize: IO<number> = 
  () => window.innerWidth

export const useDeviceSize = (): number => {

  const state = useRState<number>(getDeviceSize)

  useEffect(() => {
    const handleResize = state.apply(() => getDeviceSize())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return state.value
}
