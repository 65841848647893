import { Button, Spinner } from "react-bootstrap"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { Id } from "../../../model/Model"
import { Process } from "../../../model/Process"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { ConectoDialog, ExtraActions, ShowJsonAction } from "../detail-components"
import { useRState } from "../../../functional/react/RState"
import { AppCall, useAppCallData } from "../../../context/AppCall"
import { companyGet } from "../../../client/company"
import { userList } from "../../../client/user"
import { buyRequestGet } from "../../../client/buy-request"
import { match } from "functional/lib/match"
import { showIf } from "functional/lib/Maybe"
import MaterialTable from "material-table"
import { UserTable, userColumns } from "../user/table"
import { Unit, none } from "functional/lib/core"
import { dataToCsv, downloadTextFile } from "../../../utils/csv"
import { IO } from "functional/lib/IO"
import { processGetInvitedUserList } from "../../../client/process"

export const ProcessActions = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  return <FlexRow
    gap={8}
  >

    <InvitedEmailsButton
      buyRequest={props.buyRequest}
      data={props.data}
    />

    <ExtraActions
      actions={[
        <ShowJsonAction
          data={props.data}
          />
      ]}
    />

  </FlexRow>
}

const InvitedEmailsButton = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  const open = useRState(() => false)

  return <FlexRow>
    <Button
      variant="secondary"
      onClick={open.apply(() => true)}
    >
      Usuarios Invitados
    </Button>

    <ConectoDialog
      style={{
        minWidth: 1000
      }}
      open={open}
      title="Usuarios Invitados"
    >
      <InvitedEmailList
        buyRequest={props.buyRequest}
        data={props.data}
      />
    </ConectoDialog>

  </FlexRow>
}

const InvitedEmailList = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  const users = useAppCallData(
    processGetInvitedUserList({
      buyRequest: props.buyRequest,
      data: props.data
    })
  )

  const downloadCsv = 
    () => {

      const data = dataToCsv(users.value ?? [])({
        columns: [
          { header: "ID", cell: user => user.id },
          { header: "Nombre",cell: user => user.firstName ?? "" },
          { header: "Apellido", cell: user => user.lastName ?? ""},
          { header: "Email", cell: user => user.email ?? "" }
        ]
      })

      downloadTextFile({
        name: "invitados.csv",
        content: data,
        type: "text/csv"
      })()
    }


  return <FlexCol
    style={{
      width: "100%"
    }}
    alignItems="stretch"
    gap={8}
  >

    <UserTable
      title={`Total ${users.value?.length ?? "-"}`}
      options={{
        search: false,
        exportCsv: (columns, data) => {
          downloadCsv()
        } 
      }}

      isLoading={users.loading}
      data={users.value}
      columns={[
        userColumns.id,
        userColumns.fullName,
        userColumns.email,
        userColumns.companyIdList
      ]}
    />

    <FlexRow
      justifyContent="flex-end"
    >

      <Button
        variant="secondary"
        onClick={downloadCsv}
        disabled={users.loading}
      >
        Descargar CSV
      </Button>

    </FlexRow>

  </FlexCol>
}

