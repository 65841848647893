import { FlexCol, FlexRow } from "../../components/Flexbox"
import { IconButton } from "@material-ui/core"
import CopyIcon from "@material-ui/icons/FileCopy"
import { useSnackbarActions } from "../../components/Snackbar"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { UserRole, UserRoleT } from "../../model/User"
import { Value } from "@sinclair/typebox/value"
import { matchEnum } from "functional/lib/match"
import { Product, ProductT } from "../../model/Model"
import { Badge, Button, ButtonGroup, Spinner } from "react-bootstrap"
import { Column } from "material-table"
import { none } from "functional/lib/core"
import { useEffect } from "react"
import { RState } from "../../functional/react/RState"

export const buildColumns = <T extends object,>() => <K extends string>(columns: Record<K, Column<T>>) => columns

export const useScrollToTop = () => {
  useEffect(
    () => {
      window.scrollTo(0, 0);
    }, 
    []
  )
}


export const useCopyAction = () => {

  const snackbar = useSnackbarActions()

  const copyToClipboard = 
    (value: string) => 
    async () => {
      try {
        await navigator.clipboard.writeText(value)
        snackbar.show({ type: "success", message: "Id copiado al portapapeles" })()
      } catch (err) {
        snackbar.show({ type: "error", message: "Error al copiar al portapapeles" })()
      }
    }

  return copyToClipboard
}


export const IdView = (
  props: {
    style?: React.CSSProperties
    id?: string
  }
) => {

  const copyToClipboard = useCopyAction()

  return (
    <FlexRow 
      style={{ 
        width: 160,
        ...props.style 
      }}
    >
      <IconButton 
        size="small"
        onClick={event => {
          event.stopPropagation()
          if (props.id !== none) {
            copyToClipboard(props.id)()
          }
        }}
      >
        <CopyIcon/>
      </IconButton>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontFamily: "monospace"
        }}
      >
        {props.id ?? "-"}
      </div>
    </FlexRow>
  )
}

export const RoleListView = (
  props: {
    roles: Maybe<List<UserRole>>
  }
) => {

  return <FlexRow
    gap={4}
    justifyContent="flex-start"
  >
    {props.roles?.map(it => <RoleView role={it}/>)}
  </FlexRow>
}

export const ConectoBadge = (
  props: {
    style?: React.CSSProperties
    color?: string
    children?: React.ReactNode
  }
) => {

  return <FlexCol 
    style={{ 
      width: "fit-content",
      color: "white",
      fontSize: 12,
      fontWeight: "bold",
      backgroundColor: props.color ?? "#007bff",
      borderRadius: 6,
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 4,
      paddingBottom: 2,
      textWrap: "nowrap",
      ...props.style
    }}
    children={props.children}
  />
}

export const RoleView = (
  props: {
    role: Maybe<string>
  }
) => {

  return (
    <ConectoBadge>
      {Value.Check(UserRoleT, props.role) ? roleToText(props.role) : props.role}
    </ConectoBadge>
  )
}

export const roleToText = 
  (role: Maybe<UserRole>): string => 
  matchEnum(role ?? "_")({
    admin: "👑 Admin",
    buyer: "🛒 Comprador",
    seller: "💰 Vendedor",
    consultant: "🔍 Consultor",
    waiting: "⏳ Pendiente",
    _: "-"
  })

export const productToString = 
  (product: Maybe<Product>) =>
  matchEnum(product ?? "_")({
    gas: "🔥Gas",
    electricity: "⚡Electricidad",
    liquid: "⛽Combustible Líquido",
    mater: "♻️MATER",
    onsite: "📍Onsite",
    surplus: "➕Excedente",
    thermal: "🌋Térmico",
    _: "-"
  })

export const ProductView = (
  props: {
    product: Maybe<string>
  }
) => {
  return <ConectoBadge>
    {Value.Check(ProductT, props.product) ? productToString(props.product) : props.product}
  </ConectoBadge>
}

export const ProductListView = (
  props: {
    products: Maybe<List<string>>
  }
) => {
  return (
    <FlexRow 
      gap={4}
      flexWrap="wrap"
      justifyContent="flex-start"
    >
      {props.products?.map((product) => 
        <ProductView key={product} product={product} />
      )}
    </FlexRow>
  )
}

export const PageLoading = () => 
  <FlexCol
    justifyContent="center"
    alignItems="center"
    style={{
      height: "100vh"
    }}
    >
    <Spinner/>
  </FlexCol>

export const ButtonTabs = <K extends string>(
  props: {
    style?: React.CSSProperties
    state: RState<K>,
    options: List<{
      value: K,
      icon?: React.ReactNode,
      label: React.ReactNode
    }>
  }
) => 
  <ButtonGroup
    style={props.style}
  >
    {
      props.options.map(option => 
        <Button
          key={option.value}
          variant={props.state.value === option.value ? "primary" : "secondary"}
          onClick={props.state.apply(() => option.value)}
        >
          <FlexRow
            gap={4}
          >
            {option.icon}
            {option.label}
          </FlexRow>
          
        </Button>
      )
    }
  </ButtonGroup>
