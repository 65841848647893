import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Unit } from "functional/lib/core"

export const dataToCsv = 
  <T>(data: List<T>) =>
  (
    args: {
      separators?: {
        column?: string,
        row?: string
      }
      columns: List<{
        header: string,
        cell: (data: T) => string
      }>
    }
  ): string => {

    const separators = {
      column: args.separators?.column ?? ",",
      row: args.separators?.row ?? "\n"
    }

    return [
      args.columns.map(it => it.header).join(separators.column),
      ...(
        data.map(row =>
          args.columns.map(column => column.cell(row)).join(separators.column)  
        )
      )
    ].join(separators.row)
  }


export const downloadTextFile = (
  args: {
    name: string
    content: string
    type?: string
  }
): IO<Unit> =>
  () => {
    const blob = new Blob([args.content], { type: args.type })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = args.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }