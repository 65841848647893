import firebase, { firestore } from "firebase"
import { User } from "../model/User"
import { Unit, throws } from "functional/lib/core"
import { dbUserCollection } from "../hooks/ConectoHooks"
import { AppCall } from "../context/AppCall"
import { normalizeForFirebase } from "./client"

export const authSignUp = (
  args: {
    userDraft: Partial<User>,
    password: string
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {

    console.log("SIGNUP")

    const userDraft = args.userDraft

    const user = await firebase.auth().createUserWithEmailAndPassword(
      args.userDraft.email ?? throws("no email"), 
      args.password
    )

    if (!user) { throws("No user") }

    console.log(user)

    var userId = firebase.auth().currentUser?.uid

    console.log(userId)

    console.log("SET USER")

    await dbUserCollection()
      .doc(userId)
      .set(normalizeForFirebase(userDraft) as User, { merge: true })

    console.log("SEND EMAIL")

    await firebase.auth().currentUser?.sendEmailVerification()

    console.log("REDIRECT")

    window.location.replace('/login')

  })

