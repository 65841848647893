import firebase from "firebase"
import { AsyncState, useAsync } from "react-async"
import { deepWithoutUndefinedValues } from "../utils/convert"


export const useAsyncFunction = <I, O>(
  fn: (input: I) => Promise<O>
): [AsyncState<O>, (input: I) => void] => {
  const state = useAsync({ deferFn: (args) => fn(args[0]) })
  return [state, state.run as ((input: I) => void)]
}

export const removeFromArray = <T>(
  array: T[], 
  toRemove: T
) => {
  const index = array.indexOf(toRemove)
  if (index > -1) {
    array.splice(index, 1);
  }
  return array
}

export const RSA_KEY_LENGTH_BITS = 512

export const normalizeForFirebase = 
  deepWithoutUndefinedValues(
      it => 
        !(it instanceof Date) &&
        !(it instanceof firebase.firestore.FieldValue)
  )
