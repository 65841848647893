import GavelIcon from "@material-ui/icons/Gavel"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { Alert, Button, Card, Col, Form, Row, Table } from "react-bootstrap"
import { useSellerCompanies } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import { BuyRequestDraft, Id } from "../model/Model"
import { Maybe, showIf } from "functional/lib/Maybe"
import { Unit, none } from "functional/lib/core"
import { Process } from "../model/Process"
import { CellCol, FlexCol, FlexRow } from "../components/Flexbox"
import { RState } from "../functional/react/RState"
import { Draft } from "../model/utils"
import { IO } from "functional/lib/IO"
import { match, matchPartial } from "functional/lib/match"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { List } from "functional/lib/List"
import { Company } from "../model/Company"
import { processFieldIds } from "./BuyRequestCreateProcessStep"
import { DateTimeEditor } from "../components/editors/date"
import { NumberEditor } from "../components/editors/number"
import { useEffect } from "react"


export const ProcessEditor = (
  props: {
    request: Draft<BuyRequest>,
    process: RState<Draft<Process>>,
    buyRequestId?: Id // para publicar Nueva Ronda
  }
) => {

  const fields = RState.destructure(props.process)(
    "validTermDays", 
    "closeConsultsDate", 
    "closeDate", 
    "openDate",
    "guestIdList",
    "message",
    "guestIdList"
  )

  return <FlexCol
    alignItems='stretch'
  >
    <ProcessTypeSection
      request={props.request}
      process={props.process}
    />

    <hr />

    <ProcessTimeSection
      process={props.process}
    />

    <hr />

    <ValidTermDaysSection
      validTermDays={fields.validTermDays}
    />

    <hr />

    <ConsultSection
      closeDate={fields.closeDate?.value}
      closeConsultsDate={fields.closeConsultsDate}
    />

    <hr />

    <GuestsSection
      request={props.request}
      process={props.process.value}
      guestList={fields.guestIdList}
    />

    <hr />

    <AdditionalInfoSection
      message={fields.message}
    />
    
  </FlexCol>
}

const ValidTermDaysSection = (
  props: {
    validTermDays?: RState<Maybe<number>>
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Plazo sugerido de validez de Oferta</b>
    </FlexRow>

    <FlexRow className='justify-content-center'>

      <CellCol
        weight={1}
      />

      <CellCol
        alignItems="flex-start"
        padding={8}
      >
        <Form.Label>Días corridos desde la fecha y hora de cierre.</Form.Label>
        <NumberEditor
          min={0}
          state={props.validTermDays}
        />
      </CellCol>

      <CellCol
        weight={1}
      />

    </FlexRow>
  </FlexCol>
}

const ConsultSection = (
  props: {
    closeDate?: Maybe<Date>
    closeConsultsDate?: RState<Maybe<Date>>
  }
) => {

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <FlexRow
      justifyContent='center'
    >
      <b>Consultas</b>
    </FlexRow>

    <FlexRow>
      
      <CellCol
        weight={1}
      />

      <CellCol 
        alignItems="flex-start"
      >
        <label>Horario de cierre de recepción de consultas</label>
        <DateTimeEditor
          fieldId={processFieldIds.closeConsultsDate}
          state={props.closeConsultsDate}
        />
      </CellCol>

      <CellCol
        weight={1}
      />

    </FlexRow>

    {props.closeConsultsDate?.value &&
      props.closeDate &&
      props.closeDate <= props.closeConsultsDate?.value &&
      <Alert variant="danger">
        La fecha de cierre de recepción de Consultas debe ser anterior a la fecha de cierre de recepción de Ofertas
      </Alert>
    }

  </FlexCol>
}

export const ProcessTypeSection = (
  props: {
    request: BuyRequestDraft
    process: RState<Draft<Process>>
  }
) => {

  const fields = RState.destructure(props.process)(
    "type",
    "maxVariableCost",
    "maxFixedCost",
    "acceptsDifferentPayTerm",
    "acceptsFileUpload"
  )

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Tipo de Ronda</b>
    </FlexRow>
    <br />

    <FlexRow 
      alignItems="stretch"
      gap={16}
    >
      <CellCol
        alignItems="stretch"
      >
        <TenderBox
          selected={fields.type.value === "tender"}
          onClick={fields.type.apply(() => "tender")}

        />
      </CellCol>

      <CellCol
        alignItems="stretch"
      >
        <AuctionBox
          disabled={props.request.type !== "gas"}
          selected={fields.type.value === "auction"}
          onClick={fields.type.apply(() => "auction")}
        />
      </CellCol>
      
    </FlexRow>
    <br />

    <hr />

    <FlexRow
      justifyContent="center"
    >
      <b>Variables de Oferta</b>
    </FlexRow>

    <br />

    {
      match(props.request)({

        gas: () =>
          <GasVariablesSelector
            process={props.process} 
          />,

        electricity: request => 

          matchPartial(request.buyType)({

            mater: () => 
              <VariablesSelector
                options={energyPriceOptions} 
                state={fields.maxVariableCost}
              />,

            thermal: () =>
              <>
                <VariablesSelector
                  options={oAndMOptions} 
                  state={fields.maxFixedCost}
                />
                <br />
                <VariablesSelector
                  options={monthlyPower} 
                  state={fields.maxVariableCost}
                />
              </>

          })


      })

    }

    {props.request.type === "electricity" && props.request.buyType.type === 'onsite' && <>
      <VariablesSelector
        options={energyPriceOptions} 
        state={fields.maxVariableCost}
      />
      <br />
    </>}
  </FlexCol>
}

const AuctionBox = (
  props: {
    disabled: boolean
    onClick?: IO<Unit>
    selected: boolean
  }
) => 
  <Card 
    border={props.selected ? 'primary' : none}
    onClick={props.onClick}
    style={{
      cursor: "pointer"
    }}
  >
    <Card.Body>
      <FlexCol>
        <h4><GavelIcon /> Subasta</h4>
        <p>Compulsa de precios en tiempo real</p>
      </FlexCol>
    </Card.Body>
  </Card>

const TenderBox = (
  props: {
    selected: boolean
    onClick: IO<Unit>
  }
) => 
  <Card 
    border={props.selected ? 'primary' : none}
    onClick={props.onClick}
    style={{
      cursor: "pointer",
    }}
  >
    <Card.Body>
      <FlexCol>
        <h4><MailOutlineIcon /> Licitación</h4>
        <p>Compulsa de precios mediante sobre cerrrado</p>
      </FlexCol>
    </Card.Body>
  </Card>



const GasVariablesSelector = (
  props: {
    process: RState<Draft<Process>>
  }
) => {

  const fields = RState.destructure(props.process)(
    "defineMaxPrice",
    "maximumPrice",
    "defineMinPayTerm",
    "minimumPayTermDays",
    "maxFixedCost",
    "acceptsDifferentPayTerm",
    "acceptsFileUpload"
  )

  const defineMaxPrice = fields.defineMaxPrice?.value ?? false

  useEffect(
    !defineMaxPrice ?
      fields.maximumPrice?.apply(() => none) ?? IO.noOp :
      IO.noOp,
    [defineMaxPrice]
  )

  const defineMinPayTerm = fields.defineMinPayTerm?.value ?? false

  useEffect(
    !defineMinPayTerm ?
      fields.minimumPayTermDays?.apply(() => none) ?? IO.noOp :
      IO.noOp,
    [defineMinPayTerm]
  )

  return <FlexCol
    alignItems="stretch"
  >

    <FlexRow
      alignItems="flex-start"
      gap={8}
    >

      <CellCol
        alignItems="flex-start"
        padding={8}
      >
        <BooleanCheckEditor
          type="checkbox"
          label="Permitir al oferente adjuntar su modelo de contrato en PDF"
          state={RState.nonNone(fields.acceptsFileUpload)}
        />
      </CellCol>

    </FlexRow>

    <FlexRow
      alignItems="flex-start"
      gap={8}
    >

      <CellCol
        alignItems="flex-start"
        padding={8}
      >

        <BooleanCheckEditor
          type='checkbox'
          label="Definir un Precio Máximo"
          state={RState.nonNone(fields.defineMaxPrice)}
        />

        {fields.defineMaxPrice?.value && 
          <NumberEditor
            fieldId={processFieldIds.maximumPrice}
            min={0}
            max={10}
            placeholder="0.00 USD/MMBTU"
            step={0.01}
            decimalPlaces={2}
            state={fields.maximumPrice}
          />
        }

      </CellCol>

      <CellCol
        alignItems="flex-start"
        padding={8}
      >
        <BooleanCheckEditor
          type='checkbox'
          label="Se aceptan Ofertas con otro Plazo de Pago de Factura"
          state={RState.nonNone(fields.acceptsDifferentPayTerm)}
        />

        {showIf(fields.acceptsDifferentPayTerm?.value ?? false)(
          <>
            <BooleanCheckEditor
              type='checkbox'
              label="Definir un Plazo de Pago Mínimo"
              state={RState.nonNone(fields.defineMinPayTerm)}
            />
            {showIf(fields.defineMinPayTerm?.value ?? false)( 
              <NumberEditor
                fieldId={processFieldIds.minimumPayTermDays}
                placeholder="días"
                min={0}
                state={fields.minimumPayTermDays}
              />
            )}
          </>
        )}

      </CellCol>

    </FlexRow>



  </FlexCol>
}


const VariablesSelector = (
  props: {
    options: FieldOptions
    state?: RState<Maybe<number>>
  }
) => {
  const isValid = props.state?.value !== none

  return <>
    <Row className='justify-content-center'>
      <Col xs={1} />
      <Col xs={3}>{props.options.title}</Col>
      <Col xs={1}>
        <Row>
          <BooleanCheckEditor
            label="Si"
            value={isValid}
            onValueChanged={newValue => 
              props.state?.apply(() => newValue ? 0 : none)?.()
            }
            type='radio'
          />
        </Row>
        <Row>
          <BooleanCheckEditor
            label="No"
            value={!isValid}
            onValueChanged={newValue => 
              props.state?.apply(() => newValue ? none : 0)?.()
            }
            type='radio'
          />
        </Row>
      </Col>
      <Col xs={6} className='text-center'>
        {isValid && (props.state?.value == 0 || props.state?.value == none) &&
          <p className='text-danger'>{props.options.message}</p>}

        {isValid && <ProcessField
          options={props.options}
          state={props.state}
        />}
      </Col>
      <Col xs={1} />
    </Row>
  </>
}

const MaxFixedCost = (
  props: {
    processDraft: Process
    modifyProcessDraft: (changes: Partial<Process>) => void
  }
) => 
  <Form.Group controlId="maxPrice">
    <Row>
      <Col className='text-right'>
        <Form.Label>Precio Máximo:</Form.Label>
      </Col>
      <Col>
        <NumberEditor
          min={0}
          max={10}
          placeholder="0.00 USD"
          step={0.01}
          decimalPlaces={2}
          value={props.processDraft.maxFixedCost}
          onValueChanged={value => props.modifyProcessDraft({ maxFixedCost: value })}
        />
      </Col>
    </Row>
  </Form.Group>


const ProcessField = (
  props: {
    options?: FieldOptions
    state?: RState<Maybe<number>>
  }
) => 
  <Form.Group controlId="maxPrice">
    <Row>
      <Col className='text-right'>
        <Form.Label>{props.options?.inputTitle}</Form.Label>
      </Col>
      <Col>
        <NumberEditor
          {...props.options}
          state={props.state}
        />
      </Col>
    </Row>
  </Form.Group>


type FieldOptions = {
  title: string
  inputTitle: string
  message: string
  min: number
  max: number
  placeholder: string
  step: number
  decimalPlaces: number
}

const energyPriceOptions: FieldOptions = {  //Precio de Energía
  title: 'Cargar un Precio de Energía Máximo',
  inputTitle: "Precio de Energía Máximo",
  message: 'Cargá un Precio de Energía Máximo',
  decimalPlaces: 2,
  max: 70,
  min: 0,
  placeholder: '0.00 US$/MWh',
  step: 1
}

const oAndMOptions: FieldOptions = { //Costoe O&M
  title: 'Cargar un Costo de O&M Máximo',
  inputTitle: "Costo de O&M Máximo",
  message: 'Cargá un Costo de O&M Máximo',
  decimalPlaces: 2,
  max: 20,
  min: 0,
  placeholder: '0.00 US$/MW-mes',
  step: 2
}

const monthlyPower: FieldOptions = { //Costo Mensual de Potencia (US$/MW-mes)
  title: 'Cargar un Costo Mensual de Potencia Máximo',
  inputTitle: "Costo Mensual de Potencia Máximo",
  message: 'Cargá un Costo Mensual de Potencia Máximo',
  decimalPlaces: 2,
  max: 30000,
  min: 0,
  placeholder: '0.00 US$/MW-mes',
  step: 1
}


export const ProcessTimeSection = (
  props: {
    process: RState<Draft<Process>>
  }
) => {

  const fields = RState.destructure(props.process)(
    "openDate",
    "closeDate"
  )

  const draft = props.process.value

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent="center"
    >
      <b>Horarios</b>
    </FlexRow>

    <FlexRow>

      <CellCol
        weight={1}
      />

      <CellCol
        padding={8}
        alignItems="flex-start"
      >
        <label>Horario de Apertura</label>
        <DateTimeEditor
          fieldId={processFieldIds.openDate}
          state={fields.openDate}
        />
      </CellCol>
      

      <CellCol
        padding={8}
        alignItems="flex-start"
      >
        <label>Horario de Cierre</label>
        <DateTimeEditor
          fieldId={processFieldIds.closeDate}
          state={fields.closeDate}
        />
      </CellCol>

      <CellCol
        weight={1}
      />

    </FlexRow>

    {draft.openDate &&
      draft.closeDate &&
      draft.closeDate <= draft.openDate &&
      <Alert variant="danger">
        La fecha de Cierre debe ser posterior a la fecha de Apertura
      </Alert>
    }
  </FlexCol>
}

const GuestsSection = (
  props: {
    request: Draft<BuyRequest>
    process: Draft<Process>
    guestList: RState<List<Id>>
  }
) => {

  const [sellerCompanies, loading, error] = useSellerCompanies(
    props.request.type, 
    props.request.buyType.type, 
    props.request.buyerCompanyId
  )

  const selectedState = (id: Id): RState<boolean> =>
    ({
      value: props.guestList.value.includes(id),
      apply: transform => 
        props.guestList.apply(previous => {
          const hadIt = previous.includes(id)
          const haveIt = transform(hadIt)
          return !hadIt && haveIt ? [...previous, id] : 
            hadIt && !haveIt ? previous.filter(it => it !== id) :
            previous
        })
    })

  const CompanyRow = (
    props: {
      company: Company
      checkState?: RState<boolean>
    }
  ) => {

    const company = props.company

    return <FlexRow 
      key={company.id}
      alignItems="center"
      paddingY={10}
      paddingX={20}
      style={{
        cursor: "pointer"
      }}
      onClick={props.checkState?.apply(it => !it)}

    >

      <img 
        src={company.imageUrl} 
        style={{ height: 40 }} 
        alt='' 
      />

      <CellCol>
        {company.businessName}
      </CellCol>

      <BooleanCheckEditor
        style={{
          fontSize: 20,
          marginBottom: 28
        }}
        type="checkbox"
        state={props.checkState}
      />

    </FlexRow>
  }


  return  <FlexCol
    alignItems="stretch"
    gap={16}
  >

    <FlexRow>
      <b>Seleccioná a los Productores y Comercializadores a invitar</b>
    </FlexRow>

    <FlexRow
      gap={16}
      justifyContent="center"
    >

      {sellerCompanies && 
        <Button 
          variant='outline-primary'
          onClick={
            props.guestList.apply(() => sellerCompanies.map(it => it.id))
          }
        >
          Invitar a Todos
        </Button>
      }

      <Button 
        variant='outline-danger'
        disabled={props.guestList.value.length == 0}
        onClick={props.guestList.apply(() => [])}
      >
        Borrar Todos
      </Button>

    </FlexRow>

    <FlexRow
      gap={16}
      alignItems="flex-start"
    >
      <CellCol
        alignItems="stretch"
      >
        <FlexRow
          padding={8}
        >
          <b>Productores</b>
        </FlexRow>
        <hr style={{ margin: 0 }}/>
        {sellerCompanies?.filter(it => it.producer ?? false)?.map((company, i) =>
          <>
            <CompanyRow
              key={company.id}
              company={company}
              checkState={selectedState(company.id)}
            />
            <hr key={`separator_${i}`} style={{ margin: 0 }}/>
          </>
        )}
      </CellCol>

      <CellCol
        alignItems="stretch"
      >
        <FlexRow
          padding={8}
        >
          <b>Comercializadoras</b>
        </FlexRow>
        <hr style={{ margin: 0 }}/>
        {sellerCompanies?.filter(it => !(it.producer ?? false))?.map((company, i) =>
          <>
            <CompanyRow
              key={company.id}
              company={company}
              checkState={selectedState(company.id)}
            />
            <hr key={`separator_${i}`} style={{ margin: 0 }}/>
          </>
        )}
      </CellCol>
    </FlexRow>

    <FlexRow
      style={{ height: 16 }}
    />

    {props.process.type === 'auction' && props.guestList.value.length < 2
      && <Alert variant={'warning'}>En una Subasta se necesitan al menos dos invitados</Alert>
    }

  </FlexCol>
}

const AdditionalInfoSection = (
  props: {
    message?: RState<Maybe<string>>
  }
) => {

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow>
      <b>Ingresá otra información y/o comentarios adicionales que sea necesario comunicar a los proveedores</b>
    </FlexRow>
    <br />

    <FlexRow>
      <Form.Control 
        as="textarea" 
        style={{ width: '100%' }} 
        rows={3}
        value={props.message?.value ?? ""}
        onChange={event => {
          props.message?.apply(() => event.target.value)?.()
        }} 
      />
    </FlexRow>
  </FlexCol>
}
