import firebase from "firebase"
import { firebaseConfig } from "./config"
import { ConectoRouting } from "./ConectoRouting"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import { initializeNotificationPanel } from "./NotificationsPanel"
import { useCurrentUser } from "./hooks/ConectoHooks"
import "bootstrap/dist/css/bootstrap.min.css"
import { createRoot } from "react-dom/client"
import { useMemo } from "react"
import { ReactAppContext, appContextNew } from "./context/App"
import { throws } from "functional/lib/core"
import { useRState } from "./functional/react/RState"
import { SnackbarProvider } from "./components/Snackbar"


const main = () => {
  firebase.initializeApp(firebaseConfig)

  if ("serviceWorker" in navigator) {

    const launch =
      async () => {
        try {
          const registration = await navigator.serviceWorker.register(new URL("./firebase-messaging-sw.js"))
          console.log("Registration successful, scope is:", registration.scope)
        } catch(err) {
          console.log("Service worker registration failed, error:", err)
        }
      }

    launch()
  }

  initializeNotificationPanel()

  createRoot(
    document.getElementById("root") ?? throws("No root element")
  ).render(<ConectoApp /> as any)

}

const ConectoApp = () => {

  const [currentUser, loggingIn, error] = useCurrentUser()

  const training = useRState(() => true)

  const context = useMemo(
    appContextNew({
      user: currentUser,
      training: training.value,
    }),
    [currentUser?.id, training.value]
  )

  return <ReactAppContext.Provider
    value={context}
  >
    <SnackbarProvider>
      <ReactNotification />
      <ConectoRouting 
        user={currentUser} 
        training={training}
      />
    </SnackbarProvider>
  </ReactAppContext.Provider>
}

main()

