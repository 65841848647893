import { Maybe } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { matchEnum } from "functional/lib/match"
import { Adapter } from "functional/lib/optics/Adapter"
import { RState } from "../../functional/react/RState"
import { FlexCol, FlexRow } from "../../components/Flexbox"
import { StringChoiceEditor } from "../../components/editors/string"
import { productToString } from "./components"


export const BooleanFilter = (
  props: {
    label: string
    state: RState<Maybe<boolean>>
  }
) => {

  return <FlexCol
    alignItems="flex-start"
    style={{
      width: 160
    }}
  >
    <FlexRow
      paddingX={8}
      paddingY={4}
    >
      {props.label}
    </FlexRow>
    <StringChoiceEditor
      options={[
          ["-", "Todos"],
          ["true", "Si"],
          ["false", "No"]
      ]}
      state={RState.applyAdapter(props.state, maybeBooleanStringAdapter)}
    />
  </FlexCol>
}

const maybeBooleanStringAdapter: Adapter<Maybe<boolean>, string> = {
  to: it => it === none ? "-" : it ? "true" : "false",
  from: it => matchEnum(it)({
    "-": none,
    "true": true,
    "false": false
  })
}

export const RoleFilter = (
  props: {
    label: string
    state: RState<string>
  }
) => {

  return <FlexCol
    alignItems="flex-start"
    style={{
      width: 160
    }}
  >
    <FlexRow
      paddingX={8}
      paddingY={4}
    >
      {props.label}
    </FlexRow>
    <StringChoiceEditor
      options={[
          ["", "Todos"],
          ["buyer", "🛒 Comprador"],
          ["seller", "💰 Vendedor"],
          ["consultant", "🔍 Consultor"],
          ["admin", "👑 Admin"],
      ]}
      state={props.state}
    />
  </FlexCol>
}

export const ProductFilter = (
  props: {
    label: string
    state: RState<string>
  }
) => {

  return <FlexCol
    alignItems="flex-start"
    style={{
      width: 160
    }}
  >
    <FlexRow
      paddingX={8}
      paddingY={4}
    >
      {props.label}
    </FlexRow>
    <StringChoiceEditor
      options={[
          ["", "Todos"],
          ["gas", "🔥 Gas"],
          ["electricity", "⚡ Electricidad"],
          ["liquid", "⛽ Combustible Líquido"],
          ["mater", "♻️ MATER"],
          ["onsite", "📍 Onsite"],
          ["surplus", "➕ Excedente"],
          ["thermal", "🌋 Térmico"],
      ]}
      state={props.state}
    />
  </FlexCol>
}

