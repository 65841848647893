import { List } from "./List"

export type None = undefined
export const none: None = undefined

export type Unit = void
export const unit: Unit = undefined

export const id = <T>(x: T): T => x

export type Endo<T> = (input: T) => T

export const throws = (value: unknown): never => {
  throw value
}

export const not = (value: boolean) => !value

export const compose: {
  <T1>(): (input: T1) => T1
  <T1, T2>(
    f1: (input: T1) => T2
  ): (input: T1) => T2
  <T1, T2, T3>(
    f1: (input: T1) => T2,
    f2: (input: T2) => T3
  ): (input: T1) => T3
  <T1, T2, T3, T4>(
    f1: (input: T1) => T2,
    f2: (input: T2) => T3,
    f3: (input: T3) => T4
  ): (input: T1) => T4
  <T1, T2, T3, T4, T5>(
    f1: (input: T1) => T2,
    f2: (input: T2) => T3,
    f3: (input: T3) => T4,
    f4: (input: T4) => T5
  ): (input: T1) => T5
  <T1, T2, T3, T4, T5, T6>(
    f1: (input: T1) => T2,
    f2: (input: T2) => T3,
    f3: (input: T3) => T4,
    f4: (input: T4) => T5,
    f5: (input: T5) => T6
  ): (input: T1) => T6
  <T1, T2, T3, T4, T5, T6, T7>(
    f1: (input: T1) => T2,
    f2: (input: T2) => T3,
    f3: (input: T3) => T4,
    f4: (input: T4) => T5,
    f5: (input: T5) => T6,
    f6: (input: T6) => T7
  ): (input: T1) => T7
} = 
  (...fns: List<(input: any) => any>) =>
    (input: any) =>
      fns.reduce((acc, fn) => fn(acc), input)

export const pipe = <T>(input: T): {
  (): T
  <T1>(f1: (input: T) => T1): T1
  <T1, T2>(
    f1: (input: T) => T1,
    f2: (input: T1) => T2
  ): T2
  <T1, T2, T3>(
    f1: (input: T) => T1,
    f2: (input: T1) => T2,
    f3: (input: T2) => T3
  ): T3
  <T1, T2, T3, T4>(
    f1: (input: T) => T1,
    f2: (input: T1) => T2,
    f3: (input: T2) => T3,
    f4: (input: T3) => T4
  ): T4
  <T1, T2, T3, T4, T5>(
    f1: (input: T) => T1,
    f2: (input: T1) => T2,
    f3: (input: T2) => T3,
    f4: (input: T3) => T4,
    f5: (input: T4) => T5
  ): T5
  <T1, T2, T3, T4, T5, T6>(
    f1: (input: T) => T1,
    f2: (input: T1) => T2,
    f3: (input: T2) => T3,
    f4: (input: T3) => T4,
    f5: (input: T4) => T5,
    f6: (input: T5) => T6
  ): T6
} => 
  (...fns: List<(input: any) => any>) =>
    fns.reduce((acc, fn) => fn(acc), input)

