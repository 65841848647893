import { compose, Endo, id, none } from "../core"
import { List } from "../List"
import { Maybe } from "../Maybe"

export type Affine<A, B> = {
  get: (a: A) => Maybe<B>,
  over: (transform: Endo<B>) => Endo<A>
}

export const Affine = {

  notNone: <T>(): Affine<Maybe<T>, T> => ({
    get: id,
    over: Maybe.lift
  }),

  prism: 
    <A extends { type: string }>() => 
    <T extends A["type"]>(type: T): Affine<A, A & { type: T }> => ({
      get: value => value.type === type ? (value as A & { type: T }) : none,
      over: transform => a => a.type === type ? transform(a as A & { type: T }) : a
    }),

  listIndex: <T>(index: number): Affine<List<T>, T> => ({
    get: it => it.at(index),
    over: transform => 
      prev => 
        index < 0 || prev.length <= index ?
          prev :
          prev.toSpliced(index, 1, transform(prev[index]))
  }),

  identity: <T>(): Affine<T, T> => ({
    get: id,
    over: id
  }),

  compose: <A, B, C>(
    lhs: Affine<A, B>,
    rhs: Affine<B, C>
  ): Affine<A, C> => ({
    get: Maybe.compose(lhs.get, rhs.get),
    over: compose(rhs.over, lhs.over)
  }),

  composeN: id<{
    <T1>(): Affine<T1, T1>
    <T1, T2>(l1: Affine<T1, T2>): Affine<T1, T2>
    <T1, T2, T3>(l1: Affine<T1, T2>, l2: Affine<T2, T3>): Affine<T1, T3>
    <T1, T2, T3, T4>(l1: Affine<T1, T2>, l2: Affine<T2, T3>, l3: Affine<T3, T4>): Affine<T1, T4>
    <T1, T2, T3, T4, T5>(l1: Affine<T1, T2>, l2: Affine<T2, T3>, l3: Affine<T3, T4>, l4: Affine<T4, T5>): Affine<T1, T5>
  }>(
    (...lenses: List<Affine<any, any>>): Affine<any, any> => lenses.reduce(Affine.compose, Affine.identity())
  )

}
