import { Maybe } from "functional/lib/Maybe"
import { RState } from "../../functional/react/RState"
import { List } from "functional/lib/List"
import { useFieldErrors } from "../../functional/react/form/FormContext"
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"
import { none } from "functional/lib/core"


export const YearMonthEditor = (
  props: {
    fieldId?: string
    style?: React.CSSProperties
    state?: RState<Maybe<Date>>
  }
) => 
  <DateEditor
    fieldId={props.fieldId}
    style={props.style}
    state={props.state}
    openTo="year"
    views={["year", "month"]}
    minDate={new Date()}
  />

export const DateTimeEditor = (
  props: {  
    fieldId?: string
    style?: React.CSSProperties
    state?: RState<Maybe<Date>>
    openTo?: "year" | "month" | "date" | "hours" | "minutes"
    views?: List<"year" | "month" | "date" | "hours" | "minutes">
    minDate?: Date
  }
) => {

  const errors = useFieldErrors(props.fieldId)

  return <MuiPickersUtilsProvider
    utils={DateFnsUtils} 
    locale={esLocale}
  >
    <DateTimePicker
      autoOk
      error={errors.length > 0}
      size='small'
      variant='inline'
      style={{
        width: "100%",
        paddingTop: 4,
        cursor: 'pointer',
        ...props.style
      }}
      inputVariant='outlined'
      // bootstrap style
      inputProps={{ 
        style: {
          cursor: 'pointer',
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: 12,
          paddingRight: 12,
          textTransform: 'capitalize'
        } 
      }}
      openTo={props.openTo}
      minDate={props.minDate}
      views={props.views?.slice()}
      emptyLabel="Fecha y hora"
      value={props.state?.value ?? null}
      onChange={it => props.state?.apply(() => it ?? none)?.()}
    />
  </MuiPickersUtilsProvider>
}

export const DateEditor = (
  props: {  
    fieldId?: string
    style?: React.CSSProperties
    state?: RState<Maybe<Date>>
    openTo?: "year" | "month" | "date"
    views?: List<"year" | "month" | "date">
    minDate?: Date
  }
) => {

  const errors = useFieldErrors(props.fieldId)

  return <MuiPickersUtilsProvider 
    utils={DateFnsUtils} 
    locale={esLocale}
  >
    <DatePicker
      autoOk
      error={errors.length > 0}
      size='small'
      variant='inline'
      style={{
        width: "100%",
        paddingTop: 4,
        cursor: 'pointer',
        ...props.style
      }}
      inputVariant='outlined'
      // bootstrap style
      inputProps={{ 
        style: {
          cursor: 'pointer',
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: 12,
          paddingRight: 12,
          textTransform: 'capitalize'
        } 
      }}
      emptyLabel="Año y Mes"
      openTo={props.openTo}
      minDate={props.minDate}
      views={props.views?.slice()}
      label={!props.state?.value}
      value={props.state?.value ?? null}
      onChange={it => props.state?.apply(() => it ?? none)?.()}
    />
  </MuiPickersUtilsProvider>
}

