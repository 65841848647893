import { Button } from "react-bootstrap"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { BooleanCheckEditor, BooleanSelectEditor } from "../../../components/editors/boolean"
import { NumberEditor } from "../../../components/editors/number"
import { ProductListEditor, SelectEditor } from "../../../components/editors/select"
import { StringEditor } from "../../../components/editors/string"
import { defaultFeeConditions } from "../../../config"
import { RState, useRState } from "../../../functional/react/RState"
import { Company, CompanyRole } from "../../../model/Company"
import { FeeConditions } from "../../../model/buyRequest/BuyRequestBase"
import { Draft } from "../../../model/utils"
import { PageLoading, RoleView, useScrollToTop } from "../components"
import { CallError, CompanyIcon, ConectoGrid, DetailSection, Field, SubmitButton } from "../detail-components"
import { Maybe } from "functional/lib/Maybe"
import { useNavigate, useParams } from "react-router"
import { id, none, throws } from "functional/lib/core"
import { AppCall, useAppAsynchronism } from "../../../context/AppCall"
import { companyCreate, companyUpdate } from "../../../client/company"
import { matchEnum } from "functional/lib/match"
import { useCompany } from "../../../hooks/ConectoHooks"
import { appPaths } from "../../navigation"

export const CompanyCreatePage = () => {

  useScrollToTop()

  return <CreateCompanyForm/>
}

export const CompanEditPage = () => {

  const params = useParams()

  const id = params.id as string

  const [data, loading, error] = useCompany(id)

  useScrollToTop()

  return Maybe.map(data)(it => 
    <CreateCompanyForm
      initial={it}
    />
  ) ?? <PageLoading/>
}

const defaultCompanyDraft: Company = {
  id: "",
  businessName: "",
  cuit: "",
  displayName: "",
  shortName: "",
  role: "seller",
  producer: false,
  mockCompany: false,
  feeConditions: defaultFeeConditions,
  productList: [],
  imageUrl: ""
}

export const CreateCompanyForm = (
  props: {
    initial?: Company
  }
) => {

  const mode: "create" | "edit" = (props.initial?.id ?? "") == "" ? "create" : "edit"

  const state = useRState<Draft<Company>>(
    () => ({
      ...defaultCompanyDraft,
      ...props.initial
    })
  )

  const navigate = useNavigate()

  const call = useAppAsynchronism(
    (args: {
      data: Company
    }) => 
      AppCall.do(async _ => {
        const company = await _(
          args.data.id === "" ?
            companyCreate({ data: args.data }) :
            companyUpdate({
              id: args.data.id,
              data: args.data
            })
        )
        navigate(appPaths.company(company.id ?? throws("no company id")).detail)
      })
  )

  const imageUrl = state.value.imageUrl

  return <FlexCol
    style={{
      padding: 16
    }}
    alignItems="stretch"
  >
    <FlexCol
      style={{
        padding: 16,
        borderRadius: 8,
        background: "white",
        boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)"
      }}
      gap={8}
      alignItems="stretch"
    >

      <FlexRow
        gap={8}
        justifyContent="flex-start"
      >
        {
          imageUrl !== none && imageUrl !== "" ?
            <img
              src={imageUrl}
              style={{
                height: 64
              }}
            /> :
            <CompanyIcon
              fontSize="large"
            />
        }
        <h1>{state.value.businessName.trim() == "" ? 
          (matchEnum(mode)({
            create: "Nueva Empresa",
            edit: "Razon Social"
          })) : 
          state.value.businessName}</h1>
      </FlexRow>

      <hr/>

      <CompanyEditor
        state={state}
      />

      <hr/>

      <FlexRow
        justifyContent="flex-end"
        gap={8}
      >

        <Button
          variant="danger"
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>

        <SubmitButton<{ data: Company }>
          call={call}
          value={Maybe.do(_ => ({
            data: {
              ...state.value,
              discount: none,
              feeConditions: {
                factor: _(state.value.feeConditions?.factor),
                min: _(state.value.feeConditions?.min),
                max: _(state.value.feeConditions?.max)
              }
            }
          }))}
          label={
            matchEnum(mode)({
              create: "Crear Empresa",
              edit: "Guardar Cambios"
            })
          }
        />

      </FlexRow>

      <CallError
        call={call}
      />

    </FlexCol>
  </FlexCol>
}


export const CompanyEditor = (
  props: {
    state: RState<Draft<Company>>
  }
) => {

  const fields = RState.destructure(props.state)(
    "businessName",
    "cuit",
    "displayName",
    "shortName",
    "imageUrl",
    "role",
    "producer",
    "mockCompany",
    "productList",
    "feeConditions"
  )

  return <FlexCol
    gap={8}
    alignItems="stretch"
  >

    <DetailSection
      title="Datos de la Empresa"
    >
      <ConectoGrid
        defaults={{
          xs: 3,
        }}
        cells={[

          <Field
            label="Nombre"
          >
            <StringEditor
              style={{ width: "100%" }}
              state={RState.nonNone(fields.displayName)}
            />
          </Field>,

          <Field
            label="Razon Social"
          >
            <StringEditor
              state={fields.businessName}
            />
          </Field>,

          <Field
            label="Nombre Corto"
          >
            <StringEditor
              state={RState.nonNone(fields.shortName)}
            />
          </Field>,

          <Field
            label="CUIT"
          >
          <StringEditor
            state={RState.nonNone(fields.cuit)}
          />
          </Field>,

          [{ xs: 6 }, <Field
            label="URL Imagen"
          >
            <StringEditor
              state={RState.nonNone(fields.imageUrl)}
            />
          </Field>],
         
        ]}
      />
    </DetailSection>

    <hr/>

    <DetailSection
      title="Permisos"
    >
      <ConectoGrid
        defaults={{
          xs: 3,
        }}
        cells={[
          <Field
            label="Rol"
          >
            <SelectEditor<CompanyRole>
              state={fields.role}
              options={["buyer", "seller", "consultant"]}
              render={role => <RoleView role={role}/>}
            />
          </Field>,
          <Field
            label="Productos"
          >
            <ProductListEditor
              state={RState.nonNone(fields.productList)}
            />
          </Field>,
          <Field
            label="Productor"
          >
            <BooleanSelectEditor
              state={RState.nonNone(fields.producer)}
            />
          </Field>,
          <Field
            label="Mock"
          >
            <BooleanSelectEditor
              state={RState.nonNone(fields.mockCompany)}
            />
          </Field>,
        ]}
      />
    </DetailSection>

    <hr/>
  
    <FeeConditionsSection
      state={RState.nonNone(fields.feeConditions)}
    />

  </FlexCol>
}


const FeeConditionsSection = (
  props: {
    state?: RState<Draft<FeeConditions>>
  }
) => {

  const fields = Maybe.map(props.state)(it => RState.destructureAll(it))

  return <DetailSection
    title="Comisiones"
  >

      <ConectoGrid
        defaults={{
          xs: 3,
        }}
        cells={[
          <Field
            label="Porcentaje"
          >
            <NumberEditor
              state={fields?.factor}
              percent
              decimalPlaces={3}
            />
          </Field>,
          <Field
            label="Mínimo"
          >
            <NumberEditor
              state={fields?.min}
              decimalPlaces={2}
            />
          </Field>,
          <Field
            label="Máximo"
          >
            <NumberEditor
              state={fields?.max}
              decimalPlaces={2}
            />
          </Field>,
        ]}
      />

  </DetailSection>
}