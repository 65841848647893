import MaterialTable, { MaterialTableProps } from "material-table"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { dbUserCollection, useEntityList } from "../../../hooks/ConectoHooks"
import { User } from "../../../model/User"

import { IdView, ProductListView, RoleListView, RoleView, buildColumns } from "../components"
import { shallowCopy } from "../../../utils/utils"
import { List } from "functional/lib/List"
import { id, none, pipe } from "functional/lib/core"
import { StringChoiceEditor } from "../../../components/editors/string"
import { RState, useRState } from "../../../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { matchEnum } from "functional/lib/match"
import { Adapter } from "functional/lib/optics/Adapter"
import { useNavigate } from "react-router"
import { UserBadges } from "./detail"
import { BooleanFilter, RoleFilter } from "../filters"
import { dateToNiceFormat, dateToShortFormat } from "../../../Utilities"
import { CompanyView, FieldCompanies } from "../detail-components"
import { softMatch } from "../search"
import { appPaths } from "../../navigation"

export const AdminUsersList = () => {

  return <FlexCol
    alignItems="stretch"
    paddingY={16}
    gap={16}
  >
    <AllUserTable/>
  </FlexCol>
}

const AllUserTable = () => {

  const filter = useRState(
    () => ({
      mock: id<Maybe<boolean>>(none),
      enabled: id<Maybe<boolean>>(none),
      validated: id<Maybe<boolean>>(none),
      training: id<Maybe<boolean>>(none),
      role: ""
    })
  )

  const filters = RState.destructureAll(filter)

  const [list, loading, error] = useEntityList<User>(
    pipe(dbUserCollection())(
      it => Maybe.map(filters.mock.value)(mock => it.where("mockUser", "==", mock)) ?? it,
      it => Maybe.map(filters.enabled.value)(enabled => it.where("standBy", "==", !enabled)) ?? it,
      it => Maybe.map(filters.validated.value)(validated => it.where("validated", "==", validated)) ?? it,
      it => Maybe.map(filters.training.value)(training => it.where("training", "==", training)) ?? it,
      it => filters.role.value !== "" ? it.where("roleList", "array-contains", filters.role.value) : it
    )
  )

  return <FlexCol
  alignItems="stretch"
  gap={8}
>

    <FlexCol
      alignItems="flex-start"
      padding={16}
      style={{
        background: "white",
        borderRadius: 16,
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)"
      }}
    >

      <h5>
        Filtros
      </h5>

      <FlexRow
        justifyContent="flex-start"
        gap={8}
      >

        <RoleFilter
          label={"Rol"}
          state={filters.role}
        />

        <BooleanFilter
          label={"Validado"}
          state={filters.validated}
        />

        <BooleanFilter
          label={"Habilitado"}
          state={filters.enabled}
        />

        <BooleanFilter
          label={"Training"}
          state={filters.training}
        />

        <BooleanFilter
          label={"Mock"}
          state={filters.mock}
        />

      </FlexRow>

    </FlexCol>

    

    <UserTable
      title={"Usuarios [" + (list?.length ?? "-") + "]"}
      isLoading={loading}
      data={list ?? []}
      options={{
        pageSize: 20
      }}
      columns={[
        columns.id,
        columns.creationDate,
        columns.fullName,
        columns.email,
        columns.roleList,
        columns.productList,
        columns.state
      ]} 
    />
  </FlexCol>
}

const tableStyle: React.CSSProperties = {
  borderRadius: 16,
  overflow: "hidden"
}

export const UserTable = (
  props: Omit<MaterialTableProps<User>, "data"> & {
    data: Maybe<List<User>>
  }
) => {

  const navigate = useNavigate()

  return <MaterialTable
  style={tableStyle}
  
  {...props}
  options={{ 
    exportButton: true, 
    exportAllData: true,
    columnResizable: true,
    sorting: true,
    ...props.options
    //grouping: true,
    //filtering: true,
  }}
  data={(props.data ?? []) as User[]}
  columns={props.columns.map(shallowCopy)}
  onRowClick={(event, data) => {
    const id = data?.id
    if (id !== none) {
      navigate(appPaths.user(id))
    }
  }}
/>
}

const fullName = (...args: List<Maybe<string>>): string =>
  List.filterNotNone(args).join(" ")

export const userColumns = buildColumns<User>()({
  id: { 
    title: "Id", 
    customFilterAndSearch: (term, data) => softMatch(data.id ?? "", term),
    render: data => 
      <IdView 
        id={data.id} 
      />,
  },
  creationDate: { 
    title: "Fecha",
    defaultSort: "desc",
    customSort: (a, b) => (a.creationDate?.getTime() ?? 0) - (b.creationDate?.getTime() ?? 0),
    render: data => dateToShortFormat(data.creationDate)
  },
  dni: { title: "DNI", field: "dni" },
  firstName: { title: "Nombre", field: "firstName" },
  lastName: { title: "Apellido", field: "lastName" },
  fullName: {
    title: "Nombre",
    customFilterAndSearch: (term, data) => softMatch(fullName(data.firstName, data.lastName), term),
    render: data => fullName(data.firstName, data.lastName)
  },
  email: { title: "Email", field: "email" },
  phone: { title: "Teléfono", field: "phone" },
  cellPhone: { title: "Celular", field: "cellPhone" },

  roleList: { 
    title: "Roles", 
    render: data =>
      <RoleListView
        roles={data.roleList}
      />
  },

  productList: { 
    title: "Productos", 
    render: data =>
      <ProductListView
        products={data.productList}
      />
  },

  companyIdList: { 
    title: "Empresas", 
    render: data =>
      <FlexRow
        justifyContent="flex-start"
      >
        {data.companyIdList?.map(it => <CompanyView id={it}/>)}
      </FlexRow>
  },
  
  authorizedProductList: { 
    title: "Productos Autorizados", 
    field: "authorizedProductList",
    render: data =>
      <ProductListView
        products={data.authorizedProductList}
      /> 
  },

  desiredRole: { 
    title: "Rol Requerido",
    render: data => 
      <RoleView
        role={data.desiredRole}
      />
  },

  desiredCompany: { 
    title: "Empresa Requerida", 
    field: "desiredCompany" 
  },

  training: { 
    title: "Training", 
    render: data => <BooleanView value={data.training ?? false}/>
  },

  validated: {
    title: "Validado",
    render: data => <BooleanView value={data.validated ?? false}/>
      
  },

  mock: {
    title: "Mock",
    render: data => <BooleanView value={data.mockUser ?? false}/>
  },

  standBy: {
    title: "StandBy",
    render: data => <BooleanView value={data.standBy ?? false}/>
  },

  state: {
    title: "Estado",
    render: data => <UserBadges data={data} />
  }

})

const columns = userColumns

const BooleanView = (
  props: {
    value: boolean
  }
) => {
  return <FlexCol
  style={{
    color: props.value ? "green" : "red",
    fontWeight: "bold"
  }}
  alignItems="flex-start"
>
  {props.value ? "Si" : "No"}
</FlexCol>
}

