import { Maybe } from "functional/lib/Maybe"
import { RState, useRState } from "../../functional/react/RState"
import { IO } from "functional/lib/IO"
import { Unit, none } from "functional/lib/core"
import { nanToNone } from "../../utils/utils"
import { useEffect } from "react"
import { useFieldErrors } from "../../functional/react/form/FormContext"
import { Form } from "react-bootstrap"

export const NumberEditor = (
  props: {
    style?: React.CSSProperties
    fieldId?: string
    name?: string
    size?: 'sm' | 'lg'
    min?: number
    max?: number
    step?: number
    placeholder?: string
    value?: number
    onValueChanged?: (value: Maybe<number>) => any
    decimalPlaces?: number
    percent?: boolean
    state?: RState<Maybe<number>>
    onEnter?: IO<Unit>
  }
) => {

  const topValue = props.state?.value ?? props.value

  const percent = props.percent ?? false

  const topValueText = Maybe.map(topValue)(topValue => 
    (topValue * (percent ? 100 : 1)).toFixed(props.decimalPlaces)
  ) ?? ""

  const text = useRState(() => topValueText)

  const numericValue = nanToNone(parseFloat(text.value) / (percent ? 100 : 1))

  useEffect(
    topValue !== numericValue ?
      text.apply(() => topValueText) :
      IO.noOp,
    [props.state?.value]
  )

  const handleChange = (newText: string) => {

    if (!(/^-?\d*\.?\d*$/.test(newText)))
      return

    if (props.min !== undefined && props.min >= 0 && newText === "-")
      return

    const [first, second] = newText.split(".", 2)

    const before = first
    const after = second !== undefined ? second.slice(0, props.decimalPlaces) : ""
    const reassembled = (props.decimalPlaces ?? 0) > 0 && second !== undefined ? `${before}.${after}` : before
    const parsed = parseFloat(reassembled)
    const value = isNaN(parsed) ? none : parsed
    const resultValue = value
    const resultText: string = reassembled
    if (value !== none && props.max !== none && value > props.max) {
      return
    }
    if (value !== none && props.min !== undefined && value < props.min) {
      return
    }
    text.apply(() => resultText)()
    props.onValueChanged?.(resultValue)
    props.state?.apply(() => Maybe.map(resultValue)(it => it / (percent ? 100 : 1)))?.()
  }

  const errors = useFieldErrors(props.fieldId)

  return <Form.Control
    isInvalid={errors.length > 0}
    style={props.style}
    size={props.size}
    min={props.min}
    max={props.max}
    step={props.step}
    placeholder={props.placeholder}
    value={text.value}
    onChange={event => handleChange((event.target as any).value)}
    onKeyDown={event => event.key === "Enter" ? props.onEnter?.() : none}
  />
}
