import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/CheckRounded"
import CancelIcon from "@material-ui/icons/CloseRounded"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import SaveIcon from "@material-ui/icons/Save"
import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import Iframe from 'react-iframe'
import { Id } from "./model/Model"
import { showIf } from 'functional/lib/Maybe'
import { useAppAsynchronism } from './context/AppCall'
import { Unit, none } from 'functional/lib/core'
import { userProductsUpdate, userDataUpdate } from './client/user'
import { fileUploadToCloudFirestore } from './client/file'
import { useAppUser } from './context/App'
import { useRState } from './functional/react/RState'
import { IO } from 'functional/lib/IO'
import { useCurrentUser } from './hooks/ConectoHooks'
import { User } from './model/User'
import { IconButton, Snackbar } from '@material-ui/core'
import { FlexCol, FlexRow } from './components/Flexbox'
import { useSnackbarActions } from './components/Snackbar'

export const NewWaitingValidation = () => {
  
  const [user, , ] = useCurrentUser()

  const requestedValidation = false // TODO: lógica?

  if (user === none) return none

  return <>
    {/*<Row className="justify-content-md-center">*/}
    {/*    <Col xs={12} className='text-center'>*/}
    {/*        <Alert variant='warning'><b>¡Muchas gracias por tu interés en Conecto Energía!</b>*/}
    {/*            <br/>Tu cuenta ha sido creada pero necesitamos validar algunos de tus datos personales y de tu*/}
    {/*            empresa para darte acceso a la plataforma.*/}
    {/*            <br/>Cualquier duda comunicate con nuestro equipo por teléfono al +54 (11) 4071-8867, por <a href={'https://wa.me/541140718867'}>Whatsapp</a> o*/}
    {/*            escribinos a <a href="mailto:info@conectoenergia.com">info@conectoenergia.com</a>.*/}
    {/*        </Alert>*/}
    {/*    </Col>*/}
    {/*</Row>*/}

    <Alert 
      variant='info'
      style={{ textAlign: 'center' }}
    >
      Completá tus datos y nos vamos a estar comunicando a la brevedad.<br/>
      Cualquier consulta podés llamar o escribir al +54 11 4071 8867
    </Alert>

    <DataStateViewer
      user={user}
    />
    <br />
    <Row className="justify-content-md-center">
      <Card 
        className={requestedValidation ? 'shadow border-primary' : 'shadow border-secondary'} 
        style={{ width: '90%' }}
      >
        <Card.Body>
          <PersonalDataEditor
            user={user}
          />
          <RoleEditor
            user={user}
          />
          <UserProductsEditor
            user={user}
            />
        </Card.Body>
      </Card>
    </Row>
    <hr />
    {/*
    <UserCompaniesEditor
      user={user}
      requestedValidation={requestedValidation}
    />*/}
    <br />
    <br />
  </>
}

const DataStateViewer = (
  props: {
    user: User
  }
) => {

  const user = props.user

  const completeUserData = 
    user && 
    user.firstName && 
    user.lastName && 
    user.dni && 
    user.cellPhone && 
    user.phone && 
    user.roleList

  const completeCompanyData = false

  return <Row>
    <Col className="text-center" xs={12}>
      {(completeCompanyData && completeUserData) ? <Alert variant='primary'>
        <h4 className={'text-primary'}><b>¡Gracias!</b></h4>
        <h5 className={'text-primary'}>Vamos a validar tu información y notificarte por Mail cuando estés habilitado. 📨</h5>
      </Alert> :
        <Alert variant='light'>
          <Row className={'justify-content-center'}>
            <Col xs={3}><h5>Datos personales</h5></Col>
            <Col xs={5}>
              {!completeUserData && <h5 className={'text-warning'}><b><ErrorOutlineIcon /> Faltan completar datos</b></h5>}
              {completeUserData && <b><h5 className={'text-primary'}><CheckCircleOutlineIcon /> Ya completaste todos tus datos personales</h5></b>}
            </Col>
          </Row>
          <hr />
          <Row className={'justify-content-center'}>
            <Col xs={3}><h5>Empresa/s a representar</h5></Col>
            <Col xs={5}>
              {!completeCompanyData && <h5 className={'text-warning'}><ErrorOutlineIcon /> Faltan completar datos</h5>}
              {completeCompanyData && <h5 className={'text-primary'}><CheckCircleOutlineIcon /> Ya te asignaron al menos una Empresa</h5>}
            </Col>
          </Row>
        </Alert>}
    </Col>
  </Row>
}

const PersonalDataEditor = (
  props: {
    user: User
  }
) => {
  
  const userUpdate = useAppAsynchronism(userDataUpdate)

  const snackbar = useSnackbarActions()

  useEffect(
    userUpdate.state.type === "success" ?
      snackbar.show({
        type: "success",
        message: "Datos actualizados correctamente"
      }) : 
    userUpdate.state.type === "error" ?
      snackbar.show({
        type: "success",
        message: "Error al actualizar los datos"
      }) : 
    IO.noOp,
    [userUpdate.state.type]
  )

  const updateUser = (user: Partial<User>) =>
    userUpdate.run({
      userId: props.user.id,
      newData: user
    })

  const editable = true

  const user = props.user

  return <>
    
    <Card.Title>Datos Personales</Card.Title>
    <hr />
    {/*<h4>Mail: {props.user.email}</h4>*/}
    <FlexRow
      alignItems='flex-start'
    >

      <FlexCol
        style={{ width: 0, flexGrow: 1 }}
        alignItems='flex-start'
      >
        <label><b>Email</b></label>
        <p>{user.email}</p>
      </FlexCol>

      <UserRowCheck 
        user={user} 
        variable={'firstName'} 
        name="Nombre" 
        data={user.firstName} 
        check={true} 
        editable={editable}
        updateUser={updateUser}
      />
      <UserRowCheck 
        user={user} 
        variable={'lastName'} 
        name="Apellido" 
        data={user.lastName} 
        check={false} 
        editable={editable} 
        updateUser={updateUser}
      />
    </FlexRow>
    <FlexRow
      alignItems='flex-start'
    >
      <UserRowCheck 
        user={user} 
        variable={'dni'} 
        name="DNI" 
        data={user.dni} 
        check={true} 
        editable={editable} 
        updateUser={updateUser}
      />
      <UserRowCheck 
        user={user} 
        variable={'cellPhone'} 
        name="Teléfono Celular" 
        data={user.cellPhone} 
        check={true} 
        editable={editable} 
        updateUser={updateUser}
      />
      <UserRowCheck 
        user={user} 
        variable={'phone'} 
        name="Teléfono Fijo" 
        data={user.phone} 
        check={true} 
        editable={editable} 
        updateUser={updateUser}
      />
    </FlexRow>
    <hr />
  </>
}

const RoleEditor = (
  props: {
    user: User
  }
) => {

  const userUpdate = useAppAsynchronism(userDataUpdate)

  const updateUser = (user: Partial<User>) =>
    userUpdate.run({
      userId: props.user.id,
      newData: user
    })

  const user = props.user

  return <>
    <Row className="justify-content-center">
      <h5>Elegí tu rol en la Plataforma</h5>
    </Row>
    <Row className="justify-content-center">
      {user.roleList?.length == 0 && <h6 className={'text-warning'}>Seleccioná una opción</h6>}
    </Row>
    <Row className='justify-content-center'>
      <Col xs={'10'}>
        <Row className="justify-content-center">
          <Col sm={4} className='text-center'>
            <Card 
              onClick={updateUser({ desiredRole: 'buyer' })} 
              border={user.desiredRole === 'buyer' ? 'primary' : ''}
            >
              <Card.Body>
                <Row className="justify-content-center">
                  <img alt="Image"
                    src={require(`../src/landing/assets/images/testimonial/Compradores-dark.png`)}
                    style={{ maxWidth: '60px' }} />
                </Row>
                <Row className="justify-content-center">
                  <h4>Comprador</h4>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className='text-center'>
            <Card 
              onClick={updateUser({ desiredRole: 'consultant' })}
              border={user.desiredRole === 'consultant' ? 'primary' : ''}
            >
              <Card.Body>
                <Row className="justify-content-center">
                  <img alt="Image"
                    src={require(`../src/landing/assets/images/testimonial/Consultant-dark.png`)}
                    style={{ maxWidth: '60px' }} />
                </Row>
                <Row className="justify-content-center">
                  <h4>Consultor</h4>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className='text-center'>
            <Card 
              onClick={updateUser({ desiredRole: 'seller' })}
              border={user.desiredRole === 'seller' ? 'primary' : ''}
            >
              <Card.Body>
                <Row className="justify-content-center">
                  <img alt="Image"
                    src={require(`../src/landing/assets/images/testimonial/Vendedores-dark.png`)}
                    style={{ maxWidth: '60px' }} />
                </Row>
                <Row className="justify-content-center">
                  <h4>Vendedor</h4>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Col>
    </Row>
    <hr />
  </>
}

const UserProductsEditor = (
  props: {
    user: User
  }
) => {

  const user = props.user

  const productsUpdate = useAppAsynchronism(userProductsUpdate)

  const hasGas = user.desiredProductsList?.includes('gas') ?? false
  const hasElectricity = user.desiredProductsList?.includes('electricity') ?? false

  return <>
    <Row className="justify-content-center">
      <h5>Elegí los Productos que quieras utilizar</h5>
    </Row>
    {!((user.desiredProductsList?.length ?? 0) > 0) && <Row className="justify-content-center">
      <h6 className={'text-warning'}>Seleccioná al menos una opción</h6>
    </Row>}
    <Row className='justify-content-center'>
      <Col sm={5} className='text-center'>
        <Card border={hasGas ? 'primary' : ''}
          onClick={productsUpdate.run({
            user: user,
            operation: hasGas ? "remove" : "add",
            authorize: false,
            product: 'gas',
            desired: true
          })}>
          <Card.Body>
            <Row className="justify-content-center">
              <img src={require('../src/landing/assets/images/icon/whatshot.png')}
                style={{ 'maxHeight': "60px", 'maxWidth': "90px" }} />
            </Row>
            <Row className="justify-content-center">
              <h4>Gas Natural</h4>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={5} className='text-center'>
        <Card border={hasElectricity ? 'primary' : ''}
          onClick={productsUpdate.run({
            user: user,
            operation: hasElectricity ? "remove" : "add",
            authorize: false,
            product: 'electricity',
            desired: true
          })}
        >
          <Card.Body>
            <Row className="justify-content-center">
              <img src={require('../src/landing/assets/images/icon/rayo.png')}
                style={{ 'maxHeight': "60px", 'maxWidth': "90px" }} />
            </Row>
            <Row className="justify-content-center">
              <h4>Energía Eléctrica</h4>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </>
}

const UserRowCheck = (
  props: {
    name: string
    variable: string
    data: any
    check: boolean
    user: User
    editable: boolean
    updateUser: (user: Partial<User>) => IO<Unit>
  }
) => {

  const edit = useRState(() => false)

  const [value, setValue] = useState(props.data)

  const editable = props.editable

  return <FlexCol
    style={{
      width: 0,
      flexGrow: 1
    }}
    alignItems='flex-start'
  >

      <FlexRow
        alignItems='center'
      >
        <b>{props.name}</b>

        {showIf(!edit.value && editable)(
          <IconButton
              onClick={edit.apply(() => true)}
            >
                <EditIcon style={{ fontSize: 18 }} />
            </IconButton>
        )}

        {showIf(edit.value)(
          <>
            <IconButton
              onClick={
                IO.sequence([
                  props.updateUser({ [props.variable]: value }),
                  edit.apply(() => false)
                ])
              }
            >
                <CheckIcon style={{ fontSize: 18 }} />
            </IconButton>
            <IconButton
              onClick={edit.apply(() => false)}
            >
                <CancelIcon style={{ fontSize: 18 }} />
            </IconButton>
          </> 
        )}
      </FlexRow>

      <FlexRow>
        {showIf(!edit.value)(props.data)}

        {showIf(edit.value)(
          <input
            id={props.name}
            type="string"
            name={props.name}
            className="form-control"
            placeholder={"Ingresar " + props.name}
            value={value}
            onChange={event => {
              setValue(event.target.value)
            }}
          />)
        }
      </FlexRow>

  </FlexCol>
}


const UserCompaniesEditor = (
  props: {
    user: User
    requestedValidation: boolean
  }
) => {

  const user = props.user

  const userUpdate = useAppAsynchronism(userDataUpdate)

  const updateUser = (user: Partial<User>) =>
    userUpdate.run({
      userId: props.user.id,
      newData: user
    })

  const [createCompany, setCreateCompany] = useState(false)
  const [declareCompany, setDeclareCompany] = useState(false)
  const [desiredBusinessName, setDesiredBusinessName] = useState('')
  
  const addCompany = useRState(() => true)
  
  return <Row className="justify-content-md-center">
    <Card className={props.requestedValidation ? 'shadow border-primary' : 'shadow border-secondary'} style={{ width: '90%' }}>
      <Card.Body>
        <Card.Title>Empresa/s a representar</Card.Title>
        {
          showIf(createCompany)(
            <NewCompany
              companyId=''
              user={user} 
              createCompany={createCompany} 
              setCreateCompany={setCreateCompany} 
              updateUser={updateUser}
            />
          )
        }
        {!createCompany && <Row className={'justify-content-center'}>
          <Col xs={12} md={6}>
            <Row className={'justify-content-center'}><h5 className={'text-primary'}><b>¿Sos el primer Usuario de la Empresa?</b></h5></Row>
            <Row className={'justify-content-center'}>
              <Button 
                size={'lg'}
                variant={createCompany ? 'primary' : 'outline-primary'}
                onClick={() => {
                  setCreateCompany(true)
                }}>
                  Registrar una Empresa nueva
                </Button>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row className={'justify-content-center'}>
              <h5 className={'text-primary'}><b>¿Tu Empresa ya está registrada?</b></h5>
            </Row>
            <Row className={'justify-content-center'}>
              {
              showIf(!declareCompany && !user?.desiredBusinessName)(
                <Button size={'lg'}
                  variant={'outline-primary'}
                  onClick={() => {
                    setDeclareCompany(true)
                  }}
                >
                  Solicitar acceso a una determinada empresa
                </Button>
              )
            }
            </Row>
            {user?.desiredBusinessName && <>
              <Row className={'justify-content-center'}>
                <p className={'text-warning'}>Esperando validación</p>
              </Row>
              <Row className={'justify-content-center'}>
                <Col xs={8} className={'text-center'}>
                  Solicitaste acceso a &nbsp;<b>{user?.desiredBusinessName}</b>
                </Col>
                <Col xs={4} className={'text-center'}>
                  <Button 
                    size={'sm'} 
                    variant={'outline-danger'} 
                    onClick={updateUser({ desiredBusinessName: '' })}
                    >
                      Descartar
                    </Button>
                </Col>
              </Row>
            </>}
            {declareCompany && !user?.desiredBusinessName && <Row className={'justify-content-center'}>
              <p>Contanos a que empresa querés acceso.<br />Vamos a validarlo con el usuario autorizado.</p>
              <Col xs={11}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Razón Social"
                    aria-label="businessName"
                    value={desiredBusinessName}
                    onChange={(e) => setDesiredBusinessName(e.target.value)}
                  />
                  <Row>
                    <Button 
                      variant="outline-primary" 
                      onClick={updateUser({ desiredBusinessName: desiredBusinessName })}
                    >
                      Solicitar acceso
                    </Button>
                    <Button 
                      variant="outline-danger" 
                      onClick={() => setDeclareCompany(false)}
                    >
                      Cancelar
                    </Button>
                  </Row>
                </InputGroup>
              </Col>
            </Row>}
          </Col>
        </Row>}
        {!addCompany.value && <>
          <Row className={'justify-content-center'}>
            <Col xs={8} className={'text-right'}>
              <Button 
                variant={'outline-primary'} 
                onClick={addCompany.apply(() => true)}
              >
                Agregar otra Empresa
              </Button>
            </Col>
          </Row>
          <br />
        </>}
      </Card.Body>
    </Card>
  </Row>
}


const CompanyRowCheck = (
  props: {
    user: User
    name: string
    variable: string
    data: any
    check: boolean
    editable: boolean
    updateUser: (user: Partial<User>) => IO<Unit>
  }
) => {
  const updateState = useRState(() => false)
  const update = updateState.value
  const [value, setValue] = useState(props.data)
  const editable = props.editable
  return <Col sm={4}>
    <div className="form-group">
      <Row className="justify-content-md-center">
        <Col>
          <label><b>{props.name}</b></label>
          <br />{!update && <>{props.data}</>}
        </Col>
        <Col className={'text-right'}>
          {!update && editable && <Button 
            size={'sm'} 
            variant={'outline-warning'} 
            onClick={updateState.apply(() => true)}
          >
            <EditIcon style={{ fontSize: 17 }} />Editar
          </Button>}

          {update && <Button 
            size={'sm'} 
            variant={'outline-success'} 
            onClick={
              IO.sequence([
                props.updateUser({ desiredCompanyData: { ...props.user.desiredCompanyData, [props.variable]: value } }),
                updateState.apply(() => false)
              ])
            }
          >
            <SaveIcon fontSize='small' /> Guardar
          </Button>}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        {update && <input
          id={props.name}
          type="string"
          name={props.name}
          className="form-control"
          placeholder={"Ingresar " + props.name}
          value={value}
          onChange={event => {
            setValue(event.target.value)
          }}
        />}
      </Row>
    </div>
  </Col>
}



const NewCompany = (
  props: {
    companyId: Id
    user: User
    createCompany: boolean
    setCreateCompany: (value: boolean) => void
    updateUser: (user: Partial<User>) => IO<Unit>
  }
) => {

  const user = useAppUser()

  const upload = useAppAsynchronism(fileUploadToCloudFirestore)

  if (user === none) return <></>

  return <>
    <br />
    <Row className="justify-content-md-center">
      <Col xs={10}>
        <Card className={'shadow'}>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>Registar una Empresa Nueva</Col>
                <Col className={'text-right'}>
                  <Button size={'sm'}
                    variant={!props.createCompany ? 'primary' : 'outline-danger'}
                    onClick={() => {
                      props.setCreateCompany(false)
                    }}>Cancelar
                  </Button>
                </Col>
              </Row>
            </Card.Title>
            <Row className="justify-content-md-center">
              <CompanyRowCheck
                name="Razón Social" 
                user={user} 
                data={user?.desiredCompanyData?.businessName} 
                variable={'businessName'} 
                check={true} 
                editable={true}
                updateUser={props.updateUser}
              />
              <CompanyRowCheck 
                name="CUIT" 
                user={user} 
                data={user?.desiredCompanyData?.cuit} 
                variable={'cuit'} 
                check={false} 
                editable={true} 
                updateUser={props.updateUser}
              />
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Col xs={8}>
                {user?.desiredCompanyData?.logo?.name ?
                  <Row className="justify-content-md-center">
                    <Col xs={12}>
                      <b>Logo de la Empresa</b>
                    </Col>
                    <Col xs={4}>
                      <Iframe url={user?.desiredCompanyData?.logo?.link}
                        width="150px"
                        height="150px"
                        id="logo"
                        className="myClassname"
                        display="block"
                        position="relative" />
                    </Col>
                    <Col xs={6}>
                      <a href={user?.desiredCompanyData?.logo?.link} target='_blank'>{user?.desiredCompanyData?.logo?.name}</a>
                    </Col>
                    <Col xs={2}>
                      <Button 
                        size={'sm'} 
                        variant={'outline-danger'} 
                        onClick={
                          props.updateUser({ 
                            desiredCompanyData: { 
                              ...user.desiredCompanyData, 
                              // logo: none 
                            }
                          })
                        }
                        >
                          Eliminar
                        </Button>
                    </Col>
                  </Row> :
                  <Form.Group
                    controlId="File 1"
                  >
                    <Form.Label>Cargar el Logo de la Empresa</Form.Label>
                    <Form.Control 
                      type="file"
                      id='File 1'
                      data-browse='Subir Archivo'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                        const file = e.target.files?.[0]
                        if (file === none) return

                        upload.run({
                          file: file,
                          pathname: file.name,
                          user: user,
                          saveAs: "logo",
                        })()

                      }} />
                  </Form.Group>
                }</Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
              <Col xs={8}>
                {user?.desiredCompanyData?.cuitFile?.name ?
                  <Row className="justify-content-md-center">
                    <Col xs={12}>
                      <b>Constancia de CUIT</b>
                    </Col>
                    <Col xs={4}>
                      <Iframe url={user?.desiredCompanyData?.cuitFile?.link}
                        width="150px"
                        height="150px"
                        id="logo"
                        className="myClassname"
                        scrolling="no"
                        position="relative" />
                    </Col>
                    <Col xs={6}>
                      <a href={user?.desiredCompanyData?.cuitFile?.link} target='_blank'>{user?.desiredCompanyData?.cuitFile?.name}</a>
                    </Col>
                    <Col xs={2}>
                      <Button 
                        size={'sm'} 
                        variant={'outline-danger'} 
                        onClick={
                          props.updateUser({ 
                            desiredCompanyData: { 
                              ...user.desiredCompanyData, 
                              // cuitFile: none 
                            } 
                          })
                        }
                      >
                        Eliminar
                      </Button>
                    </Col>
                  </Row> :
                  <Form.Group
                    controlId="File 1"
                  >
                    <Form.Label>Cargar constancia de CUIT</Form.Label>
                    <Form.Control
                      id='File 1'
                      //custom
                      data-browse='Subir Archivo'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const file = e.target.files?.[0]
                        if (file === none) return

                        upload.run({
                          file: file,
                          pathname: file.name,
                          user: user,
                          saveAs: "cuitFile",
                        })()
                      }} 
                    />
                  </Form.Group>
                }</Col>
            </Row>
            <hr />
            <Row className='justify-content-center'>
              <h4>Seleccioná el Rol de la Empresa</h4>
            </Row>
            <Row className='justify-content-center'>
              <Col sm={5} className='text-center'>
                <Card 
                  onClick={
                    props.updateUser({ 
                      desiredCompanyData: { ...user.desiredCompanyData, role: 'buyer' }
                    })
                  } 
                  border={user?.desiredCompanyData?.role === 'buyer' ? 'primary' : ''}
                >
                  <Card.Body>
                    <Row className="justify-content-center">
                      <img alt="Image"
                        src={require(`../src/landing/assets/images/testimonial/Compradores-dark.png`)}
                        style={{ maxWidth: '60px' }} />
                    </Row>
                    <Row className="justify-content-center">
                      <h4>Compradora</h4>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={5} className='text-center'>
                <Card 
                  onClick={
                    props.updateUser({ 
                      desiredCompanyData: { ...user.desiredCompanyData, role: 'seller' } 
                    })
                  } 
                  border={user?.desiredCompanyData?.role === 'seller' ? 'primary' : ''}
                >
                  <Card.Body>
                    <Row className="justify-content-center">
                      <img alt="Image"
                        src={require(`../src/landing/assets/images/testimonial/Vendedores-dark.png`)}
                        style={{ maxWidth: '60px' }} />
                    </Row>
                    <Row className="justify-content-center">
                      <h4>Vendedora</h4>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <hr />
            <Row className='justify-content-center'>
              <h4>Seleccioná los Productos que le interesan a tu Empresa</h4>
            </Row>
            <Row className='justify-content-center'>
              <Col sm={5} className='text-center'>
                <Card 
                  border={user?.desiredCompanyData?.desiredProductsList?.includes('gas') ? 'primary' : ''}
                  // && company?.desiredProductsList?.includes('gas'))
                  onClick={
                      props.updateUser({
                        desiredCompanyData: {
                          ...user.desiredCompanyData,

                          desiredProductsList: 
                            user?.desiredCompanyData?.desiredProductsList?.includes('gas') ? 
                              user?.desiredCompanyData?.desiredProductsList?.filter(it => it !== 'gas') :
                            user?.desiredCompanyData?.desiredProductsList instanceof Array ? 
                              user?.desiredCompanyData?.desiredProductsList?.concat('gas') : 
                            ['gas']
                        }
                      })
                  }>
                  <Card.Body>
                    <Row className="justify-content-center">
                      <img src={require('../src/landing/assets/images/icon/whatshot.png')}
                        style={{ 'maxHeight': "60px", 'maxWidth': "90px" }} />
                    </Row>
                    <Row className="justify-content-center">
                      <h4>Gas Natural</h4>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={5} className='text-center'>
                <Card border={user.desiredCompanyData?.desiredProductsList?.includes('electricity') ? 'primary' : ''}
                  onClick={
                    props.updateUser({
                      desiredCompanyData: {
                        ...user.desiredCompanyData,
                        desiredProductsList: 
                          user?.desiredCompanyData?.desiredProductsList?.includes('electricity') ? 
                            user?.desiredCompanyData?.desiredProductsList?.filter(it => it !== 'electricity') :
                          user?.desiredCompanyData?.desiredProductsList instanceof Array ? 
                            user?.desiredCompanyData?.desiredProductsList?.concat('electricity') : 
                          ['electricity']
                      }
                    })
                  }>
                  <Card.Body>
                    <Row className="justify-content-center">
                      <img src={require('../src/landing/assets/images/icon/rayo.png')}
                        style={{ 'maxHeight': "60px", 'maxWidth': "90px" }} />
                    </Row>
                    <Row className="justify-content-center">
                      <h4>Energía Eléctrica</h4>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/*<CompanyRowCheck company={company} setCompany={setCompany} name="Rol en la Plataforma"  data={company?.role} variable={'businessName'} check={true} editable={true}/>*/}
            {/*<CompanyRowCheck company={company} setCompany={setCompany} user={props.user} name="Listado de Productos" data={company?.productList} check={true}/>*/}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </>
}