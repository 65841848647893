import { useState } from "react"
import { IO } from "functional/lib/IO"
import { Endo, Unit, compose, none } from "functional/lib/core"
import { Lens } from "functional/lib/optics/Lens"
import { Affine } from "functional/lib/optics/Affine"
import { Adapter } from "functional/lib/optics/Adapter"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"

export type RState<T> = {
  value: T
  apply: (transform: Endo<T>) => IO<Unit>
}

export const useRState = <T>(init: IO<T>): RState<T> => {
  const [value, setValue] = useState<T>(init)
  return {
    value: value,
    apply: transform => () => setValue(transform)
  }
}

export namespace RState {

  export const applyLens = <A, B>(state: RState<A>, lens: Lens<A, B>): RState<B> => ({
    value: lens.get(state.value),
    apply: compose(lens.over, state.apply)
  })

  export const applyAffine = <A, B>(state: RState<A>, affine: Affine<A, B>): RState<Maybe<B>> => ({
    value: affine.get(state.value),
    apply: transform => state.apply(affine.over(it => transform(it) ?? it))
  })

  export const applyAdapter = <A, B>(state: RState<A>, adapter: Adapter<A, B>): RState<B> => ({
    value: adapter.to(state.value),
    apply: transform => state.apply(it => adapter.from(transform(adapter.to(it))))
  })

  export const destructureAll = 
    <A extends Record<string, unknown>>(state: RState<A>): { [K in keyof A]: RState<A[K]> } => {
      const result: any = {}
      for (const key in state.value) {
        result[key] = RState.applyLens(state, Lens.field(key))
      }
      return result
    }

  export const destructure = 
    <A extends Record<string, unknown>>(state: RState<A>) => 
    <F extends keyof A>(...fields: List<F>): { [K in F]: RState<A[K]> } => {
      const result: any = {}
      for (const key of fields) {
        result[key] = RState.applyLens(state, Lens.field(key))
      }
      return result
    }

  export const match = 
    <T extends { type: string }>(state: RState<T>) =>
    <R>(cases: {
      [K in T["type"]]: (state: RState<T & { type: K }>) => R
    }): R => 
      cases[state.value.type as T["type"]]({
        value: state.value,
        apply: transition => 
          state.apply(prev => prev.type === state.value.type ? transition(prev) : prev)
      })

  export const matchPartial = 
    <T extends { type: string }>(state: RState<T>) =>
    <R>(cases: {
      [K in T["type"]]?: (state: RState<T & { type: K }>) => R
    }): Maybe<R> => 
      cases[state.value.type as T["type"]]?.({
        value: state.value,
        apply: transition => 
          state.apply(prev => prev.type === state.value.type ? transition(prev) : prev)
      })

  export const nonNone = 
    <T>(state: Maybe<RState<Maybe<T>>>): Maybe<RState<T>> =>
      Maybe.map(state)(state =>
        Maybe.map(state.value)(value => ({
          value: value,
          apply: transform => state.apply(it => it === none ? it : transform(it))
        }))
      )

  export const withDefault = <T>(state: RState<Maybe<T>>, defaultValue: T): RState<T> => ({
    value: state.value ?? defaultValue,
    apply: transform => state.apply(it => transform(it ?? defaultValue))
  })

}
