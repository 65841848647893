import { Snackbar } from "@material-ui/core"
import { IO } from "functional/lib/IO"
import { Unit, none } from "functional/lib/core"
import React, { useContext } from "react"
import { useRState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import MuiAlert from '@material-ui/lab/Alert'

export type AlertType = 'success' | 'info' | 'warning' | 'error'

export type SnackbarActions = {

  show: (
    args: {
      type: AlertType
      message: string
    }
  ) => IO<Unit>

}

const SnackbarContext = React.createContext<SnackbarActions>({
  show: () => IO.noOp
})

type SnackbarState = {
  show: boolean
  alert: Maybe<{
    type: AlertType
    message: string
  }>
}

export const useSnackbarActions = (): SnackbarActions => {
  return useContext(SnackbarContext)
}

export const SnackbarProvider = (
  props: {
    children?: React.ReactNode
  }
) => {

  const state = useRState<SnackbarState>(() => ({
    show: false,
    alert: none
  }))

  return <>
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={state.value.show}
      autoHideDuration={6000}
      onClose={state.apply(it => ({ ...it, show: false }))}
    >
      <MuiAlert variant="filled" severity={state.value.alert?.type}>
        {state.value.alert?.message}
      </MuiAlert>
    </Snackbar>
    <SnackbarContext.Provider
      value={{
        show: alert => 
          state.apply(() => ({
            show: true,
            alert: alert
          }))
      }}
      children={props.children}
    />
  </>
}


