import React, { useEffect, useState } from "react"
import firebase from "firebase"
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component'
import { dbUserCollection } from "./hooks/ConectoHooks"
import { List } from "functional/lib/List"

export const initializeNotificationPanel = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()
    messaging.usePublicVapidKey("BHTbnsqBj4R3-5pJqSfFeHN9gWf83QHKp4MpDYVGqYgeNvpobi4Vd9BCdy2EI3wrqtvdVta8-PeeibjnJf79YeA")
  }
}

const registerPushListener = (pushNotification: (data: unknown) => void) => {
  return navigator.serviceWorker.addEventListener(
    "message",
    ({ data }) =>
      store.addNotification({
        title: data["firebase-messaging-msg-data"].notification.title,
        message: data["firebase-messaging-msg-data"].notification.body,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 10000,
          onScreen: true
        }
      }) &&
      pushNotification(
        data.data
          ? data.data.message
          : data["firebase-messaging-msg-data"].data.message
      )
  )
}

export const NotificationsPanel = () => {

  const [token, setToken] = useState("")
  const [notifications, setNotifications] = useState<List<unknown>>([])

  const pushNotification = (newNotification: unknown) => {
    setNotifications(notifications.concat(newNotification))
    console.log(newNotification)
  }

  useEffect(
    () => {
      Notification
        .requestPermission()
        .then(async () => {
          const token = await firebase.messaging().getToken()
          setToken(token)
          const userId = firebase.auth().currentUser?.uid
          dbUserCollection().doc(userId).set({ token: token }, { merge: true })
          //console.log(token)
        })
        .catch(err => {
          console.log("Unable to get permission to notify.", err)
        });

      registerPushListener(pushNotification)
    },
    []
  )

  return <>
    <NotificationsActiveIcon color={notifications.length ? 'secondary' : "action"} />
    <h6>{notifications.length ? notifications.length : ''}</h6>
  </>
}

