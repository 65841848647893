import React from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { Maybe } from "functional/lib/Maybe"
import { RState } from "../functional/react/RState"
import { Unit, none } from "functional/lib/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"
import { List } from "functional/lib/List"
import { FlexCol, FlexRow } from "../components/Flexbox"
import { useFormContext } from "../functional/react/form/FormContext"
import { IO } from "functional/lib/IO"
import { NumberEditor } from "../components/editors/number"

export const BidSuccess = (
  props: {
    onNewOffer?: IO<Unit>
  }
) => {

  return <Card className='shadow'>
      <FlexCol
        padding={32}
        gap={16}
      >

      <img
        alt="Image"
        src={require(`../landing/assets/images/green-tick.svg`)}
        style={{
          width: 140,
          height: 140,
        }}
      />


      <h3>
        Oferta enviada
      </h3>

      <p>
        Tu oferta ha sido enviada con éxito.
      </p>

      <Button
        onClick={props.onNewOffer}
      >
        Enviar otra oferta
      </Button>

    </FlexCol>
  </Card>
}


export const NumericVariable = (
  props: {
    fieldId?: string
    title: string
    placeholder: string
    min: number
    max: number
    step: number
    decimalPlaces: number
    state?: RState<Maybe<number>>
    value?: Maybe<number>
    onValueChanged?: (value: Maybe<number>) => void
    units: string
  }
) => {

  return <Row>
    <Col xs={5}>
      <Form.Label>
        {props.title}
      </Form.Label>
    </Col>
    <Col>
      <NumberEditor
        fieldId={props.fieldId}
        size='sm'
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        step={props.step}
        decimalPlaces={props.decimalPlaces}
        state={props.state}
        value={props.value}
        onValueChanged={props.onValueChanged}
      />
    </Col>
    <Col xs={3} className='text text-left text-secondary'>
      {(props.state?.value ?? 0) > 0 && props.units}
    </Col>
  </Row>
}


export const DateEditor = (
  props: {
    title: string
    state?: RState<Maybe<Date>>
    views?: List<"year" | "month" | "date">
  }
) => {

  return <FlexRow>

    <Form.Label
      style={{
        width: 0,
        flexGrow: 5,
      }}
    >{props.title}</Form.Label>

    <MuiPickersUtilsProvider 
      utils={DateFnsUtils} 
      locale={esLocale}
    >
      <DatePicker
        style={{
          width: 0,
          flexGrow: 4,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        value={props.state?.value ?? null}
        onChange={it => props?.state?.apply(() => it ?? none)?.()} 
        views={props.views?.slice()}
      />
    </MuiPickersUtilsProvider>

    <FlexCol
      style={{
        flexGrow: 3
      }}
    />

  </FlexRow>
}
