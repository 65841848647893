import { useParams } from "react-router"
import { useCompany, useEntityList, useSupplyPointList, useUsersListByCompany } from "../../../hooks/ConectoHooks"
import { Maybe, showIf } from "functional/lib/Maybe"
import { Company } from "../../../model/Company"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { ButtonTabs, ConectoBadge, IdView, PageLoading, ProductListView, RoleView, useScrollToTop } from "../components"
import { BuyRequestIcon, CompanyIcon, ConectoDialog, ConectoGrid, DetailSection, Field, FieldBoolean, FieldNumber, FieldText, SubmitButton, SupplyPointIcon, UserIcon } from "../detail-components"
import { UserTable, userColumns } from "../user/table"
import MaterialTable from "material-table"
import { SupplyPoint } from "../../../model/Model"
import { useRState } from "../../../functional/react/RState"
import { ButtonGroup, Button } from "react-bootstrap"
import { matchEnum, matchEnumPartial, matchPartial } from "functional/lib/match"
import { GasBuyRequestTable, gasBuyRequestColumns } from "../buy-request/gas-table"
import { useBuyRequestCollection } from "../../../context/App"
import { GasBuyRequest } from "../../../model/buyRequest/GasBuyRequest"
import { pipe } from "functional/lib/core"
import { ElectricityBuyRequestTable, electricityBuyRequestColumns } from "../buy-request/electricity-table"
import { ElectricityBuyRequest } from "../../../model/buyRequest/ElectricityBuyRequest"
import { defaultFeeConditions } from "../../../config"
import { CompanyActions } from "./actions"
import { SupplyPointNewButton, SupplyPointTable } from "./supply-point"

export const CompanyDetailPage = () => {

  const params = useParams()

  const id = params.id as string

  const [data, loading, error] = useCompany(id)

  useScrollToTop()

  return Maybe.map(data)(it => 
    <CompanyDetail
      data={it}
    />
  ) ?? <PageLoading/>
}

export const CompanyDetail = (
  props: {
    data: Company
  }
) => {

  const data = props.data

  return <FlexCol
    alignItems="stretch"
    justifyContent="flex-start"
    padding={16}
    style={{
      background: "#f5f5f5",
      minHeight: "100vh"
    }}
    gap={8}
  >
    
    <FlexCol
      alignItems="stretch"
      padding={16}
      style={{
        border: "1px solid #ddd",
        borderRadius: 4,
        background: "white",
        boxShadow: "0 0 8px #ddd"
      }}
    >

      <Header
        data={data}
      />

      <hr/>

      <DataSection
        data={data}
      />

      <hr/>

      <PermissionsSection
        data={data}
      />

      <hr/>

      <FeeSection
        data={data}
      />

    </FlexCol>

    <Relations
      data={data}
    />

  </FlexCol>
}


const Header = (
  props: {
    data: Company
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow
      justifyContent="space-between"
    >

      <FlexRow
        gap={6}
        alignItems="center"
      >
        {
          Maybe.map(props.data.imageUrl)(image =>
            <img
              src={image}
              style={{
                height: 48
              }}
            />  
          ) ??
          <CompanyIcon
            style={{
              fontSize: 48,
              color: "#888888"
            }}
          />
        }
        <h1>
          {props.data.businessName}
        </h1>
      </FlexRow>

      <CompanyActions
        data={props.data}
      />

    </FlexRow>
    

    <CompanyBadges
      style={{
        paddingBottom: 16
      }}
      data={props.data}
    />

    <IdView
      style={{
        width: "max-content",
      }}
      id={props.data.id}
    />

  </FlexCol>
}


const CompanyBadges = (
  props: {
    style: React.CSSProperties
    data: Company
  }
) => {

  return <FlexRow
    style={props.style}
    flexWrap="wrap"
    gap={4}
    justifyContent="flex-start"
  >

    {
      showIf(props.data.mockCompany ?? false)(
        <ConectoBadge
          color="red"
          children="Mock"
        />
      )
    }

  </FlexRow>
}


const DataSection = (
  props: {
    data: Company
  }
) => {

  const data = props.data

  return <DetailSection
    title="Datos de la Empresa"
  >
    <ConectoGrid
      defaults={{ xs: 3 }}
      cells={[
        <FieldText
          label="Nombre"
          value={data.displayName}
        />,
        <FieldText
          label="Razón Social"
          value={data.businessName}
        />,
        <FieldText
          label="Nombre Corto"
          value={data.shortName}
        />,
        <FieldText
          label="CUIT"
          value={data.cuit}
        />,
      ]}
    />

  </DetailSection>
}

const PermissionsSection = (
  props: {
    data: Company
  }
) => {

  const data = props.data

  return <DetailSection
    title="Permisos"
  >
    <ConectoGrid
      defaults={{ xs: 3 }}
      cells={[
        <Field
          label="Rol"
        >
          <RoleView
            role={data.role}
          />
        </Field>,
        <Field
          label="Productos"
        >
          <ProductListView
            products={data.productList}
          />
        </Field>,
        <FieldBoolean
          label="Productor"
          value={data.producer}
        />,
        <FieldBoolean
          label="Mock"
          value={data.mockCompany}
        />
      ]}
    />
    
      
  </DetailSection>
}

const FeeSection = (
  props: {
    data: Company
  }
) => {

  const feeConditions = props.data.feeConditions ?? defaultFeeConditions

  return <DetailSection
    title="Comisiones"
  >
    <ConectoGrid
      defaults={{ xs: 3 }}
      cells={[
        <FieldNumber
          label="Porcentaje"
          value={feeConditions.factor * 100}
          units="%"
        />,
        <FieldNumber
          label="Mínimo"
          value={feeConditions.min}
          units="USD"
          decimals={2}
        />,
        <FieldNumber
          label="Máximo"
          value={feeConditions.max}
          units="USD"
          decimals={2}
        />
      ]}
    />
  </DetailSection>
}

type Tab =
  | "users"
  | "supplyPoints"
  | "gasBuyRequests"
  | "electricityBuyRequests"

const Relations = (
  props: {
    data: Company
  }
) => {

  const tab = useRState<Tab>(() => "users")

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <FlexRow
      justifyContent="space-between"
    >

      <ButtonTabs<Tab>
        style={{
          alignSelf: "flex-start"
        }}
        state={tab}
        options={[
          { 
            value: "users", 
            icon: <UserIcon/>,
            label: "Usuarios" 
          },
          ...(
            props.data.role === "buyer" ?
              [
                { 
                  value: "supplyPoints" as const, 
                  icon: <SupplyPointIcon/>,
                  label: "Puntos de Suministro" 
                },
                { 
                  value: "gasBuyRequests" as const,
                  icon: <BuyRequestIcon/>,
                  label: "Pedidos de Compra de Gas" 
                },
                { 
                  value: "electricityBuyRequests" as const, 
                  icon: <BuyRequestIcon/>,
                  label: "Pedidos de Compra de Electricidad" 
                }
              ] :
              []
          )
        ]}
      />

      {
        matchEnumPartial(tab.value)({
          supplyPoints: 
            <SupplyPointNewButton
              companyId={props.data.id}
            />
        })
      }
      

    </FlexRow>

    {
      matchEnum(tab.value)({
        users: <UsersTable data={props.data}/>,
        supplyPoints: <SupplyPointTable data={props.data}/>,
        gasBuyRequests: <GasBuyRequestsTable data={props.data}/>,
        electricityBuyRequests: <ElectricityBuyRequestsTable data={props.data}/>
      })
    }

  </FlexCol>
}




const UsersTable = (
  props: {
    data: Company
  }
) => {

  const [list, loading, error] = useUsersListByCompany(props.data.id)

  return <UserTable
    style={{
      flexGrow: 1
    }}
    title={`Total ${list?.length ?? ""}`}
    data={list}
    isLoading={loading}
    columns={[
      userColumns.id,
      userColumns.firstName,
      userColumns.lastName,
      userColumns.email,
      userColumns.roleList,
      userColumns.productList,
      userColumns.authorizedProductList,
      userColumns.training
    ]}
  />
}

const GasBuyRequestsTable = (
  props: {
    data: Company
  }
) => {

  const collection = useBuyRequestCollection("gas")

  const [list, loading, error] = useEntityList<GasBuyRequest>(
    pipe(collection)(
      it => it.where("buyerCompanyId", "==", props.data.id)
    )
  )

  return <GasBuyRequestTable
    style={{
      flexGrow: 1
    }}
    title={`Total ${list?.length ?? ""}`}
    data={list}
    isLoading={loading}
    columns={[
      gasBuyRequestColumns.id,
      gasBuyRequestColumns.creationDate,
      gasBuyRequestColumns.buyType,
      gasBuyRequestColumns.supplyPoint,
      gasBuyRequestColumns.status,
      gasBuyRequestColumns.round,
    ]}
  />
}

const ElectricityBuyRequestsTable = (
  props: {
    data: Company
  }
) => {

  const collection = useBuyRequestCollection("electricity")

  const [list, loading, error] = useEntityList<ElectricityBuyRequest>(
    pipe(collection)(
      it => it.where("buyerCompanyId", "==", props.data.id)
    )
  )

  return <ElectricityBuyRequestTable
    style={{
      flexGrow: 1
    }}
    title={`Total ${list?.length ?? ""}`}
    data={list}
    isLoading={loading}
    columns={[
      electricityBuyRequestColumns.id,
      electricityBuyRequestColumns.creationDate,
      electricityBuyRequestColumns.supplyPoint,
      electricityBuyRequestColumns.status,
      electricityBuyRequestColumns.round
    ]}
  />
}