import { matchEnum, matchEnumPartial } from "functional/lib/match"
import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { FlexCol, FlexRow } from "../../components/Flexbox"
import { AllCompanyTables } from "./company/table"
import { AdminUsersList } from "./user/table"
import { AllGasBuyRequestsTable } from './buy-request/gas-table'
import { AllElectricityBuyRequestsTable } from './buy-request/electricity-table'
import { useUrlParamState } from '../../functional/react/UrlParamState'
import { ButtonTabs } from './components'
import { AdminMailList } from './mail/table'
import { AdminCommentList } from './comment/table'
import { BuyRequestIcon, CommentIcon, CompanyIcon, EmailIcon, UserIcon } from "./detail-components"
import { useNavigate } from "react-router"
import { appPaths } from "../navigation"

type Tab = 
  | "users"
  | "companies"
  | "gasBuyRequests"
  | "electricityBuyRequests"
  | "comments"
  | "mails"

export const Admin = () => {

  const tab = useUrlParamState<Tab>("tab", () => "users")

  const SectionButton = (
    props: {
      tab: Tab
      name?: string
      children?: React.ReactNode
    }
  ) => 
    <Button 
      variant={tab.value === props.tab ? "primary" : "secondary"}
      onClick={tab.apply(() => props.tab)}
    >
      {props.children}{props.name}
    </Button>

  return <FlexCol
    alignItems="stretch"
    padding={16}
    style={{
      background: "#f5f5f5",
    }}
  >
    <FlexRow
      justifyContent="space-between"
    >
      <ButtonTabs
        state={tab}
        options={[
          { 
            value: "users", 
            icon: <UserIcon/>, 
            label: "Usuarios" 
          },
          { 
            value: "companies",
            icon: <CompanyIcon/>,
            label: "Empresas" 
          },
          { 
            value: "gasBuyRequests",
            icon: <BuyRequestIcon/>,
            label: "Pedidos de Gas" 
          },
          { 
            value: "electricityBuyRequests", 
            icon: <BuyRequestIcon/>,
            label: "Pedidos de Electricidad" 
          },
          { 
            value: "comments", 
            icon: <CommentIcon/>,
            label: "Consultas" 
          },
          { 
            value: "mails", 
            icon: <EmailIcon/>,
            label: "Mails" 
          },
        ]}
      />

      {
        matchEnumPartial(tab.value)({
          companies: <CompanyActions/>
        })
      }

    </FlexRow>


    {
      matchEnum(tab.value)({
        users: <AdminUsersList />,
        companies: <AllCompanyTables />,
        gasBuyRequests: <AllGasBuyRequestsTable/>,
        electricityBuyRequests: <AllElectricityBuyRequestsTable/>,
        comments: <AdminCommentList/>,
        mails: <AdminMailList/>,
      })
    }

  </FlexCol>

}


const CompanyActions = () => {

  const navigate = useNavigate()

  return <FlexRow
    gap={8}
  >
    <Button>
      <FlexRow
        gap={8}
        onClick={
          () => {
            navigate(appPaths.companyNew)
          }
        }
      >
        <CompanyIcon/>
        Crear Empresa
      </FlexRow>
    </Button>
  </FlexRow>
}