import { Maybe } from "functional/lib/Maybe"
import { AppCall, appCallBuyRequestCollection, appCallProcessCollection, appCallTraining } from "../context/AppCall"
import { Id, Product, SentMail } from "../model/Model"
import { Process } from "../model/Process"
import { normalizeForFirebase } from "./client"
import firebase, { firestore } from "firebase"
import { none, pipe, throws } from "functional/lib/core"
import { BuyRequestStatusPending } from "../model/buyRequest/BuyRequestStatus"
import { BuyRequest, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { buyRequestGet } from "./buy-request"
import { userList } from "./user"
import { User } from "../model/User"
import { List } from "functional/lib/List"
import { dateToMailFormat } from "../Utilities"
import { mailSendBatch } from "./mail"

export const processGet = 
  (product: "gas" | "electricity") =>
  (buyRequestId: Id) =>
  (processId: Id): AppCall<Maybe<Process>> =>
  AppCall.do(async _ => {
    const collection = await _(appCallProcessCollection(product, buyRequestId))
    const data = await collection.doc(processId).get()
    return data.exists ? data.data() : none
  })

export const processCreate = (
  args: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    process: Process
  }
): AppCall<Id> => 
  AppCall.do(async _ => {
    const newStatus: BuyRequestStatusPending = {
      type: "pending"
    }
    const collection = await _(appCallBuyRequestCollection(args.buyRequest.type))
    args.process.creationDate = new Date()
    const onceInvited = args.process.guestIdList
    await collection
      .doc(args.buyRequest.id)
      .update(
        normalizeForFirebase({
          status: newStatus,
          currentGuestIdList: args.process.guestIdList,
          onceInvited: firebase.firestore.FieldValue.arrayUnion(...onceInvited)
        })
      )
    
    const processCollection = await _(
      appCallProcessCollection(args.buyRequest.type, args.buyRequest.id)
    )

    const doc = await processCollection.add(normalizeForFirebase(args.process))

    const buyRequest = await _(buyRequestGet(args.buyRequest.type)(args.buyRequest.id)) ?? throws("No request found")

    const invitedUsers = await _(
      processGetInvitedUserListFromBuyRequest({
        buyRequest: buyRequest,
        data: args.process
      })
    )


    const training = await _(appCallTraining)

    const alwaysInvited = 
      training ?
        [
          "info@conectoenergia.com"
        ] :
        [
          "pedro.kocourek@conectoenergia.com",
          "tomas.donovan@conectoenergia.com",
          "info@conectoenergia.com"
        ]

    const emailList =
      pipe(invitedUsers)(
        List.filter(it => (it.mailSettings?.newRound ?? true)),
        List.lift(it => it.email),
        List.filterNotNone,
        it => [...it, ...alwaysInvited],
        List.unique
      )

    const buyType = buyRequest.buyType.type

    const newMailsToSend: List<SentMail> = emailList.map(email =>
      ({
        id: "",
        to: email,
        template: {
          name: 'newRound',
          data: {
            businessName: buyRequest.businessName,
            companyImageUrl: buyRequest.companyImageUrl,
            id: args.buyRequest.id,
            product: 
              ["annual", "monthly", "spot"].includes(buyType) ? "Gas Natural" :
              buyType === "mater" ? "PPA MATER" :
              buyType === "thermal" ? "Térmico OnSite" :
              buyType === "onsite" ? "PPA Solar FV OnSite" :
              "",
            closeDate: dateToMailFormat(args.process.closeDate)
          }
        }
      })
    )

    await mailSendBatch(newMailsToSend)()

    return doc.id
  })


export const processGetInvitedUserList =
  (
    props: {
      buyRequest: {
        type: BuyRequestType
        id: Id
      }
      data: Process
    }
  ): AppCall<List<User>> =>
  AppCall.do(async _ => {

    const request = await _(buyRequestGet(props.buyRequest.type)(props.buyRequest.id)) ?? throws("No request found")

    return await _(
      processGetInvitedUserListFromBuyRequest({
        buyRequest: request,
        data: props.data
      })
    )
  })

export const processGetInvitedUserListFromBuyRequest =
  (
    props: {
      buyRequest: BuyRequest
      data: Process
    }
  ): AppCall<List<User>> =>
  AppCall.do(async _ => {

    const request = props.buyRequest

    const users = await _(userList({}))

    return users
      .filter(user => 
        (user.companyIdList ?? []).some(userCompany => 
          (props.data.guestIdList ?? []).some(guestCompany => guestCompany == userCompany)
        )
      )
      .filter(
        user => (user.productList ?? []).some(userProduct => 
          userProduct == request?.type || userProduct == request?.product
        )
      )
  })
