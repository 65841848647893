import { IO } from "functional/lib/IO";
import { useEffect } from "react";
import { useRState } from "../functional/react/RState";

export const usePeriodic = <T>(
  millis: number,
  io: IO<T>
) => {
  const state = useRState<T>(io)

  useEffect(
    () => {
      const interval = 
        setInterval(
          () => {
            const newValue = io()
            state.apply(() => newValue)()
          }, 
          millis
        )
      return () => clearInterval(interval);
    }, 
    [millis]
  )

  return state.value
}

export const useNow = (refreshMillis: number = 1000) => {
  return usePeriodic(refreshMillis, () => new Date())
}
