import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import React, { useState } from "react"
import { Card, Collapse, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"
import { useBidsList, useCompany, useSellerBidsList } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import {
  computeMonthlyWeightedAveragePrice, dateToBidFormat,
  mapMonthlyCurveWithName,
  validaDateForBid,
  validaDateForCheck
} from "../Utilities"
import { Bid, DecryptedBid, MonthlyOffer } from "../model/Bid"
import { Id } from "../model/Model"
import { TooltipProps } from "react-bootstrap"
import { GasBuyRequest, GasBuyTypeMonthly } from "../model/buyRequest/GasBuyRequest"
import { Unit, none } from "functional/lib/core"
import { List } from "functional/lib/List"
import { Process } from "../model/Process"
import { BuyRequestType } from "../model/buyRequest/BuyRequest"
import { IO } from "functional/lib/IO"
import { RState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { useAppUser } from "../context/App"
import { computeFee, computeContractValue, computeContractValueAndFee } from "../utils/fee"
import { formatUsd } from "../pages/admin/detail-components"
import { DownloadBidCsvButton, bidCsvColumns } from "./csv"
import { FlexRow } from "../components/Flexbox"

export const MonthlyBidTable = (
  props: {
    buyRequest: GasBuyRequest
    process: Process

    assignable: boolean
    preAssignable: boolean

    selectedBidId?: RState<Maybe<Id>>
    selectedToPreAssign?: RState<List<Id>>
  }
) => {

  const user = useAppUser()

  // const [bidList, loading, error] = useBidsList(props.buyRequest.id, props.process.id)
  const [bidList, loading, error] = (user?.roleList?.includes('buyer')) ? 
      useBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? ""
      ) : 
      useSellerBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? "", 
        user?.training ?? false, 
        user?.companyIdList?.[0] ?? ""
      )

  const bidTable = bidList ? bidList.map(bid =>
    <MonthlyBidRow
      key={bid.id}
      buyRequest={props.buyRequest}
      process={props.process}
      bid={bid}

      assignable={props.assignable}
      assigned={bid.id === props.selectedBidId?.value}
      onAssigned={props.selectedBidId?.apply(() => bid.id) ?? IO.noOp}

      preAssignable={props.preAssignable}
      selectedToPreAssign={props.selectedToPreAssign}
    />) : null

  return <>
    <Table className='text text-secondary text-center'>
    <thead>
      <tr>
        <th align="center">Hora</th>
        <th align="center">Oferente</th>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={props.buyRequest.acceptsLessVolume ? <RenderTooltipPPPLessVolume /> : <RenderTooltipPPP />}
        >
          <th align="center">Precio Promedio</th>
        </OverlayTrigger>
        <th align="center">Valor de Contrato</th>
        {props.process.acceptsDifferentPayTerm ? <th align="center">Plazo de Pago</th> : null}
        <th align="center">Válida hasta</th>
        
        <th align="center">Ver Más</th>
        {props.assignable ? <th align="center" className='text-success'>Seleccionar</th> : null}
        {props.preAssignable ? <th align="center" className='text-info'>Seleccionar</th> : null}
      </tr>
    </thead>
    {error && <strong>Error: {JSON.stringify(error)}</strong>}
    {loading && <tbody>
      <tr>
        <td>Cargando...</td>
      </tr>
    </tbody>}
    {bidList && <tbody>{bidTable}</tbody>}
    </Table>
    {user?.roleList?.includes('seller') && bidList && bidList.length == 0 && <Row><small>Tus Ofertas enviadas recién estarán visibles cuando la Ronda sea desencriptada</small></Row>}
    {user?.roleList?.includes('buyer') && bidList && bidList.length == 0 && <Row><small>Por el momento esta Ronda no tiene Ofertas</small></Row>}
    <FlexRow
      justifyContent="flex-end"
      padding={8}
    >
      <DownloadBidCsvButton
        buyRequest={props.buyRequest}
        process={props.process}
        bidList={bidList ?? []}
        columns={[
          bidCsvColumns.id,
          bidCsvColumns.date,
          bidCsvColumns.sellerCompany,
          bidCsvColumns.monthlyAverage,
          bidCsvColumns.contractValue,
          bidCsvColumns.payTermDays,
        ]}
      />
    </FlexRow>
  </>
}

const MonthlyBidRow = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bid: Bid

    assignable: boolean
    assigned: boolean
    onAssigned: IO<Unit>

    preAssignable: boolean
    selectedToPreAssign?: RState<List<Id>>
  }
) => {
  const [open, setOpen] = useState(false)
  const bid = props.bid
  const buyType = props.buyRequest.buyType as GasBuyTypeMonthly
  const encryptedOffer = bid.encrypted && props.process.type === "tender"
  const decryptedBid = bid as DecryptedBid
  const offer = encryptedOffer ? none : decryptedBid.offer as MonthlyOffer
  const encryptedText = "Encriptado"

  const [company, loading, error] = useCompany(bid.encrypted ? none : decryptedBid.sellerCompanyId)

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  return <>
    <tr className='text-center' key={bid.id}>
      <td>{dateToBidFormat(bid.date)}</td>
      <td>{bid.encrypted ? encryptedText : company ? company.displayName : ""}</td>
      <td>{encryptedOffer ? encryptedText : computeMonthlyWeightedAveragePrice(props.buyRequest, offer).toFixed(2)}</td>
      <td>{encryptedOffer ? encryptedText : formatUsd(contractValue)}</td>
      {props.process.acceptsDifferentPayTerm ?
        <td>{encryptedOffer ? encryptedText : offer?.payTermDays}</td> :
        null
      }
      <td className={!bid.encrypted && validaDateForCheck(props.process.closeDate, offer?.validTermDays) ? 'text-danger' : none}>
        {encryptedOffer ? encryptedText : validaDateForBid(props.process.closeDate, offer?.validTermDays)}
      </td>
      <td><AddCircleOutlineIcon onClick={() => setOpen(!open)} /></td>
      {props.assignable ? <td>
        <BooleanCheckEditor
          type="checkbox"
          disabled={!bid.encrypted && validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
          value={props.assigned}
          onValueChanged={value => props.onAssigned()}
        /></td> : null
      }
      {props.preAssignable ? <td>
        <BooleanCheckEditor
          type="checkbox"
          value={props.selectedToPreAssign?.value?.includes(bid.id) ?? false}
          disabled={validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
          onValueChanged={(value) => {

            props.selectedToPreAssign?.apply(
              previous => 
                previous.includes(bid.id) ?
                  previous.filter(bidId => bidId !== bid.id) :
                  [...previous, bid.id]
            )?.()

          }
          }

        /></td> : null}
    </tr>
    <Collapse in={open}>
      <tr>
        <td colSpan={4}>
          <Card>
            <Table className='text-center text-secondary' size="sm">
              <thead>
                {offer?.decryptedPdfFiles && <tr>
                  <td colSpan={5}>
                    <Table size='sm'>
                      <thead>
                        <tr>
                          <th>PDF's</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          offer.decryptedPdfFiles.map(file => 
                            <tr id={file.link}><a target="_blank" href={file.link}>{file.name}</a></tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </td>
                </tr>
                }
                <tr>
                  <th>Mes</th>
                  <th>Precio Ofertado</th>
                  {props.buyRequest.acceptsLessVolume ? <th>Volumen Ofertado</th> : null}
                  <th>Volumen Demandado</th>
                </tr>
              </thead>
              <tbody>
                {offer ? <>
                  {offer.priceCurve &&
                    mapMonthlyCurveWithName(
                      props.buyRequest.supplyStartDate,
                      offer.priceCurve,
                      (name, value, index) =>
                        <tr key={name}>
                          <td>{name}</td>
                          <td>{value.toFixed(2)}</td>
                          {props.buyRequest.acceptsLessVolume ? <td>{offer.monthlyCurve?.[index]}</td> : null}
                          <td>{buyType.demandCurve[index]}</td>
                        </tr>
                    )
                  }
                </> : <><br /><tr className='text-info'><td>Información Encriptada</td></tr></>}
              </tbody>
            </Table>
          </Card>
        </td>
      </tr>
    </Collapse>
  </>

}

const RenderTooltipPPP = 
  (props: TooltipProps) => <Tooltip {...props} show={true}>Precio Promedio Ponderado sobre la curva Demandada</Tooltip>

const RenderTooltipPPPLessVolume = 
  (props: TooltipProps) => <Tooltip {...props} show={true}>Precio Promedio Ponderado sobre la curva Ofertada</Tooltip>
