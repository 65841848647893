import { Maybe } from "./Maybe"

export const match = 
  <T extends { type: string }>(value: T) => 
  <R>(cases: { [K in T["type"]]: (value: T & { type: K }) => R }): R => 
    ((cases as any)[value.type])(value)

export const matchPartial =
  <T extends { type: string }>(value: T) =>
  <R>(cases: { [K in T["type"]]?: (value: T & { type: K }) => R }): Maybe<R> =>
    ((cases as any)[value.type])?.(value)

export const matchEnum =
  <E extends string>(value: E) =>
  <R>(cases: Record<E, R>): R =>
    cases[value]

export const matchEnumLazy =
  <E extends string>(value: E) =>
  <R>(cases: Record<E, () => R>): R =>
    cases[value]()

export const matchEnumPartial =
  <E extends string>(value: E) =>
  <R>(cases: Partial<Record<E, R>>): Maybe<R> =>
    cases[value]

export const matchEnumPartialLazy =
  <E extends string>(value: E) =>
  <R>(cases: Partial<Record<E, () => R>>): Maybe<R> =>
    cases[value]?.()
