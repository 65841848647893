import UserIcon from "@material-ui/icons/PersonRounded"
import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import React from "react"
import { useParams } from "react-router"
import { dateToNiceFormat } from "../../../Utilities"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useUser } from "../../../hooks/ConectoHooks"
import { User } from "../../../model/User"
import { ConectoBadge, IdView, PageLoading, ProductListView, RoleListView, RoleView, useScrollToTop } from "../components"
import { ConectoGrid, DetailSection, Field, FieldBoolean, FieldCompanies, FieldText } from "../detail-components"
import { UserActions } from "./actions"



export const UserDetailPage = () => {

  const params = useParams()

  const id = params.id as string

  const [data, loading, error] = useUser(id)

  useScrollToTop()

  return Maybe.map(data)(it => 
    <UserDetail
      data={it}
    />
  ) ?? <PageLoading/>
}

export const UserDetail = (
  props: {
    data: User
  }
) => {

  const data = props.data

  return <FlexCol
    alignItems="stretch"
    justifyContent="flex-start"
    padding={16}
    style={{
      background: "#f5f5f5",
      minHeight: "100vh"
    }}
  >
    
    <FlexCol
      alignItems="stretch"
      padding={16}
      style={{
        border: "1px solid #ddd",
        borderRadius: 4,
        background: "white",
        boxShadow: "0 0 8px #ddd"
      }}
    >

      <Header
        data={data}
      />

      <hr/>

      <PersonalDataSection
        data={data}
      />

      <hr/>
      
      <StateSection
        data={data}
      />

      <hr/>

      <PermissionsSection
        data={data}
      />

      <hr/>

      <DesiredSection
        data={data}
      />

    </FlexCol>
  </FlexCol>
}

const Header = (
  props: {
    data: User
  }
) => {

  const fullName = List.filterNotNone([
    props.data.firstName,
    props.data.lastName
  ]).join(" ")

  return <FlexCol
    alignItems="flex-start"
  >
    <FlexRow
      style={{ alignSelf: "stretch" }}
      justifyContent="space-between"
    >
      <FlexRow
        gap={6}
        alignItems="center"
      >
        <UserIcon
          style={{
            fontSize: 48,
            color: "#888888"
          }}
        />
        <h1>
          {fullName}
        </h1>
      </FlexRow>
      
      <UserActions
        data={props.data}
      />

    </FlexRow>

    <UserBadges
      style={{
        paddingBottom: 8
      }}
      data={props.data}
    />

    <IdView
      style={{
        width: "max-content",
      }}
      id={props.data.id}
    />

  </FlexCol>
}



export const UserBadges = (
  props: {
    style?: React.CSSProperties
    data: User
  }
) => {

  return <FlexRow
    style={props.style}
    gap={4}
    flexWrap="wrap"
    justifyContent="flex-start"
  >
    
    {
      showIf(props.data.training ?? false)(
        <ConectoBadge
          color={"red"}
          children="Training"
        />
      )
    }

    {
      showIf(props.data.mockUser ?? false)(
        <ConectoBadge
          color={"red"}
          children="Mock"
        />
      )
    }

    <ConectoBadge
      color={props.data.validated ?? false ? "green" : "red"}
      children={props.data.validated ?? false ? "Validado" : "Sin Validar"}
    />

    {
      showIf(props.data.standBy ?? false)(
        <ConectoBadge
          color="red"
          children="Deshabilitado"
        />
      )
    }

  </FlexRow>
}


const PersonalDataSection = (
  props: {
    data: User
  }
) => {

  const data = props.data

  return <DetailSection
    title={"Datos Personales"}
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Nombre"
          value={data.firstName}
        />,
        <FieldText
          label="Apellido"
          value={data.lastName}
        />,
        <FieldText
          label="Email"
          value={data.email}
        />,
        <FieldText
          label="DNI"
          value={data.dni}
        />,
        <FieldText
          label="Telefono"
          value={data.phone}
        />,
        <FieldText
          label="Celular"
          value={data.cellPhone}
        />
      ]}
    />
    

  </DetailSection>
}

const StateSection = (
  props: {
    data: User
  }
) => {

  const data = props.data

  const enabled = !(data.standBy ?? false)

  return <DetailSection
    title={"Estado"}
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Fecha de creación"
          value={dateToNiceFormat(data.creationDate)}
        />,
        <FieldBoolean
          label="Habilitado"
          value={enabled}
          color={enabled ? none : "red"}
          bold={enabled ? false : true}
        />,
        <FieldBoolean
          label="Validado"
          value={data.validated}
          color={data.validated ? "green" : "red"}
          bold={true}
        />,
        <FieldBoolean
          label="Training"
          value={data.training}
          color={data.training ? "red" : none}
          bold={data.training}
        />,
        <FieldBoolean
          label="Mock"
          value={data.mockUser}
          color={data.mockUser ? "red" : none}
          bold={data.mockUser}
        />,
      ]}
    />

  </DetailSection>
}

const PermissionsSection = (
  props: {
    data: User
  }
) => {

  const data = props.data

  return <DetailSection
    title={"Permisos"}
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <Field
          label="Roles"
        >
          <RoleListView
            roles={data.roleList}
          />
        </Field>,
        <Field
          label="Productos"
        >
          <ProductListView
            products={data.productList}
          />
        </Field>,
        <Field
          label="Productos Autorizados"
        >
          <ProductListView
            products={data.authorizedProductList}
          />
        </Field>,
        [{ xs: 6 }, <FieldCompanies
          label="Empresas"
          companyList={data.companyIdList ?? []}
        />]
      ]}
    />
  </DetailSection>
}

const DesiredSection = (
  props: {
    data: User
  }
) => {

  const data = props.data

  return <DetailSection
    title={"Solicitado"}
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <Field
          label="Rol Solicitado"
        >
          <RoleView
            role={data.desiredRole}
          />
        </Field>,
        <Field
          label="Productos Solicitados"
        >
          <ProductListView
            products={data.desiredProductsList}
          />
        </Field>,
        <FieldText
          label="Empresa Solicitada"
          value={data.desiredCompany}
        />,
        <FieldText
          label="Nombre de Empresa Solicitada"
          value={data.desiredBusinessName}
        />,
        <FieldText
          label="Nombre de Empresa Solicitada"
          value={data.desiredBusinessName}
        />,
        <FieldCompanies
          label="Empresa"
          companyList={data.desiredCompaniesId ?? []}
        />,
      ]}
    />

  </DetailSection>
}

