import React, { Component } from 'react';

class ProfessionalForm extends Component {
    render() {
        return (
            <div className="shadow rounded p-5">
                <h2 className='text-primary'>Creá tu cuenta</h2><br/>
                <form className="row" id="contact-form" method="post" action="php/contact.php">
                    <div className="messages" />
                    <div className="form-group col-sm-6">
                        <label className="text-muted" htmlFor="InputName1">Nombre</label>
                        <input type="text" className="form-control input-1" id="InputName1" placeholder="Nombre" required="required" data-error="Firstname is required." />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="text-muted" htmlFor="InputName2">Apellido</label>
                        <input type="text" className="form-control input-1" id="InputName2" placeholder="Apellido" required="required" data-error="Lastname is required." />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-12">
                        <label className="text-muted" htmlFor="InputEmail1">Email</label>
                        <input type="email" className="form-control input-1" id="InputEmail1" placeholder="Ingresar email" required="required" data-error="Valid email is required." />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-12">
                    <label className="text-muted" htmlFor="InputPassword1">Contraseña</label>
                    <input type="password" className="form-control input-1" id="InputPassword1" placeholder="Contraseña" />
                    </div>
                    <div className="form-group col-12">
                        <label className="text-muted" htmlFor="InputPassword1">Repetir contraseña</label>
                        <input type="password" className="form-control input-1" id="InputPassword1" placeholder="Repetir contraseña" />
                    </div>
                    <div className="col-12 mt-5">
                        <button className="btn btn-primary block">Crear cuenta</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ProfessionalForm;