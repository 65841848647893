import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { BuyRequestDraft, Comment, Id, Product, SentMail, SupplyPoint, TermsAndConditions } from "../model/Model"
import { Company } from "../model/Company"
// import { ElectricityBuyRequest } from "./model/ElectricityBuyRequest"
import { auth, firestore } from "firebase"
import { List } from "functional/lib/List"
import { Maybe } from "functional/lib/Maybe"
import { id, none, pipe } from "functional/lib/core"
import { useAuthState } from "react-firebase-hooks/auth"
import { databases } from "../config"
import { useAppUser, useBidCollection, useBuyRequestCollection, useGasBuyRequestCollection, useProcessCollection } from "../context/App"
import { Bid } from "../model/Bid"
import { BuyRequest, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { GasBuyRequest } from "../model/buyRequest/GasBuyRequest"
import DocumentReference = firestore.DocumentReference
import CollectionReference = firestore.CollectionReference
import Timestamp = firestore.Timestamp
import Query = firestore.Query
import { Process } from "../model/Process"
import { User } from "../model/User"

export type AsyncHook<T, E = Error> = [Maybe<T>, boolean, Maybe<E>]

export const useFirebaseAuthState = () => {

}

export const useCurrentUserId = (): AsyncHook<Id, auth.Error> => {
  const [user, loading, error] = useAuthState(auth())
  const uid = user?.uid
  return [uid, loading, error]
}

export const useCurrentUser = (): AsyncHook<User, auth.Error | Error> => {

  const [uid, uidLoading, uidError] = useCurrentUserId()

  const [user, userLoading, userError] = useUser(uid)

  return [user, uidLoading || userLoading, uidError ?? userError]
}

export const dbCollection = <T,>(name: string): CollectionReference<T> => {
  return firestore().collection(name) as CollectionReference<T>
}



export const dbCollectionGroup = <T,>(name: string): CollectionReference<T> => {
  return firestore().collectionGroup(name) as CollectionReference<T>
}

export const dbUserCollection = () => { return dbCollection<User>(databases.users) }
export const dbCompanyCollection = () => { return dbCollection<Company>(databases.companies) }
export const dbDraftCollection = () => { return dbCollection<BuyRequestDraft>(databases.drafts) }
export const dbSentMailCollection = () => { return dbCollection<User>(databases.mail) }
export const dbTermsAndConditionsCollection = () => { return dbCollection<User>(databases.termsAndConditions) }


type MailRequest = {
  to: string
  message: string
}

export const dbMailQueue = () => { return dbCollection<MailRequest>(databases.mailQueue) }

export const dbCommentsGroupCollection = () => { return dbCollectionGroup<Comment>(databases.comments) }

export const dbSupplyPointsGroupCollection = () => { return dbCollectionGroup<SupplyPoint>(databases.supplyPoints) }

export const dbSupplyPointCollection = (companyId: Id) => {
  return dbCompanyCollection()
    .doc(companyId)
    .collection("supplyPoints") as CollectionReference<SupplyPoint>
}

export const useEntityList = <T>(query: Maybe<Query>): AsyncHook<List<T>> => {
  const [snapshot, loading, error] = useCollection(query)
  const result = snapshot ? snapshot.docs.flatMap((entity) => {
    try {
      return [{
        ...entity.data(), id: entity.id
      } as any as T]
    } catch (e) {
      console.error(e)
      return []
    }
  }) : undefined

  return [deepTimestampToDate(result), loading, error]
}

export const deepTimestampToDate =
  (value: any): any =>
    value instanceof Date ? value :
    value instanceof Timestamp ? value.toDate() :
    value instanceof Array ? value.map(deepTimestampToDate) :
    value instanceof Object ?
      Object.entries(value).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: deepTimestampToDate(value)
      }), {}) :
    value

export const usePopulatedEntityList = <T>(query: Maybe<Query>): AsyncHook<List<T>> => {
  const [snapshot, loading, error] = useCollection(query)
  const result = snapshot ? snapshot.docs.map((entity) => {
    return {
      ...entity.data(), id: entity.id, parentParent: entity.ref?.parent?.parent
    } as any as T
  }) : undefined

  if (result) {
    result.forEach((element: any) => {
      Object.entries(element).forEach(([field, value]) => {
        if (value instanceof Timestamp) {
          element[field] = value.toDate()
        }
      })
    })

  }
  return [result, loading, error]
}

export const useEntity = <T extends firestore.DocumentData>(
  docRef: Maybe<DocumentReference<T>>
): AsyncHook<T> => {
  const [entity, loading, error] = useDocument(docRef)

  const result = (entity !== none) ? { ...entity.data(), id: entity.id } as any : none

  return [deepTimestampToDate(result), loading, error]
}

export const useUser = (id: Maybe<Id>): AsyncHook<User> => {
  return useEntity(id ? dbUserCollection().doc(id) : none)
}

export const useDraftList = (
  companyIdList: Maybe<List<Id>>, 
  product: Product
): AsyncHook<List<BuyRequestDraft>> => {
  return useEntityList(
    companyIdList ? 
      dbDraftCollection()
        .where('buyerCompanyId', 'in', companyIdList)
        .where('productType', '==', product) : 
      none
  )
  //    .orderBy("lastEdit", "desc")
}

export const useCompany = (id: Maybe<Id>): AsyncHook<Company> => {
  return useEntity(id ? dbCompanyCollection().doc(id) : none)
}

export const useBuyRequest = (
  id: Maybe<Id>,
  product: BuyRequestType
): AsyncHook<BuyRequest> => {

  const collection = useBuyRequestCollection(product)
  const [request, loading, error] = useEntity<BuyRequest>(id ? collection.doc(id) : none)

  return [
    Maybe.map(request)(it => 
      ({
        ...it,
        type: it.type ?? "gas",
      }) as BuyRequest
    ), 
    loading, 
    error
  ]
}

export const useProcess = (
  buyRequestType: BuyRequestType,
  buyRequestId: Id,
  processId: Id
) => {
  const collection = useProcessCollection(buyRequestType, buyRequestId)
  return useEntity<Process>(collection.doc(processId))
}

export const useBid = (
  product: Product,
  buyRequestId: Maybe<Id>,
  processId: Maybe<Id>,
  id: Maybe<Id>
): AsyncHook<Bid> => {

  const collection = useBidCollection(
    product === "gas" ? "gas" : "electricity",
    buyRequestId,
    processId
  )

  return useEntity(
    Maybe.do(_ => _(collection).doc(_(id)))
  )
}


export const useBuyRequestList = (
  args: {
    type: BuyRequestType,
    status?: string,
    companyId?: Id,
    dateRange?: [Maybe<Date>, Maybe<Date>]
  }
): AsyncHook<List<BuyRequest>> => {

  const dateRange = args.dateRange ?? [none, none]

  const user = useAppUser()
  const roleList = user?.roleList ?? []

  const role = 
    roleList.includes("admin") ? "admin" : 
    roleList.includes("seller") ? "seller" : 
    roleList.includes("buyer") ? "buyer" :
    none
  
  const userCompanies = user?.companyIdList ?? []

  const collection = useBuyRequestCollection(args.type)

  const [result, loading, error] = useEntityList<BuyRequest>(
    pipe(collection)(
      it => role === "seller" ? it.where("onceInvited", "array-contains", userCompanies?.[0]) : it,
      it => args.status ? it.where('status.type', '==', args.status) : it,
      it => 
        role === "buyer" ? it.where('buyerCompanyId', 'in', userCompanies) :
        args.companyId ? it.where('buyerCompanyId', '==', args.companyId) : 
        it
    )
  )

  if (error) {
    console.error(error)
  }

  const filtered = 
    pipe(result)(
      it => it?.filter(it => isInRange(dateRange)(it.creationDate)),
      role === "seller" ?
        it => it?.filter(it =>
          it.status.type === "pending" ||
          it.status.type === "open" ||
          it.status.type === "waitingAnswer" ? 
            it.currentGuestIdList?.some(company => userCompanies.includes(company)) ?? false :
            true
        ) :
        id
    )

  return [
    filtered, 
    loading, 
    error
  ]

}

const isInRange = 
  ([from, to]: [Maybe<Date>, Maybe<Date>]) => 
  (value: Maybe<Date>): boolean =>
    value === none || (!from || value >= from) && (!to || value <= to)


export const useSellerAssignedBuyRequestList = (
  companyId: Id
): AsyncHook<List<GasBuyRequest>> => {

  const collection = useGasBuyRequestCollection()

  return useEntityList(
    collection
      .where("currentGuestIdList", "array-contains", companyId)
  )
}

export const useProcessList = (
  buyRequestId: Maybe<Id>,
  product: BuyRequestType
): AsyncHook<List<Process>> => {
  const collection = useProcessCollection(
    product, 
    buyRequestId ?? ""
  )
  return useEntityList(collection.orderBy("round", "asc"))
}

export const useBidsList = (
  product: BuyRequestType,
  buyRequestId: Id,
  processId: Maybe<Id>
): AsyncHook<List<Bid>> => {

  const collection = useBidCollection(
    product,
    buyRequestId,
    processId ?? ""
  )

  return useEntityList(collection?.orderBy("date", "desc"))
}

export const useSellerBidsList = (
  product: Product,
  buyRequestId: Id,
  processId: Id | null,
  training: boolean,
  company: string
): AsyncHook<List<Bid>> => {

  const collection = useBidCollection(
    product === "gas" ? "gas" : "electricity",
    buyRequestId,
    processId ?? ""
  )

  return useEntityList(
    collection?.where('sellerCompanyId', '==', company)?.orderBy("date", "desc")
  )
}

export const useSellerCompanies = (
  product: Product,
  subProduct: string,
  buyerCompanyId: Id
): AsyncHook<List<Company>, auth.Error | Error> => {
  const [user, loadingUser, errorUser] = useCurrentUser()
  const [userCompany, loadingCompany, errorCompany] = useCompany(buyerCompanyId)

  const filterProduct = (subProduct && (product === 'electricity')) ? subProduct : (product === 'gas' ? 'gas' : null)
  const [companyList, loadingCompanyList, errorList] = useEntityList<Company>(
    user && userCompany && filterProduct ?
      dbCompanyCollection()
        .where("productList", "array-contains", filterProduct)
        .where("role", "==", "seller")
        .where('mockCompany', "==", (userCompany?.mockCompany || user?.training)) :
      none
  )

  return [companyList, loadingUser || loadingCompany || loadingCompanyList, errorUser ?? errorCompany ?? errorList]
}

export const useComments = (
  buyRequestId: Maybe<Id>,
  product: Product
): AsyncHook<List<Comment>> => {

  const collection = useBuyRequestCollection(product === "gas" ? "gas" : "electricity")

  return useEntityList(
    collection
      .doc(buyRequestId)
      .collection("comments")
      .where("answered", "==", true)
      .orderBy('creationDate', 'asc')
    )
}

export const useSupplyPointListByProduct = (
  product: Product,
  companyId: Maybe<Id>
): AsyncHook<List<SupplyPoint>> => {
  return useEntityList(companyId ? dbSupplyPointCollection(companyId).where('product', "==", product) : none)
}

export const useSupplyPointList = (
  companyId: Maybe<Id>
): AsyncHook<List<SupplyPoint>> => {
  return useEntityList(companyId ? dbSupplyPointCollection(companyId) : none)
}

export const useSupplyPoint = (
  companyId: Maybe<Id>,
  id: Maybe<Id>
): AsyncHook<SupplyPoint> => {
  return useEntity(companyId && id ? dbSupplyPointCollection(companyId).doc(id) : none)
}

export const useAllUsersList = (): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection())
}

export const useNewUsersList = (): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection().where("validated", "==", false).where("standBy", "==", false))
}

export const useStandByUsersList = (): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection().where("validated", "==", false).where("standBy", "==", true))
}

export const useValidatedUsersList = (): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection().where("validated", "==", true).where("mockUser", "==", false))
}

export const useMockUsersList = (): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection().where("mockUser", "==", true))
}

export const useUsersListByCompany = (companyId: Id): AsyncHook<List<User>> => {
  return useEntityList(dbUserCollection().where("companyIdList", "array-contains", companyId))
}

export const useBuyerCompanyList = (): AsyncHook<List<Company>> => {
  return useEntityList(dbCompanyCollection().where('mockCompany', '==', false).where("role", "==", "buyer"))
}


export const useRealCompanyList = (): AsyncHook<List<Company>> => {
  return useEntityList(dbCompanyCollection().where('mockCompany', '==', false))
}

export const useSellerCompanyList = (): AsyncHook<List<Company>> => {
  return useEntityList(dbCompanyCollection().where('mockCompany', '==', false).where("role", "==", "seller"))
}

export const useMockBuyerCompanyList = (): AsyncHook<List<Company>> => {
  return useEntityList(dbCompanyCollection().where('mockCompany', '==', true).where("role", "==", "buyer"))
}

export const useMockSellerCompanyList = (): AsyncHook<List<Company>> => {
  return useEntityList(dbCompanyCollection().where('mockCompany', '==', true).where("role", "==", "seller"))
}

export const useGroupCommentsList = (): AsyncHook<List<Comment>> => {
  return usePopulatedEntityList(dbCommentsGroupCollection()
    .orderBy('creationDate', 'desc')
  )
}

export const useSentMailList = (): AsyncHook<List<SentMail>> => {
  return useEntityList(dbSentMailCollection()
    .orderBy('delivery.startTime', "desc")
  )
}

export const useTermsAndConditions = (): AsyncHook<List<TermsAndConditions>> => {
  return useEntityList(dbTermsAndConditionsCollection()
    .orderBy('date', "desc").limit(1)
  )
}
