import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from "@material-ui/core/InputLabel"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { IO } from "functional/lib/IO"
import { showIf } from "functional/lib/Maybe"
import { Unit, none } from "functional/lib/core"
import { Button, Modal, Row, Spinner } from "react-bootstrap"
import { Navigate } from "react-router"
import { buyRequestCreate } from "../client/buy-request"
import { FlexCol, FlexRow } from "../components/Flexbox"
import { useAppUser } from "../context/App"
import { useAppAsynchronism } from "../context/AppCall"
import { RState, useRState } from "../functional/react/RState"
import { useCompany } from "../hooks/ConectoHooks"
import { BuyRequestDraft } from "../model/Model"
import { Process } from "../model/Process"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { Draft } from "../model/utils"
import {
  BuyRequestDetailView
} from "../requestdetail/BuyRequestDetail"

export const BuyRequestCreateConfirmStep = (
  props: {
    request: BuyRequest
    process: Process
    onBack: IO<Unit>
  }
) => {

  const openModal = useRState(() => false)

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <BuyRequestDetailView
      style={{
        width: "100%",
        alignSelf: "center"
      }}
      buyRequest={props.request}
      processList={[props.process]}
    />

      <FlexRow
        justifyContent='space-between'
        padding={8}
      >

        <Button 
          size='lg'
          variant="light"
          onClick={props.onBack}
        >
          <FlexRow
            gap={8}
          >
            <ArrowBack/>
            Atras
          </FlexRow>
          
        </Button>

        <Button 
          size='lg'
          onClick={openModal.apply(() => true)}
        >
          <FlexRow
            gap={8}
          >
            Crear Contraseña y Publicar
            <ArrowForward/>
          </FlexRow>
        </Button>
      </FlexRow>

    <PasswordModal
      open={openModal}
      buyRequest={props.request}
      process={props.process}
    />
  </FlexCol>
}


const PasswordModal = (
  props: {
    open: RState<boolean>
    buyRequest: BuyRequestDraft
    process: Draft<Process>
  }
) => {

  const user = useAppUser()

  const password = useRState(() => "")
  const confirmPassword = useRState(() => "")

  const [company, loading, error] = useCompany(props.buyRequest.buyerCompanyId)

  const call = useAppAsynchronism(buyRequestCreate)

  const onSubmit = (password: string) =>
    company == none ?
    IO.noOp :
    call.run({
      product: props.buyRequest.type,
      buyRequest: props.buyRequest as BuyRequest,
      process: props.process as Process,
      company: company,
      placerId: user?.id ?? "",
      training: user?.training ?? false,
      password: password
    })

  if (call.state.type === "success")
    return <Navigate to="/marketplace" replace />

  return <Modal 
      show={props.open.value} 
      onHide={props.open.apply(() => false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Contraseña de Encriptación del Pedido
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <FlexCol
          alignItems="stretch"
          style={{ width: "100%" }}
          gap={8}
        >

          <PasswordInputs
            password={password}
            confirmPassword={confirmPassword}
          />

          <FlexCol>
            {password.value.length < 10 && <p className='text-danger'>La contraseña debe ser de 10 dígitos</p>}
            {password.value !== confirmPassword.value && <p className='text-danger'>Las contraseñas deben ser Iguales</p>}
          </FlexCol>

        </FlexCol>

      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="danger" 
          onClick={props.open.apply(() => false)}
        >
          Cerrar
        </Button>
        
        <Button
          variant="primary"
          onClick={onSubmit(password.value)}
          disabled={
            !company ||
            password.value === "" || 
            password.value != confirmPassword.value || 
            call.state.type === "running"
          }
        >
          Publicar Pedido de Compra
        </Button>

      </Modal.Footer>

      {showIf(call.state.type === "running")(
        <Modal.Body>
          <Row className='justify-content-center'>
            <Spinner animation="grow" variant="primary" /><b className="text-primary">Publicando Pedido de Compra</b>
          </Row>
        </Modal.Body>
      ) }
    </Modal>
}

const PasswordInputs = (
  props: {
    password: RState<string>
    confirmPassword: RState<string>
  }
) => {

  const show = useRState(() => false)

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">Ingrese una Contraseña</InputLabel>
      <PasswordEditor
        state={props.password}
        show={show}
      />
    </FormControl>

    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">Repita la Contraseña</InputLabel>
      <PasswordEditor
        state={props.confirmPassword}
        show={show}
      />
    </FormControl>

  </FlexCol>
}


const PasswordEditor = (
  props: {
    state?: RState<string>
    show?: RState<boolean>
  }
) => {

  const show = useRState(() => false)

  const showState = props.show ?? show

  return <Input
    type={showState.value ? 'text' : 'password'}
    value={props.state?.value}
    onChange={(e) => props.state?.apply(() => e.target.value)?.()}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={showState.apply(it => !it)}
          onMouseDown={
            event => {
              event.preventDefault()
            }
          }
        >
          {showState.value ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    }
  />
}

