import { useParams } from "react-router"
import { useBid, useBuyRequest } from "../../../hooks/ConectoHooks"
import { BuyRequest, BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { IdView, PageLoading, useScrollToTop } from "../components"
import { Bid, DecryptedBid, Offer } from "../../../model/Bid"
import { Id } from "../../../model/Model"
import { Maybe } from "functional/lib/Maybe"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import BidIcon from "@material-ui/icons/AttachMoneyRounded"
import { EncryptedBadge } from "../process/detail"
import { ConectoGrid, DetailSection, FieldBuyRequest, FieldCompany, FieldDate, FieldFile, FieldFiles, FieldNumber, FieldProcess, FieldText } from "../detail-components"
import { none } from "functional/lib/core"
import { match, matchEnum, matchPartial } from "functional/lib/match"
import { BidActions } from "./actions"
import { computeAnnualContractValue, computeFee, computeMonthlyContractValue, computeSpotContractValue, computeContractValue, computeContractValueAndFee } from "../../../utils/fee"


export const BidDetailPage = () => {

  const params = useParams()

  const type = params.type as BuyRequestType
  const buyRequestId = params.buyRequestId as string
  const processId = params.processId as string
  const id = params.id as string

  const buyRequest = {
    type: type,
    id: buyRequestId
  }

  const [data, loading, error] = useBid(type, buyRequestId, processId, id)

  useScrollToTop()

  return Maybe.match(data)({
    some: data => <BidDetail 
      buyRequest={buyRequest}  
      processId={processId}
      data={data}
    />,
    none: () => <PageLoading/>
  })
}

const BidDetail = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    data: Bid
  }
) => {

  const data = props.data

  return <FlexCol
    alignItems="stretch"
    justifyContent="flex-start"
    padding={16}
    style={{
      background: "#f5f5f5",
      minHeight: "100vh"
    }}
    gap={8}
  >
    
    <FlexCol
      alignItems="stretch"
      padding={16}
      style={{
        border: "1px solid #ddd",
        borderRadius: 4,
        background: "white",
        boxShadow: "0 0 8px #ddd"
      }}
    >
      <Header
        buyRequest={props.buyRequest}
        processId={props.processId}
        data={data}
      />

      <hr/>

      <DataSection
        buyRequest={props.buyRequest}
        processId={props.processId}
        data={data}
      />

      {
        Maybe.map(data.offer)(offer =>
          <>
            <hr/>
            <OfferSection
              buyRequest={props.buyRequest}
              data={offer}
            />
          </>  
        )
      }

      </FlexCol>
    </FlexCol>
}


const Header = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    data: Bid
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow
      justifyContent="space-between"
    >
      <FlexRow
        gap={6}
        alignItems="center"
      >
        <BidIcon
          style={{
            fontSize: 48,
            color: "#888888"
          }}
        />
        <h1>
          Oferta en {props.buyRequest.id}
        </h1>
      </FlexRow>

      <BidActions
        data={props.data}
      />

    </FlexRow>

    <EncryptedBadge
      encrypted={props.data.encrypted}
    />

    <IdView
      style={{
        width: "max-content",
      }}
      id={props.data.id}
    />

  </FlexCol>
}

const DataSection = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    data: Bid
  }
) => {

  return <DetailSection
    title="Datos"
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldBuyRequest
          label="Pedido de Compra"
          buyRequest={props.buyRequest}
        />,
        <FieldProcess
          label="Ronda"
          buyRequest={props.buyRequest}
          processId={props.processId}
        />,
        <FieldText
          label="Tipo"
          value={matchEnum(props.data.type)({
            auction: "👨‍⚖️ Subasta",
            tender: "✉️ Licitación"
          })}
        />,
        <FieldText
          label="Estado"
          value={props.data.encrypted ? "🔒 Encriptado" : "🔓 Desencriptado"}
        />,
        <FieldCompany
          label="Empresa"
          encrypted={props.data.encrypted}
          companyId={props.data.encrypted ? none : props.data.sellerCompanyId}
        />,
        <FieldDate
          label="Fecha"
          value={props.data.date}
        />,
      ]}
    />

  </DetailSection>
}



const OfferSection = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Offer
  }
) => {

  const [buyRequest, loading, error] = useBuyRequest(props.buyRequest.id, props.buyRequest.type)

  const { contractValue, fee } = computeContractValueAndFee(buyRequest, props.data)

  return <DetailSection
    title="Oferta"
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={
        matchPartial(props.data)({
          annual: data => [
            <FieldText
              label="Tipo"
              value="Gas Anual"
            />,
            <FieldNumber
              label="Precio de Verano"
              value={data.summerPrice}
              decimals={2}
              units="USD/MMBTU"
            />,
            <FieldNumber
              label="Precio de Invierno"
              value={data.winterPrice}
              decimals={2}
              units="USD/MMBTU"
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Plazo de Validez de la Oferta"
              value={data.validTermDays}
              units="días"
            />,
            <FieldNumber
              label="Plazo de Pago"
              value={data.payTermDays}
              units="días"
            />,
            <FieldFile
              label="XLS Desencriptado"
              file={data.decryptedXls}
            />,
            [{ xs: 4 }, <FieldFiles
              label="PDFs Desencriptados"
              files={data.decryptedPdfFiles}
            />],
          ],
          monthly: data => [
            <FieldText
              label="Tipo"
              value="Gas Mensual"
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Plazo de Validez de la Oferta"
              value={data.validTermDays}
              units="días"
            />,
            <FieldNumber
              label="Plazo de Pago"
              value={data.payTermDays}
              units="días"
            />,
            <FieldFile
              label="XLS Desencriptado"
              file={data.decryptedXls}
            />,
            [{ xs: 4 }, <FieldFiles
              label="PDFs Desencriptados"
              files={data.decryptedPdfFiles}
            />],
          ],
          spot: data => [
            <FieldText
              label="Tipo"
              value="Gas Spot"
            />,
            <FieldNumber
              label="Cantidad"
              value={data.amount}
            />,
            <FieldNumber
              label="Precio"
              value={data.price}
              decimals={2}
              units="USD/MMBTU"
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Plazo de pago"
              value={data.payTermDays}
              units="días"
            />,
          ],
          mater: data => [
            <FieldText
              label="Tipo"
              value="Electricidad Mater"
            />,
            <FieldDate
              label="Fecha de Inicio de Suministro"
              value={data.startSupplyDate}
            />,
            <FieldNumber
              label="Plazo de Validez de la Oferta"
              value={data.validTermDays}
              units="días"
            />,
            <FieldNumber
              label="Plazo"
              value={data.term}
            />,
            <FieldNumber
              label="Costo Variable"
              value={data.variableCost}
            />,
            <FieldFile
              label="XLS Desencriptado"
              file={data.decryptedXls}
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
            [{ xs: 4 }, <FieldFiles
              label="PDFs Desencriptados"
              files={data.decryptedPdfFiles}
            />],
          ],
          onsite: data => [
            <FieldText
              label="Tipo"
              value="Electricidad Onsite"
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
          ],
          thermal: data => [
            <FieldText
              label="Tipo"
              value="Electricidad Thermal"
            />,
            <FieldNumber
              label="Valor de Contrato"
              value={contractValue}
              decimals={2}
              units="USD"
            />,
            <FieldNumber
              label="Comisión Conecto"
              value={fee}
              decimals={2}
              units="USD"
            />,
          ],
        }) ?? []
      }
    />
  </DetailSection>
}



