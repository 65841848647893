import { mnemonicToSeed } from "bip39"
import { Async } from "functional/lib/Async"
import { pipe } from "functional/lib/core"
import { pki, random } from "node-forge"

type Codec<T, E> = {
  encode: (value: T) => E
  decode: (encoded: E) => T
}

export const base64: Codec<ArrayBuffer, string> = {
  encode: (data: ArrayBuffer) => Buffer.from(data).toString('base64'),
  decode: (str: string) => Buffer.from(str, 'base64')
}

export const utf8: Codec<string, ArrayBuffer> = {
  encode: (str: string) => Buffer.from(str, 'utf8'),
  decode: (data: ArrayBuffer) => Buffer.from(data).toString('utf8')
}

export const hashSha256 = (data: ArrayBuffer): Async<string> =>
  async () => {
    const digest = await crypto.subtle.digest('SHA-256', data)
    return base64.encode(digest)
  }

export const conectoAsymetricEncrypt =
  (publicKeyPem: string) => {

    const publicKey = pki.publicKeyFromPem(publicKeyPem)

    return (data: string): Async<string> => 
      async () => {

        const symetricKey = await conectoSymetricKeyGenerate()

        const symetric = await conectoSymetric(symetricKey)()

        const encryptedData = await symetric.encrypt(utf8.encode(data))()

       const encryptedSymetricKey = pipe(symetricKey)(
          it => publicKey.encrypt(it, "RSA-OAEP"),
          utf8.encode,
          base64.encode
        )

        return `${encryptedSymetricKey}.${base64.encode(encryptedData)}`
      }
        
  }

export const conectoAsymetricDecrypt =
  (password: string) =>
  async () => {

    const seed = await mnemonicToSeed(password)

    const prng = random.createInstance()
    prng.seedFileSync = () => seed.toString('hex')

    const keyPair = pki.rsa.generateKeyPair({ 
      bits: 2048,
      prng, 
      workers: 2 
    })

    return {

      publicKey: pki.publicKeyToPem(keyPair.publicKey),

      decrypt: (encrypted: string) => 
        async () => {

          const [encryptedKey, encryptedPayload] = encrypted.split(".")

          const payloadKey = pipe(encryptedKey)(
            base64.decode,
            utf8.decode,
            it => keyPair.privateKey.decrypt(it, "RSA-OAEP")
          )

          const symetric = await conectoSymetric(payloadKey)()

          const payload = await symetric.decrypt(base64.decode(encryptedPayload))()

          return utf8.decode(payload)
        }

    }
  }

const symetricAlgorithm = {
  name: 'AES-CTR',
  counter: new Uint8Array(16),
  length: 128
} as const

export const conectoSymetricKeyGenerate: Async<string> =
  async () => {

    const key = await crypto.subtle.generateKey(
      symetricAlgorithm,
      true,
      ['encrypt', 'decrypt']
    )

    const exported = await crypto.subtle.exportKey('raw', key)

    return base64.encode(exported)
  }

export const conectoSymetric =
  (key: string) => 
  async () => {

    const importedKey = await crypto.subtle.importKey(
      'raw',
      base64.decode(key),
      symetricAlgorithm,
      true,
      ['encrypt', 'decrypt']
    )

    return {
      encrypt: (data: ArrayBuffer): Async<ArrayBuffer> => 
        async () => {
          return await crypto.subtle.encrypt(
            symetricAlgorithm, 
            importedKey, 
            data
          )
        },

      decrypt: (data: ArrayBuffer): Async<ArrayBuffer> =>
        async () => {
          return await crypto.subtle.decrypt(
            symetricAlgorithm, 
            importedKey, 
            data
          )
        }
      }
  }