import { List } from "./List"
import { none } from "./core"

export namespace Record {

  export const isEmpty = <T>(record: Record<string, T>): boolean => Object.keys(record).length === 0

  export const empty = <T>(defaultValue: T): Record<string, T> => ({})

  export const merge = 
    <T>(combine: (lhs: T, rhs: T) => T) => 
    <K extends string = string>(lhs: Record<K, T>, rhs: Record<K, T>): Record<K, T> => {

      const keys = List.unique(List.concat(Record.keys(lhs), Record.keys(rhs)))

      return Record.of(keys)(key => {
        const l = lhs[key]
        const r = rhs[key]
        return (
          l === none ? r : 
          r === none ? l :
          combine(lhs[key], rhs[key])
        )
      })

    }

  export const keys = <K extends string, T>(record: Record<K, T>): List<K> => Object.keys(record) as any as List<K>

  export const values = <T>(record: Record<string, T>): List<T> => Object.values(record) as any as List<T>

  export const entries = <K extends string, T>(record: Record<K, T>): List<[K, T]> => Object.entries(record) as any as List<[K, T]>

  export const of = 
    <K extends string = string>(keys: List<K>) => 
    <T>(f: (key: K) => T): Record<string, T> => {
      const result: Record<string, T> = {}
      for (const key of keys) {
        result[key] = f(key)
      }
      return result
    }

  export const mapValues = <K extends string, T, U>(record: Record<K, T>, f: (value: T, key: K) => U): Record<string, U> => 
    of(keys(record))(key => f(record[key], key))

}
