import { StaticDecode, TSchema } from "@sinclair/typebox"
import CollectionReference = firestore.CollectionReference
import { firestore } from "firebase"
import { Value } from "@sinclair/typebox/value"


export const dbCollectionTyped = <T extends TSchema,>(
  name: string, 
  type: T
): CollectionReference<StaticDecode<T>> => {
  return collectionTyped(type)(firestore().collection(name))
}

export const collectionTyped = 
  <T extends TSchema,>(type: T) => 
  (collection: CollectionReference,): CollectionReference<StaticDecode<T>> => 
  collection.withConverter({
    fromFirestore: it => {
      try {
        return Value.Decode(type, ({ ...it.data(), id: it.id }))
      } catch(e) {
        console.log(it.id)
        console.log(it.data())
        console.error(e)
        throw e
      }
    },
    toFirestore: it => Value.Encode(type, it)
  })

