import React, { Component } from 'react';

class Feature extends Component {
    render() {
        return (
            <section className="text-center p-0">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <a href='/'>
                        <div className="px-4 py-7 rounded hover-translate">
                            <div>
                                <img alt="Image" src={require(`../assets/images/testimonial/Conecto-dark.png`)}/>
                            </div>
                            <h5 className="mt-4 mb-3">Conecto Energía</h5>
                            <p className="mb-0">Máxima seguridad en el proceso de compra-venta.</p>
                        </div>
                    </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <a href='/vendedores'>
                        <div className="px-4 py-7 hover-translate">
                            <div>
                                <img alt="Image" src={require(`../assets/images/testimonial/Vendedores-dark.png`)}/>
                            </div>
                            <h5 className="mt-4 mb-3">Vendedores</h5>
                            <p className="mb-0">Accedé al Marketplace y ofertá tu energía</p>
                        </div>
                    </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <a href='/compradores'>
                        <div className="px-4 py-7 rounded hover-translate">
                        <div>
                            <img alt="Image" src={require(`../assets/images/testimonial/Compradores-dark.png`)}/>
                        </div>
                        <h5 className="mt-4 mb-3">Compradores</h5>
                        <p className="mb-0">Publicá tu pedido y recibí ofertas competitivas.</p>
                        </div>
                    </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                    <a href='/consultores'>
                        <div className="px-4 py-7 rounded hover-translate">
                        <div>
                            <img alt="Image" src={require(`../assets/images/testimonial/Consultant-dark.png`)}/>
                        </div>
                        <h5 className="mt-4 mb-3">Consultores</h5>
                        <p className="mb-0">Traé a tus clientes a comprar con Conecto.</p>
                        </div>
                    </a>
                </div>
                </div>
            </div>
            </section>
        );
    }
}

export default Feature;