import { compose, Endo, id, throws } from "../core"
import { List } from "../List"

export type Lens<A, B> = {
  get: (a: A) => B,
  over: (transform: Endo<B>) => Endo<A>
}

export const Lens = {

  field: <A, B extends keyof A>(name: B): Lens<A, A[B]> => ({
    get: a => a[name],
    over: transform => a => ({ ...a, [name]: transform(a[name]) })
  }),

  identity: <T>(): Lens<T, T> => ({
    get: id,
    over: id
  }),

  listIndex: <T>(index: number): Lens<List<T>, T> => ({
    get: it => it[index],
    over: transform => 
      prev => 
        index < 0 || prev.length <= index ?
          prev :
          prev.toSpliced(index, 1, transform(prev[index]))
  }),

  compose: <A, B, C>(
    lhs: Lens<A, B>,
    rhs: Lens<B, C>
  ): Lens<A, C> => ({
    get: compose(lhs.get, rhs.get),
    over: compose(rhs.over, lhs.over)
  }),

  composeN: id<{
    <T1>(): Lens<T1, T1>
    <T1, T2>(l1: Lens<T1, T2>): Lens<T1, T2>
    <T1, T2, T3>(l1: Lens<T1, T2>, l2: Lens<T2, T3>): Lens<T1, T3>
    <T1, T2, T3, T4>(l1: Lens<T1, T2>, l2: Lens<T2, T3>, l3: Lens<T3, T4>): Lens<T1, T4>
    <T1, T2, T3, T4, T5>(l1: Lens<T1, T2>, l2: Lens<T2, T3>, l3: Lens<T3, T4>, l4: Lens<T4, T5>): Lens<T1, T5>
  }>(
    (...lenses: List<Lens<any, any>>): Lens<any, any> => lenses.reduce(Lens.compose, Lens.identity())
  )

}
