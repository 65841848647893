import { ContextAsync } from "functional/lib/ContextAsync"
import { AsyncData, useAsyncData } from "../functional/react/AsyncData"
import { AppCollections, AppContext, useAppContext } from "./App"
import { List } from "functional/lib/List"
import { Asynchronism, useAsynchronism } from "../functional/react/Asynchronism"
import firebase from "firebase"
import Firestore = firebase.firestore.Firestore
import CollectionReference = firebase.firestore.CollectionReference
import DocumentReference = firebase.firestore.DocumentReference
import Query = firebase.firestore.Query
import { GasBuyRequest } from "../model/buyRequest/GasBuyRequest"
import { ElectricityBuyRequest } from "../model/buyRequest/ElectricityBuyRequest"
import { BuyRequest, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { Bid } from "../model/Bid"
import { Process } from "../model/Process"
import { Maybe } from "functional/lib/Maybe"
import { User } from "../model/User"
import { Company } from "../model/Company"

export const AppCall = ContextAsync<AppContext>()
export type AppCall<T> = ContextAsync<AppContext, T>

export const useAppCallData = <T>(call: AppCall<T>, dependencies?: List<unknown>): AsyncData<T> => {
  const ctx = useAppContext()
  return useAsyncData(call(ctx), dependencies)
}

export const useAppAsynchronism = <I, O>(call: (input: I) => AppCall<O>): Asynchronism<I, O> => {
  const ctx = useAppContext()
  return useAsynchronism((input: I) => call(input)(ctx))
}

export const appCallUserData: AppCall<Maybe<User>> =
  ctx => async () => ctx.firebase.user

export const appCallTraining: AppCall<boolean> =
  ctx => async () => ctx.firebase.user?.training ?? false

export const appCallCollections: AppCall<AppCollections> =
  ctx => async () => ctx.firebase.collections

export const appCallGasBuyRequestCollection: AppCall<CollectionReference<GasBuyRequest>> =
  ctx => async () => ctx.firebase.collections.buyRequests.gas

export const appCallElectricityBuyRequestCollection: AppCall<CollectionReference<ElectricityBuyRequest>> =
  ctx => async () => ctx.firebase.collections.buyRequests.electricity

export const appCallUserCollection: AppCall<CollectionReference<User>> =
  ctx => async () => ctx.firebase.collections.users

export const appCallCompanyCollection: AppCall<CollectionReference<Company>> =
  ctx => async () => ctx.firebase.collections.companies

export const appCallBuyRequestCollection = 
  (product: BuyRequestType): AppCall<CollectionReference<BuyRequest>> =>
  ctx => async () => ctx.firebase.collections.buyRequests[product]

export const appCallProcessCollection =
  (product: BuyRequestType, buyRequestId: string): AppCall<CollectionReference<Process>> =>
  ctx => async () => ctx.firebase.collections.process(product, buyRequestId)

export const appCallBidCollection =
  (product: BuyRequestType, buyRequestId: string, processId: string): AppCall<CollectionReference<Bid>> =>
  ctx => async () => ctx.firebase.collections.bids(product, buyRequestId, processId)

