import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined"
import UnfoldLessOutlinedIcon from "@material-ui/icons/UnfoldLessOutlined"
import bsCustomFileInput from 'bs-custom-file-input'
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import { AsyncState } from "react-async"
import { Button, Card, Col, Collapse, Form, Row, Spinner, Table } from "react-bootstrap"
import Countdown from "react-countdown"
import ReactFileReader from 'react-file-reader'
import { NumericInput } from "../../InputViews"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { buildPathForStorage } from "../../Utilities"
import { useAsyncFunction } from "../../client/client"
import { ThermalOffer } from "../../model/Bid"
import { BidSuccess, NumericVariable } from "../BidComponents"
import { countDownRenderer } from "../BidForm"
import { ElectricityBuyRequest } from "../../model/buyRequest/ElectricityBuyRequest"
import { FileData } from "../../model/common"
import { none } from "functional/lib/core"
import { Process } from "../../model/Process"
import { bidCreate } from "../../client/bid"
import { matchPartial } from "functional/lib/match"
import { useAppAsynchronism } from "../../context/AppCall"
import { Async } from "functional/lib/Async"
import { fileEncryptAndUpload } from "../../client/file"
import { IO } from "functional/lib/IO"


export const ThermalBidForm = (
  props: {
    buyRequest: ElectricityBuyRequest
    process: Process
  }
) => {

  const call = useAppAsynchronism(bidCreate)

  const [uploadedStatus, setUploadedStatus] = useState(
    new Map<string, { encryptionProgress: number, uploadProgress: number, done: boolean }>()
  );

  const [draft, setDraft] = useState<Partial<ThermalOffer>>({
    decryptedXls: none,
    type: "thermal",
    fixedCost: none,
    variableCost: none,
    term: none,
    startSupplyMonth: none,
    encryptedPdfFiles: [],
    encryptedXls: none,
    validTermDays: props.process.validTermDays
  })

  const modifyDraft = (changes: any) => { setDraft({ ...draft, ...changes }) }

  const removePdf = (filename: string) => {
    modifyDraft({
      encryptedPdfFiles: draft.encryptedPdfFiles?.filter(it => it.name !== filename)
    })
    const uploaded = uploadedStatus
    uploaded.delete(filename)
    setUploadedStatus(uploaded)
  }

  const removeXls = () => {
    modifyDraft({
      encryptedXls: null
    })
  }

  const handleFileEncryption = (fileList: FileList): Async<FileData> => 
    async () => {
      const file = fileList[0]
      if (uploadedStatus.has(file.name)) {
        throw "file already uploaded"
      }

      const path = buildPathForStorage(false, "Electricity", props.buyRequest.id ?? "", 'Encrypted', none)()

      const result = await fileEncryptAndUpload(
        file,
        path,
        (encryption, upload) => {
          setUploadedStatus(
            uploadedStatus.set(
              file.name,
              {
                encryptionProgress: encryption,
                uploadProgress: upload,
                done: false
              }
            )
          )
        }
      )()

      setUploadedStatus(
        uploadedStatus.set(
          file.name,
          {
            encryptionProgress: 1.0,
            uploadProgress: 1.0,
            done: true
          }
        )
      )
      return result
    }

  const handlePdfUpload = async (file: FileList): Promise<void> => {
    try {
      const fileData = await handleFileEncryption(file)()
      modifyDraft({
        encryptedPdfFiles: draft.encryptedPdfFiles?.concat([fileData])
      })
    } catch (reason) {
      if (reason === "file already uploaded") alert("El archivo ya fue subido")
      throw reason
    }
  }

  const handleXlsUpload = async (file: FileList): Promise<void> => {
    try {
      const fileData = await handleFileEncryption(file)()
      modifyDraft({
        encryptedXls: fileData
      })
    } catch(reason) {
      if (reason === "file already uploaded") alert("El archivo ya fue subido")
      throw reason
    }
  }

  bsCustomFileInput.init()
  console.log(props.process.validTermDays)
  const [acceptedTaC, setAcceptedTaC] = useState(false)
  const [acceptedValidDays, setAcceptedValidDays] = useState(true)
  const validDays = "Acepto el plazo de " + props.process.validTermDays + " días desde la fecha y hora de cierre de esta Ronda."
  //Acpeto el plazo de X días de validez de esta Oferta según lo sugerido por el Comprador.
  //El plazo de validez de esta Oferta es de x días (a completar).

  const isValidPrice = true

  const unfinishedOffer = draft.fixedCost === null || draft.fixedCost === 0 ||
    draft.variableCost === null || draft.variableCost === 0 ||
    draft.term === null || draft.term === 0 ||
    draft.startSupplyDays === null || draft.startSupplyDays === 0

  const enableOffer = 
    isValidPrice && 
    acceptedTaC && 
    !unfinishedOffer &&
    call.state.type !== 'running' &&
    call.state.type !== "success"

  const buttonColor = enableOffer ? 'primary' : 'outline-primary'

  const priceColor = unfinishedOffer ? 'text-secondary' : (isValidPrice && !unfinishedOffer) ? 'text-success' : "text-danger"
  const [showBidForm, setShowBidForm] = useState(true)

  const [xlsEncryptionState, startXlsEncryption] = useAsyncFunction(handleXlsUpload)
  const [pdfEncryptionState, startPdfEncryption] = useAsyncFunction(handlePdfUpload)

  const onSubmit = call.run({
    product: "electricity",
    buyRequestId: props.buyRequest.id ?? "",
    offer: draft
  })

  if (call.state.type === "success") {
    return <BidSuccess
      onNewOffer={
        IO.sequence([
          () => setAcceptedTaC(false),
          call.reset
        ]) 
      }
    />
  }

  return <Card className='shadow'>
    <Card.Title>
      <br />
      <Row className='justify-content-center text-secondary'>
        <h5>Ingresá tu Oferta</h5>&nbsp;
        {showBidForm && <UnfoldLessOutlinedIcon onClick={() => setShowBidForm(!showBidForm)} />}
        {!showBidForm && <AddCircleOutlineOutlinedIcon onClick={() => setShowBidForm(!showBidForm)} />}
      </Row>
      <hr />
    </Card.Title>
    <Collapse in={showBidForm}>
      <Card.Body>
        <Form>
          <Form.Group>
            <NumericVariable
              title={'Precio Mensual por Potencia'}
              placeholder={"0.00 US$/MW-mes"}
              units={"US$/MW-mes"}
              min={0}
              max={props.process.maxFixedCost ? props.process.maxFixedCost : 30000}
              step={0.01}
              decimalPlaces={2}
              value={draft.fixedCost}
              onValueChanged={value => modifyDraft({ fixedCost: value })}
            />
            <br />
            <NumericVariable
              title={'Precio Variable por Energía'}
              placeholder={"0.00 US$/MWh"}
              units={"US$/MWh"}
              min={0}
              max={props.process.maxVariableCost ? props.process.maxVariableCost : 20}
              step={0.01}
              decimalPlaces={2}
              value={draft.variableCost}
              onValueChanged={value => modifyDraft({ variableCost: value })}
            />
            <hr />
            <br />
            <NumericVariable
              title={'Período de Abastecimiento de Contrato'}
              placeholder={"Años"}
              units={"Años"}
              min={0}
              max={30}
              step={1}
              decimalPlaces={2}
              value={draft.term}
              onValueChanged={value => modifyDraft({ term: value })}
            />
            <br />
            <NumericVariable
              title={'Plazo de Inicio de Suministro'}
              placeholder={"días"}
              units={"días"}
              min={0}
              max={730}
              step={1}
              decimalPlaces={2}
              value={draft.startSupplyDays}
              onValueChanged={value => modifyDraft({ startSupplyDays: value })}
            />
            {/*<Row className='justify-content-center'>*/}
            {/*    <Col xs={5}>*/}
            {/*        <Form.Label>Mes de Inicio de Suministro</Form.Label>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <YearMonthPicker*/}
            {/*            valueDate={draft.startSupplyMonth}*/}
            {/*            setDate={value => {modifyDraft({startSupplyMonth: value})}}/>*/}
            {/*    </Col>*/}
            {/*    <Col xs={3}/>*/}
            {/*</Row>*/}
            <hr />
            <Row>
              <Col sm={4}>
                <br />
                <ReactFileReader
                  elementId='xls'
                  fileTypes={".xlsx"}
                  handleFiles={startXlsEncryption}
                >
                  <Button size='sm'>Cargar Template de Oferta</Button>
                </ReactFileReader>
                <br />
                <small className='text-secondary'>El archivo debe ser <b>.xlsx</b></small>
              </Col>
              <Col>
                {xlsEncryptionState.isPending && <Row className='text-secondary justify-content-center'><h5><Spinner animation="grow" /> Encriptando Archivo</h5></Row>}
                {draft.encryptedXls && <Table size="sm">
                  <tbody>
                    <tr>
                      <td>{draft.encryptedXls.name}</td>
                      <td className='text-danger'>
                        <Button
                          size='sm'
                          variant='outline-danger'
                          onClick={() => {
                            removeXls()
                          }}>
                          Borrar
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>}
                <br /><small>Cargar el Template de
                  Oferta <b>completo</b></small>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col sm={4}>
                <br />
                <ReactFileReader
                  elementId='pdf'
                  fileTypes={".pdf"}
                  handleFiles={startPdfEncryption}
                >
                  <Button size='sm'>Subir Archivos</Button>
                </ReactFileReader>
                <br />
                <small className='text-secondary'>Los archivo deben ser <b>.pdf</b></small>
              </Col>
              <Col>
                {(draft.encryptedPdfFiles?.length ?? 0) > 0 && <Table size="sm">
                  <tbody>
                    {draft.encryptedPdfFiles?.map((fileData) => {
                      return <tr>
                        <td>{fileData.name}</td>
                        <td className='text-danger'>
                          <Button
                            size='sm'
                            variant='outline-danger'
                            onClick={() => {
                              removePdf(fileData.name)
                            }}>
                            Borrar
                          </Button>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </Table>}
                {pdfEncryptionState.isLoading && <Row className='text-secondary justify-content-center'><h5><Spinner animation="grow" /> Encriptando Archivo</h5></Row>}
                <br />
                <small>
                  Podés cargar la cantidad de PDF's que consideres necesarios.<br />
                  Se deben cargar de a uno.
                </small>
              </Col>
            </Row>
            <hr />
          </Form.Group>

          <Row className='justify-content-center'>
            {unfinishedOffer ? <small className={priceColor}>Tu oferta está incompleta</small> :
              <small className={priceColor}>Oferta completa. ¡Podés enviarla!</small>}
          </Row>
          <br />
          <Row className='justify-content-center'>
            {props.buyRequest.feeType == 'none' &&
              <p className='text-secondary'>Este pedido no tiene comisión para el vendedor.</p>}
            {(props.buyRequest.feeType == 'seller' || props.buyRequest.feeType == 'split' || props.buyRequest.feeType == 'buyer') &&
              <h6 className='text-secondary'>Comision: 0.00 USD
              </h6>}
          </Row>
          <Row className='justify-content-center'>
            <h4 className='text-danger'>
              <Countdown date={props.process.closeDate}
                renderer={countDownRenderer} />
            </h4>
          </Row>
          <br />
          <Row className='justify-content-center'>
            <Col xs={1} />
            <Col>
              <BooleanCheckEditor
                label="Acepto los Términos y Condiciones Particulares del Pedio de Compra"
                value={acceptedTaC}
                onValueChanged={setAcceptedTaC}
              />
            </Col>
            <Col xs={1} />
          </Row>
          <br />
          <Row className='justify-content-center'>
            <Col xs={1} />
            <Col>
              <BooleanCheckEditor
                label={validDays}
                value={acceptedValidDays}
                onValueChanged={setAcceptedValidDays}
              />
            </Col>
            <Col xs={1} />
          </Row>
          <Row className='justify-content-center'>
            <Col xs={1} />
            <Col>
              <Form.Check
                type={'checkbox'}
                label={'Modificar el Plazo de validez de Oferta'}
                disabled={false}
                checked={!acceptedValidDays}
                onChange={event => setAcceptedValidDays(!acceptedValidDays)}
              />
              <br />
              {!acceptedValidDays && <Row className='justify-content-center'>
                <Col xs={8}>
                  <NumericInput
                    name={'Plazo de validez de Oferta'}
                    value={draft.validTermDays}
                    placeholder={'días'}
                    onValueChanged={value => modifyDraft({ validTermDays: value })} />
                </Col>
              </Row>}
            </Col>
            <Col xs={1} />
          </Row>
        </Form><br />
        {matchPartial(call.state)({
          running: () => <p style={{ color: "blue" }}>Enviando Oferta</p>,
          error: () => <p style={{ color: "red" }}>Error al enviar oferta</p>,
          //success: () => <p style={{ color: "green" }}>Oferta enviada con Éxito</p>,
        })}
        <Button
          disabled={!enableOffer}
          variant={buttonColor}
          onClick={onSubmit}
        >
          Enviar Oferta
        </Button>
      </Card.Body>
    </Collapse>
  </Card>
}

ReactFileReader.defaultProps = {
  fileTypes: 'image/*',
  multipleFiles: false,
  base64: false,
  disabled: false,
}
