import firebase, { firestore } from "firebase"
import { ContextAsync } from "functional/lib/ContextAsync"
import { IO } from "functional/lib/IO"
import { Maybe } from "functional/lib/Maybe"
import React, { useMemo } from "react"
import { none, pipe, throws } from "functional/lib/core"

import Firestore = firebase.firestore.Firestore
import CollectionReference = firebase.firestore.CollectionReference
import DocumentReference = firebase.firestore.DocumentReference
import Query = firebase.firestore.Query
import { GasBuyRequest, GasBuyRequestT } from "../model/buyRequest/GasBuyRequest"
import { collectionTyped, dbCollectionTyped } from "./collections"
import { databases } from "../config"
import { ElectricityBuyRequest, ElectricityBuyRequestT } from "../model/buyRequest/ElectricityBuyRequest"
import { Id } from "../model/Model"
import { BuyRequest, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { matchEnum } from "functional/lib/match"
import { Bid } from "../model/Bid"
import { List } from "functional/lib/List"
import { Process, ProcessT } from "../model/Process"
import { Value } from "@sinclair/typebox/value"
import { User, UserT } from "../model/User"
import { RState } from "../functional/react/RState"
import { Company, CompanyT } from "../model/Company"

export type AppCollections = {
  buyRequests: {
    gas: CollectionReference<GasBuyRequest>
    electricity: CollectionReference<ElectricityBuyRequest>
  }
  companies: CollectionReference<Company>
  users: CollectionReference<User>
  process: (product: "gas" | "electricity", buyRequestId: Id) => CollectionReference<Process> 
  bids: (product: "gas" | "electricity", buyRequestId: Id, processId: Id) => CollectionReference<Bid> 
}

export type AppContext = {
  training: boolean
  firebase: {
    user: Maybe<User>
    firestore: Firestore
    collections: AppCollections
  }
}


export const appContextNew =
  (
    args: {
      user: Maybe<User>
      training: boolean
    }
  ): IO<AppContext> => 
  () => {

    const isAdmin = args.user?.roleList?.includes("admin") ?? false

    const training = isAdmin ? args.training : args.user?.training ?? false
    const firestore = firebase.firestore()

    const buyRequestsGas = dbCollectionTyped(
      training ? databases.trainingGasBuyRequests : databases.gasBuyRequests, 
      GasBuyRequestT
    )

    const buyRequestsElectricity = dbCollectionTyped(
      training ? databases.trainingElectricityBuyRequests : databases.electricityBuyRequests, 
      ElectricityBuyRequestT
    )

    const buyRequests = (product: "gas" | "electricity") =>
      matchEnum(product)<CollectionReference<BuyRequest>>({
        gas: buyRequestsGas,
        electricity: buyRequestsElectricity
      })

    return {
      training: training,
      firebase: {
        user: args.user,
        firestore: firestore,
        collections: {
          users: dbCollectionTyped(databases.users, UserT),
          companies: dbCollectionTyped(databases.companies, CompanyT),
          buyRequests: {
            gas: buyRequestsGas,
            electricity: buyRequestsElectricity,
          },
          process: (product, buyRequestId) =>
            collectionTyped(ProcessT)(
              buyRequests(product)
                .doc(buyRequestId)
                .collection("process")
            ),

          bids: (product, buyRequestId, processId) =>
            buyRequests(product)
              .doc(buyRequestId)
              .collection("process")
              .doc(processId)
              .collection("bids") as CollectionReference<Bid>
        }
      }
    }
  }

export const ReactAppContext = React.createContext<Maybe<AppContext>>(none)

export const useAppContext = () => {
  return React.useContext(ReactAppContext) ?? throws(new Error("AppContext not initialized"))
}

export const useAppUser = () => {
  const ctx = useAppContext()
  return ctx.firebase.user
}

export const useAppCollections = (): AppCollections => {
  const ctx = useAppContext()
  return ctx.firebase.collections
}

export const useGasBuyRequestCollection = (): CollectionReference<GasBuyRequest> => {
  const collections = useAppCollections()
  return collections.buyRequests.gas
}

export const useElectricityBuyRequestCollection = (): CollectionReference<ElectricityBuyRequest> => {
  const collections = useAppCollections()
  return collections.buyRequests.electricity
}

export const useBuyRequestCollection = (type: BuyRequestType): CollectionReference<BuyRequest> => {
  const collections = useAppCollections()
  return collections.buyRequests[type]
}

export const useProcessCollection = (type: BuyRequestType, buyRequestId: Id): CollectionReference<Process> => {
  const collections = useAppCollections()
  return useMemo(
    () => collections.process(type, buyRequestId),
    [type, buyRequestId]
  )
}

export const useBidCollection = (
  type: BuyRequestType, 
  buyRequestId: Maybe<Id>, 
  processId: Maybe<Id>
): Maybe<CollectionReference<Bid>> => {
  const collections = useAppCollections()
  return Maybe.do(_ => {
    return collections.bids(type, _(buyRequestId), _(processId))
  })
}

export const useFirebaseQuery = <T extends firestore.DocumentData>(
  query: (collections: AppCollections) => Query<T>
): [Maybe<List<T>>, boolean, Maybe<Error>] => {

  const collections = useAppCollections()

  const [snapshot, loading, error] = useCollection(query(collections))
  const result = 
    snapshot ? 
      snapshot.docs.map((entity) => {
        return {
          ...entity.data(), id: entity.id
        } as any as T
      }) : 
      none

  return [result, loading, error]
}

export const useFirebaseDocument = <T extends firestore.DocumentData>(
  docRef: (collections: AppCollections) => DocumentReference<T>
): [Maybe<T>, boolean, Maybe<Error>] => {

  const collections = useAppCollections()

  const [entity, loading, error] = useDocument(docRef(collections))

  const result = entity ? { ...entity.data(), id: entity.id } as any : none

  return [result, loading, error]
}
