import { useEffect } from "react"
import { useRState } from "./RState"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { de } from "date-fns/locale"
import { IO } from "functional/lib/IO"



export const useUrlParamState = <T extends string>(
  key: string,
  defaultValue: IO<T>
) => {

  const [searchParams, setSearchParams] = useSearchParams()


  const state = useRState<T>(() => (searchParams.get(key) as T) ?? defaultValue())

  useEffect(() => {
    setSearchParams({ [key]: state.value })
  }, [state.value])

  return state
}



