import firebase from "firebase"
import React, { useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import { useNavigate } from "react-router"
import { useCurrentUser } from "./hooks/ConectoHooks"
import { PasswordRecover } from "./Utilities"
import { useAsyncFunction } from "./client/client"
import { Footer } from "./landing/widgets/footer"
import { FlexCol } from "./components/Flexbox"


export const LoginPage = () => {

  const [user, loading, error] = useCurrentUser()
  const [state, callLogin] = useAsyncFunction(
    (input: { email: string, password: string }) => 
      firebase.auth().signInWithEmailAndPassword(input.email, input.password)
  )

  return <LoginForm
    onLogin={(email, password) => {
      callLogin({
        email: email,
        password: password
      })
    }}
    isLoggingIn={state.isLoading || loading}
    error={state.error ? state.error.message : null}
  />
}

const LoginForm = (
  props: {
    onLogin: (email: string, password: string) => void
    isLoggingIn: boolean,
    error: string | null
  }
) => {
  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const valid = email.length > 0 && password.length > 0

  return <FlexCol
    alignItems="stretch"
    style={{
      minHeight: "100vh"
    }}
    justifyContent="space-between"
  >
    <FlexCol
      style={{
        width: "100%",
        maxWidth: 600,
        alignSelf: "center",
        paddingTop: 40,
      }}
      paddingX={8}
    >
        <h1 style={{ color: "#212529" }}>Bienvenido!</h1>
        <p style={{ fontWeight: 400, color: "#6c757d" }}>Inicie sesión para continuar</p>
        {props.error && <p className="text-danger">{props.error}</p>}
        <Form 
          onSubmit={
            event => {
              props.onLogin(email, password)
              event.preventDefault()
            }
          }
          style={{
            width: "100%",
          }}
        >
          <FlexCol
            gap={10}
            alignItems="stretch"
           
          >
            <Form.Group
              controlId="LoginForm"
            >
              <Form.Control
                type="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </Form.Group>
            <PasswordRecover 
              style={{
                alignSelf: "stretch"
              }}
            />
            <Button
              variant="primary"
              type="submit"
              style={{ "width": "100%" }}
              disabled={!valid || props.isLoggingIn}
            >
              {!props.isLoggingIn ?
                "Iniciar Sesión" :
                <React.Fragment>
                  <Spinner animation="border" variant="light" size="sm" />
                  &nbsp;&nbsp;Iniciando Sesión
                </React.Fragment>

              }
            </Button>
            <div
              style={{ "textAlign": "center", "padding": 8 }}>
              <small>
                ¿No tenés cuenta?{" "}
                <a
                  style={{ "color": "#007bff", cursor: "pointer" }}
                  onClick={() => navigate('/form')}
                >
                  Crear Cuenta
                </a>
              </small>
            </div>
          </FlexCol>
        </Form>

    </FlexCol>
    <Footer/>
  </FlexCol>
}