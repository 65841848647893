import { List } from "functional/lib/List"
import { SentMail } from "../model/Model"
import { firestore } from "firebase"
import { normalizeForFirebase } from "./client"


export const mailSendBatch = (
  mails: List<SentMail>
) =>
  async () => {

    if (mails.length === 0) return

    const db = firestore()
    const mailCollection = db.collection("mail") //mailQueue
    const batch = db.batch()

    mails.forEach(mail => {
      const doc = mailCollection.doc()
      batch.set(
        doc,
        normalizeForFirebase({
          ...mail,
          id: doc.id
        })
      )
    })
    await batch.commit()
  }