import React, { useState } from "react"
import { User } from "./model/User"
import { Button, Col, Row } from "react-bootstrap"
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded"
import { GraphCard } from "./components/Card"
import { useCurrentUser } from "./hooks/ConectoHooks"

export const MarketData = (
  props: {
    currentUser: User
  }
) => {
  const [freeGraph, setFreeGraph] = useState(true)

  return <>
    <Row className={'justify-content-center'}>
      <Col className={'text-center'} xs={8} >
        <h5>Precios de gas natural para productores domésticos en Argentina</h5>
        {!freeGraph && <Button variant={'outline-success'} onClick={() => setFreeGraph(true)}><ArrowBackIosRoundedIcon fontSize='small' /> Volver</Button>}
      </Col>
      <Col className={'text-center'} xs={4}>
        <Button variant={'outline-secondary'}
        // onClick={() => setFreeGraph(false)}
        >Ver más gráficos
        </Button>
      </Col>
    </Row>
    <br />
    {!freeGraph && <Row className={'justify-content-center'}>
      <SellingGraphCard />
      <SellingGraphCard />
      <SellingGraphCard />
    </Row>}
    <Row className={'justify-content-center'}>
      {freeGraph && props.currentUser.roleList?.includes('buyer') && <GraphCard />}
    </Row>
  </>
}


const SellingGraphCard = () => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  })
  const classes = useStyles()

  return <Col className={'text-center'}>
    <Row className={'justify-content-center'}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image="https://imgur.com/tj9Fx29.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Lizard
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
              across all continents except Antarctica
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size={'sm'} variant="outline-primary">
            Comprar
          </Button>
          <Button size={'sm'} variant="outline-primary">
            Saber más
          </Button>
        </CardActions>
      </Card>
    </Row>
  </Col>
}