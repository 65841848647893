import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { Footer } from "../../../widgets/footer"
import { User } from "../../../../model/User"
import { BooleanCheckEditor } from "../../../../components/editors/boolean"
import { none } from 'functional/lib/core'
import { authSignUp } from '../../../../client/auth'
import { useAppAsynchronism } from '../../../../context/AppCall'
import { matchPartial } from 'functional/lib/match'


export function SignUpForm() {

  const [terms, setTerms] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeteadPassword] = useState('')
  const call = useAppAsynchronism(authSignUp)

  const enableButton = 
    repeatedPassword == password && 
    password.length > 5 && 
    terms && 
    call.state.type !== "running" && 
    call.state.type !== "success"

  const initialUserDraft: Partial<User> = {
    dni: none,
    email: none,
    firstName: none,
    lastName: none,
    phone: none,
    cellPhone: none,

    creationDate: new Date(),
    roleList: ['waiting'],
    productList: ['gas'],
    companyIdList: [''],

    desiredRole: none,
    desiredCompany: none,
    desiredProductsList: [],

    standBy: false,
    mockUser: false,
    authorizedProductList: [],
    validated: false,
    training: true,
  }

  const [userDraft, setUserDraft] = useState(initialUserDraft)

  const modifyUserDraft = (changes: Partial<User>) => setUserDraft({ ...userDraft, ...changes })

  return (<div>
    <section className="position-relative">
      <Container>
        <Row className="justify-content-center text-center">
          <Col className="col-lg-8 col-md-12">
            <div className="mb-6">
              <h2 className="mt-3">Creá tu cuenta en Conecto Energía</h2>
              {/*<p className="lead">We use the latest technologies it voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>*/}
            </div>
          </Col>
        </Row>
        <div className="row">
          <div className="col-lg-8 col-md-10 ml-auto mr-auto">
            <div className="register-form text-center">
              <form id="contact-form" method="post" action="php/contact.php">
                <div className="messages" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Control
                        type="name"
                        placeholder="Nombre"
                        value={userDraft.firstName}
                        onChange={event => { modifyUserDraft({ firstName: event.target.value }) }}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group2">
                      <Form.Control
                        type="lastName"
                        placeholder="Apellido"
                        value={userDraft.lastName}
                        onChange={event => { modifyUserDraft({ lastName: event.target.value }) }}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Control
                        type="email"
                        placeholder="Email empresarial"
                        value={userDraft.email}
                        onChange={event => { modifyUserDraft({ email: event.target.value }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Control
                        type="phone"
                        placeholder="Teléfono Celular"
                        value={userDraft.cellPhone}
                        onChange={event => { modifyUserDraft({ cellPhone: event.target.value }) }}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        value={password}
                        onChange={event => { setPassword(event.target.value) }} />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Control
                        type="password"
                        placeholder="Repetir Contraseña"
                        value={repeatedPassword}
                        onChange={event => { setRepeteadPassword(event.target.value) }} />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <p>Al crear una cuenta se aceptan los <a target='_blank'
                      href='https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/T%C3%A9rminos%20y%20Condiciones%20Generales%2FTe%CC%81rminos%20y%20Condiciones%20Generales%20(Vigente%20al%2028%20de%20febrero%20de%202020).pdf?alt=media&token=dfdfa9b0-fdde-4d6c-8a1d-e078675b70f6'>Términos
                      y Condiciones</a></p>
                    <div className="remember-checkbox clearfix mb-5">
                      <div className="custom-control custom-checkbox">
                        <BooleanCheckEditor
                          type="checkbox"
                          value={terms}
                          onValueChanged={value => { setTerms(value) }}
                          label='He leído y acepto los Términos y Condiciones Generales del Sitio.'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Button
                      disabled={!enableButton}
                      onClick={call.run({ userDraft: userDraft, password: password })}
                    >
                      {
                        matchPartial(call.state)({
                          running: () => <Spinner size='sm' style={{ marginRight: 8 }} />,
                        })
                      }
                      Crear Cuenta
                    </Button>
                    {
                      matchPartial(call.state)({
                        error: state => <div className="alert alert-danger mt-4" role="alert">{String(state.error)}</div>,
                      })
                    }
                    <span className="mt-4 d-block">¿Ya tenés cuenta? <Link to="/login"><i>Iniciar sesión!</i></Link></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
    <Footer />
  </div>

  );
}

// }

// export default SignUpForm;