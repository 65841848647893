import { StaticDecode, Type } from "@sinclair/typebox"
import { ListT } from "../utils"

export const BuyRequestStatusOpenT = Type.Object({
  type: Type.Literal("open"),
})
export type BuyRequestStatusOpen = StaticDecode<typeof BuyRequestStatusOpenT>

export const BuyRequestStatusPendingT = Type.Object({
  type: Type.Literal("pending"),
})
export type BuyRequestStatusPending = StaticDecode<typeof BuyRequestStatusPendingT>

export const BuyRequestStatusWaitingAnswerT = Type.Object({
  type: Type.Literal("waitingAnswer"),
})
export type BuyRequestStatusWaitingAnswer = StaticDecode<typeof BuyRequestStatusWaitingAnswerT>

export const BuyRequestStatusAssignedT = Type.Object({
  type: Type.Literal("assigned"),
  assignedCompanyId: Type.String(),
  processId: Type.String(),
  bidId: Type.String(),
  comment: Type.String(),
  ranking: Type.Optional(Type.Record(Type.String(), Type.String()))
})
export type BuyRequestStatusAssigned = StaticDecode<typeof BuyRequestStatusAssignedT>

export const BuyRequestStatusPreAssignedT = Type.Object({
  type: Type.Literal("preAssigned"),
  assignedCompanyId: Type.Optional(Type.String()),
  preAssignedCompanyIdList: Type.Optional(ListT(Type.String())),
  processId: Type.String(),
  bidIdList: ListT(Type.String()),
  comment: Type.String(),
})
export type BuyRequestStatusPreAssigned = StaticDecode<typeof BuyRequestStatusPreAssignedT>

export const BuyRequestStatusDesertedT = Type.Object({
  type: Type.Literal("deserted"),
  comment: Type.Optional(Type.String()),
})
export type BuyRequestStatusDeserted = StaticDecode<typeof BuyRequestStatusDesertedT>

export const BuyRequestStatusT = Type.Union([
  BuyRequestStatusOpenT,
  BuyRequestStatusPendingT,
  BuyRequestStatusWaitingAnswerT,
  BuyRequestStatusAssignedT,
  BuyRequestStatusPreAssignedT,
  BuyRequestStatusDesertedT,
])
export type BuyRequestStatus = StaticDecode<typeof BuyRequestStatusT>
