import MaterialTable, { MaterialTableProps } from "material-table"
import { User } from "../../../model/User"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { useNavigate } from "react-router"
import { shallowCopy } from "../../../utils/utils"
import { none } from "functional/lib/core"
import { SentMail } from "../../../model/Model"
import { IdView, buildColumns } from "../components"
import { softMatch } from "../search"
import { dateToShortFormat } from "../../../Utilities"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useRState } from "../../../functional/react/RState"
import { useSentMailList } from "../../../hooks/ConectoHooks"
import { ConectoDialog } from "../detail-components"
import ReactJson from "@microlink/react-json-view"



export const AdminMailList = () => {

return <FlexCol
    alignItems="stretch"
    paddingY={16}
    gap={16}
>
    <AllMailsTable/>
</FlexCol>
}



const AllMailsTable = () => {
  
    const [list, loading, error] = useSentMailList()

    return <FlexCol
    alignItems="stretch"
    gap={8}
  >
  
      <MailTable
        title={"Mails [" + (list?.length ?? "-") + "]"}
        isLoading={loading}
        data={list ?? []}
        options={{
          pageSize: 20
        }}
        columns={[
          columns.id,
          columns.date,
          columns.sentTo,
          columns.template,
          columns.state
        ]} 
      />
    </FlexCol>
  }
  


export const MailTable = (
    props: Omit<MaterialTableProps<SentMail>, "data"> & {
      data: Maybe<List<SentMail>>
    }
  ) => {
  
    const selectedEmail = useRState<Maybe<SentMail>>(() => none)
  
    return <FlexCol
      alignItems="stretch"
    >
      <MaterialTable
        style={{
            borderRadius: 16,
            overflow: "hidden"
        }}
        
        {...props}
        options={{ 
          exportButton: true, 
          exportAllData: true,
          columnResizable: true,
          sorting: true,
          ...props.options
          //grouping: true,
          //filtering: true,
        }}
        data={(props.data ?? []) as SentMail[]}
        columns={props.columns.map(shallowCopy)}
        onRowClick={(event, data) => {
          const id = data?.id
          if (id !== none) {
            selectedEmail.apply(() => data)()
          }
        }}
      />

      <ConectoDialog
        open={{
          value: selectedEmail.value !== none,
          apply: transform => selectedEmail.apply(value => transform(value !== none) ? value : none)
        }}
        title="Dato JSON"
        style={{
          width: 800
        }}
      >
        <FlexCol
          style={{ width: "100%" }}
          alignItems="flex-start"
        >
          <ReactJson
            src={selectedEmail.value ?? {}}
            name={false}
            collapsed={1}
          />
        </FlexCol>
      </ConectoDialog>

    </FlexCol>
  }
  


export const mailColumns = buildColumns<SentMail>()({
    id: { 
        title: "Id", 
        customFilterAndSearch: (term, data) => softMatch(data.id ?? "", term),
        render: data => 
        <IdView
            id={data.id} 
        />,
    },

    date: { 
        title: "Fecha",
        defaultSort: "desc",
        customSort: (a, b) => (a.delivery?.startTime?.getTime() ?? 0) - (b.delivery?.startTime?.getTime() ?? 0),
        render: data => dateToShortFormat(data.delivery?.startTime)
    },

    sentTo: { 
        title: "Enviado a",
        field: "to"
    },

    template: {
        title: "Template",
        render: data => Maybe.lift(camelCaseToTitle)(data.template?.name) ?? "-"
    },

    state: { 
        title: "Estado",
        render: data => {
          const status = data.delivery?.state
          return <FlexRow
            justifyContent="flex-start"
            style={{
              fontWeight: "bold",
              color: 
                status === "SUCCESS" ? "green" :
                status === "ERROR" ? "red" :
                status === "PENDING" ? "orange" :
                status === "PROCESSING" ? "blue" :
                "black"
            }}
          >
            {
              status === "SUCCESS" ? "✅ Enviado" :
              status === "ERROR" ? "❌ Error" :
              status === "PENDING" ? "⏳ Pendiente" :
              status === "PROCESSING" ? "🔄 Procesando" :
              status ?? "-"
            }
          </FlexRow>
        }
    },

})

const columns = mailColumns


const camelCaseToTitle = (camelCase: string) => {
    return camelCase
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, str => str.toUpperCase())
  }

