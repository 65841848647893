import { showIf } from "functional/lib/Maybe"
import { Button, Spinner } from "react-bootstrap"
import { userDataUpdate } from "../../../client/user"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useAppAsynchronism } from "../../../context/AppCall"
import { User, UserRole, UserRoleT } from "../../../model/User"
import { CompanyView, ConectoDialog, ExtraActions, ShowJsonAction } from "../detail-components"

import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Unit, id, pipe } from "functional/lib/core"
import { BooleanCheckEditor } from "../../../components/editors/boolean"
import { RState, useRState } from "../../../functional/react/RState"
import { Id, Product, ProductT } from "../../../model/Model"
import { castOrNone } from "../../../utils/convert"
import { ProductView } from "../components"
import { CompanyListEditor, MultiSelectEditor, ProductListEditor } from "../../../components/editors/select"

export const UserActions = (
  props: {
    data: User
  }
) => {

  const validated = props.data.validated ?? false

  return <FlexRow
    gap={8}
  >

    {
      showIf(!validated)(
        <ValidateButton
          data={props.data}
        />
      )
    }

    <ExtraActions
      actions={[
        <EnableButton
          data={props.data}
        />,
        <ShowJsonAction
          data={props.data}
        />
      ]}
    />
  </FlexRow>
}

const ValidateButton = (
  props: {
    data: User
  }
) => {

  const open = useRState(() => false)

  return <FlexRow>
    <Button
      variant="success"
      onClick={open.apply(() => true)}
    >
      Validar
    </Button>

    <ConectoDialog
      open={open}
    >
      <ValidateForm
        data={props.data}
        onCancel={open.apply(() => false)}
      />
    </ConectoDialog>

  </FlexRow>
}

type FormState = {
  role: UserRole
  products: List<Product>
  companyList: List<Id>
  training: boolean
  mock: boolean
}

const ValidateForm = (
  props: {
    data: User
    onCancel?: IO<Unit>
  }
) => {

  const state = useRState<FormState>(() => ({
    role: castOrNone(UserRoleT)(props.data.desiredRole) ?? "seller",
    products: 
      pipe(props.data.desiredProductsList ?? [])(
        List.lift(castOrNone(ProductT)),
        List.filterNotNone
      ),
    companyList: props.data.desiredCompaniesId ?? [],
    training: false,
    mock: false
  }))

  const fields = RState.destructureAll(state)

  const call = useAppAsynchronism(
    (args: {
      userId: Id
      data: FormState
    }) =>
      userDataUpdate({
        userId: args.userId,
        newData: {
          validated: true,
          roleList: [args.data.role],
          productList: args.data.products,
          companyIdList: args.data.companyList,
          training: args.data.training,
          mockUser: args.data.mock
        }
      })
  )

  return <FlexCol
    padding={16}
    alignItems="stretch"
  >
    <h4>Validar Usuario</h4>
    <p>
      ¿Estás seguro que quieres validar a este usuario?
    </p>


    <FlexCol
      alignItems="flex-start"
      gap={8}
    >

      <FlexRow
        gap={8}
      >

        <b>Productos:</b>

        <ProductListEditor
          state={fields.products}
        />

      </FlexRow>

      <FlexRow
        gap={8}
      >

        <b>Empresas:</b>

        <CompanyListEditor
          state={fields.companyList}
        />

      </FlexRow>

      <BooleanCheckEditor
        label="Training"
        state={fields.training}
      />

      <BooleanCheckEditor
        label="Mock"
        state={fields.mock}
      />

    </FlexCol>

    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >
      <Button
        variant="danger"
        onClick={props.onCancel}
      >
        Cancelar
      </Button>
      <Button
        variant="success"
        disabled={call.state.type === "running"}
        onClick={
          call.run({
            userId: props.data.id,
            data: state.value
          })
        }
      >
        <FlexRow
          gap={8}
        >
          {showIf(call.state.type === "running")(<Spinner size="sm" />)}
          Validar
        </FlexRow>
        
      </Button>
    </FlexRow>

  </FlexCol>
}




const EnableButton = (
  props: {
    data: User
  }
) => {

  const enabled = !(props.data.standBy ?? false)

  const callEnable = useAppAsynchronism(
    (enable: boolean) =>
      userDataUpdate({
        userId: props.data.id,
        newData: {
          standBy: !enable
        }
      })
  )

  return <FlexRow
    onClick={callEnable.run(!enabled)}
  >
    {enabled ? "Deshabilitar" : "Habilitar"}
  </FlexRow>
}
