import { None, Unit, none, unit } from "functional/lib/core"
import { FormErrors } from "./FormErrors"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { Record } from "functional/lib/Record"
import { Draft } from "../../../model/utils"


export type Validator<Draft, Value = Draft> = (input: Draft) => Validated<Value>


export namespace Validator {

  export const id = <T>(): Validator<T> =>
    Validated.pure

  export const mapWithErrors = <Draft, Value = Draft>(
    transform: (input: Draft) => [Value, FormErrors]
  ): Validator<Draft, Value> =>
    input => {
      const [value, errors] = transform(input)
      return {
        value: { type: "available", value },
        errors
      }
    }

  export const equals = <T>(value: T, fieldId: string, message: string): Validator<T> =>
    predicate(input => input === value, fieldId, message)

  export const predicate = <T>(predicate: (input: T) => boolean, fieldId: string, message: string): Validator<T> =>
    input => predicate(input) ? 
      Validated.pure(input) : 
      Validated.map(Validated.error({ [fieldId]: [message] }), _ => input)

  export const record = <
    Draft extends Record<string, unknown>, 
    Value extends Record<string, unknown>
  >(
    validators: { [K in keyof Draft]?: Validator<Draft[K], Value[K & keyof Value]> }
  ): Validator<Draft, Value> => 
    input => {

      const keys = Record.keys(input)

      const validationResults = Record.of(keys)(key => {
        const validator = validators[key] ?? id()
        return validator(input[key] as any)
      })

      const errors = 
        Record.values(validationResults)
          .map(it => it.errors)
          .reduce(FormErrors.combine, FormErrors.identity)

      const unavailable = Record.values(validationResults).some(it => it.value.type === "unavailable")
      
      return {
        value: unavailable ? 
          { type: "unavailable", value: none } : 
          { 
            type: "available", 
            value: Record.mapValues(validationResults, it => it.value.value) as any
          },
        errors: errors
      }
    }

  export const sum = <
    Draft extends { type: string }, 
    Value extends Draft
  >(
    validators: { [K in Draft["type"]]: Validator<Draft & { type: K }, Value & { type: K }> }
  ): Validator<Draft, Value> => 
    input => {
      const validator = validators[input.type as Draft["type"]]
      return validator(input)
    }

  export const list = 
    <Draft, Value = Draft>(
      validator: (index: number) => Validator<Draft, Value>
    ): Validator<List<Draft>, List<Value>> =>
    draft => {

      const results = draft.map((it, i) => validator(i)(it))

      const errors = results.map(it => it.errors).reduce(FormErrors.combine, FormErrors.identity)

      return {
        value: 
          results.some(it => it.value.type === "unavailable") ? 
            { type: "unavailable" } :
            { 
              type: "available", 
              value: List.filterNotNone(results.map(it => it.value.value)) 
            },
        errors: errors
      }
    }

  export const notNone = <T>(fieldId: string, message: string): Validator<Maybe<T>, T> =>
    input => input === none ? Validated.fatalError({ [fieldId]: [message] }) : Validated.pure(input)

  export const compose: {
    <T>(): Validator<T, T>
    <T1, T2>(v1: Validator<T1, T2>): Validator<T1, T2>
    <T1, T2, T3>(v1: Validator<T1, T2>, v2: Validator<T2, T3>): Validator<T1, T3>
    <T1, T2, T3, T4>(v1: Validator<T1, T2>, v2: Validator<T2, T3>, v3: Validator<T3, T4>): Validator<T1, T4>
    <T1, T2, T3, T4, T5>(v1: Validator<T1, T2>, v2: Validator<T2, T3>, v3: Validator<T3, T4>, v4: Validator<T4, T5>): Validator<T1, T5>
    <T1, T2, T3, T4, T5, T6>(v1: Validator<T1, T2>, v2: Validator<T2, T3>, v3: Validator<T3, T4>, v4: Validator<T4, T5>, v5: Validator<T5, T6>): Validator<T1, T6>
    <T1, T2, T3, T4, T5, T6, T7>(v1: Validator<T1, T2>, v2: Validator<T2, T3>, v3: Validator<T3, T4>, v4: Validator<T4, T5>, v5: Validator<T5, T6>, v6: Validator<T6, T7>): Validator<T1, T7>
  } = (...validators: Validator<unknown, unknown>[]) =>
    (input: any) => validators.reduce((acc, validator) => Validated.bind(acc, validator), Validated.pure(input))

}


export type Validated<T> = {
  value: 
    | {
      type: "available",
      value: T
    }
    | {
      type: "unavailable",
      value: None
    }
  errors: FormErrors
}


export namespace Validated {

  export const isValid = (validator: Validated<unknown>): boolean => 
    validator.value.type === "available" && 
    FormErrors.isEmpty(validator.errors)

  export const pure = <T>(value: T): Validated<T> => ({
    value: { type: "available", value },
    errors: {}
  })

  export const noOp = pure(unit)

  export const map = <T, U>(validator: Validated<T>, f: (value: T) => U): Validated<U> => ({
    value: validator.value.type === "available"
      ? { type: "available", value: f(validator.value.value) }
      : { type: "unavailable", value: none },
    errors: validator.errors
  })

  export const fatalError = (errors: FormErrors): Validated<never> => ({
    value: { type: "unavailable", value: none },
    errors
  })

  export const error = (errors: FormErrors): Validated<Unit> => ({
    value: { type: "available", value: unit },
    errors
  })

  export const bind = <T, U>(validator: Validated<T>, f: (value: T) => Validated<U>): Validated<U> => {
    if (validator.value.type === "available") {
      const result = f(validator.value.value)
      return {
        value: result.value.type === "available"
          ? { type: "available", value: result.value.value }
          : { type: "unavailable", value: none },
        errors: FormErrors.combine(validator.errors, result.errors)
      }
    } else {
      return {
        value: { type: "unavailable", value: none },
        errors: validator.errors
      }
    }
  }

  export const sequence = <T>(list: List<Validated<T>>): Validated<List<T>> =>
    list.length == 0 ? pure([]) :
    bind(list[0], head =>
    bind(sequence(list.slice(1)), tail =>
      pure<List<T>>([head, ...tail])
    )
    )

  export const Do = <R>(
    body: (_: <T>(value: Validated<T>) => T) => R
  ): Validated<R> => {

    let errors = FormErrors.identity
    const exitSymbol = Symbol()

    try {

      const result = body(value => {

        if (value.value.type === "unavailable") {
          throw exitSymbol
        }

        errors = FormErrors.combine(errors, value.errors)
        return value.value.value

      })

      return Validated.pure(result)

    } catch (e) {
      if (e === exitSymbol) {
        return Validated.fatalError(errors)
      } else {
        throw e
      }
    }
  }

}

export const validate = 
  (condition: boolean) =>
  (fieldId: string, message: string): Validated<Unit> =>
    !condition ? fieldError(fieldId, message) : Validated.noOp

export const fieldError = (fieldId: string, message: string): Validated<Unit> =>
  Validated.error({ [fieldId]: [message] })

export const validateNotNone = <T,>(value: Maybe<T>) => (fieldId: string, message: string): Validated<T> =>
  value === none ? Validated.fatalError({ [fieldId]: [message] }) : Validated.pure(value)

