import React, { Component } from 'react';
import Typed from 'react-typed';

export class BuyersHero extends Component {
    render() {
        return (
              <div className="row justify-content-center mb-8">
                <div className="col-12 col-lg-8 text-center">
                  {/* Heading */}
                    <h2>En Conecto Energía podés licitar
                        <br/><Typed
                        className="text-primary"
                        strings={['Gas Natural','PPA MATER','PPA Solar FV Onsite', 'Energía Plus', 'Servicios de Generación Onsite']}
                    typeSpeed={40}
                    backSpeed={50}
                    loop >
                  </Typed></h2>
                {/*    <br/>*/}
                {/*  <h4 className='text-secondary'>*/}
                {/*      Accedé a todo el mercado en un solo lugar y de manera segura y eficiente para conseguir los mejores contratos de abastecimiento de energía para tu negocio.*/}
                {/*  </h4>*/}
                </div>
              </div>
        );
    }
}

export class SellersHero extends Component {
    render() {
        return (      <div className="row justify-content-center mb-8">
                        <div className="col-12 col-lg-8 text-center">
                            {/* Heading */}
                            <h2>En Conecto Energía podés acceder a pedidos de<br/> <Typed
                                className="text-primary"
                                strings={['Gas Natural','PPA MATER','PPA Solar FV Onsite', 'Energía Plus', 'Servicios de Generación Onsite']}
                                typeSpeed={60}
                                backSpeed={70}
                                loop >
                            </Typed></h2>
                            {/*<br/>*/}
                            {/*<h4 className='text-secondary'>*/}
                            {/*    Si sos productor, generador o comercializador de energía, usá nuestra plataforma para ampliar tu fuerza de venta, reducir tus costos y acortar los tiempos de transacción.*/}
                            {/*</h4>*/}
                        </div>
                    </div>
        );
    }
}

export class ConsultantsHero extends Component {
    render() {
        return (
                <div className="row justify-content-center mb-8">
                    <div className="col-12 col-lg-8 text-center">
                        {/* Heading */}
                        <h2>Podés ayudar a tus empresas a comprar <br/><Typed
                            className="text-primary"
                            strings={['Gas Natural','PPA MATER','PPA Solar FV Onsite', 'Energía Plus', 'Servicios de Generación Onsite']}
                            typeSpeed={40}
                            backSpeed={50}
                            loop >
                        </Typed></h2>
                        {/*<br/>*/}
                        {/*<h4 className='text-secondary'>*/}
                        {/*    Sumate a la red de Conecto Energía trayendo a nuestra plataforma los pedidos de compra de tus clientes. Les estarás acercando la mejor manera de acceder al mercado.*/}
                        {/*</h4>*/}
                    </div>
                </div>
        );
    }
}
