import { IO } from 'functional/lib/IO'
import { Maybe, showIf } from 'functional/lib/Maybe'
import { Unit, none } from 'functional/lib/core'
import React from "react"
import { FlexCol, FlexRow, Flexbox } from '../../components/Flexbox'
import { useAppUser } from '../../context/App'
import { useRState } from '../../functional/react/RState'
import {
  useBuyRequest,
  useBuyRequestList
} from "../../hooks/ConectoHooks"
import { deviceSizes, useDeviceSize } from '../../hooks/responsive'
import { BuyRequestDraft, Id } from "../../model/Model"
import { BuyRequestType } from '../../model/buyRequest/BuyRequest'
import { BuyRequestDetail } from "../../requestdetail/BuyRequestDetail"
import { BuyRequestListPanel } from './BuyRequestListPanel'
import { BuyRequestStatus } from '../../model/buyRequest/BuyRequestStatus'

export const MarketPlace = (
  props: {
    onNewBuyRequest?: (draft: BuyRequestDraft) => IO<Unit>
  }
) => {

  const user = useAppUser()

  const requestType = useRState(
    () => 
      (["gas", "electricity"] as const)
        .find(it => it === user?.productList?.[0]) ?? 
      "gas"
    )

  const statusFilter = useRState<Maybe<BuyRequestStatus["type"]>>(() => none)
  const companyIdFilter = useRState<Maybe<string>>(() => none)
  const dateRangeFilter = useRState<[Maybe<Date>, Maybe<Date>]>(defaultDateRange)

  const [requestList, loading, error] = useBuyRequestList({
    type: requestType.value,
    status: statusFilter.value,
    companyId: companyIdFilter.value,
    dateRange: dateRangeFilter.value,
  })

  const selectedRequestId = useRState<Maybe<Id>>(() => none)

  const deviceSize = useDeviceSize()
  const small = deviceSize <= deviceSizes.lg

  const resetFilters = IO.sequence([
    selectedRequestId.apply(() => none),
    statusFilter.apply(() => none),
    companyIdFilter.apply(() => none),
    () => {
      const value = defaultDateRange()
      dateRangeFilter.apply(() => value)()
    }
  ])

  return <FlexRow
    style={{
      width: "100%",
    }}
    paddingY={16}
    alignItems={'flex-start'}
  >
    <BuyRequestListPanel
      style={
        small ? {
          flexGrow: 1
        } : {
          width: 0,
          flexGrow: 2
        }
      }
      type={{
        value: requestType.value,
        apply: transform => 
          IO.sequence([
            resetFilters,
            requestType.apply(transform)
          ]) 
      }}
      buyRequestList={requestList ?? []}
      loading={loading}
      statusFilter={statusFilter}
      companyIdFilter={companyIdFilter}
      onNewBuyRequest={props.onNewBuyRequest}
      dateRangeFilter={dateRangeFilter}
      selectedRequestId={selectedRequestId}
    />
      
    {
      showIf(!small)(
        <BuyRequestDetailPanel 
          style={small ? {} : {
            width: 0,
            flexGrow: 3
          }}
          type={requestType.value}
          selectedRequestId={selectedRequestId.value}
        />
      )
    }
    

  </FlexRow>
}

const defaultDateRange: IO<[Maybe<Date>, Maybe<Date>]> = 
  () => {
    const now = new Date()
    const year = now.getFullYear()
    return [new Date(year, 0, 1), new Date(year + 1, 0, 1)]
  }

const BuyRequestDetailPanel = (
  props: {
    style?: React.CSSProperties
    type: BuyRequestType
    selectedRequestId: Maybe<Id>
  }
) => {

  const selectedRequestId = props.selectedRequestId

  const [selectedRequest, loading, error] = useBuyRequest(
    props.selectedRequestId, 
    props.type
  )

  return <FlexCol
    style={props.style}
  >
    {selectedRequestId === none &&
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h5 className='text text-center text-secondary'>
          Seleccioná un Pedido de Compra<br />para ver el detalle
        </h5>
      </>
    }
    {selectedRequestId !== none && selectedRequest &&
      <FlexCol
        style={{
          width: '100%',
          paddingRight: 10,
          paddingLeft: 10,
        }}
        alignItems='center'
      >
        <BuyRequestDetail
          style={{
            width: '100%'
          }}
          buyRequest={selectedRequest}
        />
      </FlexCol>
     
    }
    {selectedRequestId !== none && loading &&
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h5 className='text text-center text-secondary'>
          Cargando...
        </h5>
      </>
    }
    {selectedRequestId !== none && error &&
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h5 className='text text-center text-secondary'>
          {error.message}
        </h5>
      </>
    }
  </FlexCol>
}

