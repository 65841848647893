import {
  Card,
  CardContent,
  Paper, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { SupervisorAccount } from "@material-ui/icons"
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import firebase from "firebase"
import React, { useState } from "react"
import { Alert, Badge, Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap"
import { useCompany, useCurrentUser, useSupplyPointList, useUsersListByCompany } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import { PasswordRecover } from "../Utilities"
import { useAsyncFunction } from "../client/client"
import { Id, SupplyPoint } from "../model/Model"
import { Company } from "../model/Company"
import { User } from "../model/User"
import { useAppAsynchronism } from "../context/AppCall"
import { notificationMailSet, notificationSet } from "../client/notification"
import { FlexCol } from "../components/Flexbox"

export const AccountPage = (
  props: {
    currentUser: User
  }
) => {
  const [user, loadingUser, Error] = useCurrentUser()
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState('1')

  return <FlexCol
    alignItems="stretch"
    padding={16}
  >
    {loadingUser && "Cargando Información del Usuario"}
    {user?.validated && user?.training && <Alert variant='danger' className={'text-center'}>Usuario en Etapa de Entrenamiento</Alert>}
    <Row className={'justify-content-center'}>
      <Col xs={3} />
      <Col xs={6} className='text-left'>
        {selected === '1' && <h4 className='text-secondary'>Datos Personales</h4>}
        {selected === '2' && <h4 className='text-secondary'>Empresas Asociadas</h4>}
        {selected === '3' && <h4 className='text-secondary'>Documentos Legales</h4>}
        {selected === '4' && <h4 className='text-secondary'>Notificaciones</h4>}
      </Col>
      <Col xs={3} className={''}>
        <Button variant="danger" onClick={() => { firebase.auth().signOut(); window.location.href = "/login" }}>
          Cerrar Sesión
        </Button>
      </Col>
    </Row>
    <Row className={'justify-content-center'}>
      <Col xs={3} className='border-right'>
        <Row><br /></Row>
        <Row className='justify-content-center'>
          <h6 className={selected === '1' ? 'text-primary' : "text-secondary"}
            onClick={() => setSelected('1')} style={{ cursor: "pointer" }}>Datos Personales</h6>
        </Row>
        <hr />
        <Row className='justify-content-center'>
          <h6 className={selected === '2' ? 'text-primary' : "text-secondary"}
            onClick={() => setSelected('2')} style={{ cursor: "pointer" }}>Empresas Asociadas</h6>
        </Row>
        <hr />
        <Row className='justify-content-center'>
          <h6 className={selected === '4' ? 'text-primary' : "text-secondary"}
            onClick={() => setSelected('4')} style={{ cursor: "pointer" }}>Notificaciones</h6>
        </Row>
        <hr />
        <Row className='justify-content-center'>
          <h6 className={selected === '3' ? 'text-primary' : "text-secondary"}
            onClick={() => setSelected('3')} style={{ cursor: "pointer" }}>Documentos Legales</h6>
        </Row>
      </Col>
      <Col xs={9}>
        <br />
        {selected === '1' && user && <UserData currentUser={user} />}
        {selected === '2' && user && <Authorizations user={user} />}
        {selected === '3' && user && <LegalDocuments />}
        {selected === '4' && user && <Notifications user={user} />}
      </Col>
    </Row>
  </FlexCol>
}

const UserData = (
  props: {
    currentUser: User
  }
) => {


  const user = props.currentUser

  return <>
    <Row>
      {user && !user.validated && <Alert variant='danger' className='text-center'>
        <h6>Tu usuario aún no está validado.</h6>
        Necesitamos que por favor completes el formulario de<a className='text-primary'
          onClick={() => window.open('https://airtable.com/shr5RCkOeldEpJ1LZ', '_blank')}>
          <u>Validación de Usuario</u>. </a>
        Si sos el primer usuario de tu empresa, también necesitamos que llenes el formulario de <a
          className='text-primary'
          onClick={() => window.open('https://airtable.com/embed/shrDTo0L0V5MgmuVP', '_blank')}> <u>Alta de
            Empresa</u> </a>
        y el formulario de <a className='text-primary'
          onClick={() => window.open('https://airtable.com/embed/shrtARXeydLOKeC1H', '_blank')}><u>Alta
            de Puntos de Suministro</u>.</a>
        <br /><br />
        Cualquier duda comunicate con nosotros.
      </Alert>}
    </Row>
    <Row className='justify-content-center'>
      <Card className='text-center' style={{ width: '90%' }}>
        <Table>
          <tbody>
            <tr>
              <td>Nombre</td>
              <td>{user.firstName + " " + user.lastName}</td>
            </tr>
            <tr>
              <td>Mail</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>DNI</td>
              <td>{user.dni}</td>
            </tr>
            <tr>
              <td>Celular</td>
              <td>{user.cellPhone}</td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td>{user.phone}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Row>
    <br />
    <PasswordRecover email={user.email} />
  </>
}

const Authorizations = (
  props: {
    user: User
  }
) => {
  const CompanyRow = (
    props: {
      companyId: Id
    }
  ) => {
    const [company, loading, error] = useCompany(props.companyId)
    return <>
      <Row className='justify-content-center'>
        <Card style={{ width: '90%' }} key={props.companyId} className={'shadow'}>
          <CardContent>
            <Row>
              <Col>
                <Typography gutterBottom variant="h5" component="h2">
                  {company && company.businessName}
                  {loading && "Cargando..."}
                  {error && error.message}
                </Typography>
              </Col>
              <Col>
                {company && company.mockCompany && <p className='text-warning'>Empresa de Prueba</p>}
              </Col>
            </Row>
            <hr />
            <Row className={'justify-content-center'}>
              <Col xs={6}>
                <Row>
                  <Col xs={5}><b>Razón Social</b></Col>
                  <Col xs={7}>{company?.businessName}</Col>
                </Row>
                <Row>
                  <Col xs={5}><b>Alias</b></Col>
                  <Col xs={7}>{company?.displayName}</Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col><b>CUIT</b></Col>
                  <Col>{company?.cuit}</Col>
                </Row>
                <Row>
                  <Col><b>Productos habilitados</b></Col>
                  <Col>{company?.productList?.map(product => <Badge bg="primary">
                    {product === 'gas' && "Gas Natural"}
                    {product === 'thermal' && "Térmico On-Site"}
                    {product === 'mater' && "PPA MATER"}
                    {product === 'onsite' && "PPA Solar FV On-Site"}
                  </Badge>)}
                  </Col>
                </Row>
              </Col>
            </Row>
            {company && <CompanyUsersList company={company} />}
            {company?.role.includes('buyer') && <SupplyPointsList companyId={props.companyId} />}
          </CardContent>
        </Card>
      </Row>
      <br />
    </>
  }

  const SupplyPointsList = (
    props: {
      companyId: Id
    }
  ) => {
    const [supplyPointList, loading, error] = useSupplyPointList(props.companyId)

    return <><hr />
      <Row className='justify-content-center'>
        <Col className={'text-left'}>
          <h5>Puntos de Suministro</h5>
        </Col>
      </Row>
      {supplyPointList?.map(supplyPoint => <SupplyPointCard supplyPoint={supplyPoint} />)}
    </>

  }

  const user = props.user
  return <>{user.validated && user.companyIdList && user.companyIdList?.map(id => <><CompanyRow key={id} companyId={id} /><br /></>)}</>
}

const LegalDocuments = () =>
  <Modal.Body className='text-center'>
    <Row className='justify-content-center'>
    </Row>
    <Row className='justify-content-center'>
      <a target="_blank"
        href="https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/T%C3%A9rminos%20y%20Condiciones%20Generales%2FTyC%20Generales%20y%20Poli%CC%81tica%20General%20de%20Comisiones%20(Vigente%20al%2028%20de%20febrero%20de%202020).pdf?alt=media&token=90d890aa-9af6-4fb0-a498-358f7eeda3fc"
      >Términos y Condiciones Generales del Sitio</a>
    </Row>
    <Row className='justify-content-center'>
      <a target="_blank"
        href=""
      >Términos y Condiciones Particulares Gas</a>
    </Row>
  </Modal.Body>


const Notifications = (
  props: {
    user: User
  }
) => {
  const mailNotificationSetAsync = useAppAsynchronism(notificationMailSet)
  const notificationSetAsync = useAppAsynchronism(notificationSet)

  return <>
    <Row className='justify-content-center'>
      <h6 className='text-secondary'>Seleccioná las Notificaciones que quieras recibir</h6>
    </Row>
    <Row className='justify-content-center'>
      <Card className="shadow" style={{ width: '90%' }}>
        <Table>
          <thead>
            <tr className='text-center'>
              <th>{(mailNotificationSetAsync.state.type === "running" || notificationSetAsync.state.type === "running") &&
                <Spinner animation="grow" variant="primary" />}</th>
              <th>Email</th>
              <th>Notificación</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td>Nuevo Pedido</td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.mailSettings?.newBuyRequest}
                  onValueChanged={(value) => mailNotificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newBuyRequest'
                  })()} />
              </td>

              <td>
                <BooleanCheckEditor
                  value={props.user?.notificationSettings?.newBuyRequest}
                  onValueChanged={(value) => notificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newBuyRequest'
                  })()} />
              </td>
            </tr>
            <tr className='text-center'>
              <td>Nueva Ronda</td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.mailSettings?.newRound}
                  onValueChanged={(value) => mailNotificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newRound'
                  })()} />

              </td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.notificationSettings?.newRound}
                  onValueChanged={(value) => notificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newRound'
                  })()} />
              </td>
            </tr>
            <tr className='text-center'>
              <td>Novedades/Consultas</td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.mailSettings?.newConsult}
                  onValueChanged={(value) => mailNotificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newConsult'
                  })()} />

              </td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.notificationSettings?.newConsult}
                  onValueChanged={(value) => notificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'newConsult'
                  })()} />
              </td>
            </tr>
            <tr className='text-center'>
              <td>Adjudicación</td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.mailSettings?.assigned}
                  onValueChanged={(value) => mailNotificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'assigned'
                  })()} />
              </td>
              <td>
                <BooleanCheckEditor
                  value={props.user?.notificationSettings?.assigned}
                  onValueChanged={(value) => notificationSetAsync.run({
                    user: props.user,
                    value: value,
                    notification: 'assigned'
                  })()} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Row>
  </>
}

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 650,
  },
  table: {}
})

const CompanyUsersList = (
  props: {
    company: Company
  }
) => {

  const classes = useStyles();
  // const [email, setEmail] = useState('')
  // const [addEmail, setAddEmail] = useState(false)
  const [usersList, usersListLoading, usersError] = useUsersListByCompany(props.company.id)

  return <>
    <br />
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell><SupervisorAccount /><b>Usuarios</b></TableCell>
            <TableCell><b>Productos asociados</b></TableCell>
            <TableCell><b>Productos autorizados</b></TableCell>
            <TableCell align="right">
              {/*{!addEmail &&*/}
              {/*<Button variant={'outline-secondary'} size={'sm'} onClick={() => setAddEmail(true)}>*/}
              {/*    <AddIcon style={{fontSize: 15}}/>*/}
              {/*    <SupervisorAccount style={{fontSize: 15}}/></Button>}*/}
              {/*{addEmail && <>*/}
              {/*    <br/>*/}
              {/*    <Input placeholder={'Añadir Email'} variant={'filled'} value={email}*/}
              {/*           onChange={e => setEmail(e.target.value)}/>*/}
              {/*    <Button variant={'outline-primary'} size={'sm'} onClick={() => {*/}
              {/*        setAddEmail(false);*/}
              {/*        // updateDoc('sellers', props.seller.objectID, {usersEmailList: (props.seller.usersEmailList||[]).concat(email)});*/}
              {/*        setEmail('')*/}
              {/*    }}>Autorizar</Button>*/}
              {/*    <Button variant={'outline-danger'} size={'sm'} onClick={() => {``*/}
              {/*        setAddEmail(false)*/}
              {/*    }}>Salir</Button>*/}
              {/*</>}*/}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersList?.map(user => <TableRow key={user.id}>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user?.productList?.map(product =>
              <Badge bg={'secondary'}>{product === 'gas' && "Gas Natural"}
                {product === 'thermal' && "Térmico On-Site"}
                {product === 'mater' && "PPA MATER"}
                {product === 'onsite' && "PPA Solar FV On-Site"}</Badge>
            )}</TableCell>
            <TableCell>{user?.authorizedProductList?.map(product =>
              <Badge bg={'success'}>{product === 'gas' && "Gas Natural"}
                {product === 'thermal' && "Térmico On-Site"}
                {product === 'mater' && "PPA MATER"}
                {product === 'onsite' && "PPA Solar FV On-Site"}</Badge>
            )}</TableCell>

            {/*<TableCell align="right">*/}
            {/*    <Button size={'sm'} variant={'outline-primary'}>*/}
            {/*        <EditIcon style={{fontSize: 15}}/>*/}
            {/*    </Button>*/}
            {/*    <Button size={'sm'} variant={'outline-danger'}>*/}
            {/*        <DeleteOutlineIcon style={{fontSize: 15}}/>*/}
            {/*    </Button>*/}
            {/*</TableCell>*/}
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

const SupplyPointCard = (
  props: {
    supplyPoint: SupplyPoint
  }
) => {

  const classes = useStyles()
  const supplyPoint = props.supplyPoint
  const [show, setShow] = useState(false)


  return <Row className='justify-content-center'>
    <Card style={{ width: '90%' }} key={supplyPoint.id}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size={"small"}>
          <TableHead>
            <TableCell><HomeWorkIcon /><b>{supplyPoint.name}</b></TableCell>
            <TableCell align="right">
              <Button variant={'outline-secondary'} size={'sm'} onClick={() => setShow(!show)}>Ver más</Button>
            </TableCell>
          </TableHead>
          {show && <TableBody>
            <tr>
              <td>Nombre Corto</td>
              <td>{supplyPoint?.shortName}</td>
            </tr>
            <tr>
              <td>Categoría</td>
              <td>{supplyPoint?.category}</td>
            </tr>
            <tr>
              <td>Distribuidora</td>
              <td>{supplyPoint?.distributor?.name}, {supplyPoint?.distributor?.identifier}</td>
            </tr>
            <tr>
              <td>Dirección</td>
              <td>{supplyPoint?.address.street}, {supplyPoint?.address.locality},{supplyPoint?.address.province}</td>
            </tr>
            <tr>
              <td>Producto</td>
              <td>{supplyPoint?.product}</td>
            </tr>
          </TableBody>}
        </Table>
      </TableContainer>
    </Card>
  </Row>
}
