import { Maybe } from "functional/lib/Maybe"
import { none, throws } from "functional/lib/core"
import { Async } from "functional/lib/Async"
import { useRState } from "./RState"
import { useEffect } from "react"
import { List } from "functional/lib/List"

export type AsyncData<T> = {
  value: Maybe<T>
  loading: boolean
  error: Maybe<unknown>
}

export const useAsyncData = <T>(
  fetch: Async<T>,
  dependencies: List<unknown> = []
): AsyncData<T> => {

  const state = useRState<AsyncData<T>>(() => ({
    value: none,
    loading: true,
    error: none
  }))

  useEffect(
    () => {

      const run =
        async () => {
          state.apply(it => ({
            value: it.value,
            loading: true,
            error: none
          }))()
          try {
            const value = await fetch()
            state.apply(() => ({
              value: value,
              loading: false,
              error: none
            }))()
          } catch (error) {
            console.error(error)
            state.apply(it => ({
              value: it.value,
              loading: false,
              error: error
            }))()
          }
        }

      run()

    }, 
    dependencies
  )

  return state.value
}

export const AsyncState = {

  pure: <T>(value: T): AsyncData<T> => ({
    value: value,
    loading: false,
    error: none
  }),

  map: <I>(value: AsyncData<I>) => <O>(f: (value: I) => O): AsyncData<O> => ({
    value: Maybe.map(value.value)(f),
    loading: value.loading,
    error: value.error
  }),

  lift: <I, O>(f: (value: I) => O) => (value: AsyncData<I>): AsyncData<O> =>
    AsyncState.map(value)(f),

  bind: <I>(value: AsyncData<I>) => <O>(f: (value: I) => AsyncData<O>): AsyncData<O> => {

    if (value.value === none) return {
      value: none,
      loading: value.loading,
      error: value.error
    }

    const result = f(value.value)

    return {
      value: result.value,
      loading: value.loading || result.loading,
      error: value.error ?? result.error
    }
  },

  do: <R>(
    body: (_: <T>(value: AsyncData<T>) => T) => R
  ): AsyncData<R> => {

    const symbol = Symbol()

    let loading = false
    let error: Maybe<unknown> = none

    try {
      const result = body(value => {
        loading = loading || value.loading
        error = error ?? value.error
        return value.value ?? throws(symbol)
      })
      return {
        value: result,
        loading: loading,
        error: error
      }
    } catch (error) {
      if (error === symbol) {
        return {
          value: none,
          loading: loading,
          error: error
        }
      }
      throw error
    }

  },

}