import { List } from "functional/lib/List"
import { id } from "functional/lib/core"


export type FormErrors = Record<string, List<string>>

export const FormErrors = {

  of: (key: string, message: string): FormErrors => ({ [key]: [message] }),

  isEmpty: (errors: FormErrors): boolean => Object.keys(errors).length === 0,

  identity: id<FormErrors>({}),

  combine: (lhs: FormErrors, rhs: FormErrors): FormErrors => {
    
    const result: FormErrors = { ...lhs }
    for (const [key, value] of Object.entries(rhs)) {
      result[key] = result[key]?.concat(value) ?? value
    }
    return result
  }

}
