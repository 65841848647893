import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { id, none, pipe } from "functional/lib/core"
import MaterialTable, { MaterialTableProps } from "material-table"
import { Alert } from "react-bootstrap"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useAppContext, useBuyRequestCollection } from "../../../context/App"
import { RState, useRState } from "../../../functional/react/RState"
import { useEntityList } from "../../../hooks/ConectoHooks"
import { BuyRequestStatus } from "../../../model/buyRequest/BuyRequestStatus"
import { ElectricityBuyRequest } from "../../../model/buyRequest/ElectricityBuyRequest"
import { shallowCopy } from "../../../utils/utils"
import { CompanyView } from "../detail-components"
import { IdView, buildColumns } from "../components"
import { BuyRequestStatusFilter, BuyRequestStatusView, RoundView, SupplyPointView } from "./components"
import { useNavigate } from "react-router"
import { matchEnum } from "functional/lib/match"
import { appPaths } from "../../navigation"

export const AllElectricityBuyRequestsTable = () => {

  const { training } = useAppContext()

  const filter = useRState(
    () => ({
      status: id<Maybe<BuyRequestStatus["type"] | "all">>("all")
    })
  )

  const filters = RState.destructureAll(filter)

  const collection = useBuyRequestCollection("electricity")

  const [list, loading, error] = useEntityList<ElectricityBuyRequest>(
    pipe(collection)(
      it => filters.status.value !== "all" ? it.where("status.type", "==", filters.status.value) : it
    )
  )

  return <FlexCol
    alignItems="stretch"
    gap={8}
    paddingY={16}
  >

    {
      showIf(training)(
        <Alert>
          Solo se muestran los pedidos en el <b>SANDBOX</b>, sacar el modo "Training" arriba para ver los pedidos en producción.
        </Alert>
      )
    }
    
    <FlexCol
      alignItems="flex-start"
      padding={16}
      style={{
        background: "white",
        borderRadius: 16,
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)"
      }}
    >

      <h5>
        Filtros
      </h5>

      <FlexRow
        justifyContent="flex-start"
        gap={8}
      >

        <BuyRequestStatusFilter
          label="Estado"
          state={filters.status}
        />

      </FlexRow>

    </FlexCol>

    <ElectricityBuyRequestTable
      options={{ pageSize: 20 }}
      isLoading={loading}
      title={`Pedidos de Compra [${list?.length ?? "-"}]`}
      data={(list ?? []) as ElectricityBuyRequest[]}
      columns={[
        columns.id,
        columns.creationDate,
        columns.buyer,
        columns.buyType,
        columns.supplyPoint,
        columns.status,
        columns.round
      ]}
    />

  </FlexCol>
}


export const ElectricityBuyRequestTable = (
  props: Omit<MaterialTableProps<ElectricityBuyRequest>, "data"> & {
    data: Maybe<List<ElectricityBuyRequest>>
  }
) => {

  const navigate = useNavigate()

  return <MaterialTable<ElectricityBuyRequest>
    {...props}
    style={{
      borderRadius: 16,
      overflow: "hidden",
      ...props.style
    }}
    onRowClick={(event, data) => {
      event?.stopPropagation()
      if (data?.id !== none) {
        navigate(appPaths.buyRequest("electricity", data.id).detail)
      }
    }}
    options={{ 
      exportButton: true, 
      exportAllData: true,
      columnResizable: true,
      sorting: true,
      ...props.options,
    }}
    data={(props.data ?? []) as ElectricityBuyRequest[]}
    columns={props.columns.map(shallowCopy)}
  />
}

export const electricityBuyRequestColumns = buildColumns<ElectricityBuyRequest>()({

  id: {
    title: "Id",
    customFilterAndSearch: (term, data) => data.id?.includes(term) ?? false,
    render: data => <IdView id={data.id ?? ""} />
  },
  creationDate: {
    title: "Creado el",
    field: "creationDate",
    type: "datetime",
    defaultSort: "desc"
  },
  buyer: {
    title: "Comprador",
    render: data => <CompanyView id={data.buyerCompanyId} />
  },
  status: {
    title: "Estado",
    render: data => <BuyRequestStatusView status={data.status} />
  },
  supplyPoint: {
    title: "Punto de Suministro",
    render: data => <SupplyPointView
      companyId={data.buyerCompanyId} 
      id={data.supplyPointId} 
    />
  },
  round: {
    title: "Ronda",
    render: data => <RoundView
      type="electricity" 
      id={data.id ?? ""}
    />
  },

  buyType: {
    title: "Tipo de Compra",
    render: data => 
      matchEnum(data.buyType.type)({
        mater: "♻️ MATER",
        onsite: "📍 Onsite",
        surplus: "➕ Excedente",
        thermal: "🌋 Térmica",
      })
  }

})

const columns = electricityBuyRequestColumns
