import { IO } from "functional/lib/IO"
import { Maybe } from "functional/lib/Maybe"
import { Unit } from "functional/lib/core"
import { Button } from "react-bootstrap"
import { companyUpdate } from "../../../client/company"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { NumberEditor } from "../../../components/editors/number"
import { defaultFeeConditions } from "../../../config"
import { useAppAsynchronism } from "../../../context/AppCall"
import { RState, useRState } from "../../../functional/react/RState"
import { Company } from "../../../model/Company"
import { Id } from "../../../model/Model"
import { FeeConditions } from "../../../model/buyRequest/BuyRequestBase"
import { CallError, ConectoDialog, EditIcon, ExtraActions, ShowJsonAction, SubmitButton } from "../detail-components"
import { useNavigate } from "react-router"
import { appPaths } from "../../navigation"

export const CompanyActions = (
  props: {
    data: Company
  }
) => {

  return <FlexRow>

    <EditButton
      data={props.data}
    />

    <ExtraActions
      actions={[
        <EditFeeAction
          data={props.data}
        />,
        <ShowJsonAction
          data={props.data}
        />
      ]}
    />

  </FlexRow>
}


const EditButton = (
  props: {
    data: Company
  }
) => {

  const navigate = useNavigate()

  return <Button
    variant="light"
    onClick={() => {
      navigate(appPaths.company(props.data.id).edit)
    }}
  >
    <FlexRow
      gap={8}
    >
      <EditIcon/>
      Editar Datos
    </FlexRow>
    
  </Button>
}


const EditFeeAction = (
  props: {
    data: Company
  }
) => {

  const open = useRState(() => false)

  return <FlexCol>
    <FlexRow
      onClick={open.apply(() => true)}
    > 
      Modificar Comisión
    </FlexRow>

    <ConectoDialog
      open={open}
      title="Modificar Comisión"
      style={{
        width: 400
      }}
    >
      <EditFeeForm
        data={props.data}
        onCancel={open.apply(() => false)}
      />
    </ConectoDialog>

  </FlexCol>
}




const EditFeeForm = (
  props: {
    data: Company
    onCancel: IO<Unit>
  }
) => {

  const feeConditions = props.data.feeConditions ?? defaultFeeConditions

  const state = useRState<FeeConditionsDraft>(
    () => ({
      max: feeConditions.max,
      min: feeConditions.min,
      percent: feeConditions.factor * 100
    })
  )

  const result = 
    Maybe.do<FeeConditions>(_ => ({
      factor: _(state.value.percent) / 100,
      min: _(state.value.min),
      max: _(state.value.max)
    }))

  const call = useAppAsynchronism(
    (args: {
      companyId: Id
      data: FeeConditions
    }) =>
      companyUpdate({
        id: args.companyId,
        data: {
          feeConditions: args.data
        }
      })
  )

  return <FlexCol
    style={{ 
      width: "100%",
      padding: 10
    }}
    alignItems="stretch"
    gap={16}
  >

    <FeeConditionsEditor
      state={state}
    />

    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >

      <Button
        variant="light"
        onClick={props.onCancel}
      >
        Cancelar
      </Button>

      <SubmitButton
        label="Modificar"
        call={call}
        value={
          Maybe.do(_ => ({
            companyId: props.data.id,
            data: _(result)
          }))
        }
      />

    </FlexRow>

    <CallError
      call={call}
    />

  </FlexCol>
}


export type FeeConditionsDraft = {
  min: Maybe<number>
  max: Maybe<number>
  percent: Maybe<number>
}

export const FeeConditionsEditor = (
  props: {
    state: RState<FeeConditionsDraft>
  }
) => {

  const fields = RState.destructureAll(props.state)

  return <FlexCol
    gap={8}
    alignItems="stretch"
  >

    <FlexCol
      alignItems="flex-start"
      gap={2}
    >
      <b>Porcentaje</b>
      <NumberEditor
        state={fields.percent}
        decimalPlaces={3}
      />
    </FlexCol>

    <FlexCol
      alignItems="flex-start"
      gap={2}
    >
      <b>Mínimo (USD)</b>
      <NumberEditor
        state={fields.min}
      />
    </FlexCol>

    <FlexCol
      alignItems="flex-start"
      gap={2}
    >
      <b>Máximo (USD)</b>
      <NumberEditor
        state={fields.max}
      />
    </FlexCol>

  </FlexCol>
}
