import { Maybe, showIf, someIf } from "functional/lib/Maybe"
import { FlexCol, FlexRow } from "../../components/Flexbox"
import { none } from "functional/lib/core"
import { Id } from "../../model/Model"
import { useBuyRequest, useCompany, useProcess, useUser } from "../../hooks/ConectoHooks"
import { useNavigate } from "react-router"
import { List } from "functional/lib/List"
import { Alert, Button, Spinner } from "react-bootstrap"
import { Skeleton } from "@material-ui/lab"
import { dateToNiceFormat } from "../../Utilities"
import { FileData } from "../../model/common"
import { BuyRequest, BuyRequestType } from "../../model/buyRequest/BuyRequest"
import { Dialog, Grid, GridProps, GridSpacing, IconButton, Menu, MenuItem } from "@material-ui/core"
import { RState, useRState } from "../../functional/react/RState"
import React from "react"
import { Asynchronism } from "../../functional/react/Asynchronism"
import { matchPartial } from "functional/lib/match"
import ReactJson from "@microlink/react-json-view"
import { appPaths } from "../navigation"

import MaterialCompanyIcon from "@material-ui/icons/DomainRounded"
import MaterialUserIcon from "@material-ui/icons/PersonRounded"
import MaterialFileIcon from "@material-ui/icons/DescriptionRounded"
import MaterialBuyRequestIcon from "@material-ui/icons/ShoppingCartRounded"
import MaterialProcessIcon from "@material-ui/icons/RotateRightRounded"
import MaterialBidIcon from "@material-ui/icons/AttachMoneyRounded"
import MaterialCommentIcon from "@material-ui/icons/CommentRounded"
import MaterialEmailIcon from "@material-ui/icons/EmailRounded"
import MaterialSupplyPointIcon from "@material-ui/icons/PlaceRounded"

import MaterialCloseIcon from "@material-ui/icons/CloseRounded"
import MaterialMoreIcon from "@material-ui/icons/MoreVert"
import MaterialNewIcon from "@material-ui/icons/AddRounded"
import MaterialEditIcon from "@material-ui/icons/EditRounded"
import MaterialDeleteIcon from "@material-ui/icons/DeleteRounded"
import MaterialDownloadIcon from "@material-ui/icons/GetAppRounded"

export const CompanyIcon = MaterialCompanyIcon
export const UserIcon = MaterialUserIcon
export const FileIcon = MaterialFileIcon
export const BuyRequestIcon = MaterialBuyRequestIcon
export const ProcessIcon = MaterialProcessIcon
export const BidIcon = MaterialBidIcon
export const CommentIcon = MaterialCommentIcon
export const EmailIcon = MaterialEmailIcon
export const SupplyPointIcon = MaterialSupplyPointIcon
export const DownloadIcon = MaterialDownloadIcon

export const MoreIcon = MaterialMoreIcon
export const NewIcon = MaterialNewIcon
export const EditIcon = MaterialEditIcon
export const CloseIcon = MaterialCloseIcon
export const DeleteIcon = MaterialDeleteIcon


export const DetailSection = (
  props: {
    title: string
    children?: React.ReactNode
  }
) => {

  return <FlexCol
  alignItems="stretch"
>

    <h4>
      {props.title}
    </h4>

    <FlexRow
      style={{ alignSelf: "stretch" }}
      alignItems="flex-start"
      children={props.children}
    />
  </FlexCol>
}

export const Field = (
  props: {
    style?: React.CSSProperties
    label: string
    weight?: number
    children: React.ReactNode
  }
) => {

  return <FlexCol
    style={{
      width: 0,
      flexGrow: props.weight ?? 1,
      ...props.style
    }}
    alignItems="stretch"
    gap={4}
  >
    <FlexRow
      style={{
        fontWeight: "bold"
      }}
      justifyContent="flex-start"
    >
      {props.label}
    </FlexRow>
    <FlexRow
      flexWrap="wrap"
      gap={4}
      justifyContent="flex-start"
    >
      {props.children}
    </FlexRow>
  </FlexCol>

}

export const FieldText = (
  props: {
    label: string
    value: Maybe<string>
    weight?: number
  }
) => {

  return <Field
    label={props.label}
    weight={props.weight}
  >
    {props.value ?? "-"}
  </Field>

}

export const FieldDate = (
  props: {
    label: string
    value: Maybe<Date>
    weight?: number
  }
) => 
  <FieldText
    label={props.label}
    weight={props.weight}
    value={dateToNiceFormat(props.value)}
  />

export const FieldBoolean = (
  props: {
    label: string
    value: Maybe<boolean>
    weight?: number
    color?: string
    bold?: boolean
  }
) => {

  return <Field
    label={props.label}
    weight={props.weight}
  >
    <FlexCol
      style={{
        color: props.color ?? "black",
        fontWeight: props.bold ? "bold" : "normal"
      }}
    >
      {props.value === none ? "-" : props.value ? "Sí" : "No"}
    </FlexCol>
  </Field>

}

export const FieldNumber = (
  props: {
    label: string
    value: Maybe<number>
    weight?: number
    units?: string
    decimals?: number
  }
) => {

  const stringValue = formatNumber(
    props.value,
    props.decimals
  )

  return <Field
    label={props.label}
    weight={props.weight}
  >
    {props.value === none ? 
      "-" : 
      `${stringValue} ${props.units ?? ""}`
    }
  </Field>
}



export const formatNumber = (value?: number, decimals?: number): string => {
  if (value === none) return "-"

  const formatter = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: decimals ?? 0,
    maximumFractionDigits: decimals ?? 0,
  });

  return formatter.format(value);
}

export const formatUsd = (value?: number): string => 
  `${formatNumber(value, 2)} USD`


export const FieldCompany = (
  props: {
    label: string
    companyId: Maybe<Id>
    encrypted?: boolean
  }
) => {

  return <Field
    label={props.label}
  > 
    {
      (props.encrypted ?? false) ? "🔒 Encriptado" :
      Maybe.map(props.companyId)(id => 
        <CompanyView id={id}/>
      ) ??
      "-"
    }
  </Field>
}

export const CompanyView = (
  props: {
    id: Id
    clickable?: boolean
  }
) => {
  
    const [company, loading, error] = useCompany(props.id)
  
    const navigate = useNavigate()

    return <FlexRow
      style={{
        border: "1px solid #ddd",
        borderRadius: 8,
        padding: 8,
        cursor: "pointer"
      }}
      onClick={
        someIf(props.clickable ?? true)(
          () => {
            navigate(appPaths.company(props.id).detail)
          }
        )
      }
      gap={4}
    >
      {
        loading ? <Skeleton width={100}/> :
        <>
          <img
            style={{
              height: 24,
            }}
            src={company?.imageUrl}
          />        
          {company?.displayName ?? company?.businessName ?? "-"}
        </>
      }
      
    </FlexRow>
}

export const FieldUser = (
  props: {
    label: string
    userId: Maybe<Id>
  }
) => {

  return <Field
    label={props.label}
  > 
    {
      Maybe.map(props.userId)(id => 
        <UserView id={id}/>
      ) ??
      "-"
    }
  </Field>
}

export const UserView = (
  props: {
    id: Maybe<Id>
  }
) => 
  Maybe.match(props.id)<React.ReactNode>({
    some: id => <UserViewNotNone id={id}/>,
    none: () => "-"
  })

const UserViewNotNone = (
  props: {
    id: Id
  }
) => {
  
    const [user, loading, error] = useUser(props.id)
  
    const fullName = List.filterNotNone([user?.firstName, user?.lastName]).join(" ")

    const navigate = useNavigate()

    return <FlexRow
      style={{
        border: "1px solid #ddd",
        borderRadius: 8,
        padding: 8,
        cursor: "pointer"
      }}
      onClick={
        () => {
          navigate(appPaths.user(props.id))
        }
      }
      gap={4}
    >
      {
        loading ? <Skeleton width={100}/> :
        <>
          <UserIcon
            style={{ color: "gray" }}
          />
          {fullName}
        </>
        
      }
      
    </FlexRow>
}

export const FieldCompanies = (
  props: {
    style?: React.CSSProperties
    weigth?: number
    label: string
    companyList?: List<Id>
  }
) => {

  return <Field
    style={props.style}
    label={props.label}
    weight={props.weigth}
  >
    {props.companyList?.map(it => <CompanyView id={it}/>)}
  </Field>
}

export const FileView = (
  props: {
    file: Maybe<FileData>
  }
) => {

  return <FlexRow
    style={{
      border: "1px solid #ddd",
      borderRadius: 8,
      padding: 8,
      cursor: "pointer"
    }}
    onClick={
      () => {
        if (props.file !== none) {
          window.open(props.file.link)
        }
      }
    }
    gap={4}
  >
    <FileIcon
      style={{ color: "gray" }}
    />
    {props.file?.name ?? "-"}
  </FlexRow>
}

export const FieldFile = (
  props: {
    label: string
    file: Maybe<FileData>
  }
) => {

  return <Field
    label={props.label}
  >
    {
      Maybe.map(props.file)(it => <FileView file={it}/>) ?? "-"
    }
    
  </Field>
}

export const FieldFiles = (
  props: {
    label: string
    files: Maybe<List<FileData>>
  }
) => {

  const isEmpty = (props.files?.length ?? 0) === 0

  return <Field
    label={props.label}
  >
    {props.files?.map(it => <FileView file={it}/>)}
    {
      showIf(isEmpty)("-")
    }
  </Field>
}

export const BuyRequestView = (
  props: {
    buyRequest: Maybe<{
      type: BuyRequestType
      id: Id
    }>
  }
) => 
  Maybe.match(props.buyRequest)<React.ReactNode>({
    some: it => <BuyRequestViewNotNone buyRequest={it}/>,
    none: () => "-",
  })


const BuyRequestViewNotNone = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
  }
) => {

    const { type, id } = props.buyRequest 
  
    const [request, loading, error] = useBuyRequest(id, type)

    const navigate = useNavigate()

    return <FlexRow
      style={{
        border: "1px solid #ddd",
        borderRadius: 8,
        padding: 8,
        cursor: "pointer"
      }}
      onClick={
        () => {
          navigate(appPaths.buyRequest(type, id).detail)
        }
      }
      gap={4}
    >
      {
        loading ? <Skeleton width={100}/> :
        <>
          <BuyRequestIcon
            style={{ color: "gray" }}
          />
          {id}
        </>
        
      }
      
    </FlexRow>
}

export const ProcessView = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
  }
) => {

    const { type, id } = props.buyRequest 

    const [process, loading, ] = useProcess(type, id, props.processId)

    const navigate = useNavigate()

    return <FlexRow
      style={{
        border: "1px solid #ddd",
        borderRadius: 8,
        padding: 8,
        cursor: "pointer"
      }}
      onClick={
        () => {
          navigate(
            appPaths
              .buyRequest(type, id)
              .process(props.processId)
              .detail
          )
        }
      }
      gap={4}
    >
      {
        loading ? <Skeleton width={100}/> :
        <>
          <ProcessIcon
            style={{ color: "gray" }}
          />
          Ronda {process?.round ?? "-"} de {id}
        </>
      }
      
    </FlexRow>
}

export const FieldBuyRequest = (
  props: {
    label: string
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
  }
) => {

  return <Field
    label={props.label}
  > 
    <BuyRequestView
      buyRequest={props.buyRequest}
    />
  </Field>
}

export const FieldProcess = (
  props: {
    label: string
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
  }
) => {

  return <Field
    label={props.label}
  > 
    <ProcessView
      buyRequest={props.buyRequest}
      processId={props.processId}
    />
  </Field>
}


export const BidView = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    bidId: Id
  }
) => {

    const { type, id } = props.buyRequest 

    const navigate = useNavigate()

    return <FlexRow
      style={{
        border: "1px solid #ddd",
        borderRadius: 8,
        padding: 8,
        cursor: "pointer"
      }}
      onClick={
        () => {
          navigate(
            appPaths
              .buyRequest(type, id)
              .process(props.processId)
              .bid(props.bidId)
          )
        }
      }
      gap={4}
    >
      <BidIcon
        style={{ color: "gray" }}
      />
      Oferta en {id}
    </FlexRow>
}

export const FieldBid = (
  props: {
    label: string
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    bidId: Id
  }
) => {

  return <Field
    label={props.label}
  > 
    <BidView
      buyRequest={props.buyRequest}
      processId={props.processId}
      bidId={props.bidId}
    />
  </Field>
}

export const FieldBids = (
  props: {
    label: string
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    processId: Id
    bidIds: List<Id>
  }
) => {

  return <Field
    label={props.label}
  > 
    {
      props.bidIds.map(it => 
        <BidView
          buyRequest={props.buyRequest}
          processId={props.processId}
          bidId={it}
        />
      )
    }
  </Field>
}

export type ConectoCell = React.ReactNode | Readonly<[GridProps, React.ReactNode]>

export const ConectoGrid = (
  props: {
    style?: React.CSSProperties
    defaults?: GridProps
    spacing?: GridSpacing
    cells?: List<ConectoCell>
  }
) => {

  return <Grid 
    style={props.style}
    container
    spacing={props.spacing ?? 4}
  >
    {
      props.cells?.map((cell, i) => {

        const [itemProps, node] = cell instanceof Array ? cell : [{}, cell]

        return <Grid item {...{ ...props.defaults, ...itemProps}} key={i}>
          <FlexRow>
            {node}
          </FlexRow>
        </Grid>
      })
    }
  </Grid>
}


export const ExtraActions = (
  props: {
    style?: React.CSSProperties
    actions?: List<React.ReactNode>
  }
) => {

  const anchorElement = useRState<Maybe<Element>>(() => none)

  const open = anchorElement.value !== none

  const close = anchorElement.apply(() => none)

  return <FlexRow
    style={props.style}
    justifyContent="center"
  >

    <IconButton
      onClick={event => 
        anchorElement.apply(() => event.currentTarget)()
      }
    >
      <MoreIcon/>
    </IconButton>

    <Menu
      open={open}
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorElement.value}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    > 
      {
        props.actions?.map((action, index) =>
          <MenuItem key={index}>
            {action}
          </MenuItem>
        )
      }
    </Menu>

  </FlexRow>
}


export const ConectoDialog = (
  props: {
    open: RState<boolean>
    style?: React.CSSProperties
    title?: string
    description?: string
    showClose?: boolean
    children?: React.ReactNode
  }
) => 
  <Dialog
    PaperProps={{
      style: {
        maxWidth: 600,
        ...props.style
      }
    }}
    fullWidth
    open={props.open.value}
    onClose={props.open.apply(() => false)}
  >
    {
      showIf(props.showClose ?? true)(
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0
          }}
          onClick={props.open.apply(() => false)}
        >
          <CloseIcon/>
        </IconButton>
      )
    }
    <FlexCol
      padding={16}
      gap={4}
    > 
      {
        Maybe.map(props.title)(title => <h4>{title}</h4>)
      }
      {
        Maybe.map(props.description)(description => <p>{description}</p>)
      }
      {props.children}
    </FlexCol>
    
  </Dialog>


export const SubmitButton = <I,>(
  props: {
    style?: React.CSSProperties
    label: string
    value?: Maybe<I>
    call?: Asynchronism<I, any>
    enabled?: boolean
    variant?: "primary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link"
  }
) => {

  const loading = props.call?.state?.type === "running"

  const enabled = props.enabled ?? true

  return <Button
    style={props.style}
    variant={props.variant ?? "success"}
    disabled={!enabled || loading}
    onClick={
      Maybe.map(props.value)(it => props.call?.run(it))
    }
    >
      <FlexRow
        gap={8}
      >
        {showIf(loading)(<Spinner size="sm"/>)}
        {props.label ?? "Aceptar"}
      </FlexRow>
    </Button>
}


export const CallError = (
  props: {
    call: Asynchronism<any, any>
  }
) => 
  matchPartial(props.call.state)({
    error: error => <Alert
      variant="danger"
    >
      <b>Error:</b>
      <br/>
      {JSON.stringify(error.error)}
    </Alert>
  })

export const ErrorAlert = (
  props: {
    error?: unknown
  }
) => 
  Maybe.map(props.error)(error => 
    <Alert
      variant="danger"
    >
      <b>Error:</b>
      <br/>
      {JSON.stringify(error)}
    </Alert>
  )

export const ShowJsonAction = (
  props: {
    data: object
  }
) => {

  const open = useRState(() => false)

  return <FlexCol>
    <FlexRow
      onClick={open.apply(() => true)}
    > 
      Ver JSON
    </FlexRow>

    <ConectoDialog
      open={open}
      title="Dato JSON"
      style={{
        minWidth: 800
      }}
    >
      <FlexCol
        style={{ width: "100%" }}
        alignItems="flex-start"
      >
        <ReactJson
          src={props.data}
          collapsed={1}
          collapseStringsAfterLength={50}
          name={false}
          enableClipboard={false}
        />
      </FlexCol>
    </ConectoDialog>

  </FlexCol>
}