import { Unit } from "functional/lib/core"
import { Id, Product } from "../model/Model"
import { User } from "../model/User"
import { AppCall, appCallBuyRequestCollection } from "../context/AppCall"
import { normalizeForFirebase } from "./client"
import { deepDateToTimestamp } from "./bid"
import { BuyRequestType } from "../model/buyRequest/BuyRequest"


export const commentCreate = (
  args: {
    product: BuyRequestType
    buyRequestId: Id
    comment: string
    user: User
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {
    console.log(args)
    const creationDate = new Date()
    
    const collection = await _(appCallBuyRequestCollection(args.product))

    const doc = await collection
      .doc(args.buyRequestId)
      .collection('comments')
      .add(
        deepDateToTimestamp(
          normalizeForFirebase({
            answered: false,
            comment: args.comment,
            creationDate: creationDate,
            user: args.user,
            answer: null,
            training: args.user.training,
            product: args.product,
            buyRequestId: args.buyRequestId
          })
        )
      )
    
    alert('Recibimos tu consulta! Te responderemos a la brevedad.')
  })


// export function newTrainingCommentFunction(
//     args: {
//         product: Product
//         buyRequestId: Id
//         comment: string
//         user: User
//     }
// ): Promise<void> {
//     console.log(args)
//     const creationDate = new Date()
//     const collection = args.product === 'gas' ? dbGasBuyRequestsCollection(): (args.product === 'electricity' ? dbTrainingElectricityBuyRequestsCollection()  : null)
//     console.log(collection)
//     return collection
//         .doc(args.buyRequestId)
//         .collection('comments')
//         .add({  answered: false,
//             comment: args.comment,
//             creationDate: creationDate,
//             user: args.user,
//             answer: 'Contestar'
//         }).then(doc => alert('Recibimos tu consulta! Te responderemos a la brevedad.'))
// }


export const commentAnswer = (
  args: {
    answer: string
    commentId: Id
    buyRequestId: Id
    product: Product
    training: boolean
    answered: boolean
  }
) => 
  AppCall.do(async _ => {
    const collection = await _(appCallBuyRequestCollection(args.product == "gas" ? "gas" : "electricity"))

    await collection
      .doc(args.buyRequestId)
      .collection('comments')
      .doc(args.commentId)
      .update({ answer: args.answer, answered: args.answered })
  })
  
