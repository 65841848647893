
import { IO } from "functional/lib/IO"
import { Maybe } from "functional/lib/Maybe"
import { Unit, none } from "functional/lib/core"
import { propTypes } from "react-bootstrap/esm/Image"

export type FlexboxProps = {
  className?: string
  style?: React.CSSProperties
  direction?: "row" | "column"
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline"
  justifyContent?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | "stretch"
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse"
  gap?: string | number
  padding?: number
  paddingX?: number
  paddingY?: number
  margin?: number
  marginX?: number
  marginY?: number
  children?: React.ReactNode
  onClick?: IO<Unit>
  onClickWithEvent?: (event: React.MouseEvent) => IO<Unit>
  spacing?: number
  stopPropagation?: boolean
}

export const Flexbox = (
  props: FlexboxProps
) => {

  const direction = props.direction ?? "row"

  const divider = (key: string) => 
    props.spacing !== none ? 
      <div 
        key={key}
        style={
          direction === "row" ? { width: props.spacing } :
          direction === "column" ? { height: props.spacing }: 
          {}
        }
      /> :
      none

  const children = Array.isArray(props.children) && divider !== none ?
    props.children
      .flat()
      .filter(it => it !== null && it !== none)
      .flatMap((child, i) => i == 0 ? [child] : [divider(`_divider_${i}`), child]) :
    props.children

  const onClickWithEvent = 
    props.onClickWithEvent ?? 
    Maybe.map(props.onClick)(onClick => () => onClick)

  return <div
    className={props.className}
    style={{
      display: "flex",
      flexDirection: direction,
      alignItems: props.alignItems ?? "center",
      justifyContent: props.justifyContent ?? "center",

      flexWrap: props.flexWrap,
      gap: props.gap,

      paddingLeft: props.paddingX ?? props.padding,
      paddingRight: props.paddingX ?? props.padding,
      paddingTop: props.paddingY ?? props.padding,
      paddingBottom: props.paddingY ?? props.padding,

      marginLeft: props.marginX ?? props.margin,
      marginRight: props.marginX ?? props.margin,
      marginTop: props.marginY ?? props.margin,
      marginBottom: props.marginY ?? props.margin,

      ...props.style
    }}
    children={children}
    onClick={event => {
      if (onClickWithEvent !== none) {
        if (props.stopPropagation ?? true) {
          event.stopPropagation()
        }
        onClickWithEvent(event)()
      }
    }}
  />
}

export const FlexRow = (
  props: FlexboxProps
) => 
  <Flexbox
    direction="row"
    {...props}
  />

export const FlexCol = (
  props: FlexboxProps
) => 
  <Flexbox
    direction="column"
    {...props}
  />

export const CellCol = (
  props: FlexboxProps & {
    weight?: number
  }
) => 
  <Flexbox
    direction="column"
    {...props}
    style={{
      width: 0,
      flexGrow: props.weight ?? 1,
      ...props.style
    }}
  />

export const CellRow = (
  props: FlexboxProps & {
    weight?: number
  }
) => 
  <Flexbox
    direction="row"
    {...props}
    style={{
      width: 0,
      flexGrow: props.weight ?? 1,
      ...props.style
    }}
  />
