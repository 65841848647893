import { TSchema } from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value"
import { List } from "functional/lib/List"
import { none, pipe } from "functional/lib/core"

export const deepWithoutUndefinedValues = 
  (process: (value: unknown) => boolean) =>
  <T>(value: T): T => 
    typeof value === "object" && 
    value !== null && 
    process(value) ? (
      Array.isArray(value) ?
        value.map(value => deepWithoutUndefinedValues(process)(value)) as unknown as T :  
        pipe(value)(
          Object.entries,
          List.filter(([_, value]) => value !== none),
          List.lift(([key, value]) => [key, deepWithoutUndefinedValues(process)(value)]),
          Object.fromEntries
        ) as T 
    ):
    value

export const castOrNone = <T extends TSchema>(schema: T) => (value: unknown) =>
    Value.Check(schema, value) ? value : none
