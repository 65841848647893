import MaterialTable, { MaterialTableProps } from "material-table"
import { Process } from "../../../model/Process"
import { Maybe } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { useNavigate } from "react-router"
import { none } from "functional/lib/core"
import { Id } from "../../../model/Model"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { shallowCopy } from "../../../utils/utils"
import { IdView, buildColumns } from "../components"
import { matchEnum } from "functional/lib/match"
import { EncryptedBadge } from "./detail"
import { appPaths } from "../../navigation"


export const ProcessTable = (
  props: Omit<MaterialTableProps<Process>, "data"> & {
    data: Maybe<List<Process>>
    buyRequestType: BuyRequestType
    buyRequestId: Id
  }
) => {

  const navigate = useNavigate()

  return <MaterialTable<Process>
    {...props}
    style={{
      borderRadius: 16,
      overflow: "hidden",
      ...props.style
    }}
    onRowClick={(event, data) => {
      event?.stopPropagation()
      if (data?.id !== none) {
        navigate(
          appPaths
            .buyRequest(props.buyRequestType, props.buyRequestId)
            .process(data.id)
            .detail
          )
      }
    }}
    options={{ 
      exportButton: true, 
      exportAllData: true,
      columnResizable: true,
      sorting: true,
      ...props.options,
    }}
    data={(props.data ?? []) as Process[]}
    columns={props.columns.map(shallowCopy)}
  />
}


export const processColumns = buildColumns<Process>()({

  id: {
    title: "ID",
    render: data => <IdView id={data.id} />
  },

  round: {
    title: "Ronda",
    field: "round",
  },

  creationDate: {
    title: "Creado el",
    field: "creationDate",
    type: "datetime",
    defaultSort: "desc"
  },

  type: {
    title: "Tipo",
    render: data => matchEnum(data.type)({
      auction: "👨‍⚖️ Subasta",
      tender: "✉️ Licitación"
    })
  },

  openDate: {
    title: "Aperura",
    field: "openDate",
    type: "datetime",
  },

  closeDate: {
    title: "Cierre",
    field: "closeDate",
    type: "datetime",
  },

  encrypted: {
    title: "Encriptado",
    render: data => <EncryptedBadge encrypted={data.encrypted}/>
  }

})

