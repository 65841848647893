import { Maybe } from "functional/lib/Maybe"
import { Validator } from "../../functional/react/form/Validated"
import { Draft } from "../../model/utils"
import { BuyRequest } from "../../model/buyRequest/BuyRequest"
import { none } from "functional/lib/core"
import { GasBuyRequest, GasBuyType, GasBuyTypeAnnual, GasBuyTypeMonthly, GasMix } from "../../model/buyRequest/GasBuyRequest"
import { ElectricityBuyRequest } from "../../model/buyRequest/ElectricityBuyRequest"



export const gasFormFieldIds = {

  company: "company",
  mix: "mix",
  mixInput: "mixInput",
  quality: "quality",
  supplyPoint: "supplyPoint",

  supplyStartDate: "supplyStartDate",

  amount: "amount",
  termDays: "termDays",

  takeOrPay: "takeOrPay",
  deliverOrPay: "deliverOrPay",
  referencePayTermDays: "referencePayTermDays",

  demandCurve: "demandCurve",
  demandCurveValue: (i: number) => `demandCurveValue_${i}`,
  cmdCurve: "cmdCurve",
  cmdCurveValue: (i: number) => `cmdCurveValue_${i}`

}

const validatorNonNonePositive = (
  fieldId: string, 
  emptyMessage: string, 
  negativeMessage: string,
): Validator<Maybe<number>, number> =>
  Validator.compose(
    Validator.notNone(fieldId, emptyMessage),
    Validator.predicate(it => it > 0, fieldId, negativeMessage)
  )

const validatorNonNoneNonNegative = (
  fieldId: string, 
  emptyMessage: string, 
  negativeMessage: string,
): Validator<Maybe<number>, number> =>
  Validator.compose(
    Validator.notNone(fieldId, emptyMessage),
    Validator.predicate(it => it >= 0, fieldId, negativeMessage)
  )


const gasMixValidator =
  Validator.mapWithErrors<Draft<Maybe<GasMix>>, Maybe<GasMix>>(
    it =>
      it?.type === "free" ? 
        [
          {
            type: "free",
            distribution: it.distribution.map(it => it ?? 0)
          },
          it.distribution.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) === 100 ? 
            {} : 
            { [gasFormFieldIds.mixInput]: ["La distribución de mix debe sumar 100"] }
        ]:
        [it, {}] 
  )

const gasBuyTypeValidator: Validator<Draft<GasBuyType>, GasBuyType> =
  Validator.sum({

    annual: Validator.record<Draft<GasBuyTypeAnnual>, GasBuyTypeAnnual>({

      demandCurve: 
        Validator.compose(
          Validator.predicate(it => it.length === 12, gasFormFieldIds.demandCurve, "El consumo debe tener 12 meses"),
          Validator.list(i => 
            validatorNonNoneNonNegative(
              gasFormFieldIds.demandCurveValue(i), 
              `Ingresa un volumen en el mes ${i + 1}`, 
              `El volumen del mes ${i + 1} no puede ser negativo`
            )
          ),
        ),
        
      cmdCurve: 
        Validator.compose(
          Validator.notNone(gasFormFieldIds.cmdCurve, "Ingresa un CMD"),
          Validator.predicate(it => it.length === 12, gasFormFieldIds.cmdCurve, "CMD debe tener 12 meses"),
          Validator.list(i => 
            validatorNonNoneNonNegative(
              gasFormFieldIds.cmdCurveValue(i), 
              `Ingresa un CMD en el mes ${i + 1}`, 
              `El CMD del mes ${i + 1} no puede ser negativo`
            )
          ),
        ),

    }),

    monthly: Validator.record<Draft<GasBuyTypeMonthly>, GasBuyTypeMonthly>({

      demandCurve: 
        Validator.compose(
          Validator.predicate(it => it.length > 0, gasFormFieldIds.demandCurve, "Ingresa un plazo de contrato"),
          Validator.list(i => 
            validatorNonNoneNonNegative(
              gasFormFieldIds.demandCurveValue(i), 
              `Ingresa un consumo en el mes ${i + 1}`, 
              `El consumo del mes ${i + 1} no puede ser negativo`
            )
          ),
        ),
        
      cmdCurve: 
        Validator.compose(
          Validator.notNone(gasFormFieldIds.cmdCurve, "Ingresa un CMD"),
          Validator.predicate(it => it.length > 0, gasFormFieldIds.cmdCurve, "Ingresa un plazo de contrato"),
          Validator.list(i => 
            validatorNonNoneNonNegative(
              gasFormFieldIds.cmdCurveValue(i), 
              `Ingresa un CMD en el mes ${i + 1}`, 
              `El CMD del mes ${i + 1} no puede ser negativo`
            )
          ),
        ),

    }),

    spot: Validator.record({

      termDays: validatorNonNonePositive(
        gasFormFieldIds.termDays, 
        `Ingresa un Plazo de contrato`, 
        `El Plazo de contrato debe ser mayor a 0`
      ),

      amount: validatorNonNonePositive(
        gasFormFieldIds.amount, 
        `Ingresa una Cantidad Total`, 
        `La Cantidad Total debe ser mayor a 0`
      )

    })

  })
    

const buyRequestGasValidator: Validator<Draft<GasBuyRequest>, GasBuyRequest> =
  Validator.compose(
    Validator.predicate(
      it => !(it.product === "wellhead" && it.mix === none), 
      gasFormFieldIds.mix, 
      "Seleciona un Tipo de Mix"
    ),
    Validator.record<Draft<GasBuyRequest>, GasBuyRequest>({

      buyerCompanyId: Validator.predicate(it => it !== "", gasFormFieldIds.company, "Elegí una Empresa"),

      supplyPointId: Validator.predicate(it => it !== "", gasFormFieldIds.supplyPoint, "Elegí un Punto de Suministro"),

      mix: gasMixValidator,

      quality: validatorNonNonePositive(
        gasFormFieldIds.quality, 
        "Ingresa una Calidad", 
        "La Calidad debe ser mayor a 0"
      ),

      takeOrPay: validatorNonNoneNonNegative(
        gasFormFieldIds.takeOrPay, 
        "Ingresa un Take or Pay", 
        "Take or Pay no puede ser negativo"
      ),

      deliverOrPay: validatorNonNoneNonNegative(
        gasFormFieldIds.deliverOrPay, 
        "Ingresa un Deliver or Pay", 
        "Deliver or Pay no puede ser negativo"
      ),

      referencePayTermDays: validatorNonNonePositive(
        gasFormFieldIds.referencePayTermDays, 
        "Ingresa un Plazo de Pago", 
        "El plazo de pago no puede ser negativo"
      ),

      buyType: gasBuyTypeValidator


    }),
  )

export const electricityFormFieldIds = {

  company: "company",
  supplyPoint: "supplyPoint",
  totalAmount: "totalAmount",

  startSupplyDate: "startSupplyDate",

  supplyStartDate: "supplyStartDate",

}


export const buyRequestElectricityValidator =
  Validator.record<Draft<ElectricityBuyRequest>, ElectricityBuyRequest>({

    buyerCompanyId: Validator.predicate(it => it !== "", electricityFormFieldIds.company, "Elegí una Empresa"),

    supplyPointId: Validator.predicate(it => it !== "", electricityFormFieldIds.supplyPoint, "Elegí un Punto de Suministro"),

    totalAmount: validatorNonNonePositive(
      electricityFormFieldIds.totalAmount, 
      "Ingresa la Cantidad a contratar Total", 
      "La Cantidad a contratar Total debe ser mayor a 0"
    ),

  })

export const buyRequestValidator = 
  Validator.sum<Draft<BuyRequest>, BuyRequest>({

    gas: buyRequestGasValidator,

    electricity: buyRequestElectricityValidator,

  })
