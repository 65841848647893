import { StaticDecode, Type } from "@sinclair/typebox"
import { BuyRequestStatusT } from "./BuyRequestStatus"
import { ListT, MaybeT } from "../utils"
import { TimestampT } from "../common"

export const FeeConditionsT = Type.Object({
  factor: Type.Number(),
  min: Type.Number(),
  max: Type.Number(),
})
export type FeeConditions = StaticDecode<typeof FeeConditionsT>

export const BuyRequestFeeTypeT = Type.Union([
  Type.Literal("none"), 
  Type.Literal("buyer"), 
  Type.Literal("seller"), 
  Type.Literal("split"),
])
export type BuyRequestFeeType = StaticDecode<typeof BuyRequestFeeTypeT>

export const BuyRequestBaseFields = {

  id: Type.Optional(Type.String()),

  supplyStartDate: Type.Optional(TimestampT),

  publicKey: Type.String(),

  buyerCompanyId: Type.String(),

  companyImageUrl: MaybeT(Type.String()),

  status: BuyRequestStatusT,

  placerId: Type.Optional(Type.String()),

  feeType: MaybeT(BuyRequestFeeTypeT),

  currentGuestIdList: MaybeT(ListT(Type.String())),

  onceInvited: MaybeT(ListT(Type.String())),

  creationDate: TimestampT,

  businessName: MaybeT(Type.String()),

  user: MaybeT(
    Type.Object({
      firstName: MaybeT(Type.String()),
      lastName: MaybeT(Type.String()),
    }),
  ),

  supplyPointId: Type.String(),

  lastEdit: MaybeT(TimestampT),

  feeConditions: MaybeT(FeeConditionsT),

}

