import React from "react"
import { Button } from "react-bootstrap"

import { Id } from "../model/Model"
import { ProcessEditor } from "./ProcessEditor"
import { Process } from "../model/Process"
import { IO } from "functional/lib/IO"
import { Unit, none } from "functional/lib/core"
import { FlexCol, FlexRow } from "../components/Flexbox"
import { Draft } from "../model/utils"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { useFormState } from "../functional/react/form/state"
import { Validator } from "../functional/react/form/Validated"
import { FormReactContext } from "../functional/react/form/FormContext"
import { FormErrorsViewer } from "../components/Form"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"

export const processFieldIds = {

	openDate: "openDate",
	closeDate: "closeDate",

	closeConsultsDate: "closeConsultsDate",

	guestList: "guestList",

	minimumPayTermDays: "minimumPayTermDays",
	maximumPrice: "maximumPrice"

}

export const processValidator: Validator<Draft<Process>, Process> = 
	Validator.compose(
		Validator.predicate(
			it => 
				it.openDate !== none && it.closeDate !== none ?
					it.openDate < it.closeDate :
					true, 
				processFieldIds.closeDate,
				"La fecha de cierre debe ser posterior a la fecha de apertura"
		),
		Validator.predicate(
			it => 
				it.closeConsultsDate !== none && it.closeDate !== none ?
					it.closeConsultsDate <= it.closeDate :
					true, 
				processFieldIds.closeConsultsDate,
				"La fecha de cierre de consultas debe ser anterior a la fecha de cierre"
		),
		Validator.predicate(
			it => !(it.defineMinPayTerm ?? false) || it.minimumPayTermDays !== none,
			processFieldIds.minimumPayTermDays,
			"Ingresa un plazo de pago mínimo"
		),
		Validator.predicate(
			it => !(it.defineMaxPrice ?? false) || it.maximumPrice !== none,
			processFieldIds.maximumPrice,
			"Ingresa un precio máximo"
		),
		Validator.record<Draft<Process>, Process>({

			openDate: Validator.notNone(processFieldIds.openDate, "Ingresa una fecha de apertura"),
			closeDate: Validator.notNone(processFieldIds.closeDate, "Ingresa una fecha de cierre"),
		
			guestIdList: Validator.predicate(it => it.length > 0, processFieldIds.guestList, "Selecciona al menos un invitado"),

		})
	)


export const BuyRequestCreateProcessStep = (
	props: {
		buyRequestId?: Id // para publicar Nueva Ronda
		request: BuyRequest
		initialProcess: Draft<Process>
		onBack: IO<Unit>
		onNext: (process: Process) => IO<Unit>
	}
) => {

	const form = useFormState({
		initialDraft: () => props.initialProcess,
		validator: processValidator
	})

	return <FormReactContext.Provider
		value={form}
	>
		<FlexCol
			alignItems="stretch"
			gap={20}
		>
			<ProcessEditor
				request={props.request}
				process={form.state}
				buyRequestId={props.buyRequestId}
			/>

			<FlexRow
        justifyContent='space-between'
        padding={8}
      >

        <Button 
          size='lg'
          variant="light"
          onClick={props.onBack}
        >
          <FlexRow
            gap={8}
          >
            <ArrowBack/>
            Atras
          </FlexRow>
          
        </Button>

        <Button 
          size='lg'
          onClick={
						form.validated === none ?
							form.showErrorsState.apply(() => true) :
							props.onNext(form.validated)
          }
        >
          <FlexRow
            gap={8}
          >
            Siguiente
            <ArrowForward/>
          </FlexRow>
        </Button>
      </FlexRow>

		</FlexCol>

		<br/>

    <FormErrorsViewer
      form={form}
    />

	</FormReactContext.Provider>
}
	

