import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
window.fn = OwlCarousel;


export class Testimonial extends Component {
  render() {
    return (
            <section>
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col">
                  <OwlCarousel
                    className="owl-carousel no-pb owl-2"
                    dotData={false}
                    nav={true}
                    items={1}
                    dotsSpeed={10}
                    autoplay={12000}
                    autoplayTimeout={12000}
                    dots={true}
                    loop={true}
                >
                    <div className="item">
                      <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-10 col-lg-8">
                          <div className="card bg-transparent border-0">
                            <div>
                              <img alt="Image" src={require(`../assets/images/testimonial/alumbrera.png`)} className="shadow-primary img-fluid d-inline" />
                            </div>
                            <div className="card-body p-0 mt-5">
                              <p className="lead font-w-5">"Utilizamos la plataforma en nuestra licitación de energías renovables el año 2019, la información es transparente, completa de fácil manejo y con un muy buen nivel de seguridad, recomendable y segura"</p>
                              <div>
                                <h5 className="text-primary d-inline mb-0">Pablo Dramis</h5>
                                <small className="text-dark font-w-5 font-italic"> - Superintendente de Abastecimiento en Minera Alumbrera</small>
                                <br/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-10 col-lg-8">
                          <div className="card bg-transparent border-0">
                            <div>
                              <img alt="Image" src={require(`../assets/images/testimonial/sami.png`)} className="shadow-primary img-fluid d-inline" />
                            </div>
                            <div className="card-body p-0 mt-5">
                              <p className="lead font-w-5">"Conecto Energía nos brindó el asesoramiento que necesitábamos para definir el modelo de PPA óptimo para San Miguel. Luego concretamos la licitación a través de su plataforma: eso nos permitió llegar a más generadores, llevando a cabo un proceso muy ágil y transparente"</p>
                              <div>
                                <h5 className="text-primary d-inline mb-0">Paula Marincioni</h5>
                                <small className="text-dark font-w-5 font-italic"> - Gerente Global de Ambiente, Seguridad y Salud en San Miguel Global</small>
                                <br/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-10 col-lg-8">
                          <div className="card bg-transparent border-0">
                            <div>
                              <img alt="Image" src={require(`../assets/images/testimonial/fiasa.png`)} className="shadow-primary img-fluid d-inline" />
                            </div>
                            <div className="card-body p-0 mt-5">
                              <p className="lead font-w-5">"Conecto Energía fue muy buena ya que se logra una mayor transparencia en la contratación, mejora en costos y es de fácil utilización.<br/>
                                La volveremos a utilizar para las próximas contrataciones de energía eléctrica y gas natural."</p>
                              <div>
                                <h5 className="text-primary d-inline mb-0">Gonzalo Olmos</h5>
                                <small className="text-dark font-w-5 font-italic">- Jefe de Operaciones en FIASA</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-10 col-lg-8">
                          <div className="card bg-transparent border-0">
                            <div>
                              <img alt="Image" src={require(`../assets/images/testimonial/colortex.png`)} className="shadow-primary img-fluid d-inline" />
                            </div>
                            <div className="card-body p-0 mt-5">
                              <p className="lead font-w-5">"Utilizar Conecto Energía para nuestras compras anuales de gas natural nos permitió tener acceso a una mayor cantidad de comercializadores
                                y conseguir mejor precio, todo ello en una forma sencilla transparente y segura."</p>
                              <div>
                                <h5 className="text-primary d-inline mb-0">Denise Karagozlu</h5>
                                <small className="text-dark font-w-5 font-italic">- Directora de Karatex</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        );
  }
}


//
//
// export class Testimonial2 extends Component {
//   render() {
//     return (
//         <section>
//           <div className="container">
//             <div className="row justify-content-center text-center text-white">
//               <div className="col">
//                 <OwlCarousel
//                     className="owl-carousel no-pb owl-2"
//                     dotData={false}
//                     nav={true}
//                     items={1}
//                     dotsSpeed={10}
//                     autoplay={12000}
//                     autoplayTimeout={12000}
//                     dots={true}
//                     loop={true}
//                 >
//                   <div className="item">
//                     <div className="row justify-content-center text-center">
//                       <div className="col-12 col-md-10 col-lg-8">
//                         <div className="card bg-transparent border-0">
//                           <div>
//                             <img alt="Image" src={require(`../assets/images/testimonial/alumbrera-white.png`)} className="shadow-primary img-fluid d-inline" />
//                           </div>
//                           <div className="card-body p-0 mt-5">
//                             <p className="lead font-w-5 text-white">"Utilizamos la plataforma en nuestra licitación de energías renovables el año 2019, la información es transparente, completa de facil manejo y con un muy buen nivel de seguridad, recomendable y segura"</p>
//                             <div>
//                               <h5 className="text-white d-inline mb-0">Pablo Dramis</h5>
//                               <small className="text-dark font-w-5 font-italic"> - Superintendente de Abastecimiento en Minera Alumbrera</small>
//                               <br/>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="item">
//                     <div className="row justify-content-center text-center">
//                       <div className="col-12 col-md-10 col-lg-8">
//                         <div className="card bg-transparent border-0">
//                           <div>
//                             <img alt="Image" src={require(`../assets/images/testimonial/sami-white.png`)} className="shadow-primary img-fluid d-inline" />
//                           </div>
//                           <div className="card-body p-0 mt-5">
//                             <p className="lead font-w-5 text-white">"Conecto Energía nos brindó el asesoramiento que necesitábamos para definir el modelo de PPA óptimo para San Miguel. Luego concretamos la licitación a través de su plataforma: eso nos permitió llegar a más generadores, llevando a cabo un proceso muy ágil y transparente"</p>
//                             <div>
//                               <h5 className="text-white d-inline mb-0">Paula Marincioni</h5>
//                               <small className="text-dark font-w-5 font-italic"> - Gerente Global de Ambiente, Seguridad y Salud en San Miguel Global</small>
//                               <br/>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="item">
//                     <div className="row justify-content-center text-center">
//                       <div className="col-12 col-md-10 col-lg-8">
//                         <div className="card bg-transparent border-0">
//                           <div>
//                             <img alt="Image" src={require(`../assets/images/testimonial/fiasa-white.png`)} className="shadow-primary img-fluid d-inline" />
//                           </div>
//                           <div className="card-body p-0 mt-5">
//                             <p className="lead font-w-5 text-white">"Conecto Energía fue muy buena ya que se logra una mayor transparencia en la contratación, mejora en costos y es de fácil utilización.<br/>
//                               La volveremos a utilizar para las próximas contrataciones de energía eléctrica y gas natural."</p>
//                             <div>
//                               <h5 className="text-white d-inline mb-0">Gonzalo Olmos</h5>
//                               <small className="text-dark font-w-5 font-italic">- Jefe de Operaciones en FIASA</small>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="item">
//                     <div className="row justify-content-center text-center">
//                       <div className="col-12 col-md-10 col-lg-8">
//                         <div className="card bg-transparent border-0">
//                           <div>
//                             <img alt="Image" src={require(`../assets/images/testimonial/colortex-white.png`)} className="shadow-primary img-fluid d-inline" />
//                           </div>
//                           <div className="card-body p-0 mt-5">
//                             <p className="lead font-w-5 text-white">"Utilizar Conecto Energía para nuestras compras anuales de gas natural nos permitió tener acceso a una mayor cantidad de comercializadores
//                               y conseguir mejor precio, todo ello en una forma sencilla transparente y segura."</p>
//                             <div>
//                               <h5 className="text-white d-inline mb-0">Denise Karagozlu</h5>
//                               <small className="text-dark font-w-5 font-italic">- Directora de Karatex</small>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </OwlCarousel>
//               </div>
//             </div>
//           </div>
//         </section>
//     );
//   }
// }