import { IO } from "functional/lib/IO"
import { Validated, Validator } from "./Validated"
import { useRState } from "../RState"
import { none } from "functional/lib/core"

export const useFormState = <Draft, T>(
  args: {
    validator: Validator<Draft, T>,
    initialDraft: IO<Draft>,
  }
) => {

  const state = useRState<Draft>(args.initialDraft)

  const showErrorsState = useRState(() => false)

  const validationResult = args.validator(state.value)

  const validated = validationResult.value.value

  const isValid = Validated.isValid(validationResult)

  return {
    state,
    isValid,
    validated: isValid ? validated : none,
    showErrorsState,
    showErrors: showErrorsState.value,
    errors: validationResult.errors
  }
}

