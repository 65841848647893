import { FlexRow } from "../../../components/Flexbox"
import { Bid } from "../../../model/Bid"
import { ExtraActions, ShowJsonAction } from "../detail-components"



export const BidActions = (
    props: {
      data: Bid
    }
) => {
  
  return <FlexRow>

    <ExtraActions
      actions={[
          <ShowJsonAction
            data={props.data}
          />
      ]}
    />

  </FlexRow>
}
  