
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Button, Card, Col, Form } from "react-bootstrap"
import { Chart } from "react-google-charts"


import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { Async } from "functional/lib/Async"
import { IO } from 'functional/lib/IO'
import { List } from 'functional/lib/List'
import { Maybe, showIf } from 'functional/lib/Maybe'
import { Unit, none } from 'functional/lib/core'
import { matchEnum } from 'functional/lib/match'
import { Lens } from "functional/lib/optics/Lens"
import { useEffect } from "react"
import { ChartWrapperOptions } from "react-google-charts/dist/types"
import { DAYSPERMONTH, MONTHS, sum } from "../../Utilities"
import { CellCol, FlexCol, FlexRow } from '../../components/Flexbox'
import { FormErrorsViewer } from "../../components/Form"
import { StringChoiceEditor } from '../../components/editors/string'
import { RState, useRState } from '../../functional/react/RState'
import { FormReactContext, useFieldErrors } from "../../functional/react/form/FormContext"
import { useFormState } from "../../functional/react/form/state"
import {
  useCompany,
  useCurrentUser,
  useSupplyPoint,
  useSupplyPointListByProduct
} from "../../hooks/ConectoHooks"
import { BuyRequestDraft, Id, Product, SupplyPoint } from "../../model/Model"
import { BuyRequest } from "../../model/buyRequest/BuyRequest"
import { GasBuyRequest, GasMixDistributor, GasMixFree } from '../../model/buyRequest/GasBuyRequest'
import { Draft } from '../../model/utils'
import { nanToNone } from '../../utils/utils'
import { ElectricityFileLoader, ElectricityProductForm, ElectricityTotalAmount } from "./electricity"
import { GasDemandForm, GasFilesSection, GasPayConditionsForm, GasSupplyConditionsForm } from './gas'
import { buyRequestValidator, gasFormFieldIds } from "./validation"
import { NumberEditor } from "../../components/editors/number"
import { useIO } from "../../hooks/effect"


export const BuyRequestCreateFormStep = (
  props: {
    initialRequest: Draft<BuyRequest>
    updateDraft?: (draft: Draft<BuyRequest>) => IO<Unit>
    onBack: IO<Unit>
    onNext: (request: BuyRequest) => IO<Unit>
  }
) => {

  const form = useFormState<Draft<BuyRequest>, BuyRequest>({
    initialDraft: () => props.initialRequest,
    validator: buyRequestValidator,
  })

  const currentDraft = form.state.value

  useIO(
    props.updateDraft?.(currentDraft) ?? IO.noOp,
    currentDraft
  )

  return <FormReactContext.Provider
    value={form}
  >
    <FlexCol
      alignItems='stretch'
    >
      {RState.matchPartial(form.state)({
        electricity: request => 
          <ElectricityProductForm
            request={request}
          />,
      })}

      <CompanyForm
        request={form.state}
      />

      {
        RState.match(form.state)({

          electricity: request =>
            <>
              <ElectricityTotalAmount
                request={request}
              />
              <hr />
              <ElectricityFileLoader
                request={request}
              />
            </>,

          gas: request =>
            <>
              <GasProductForm
                request={request}
              />
              <hr />
              <GasDemandForm
                request={request}
              />
              <hr />
              <GasSupplyConditionsForm
                request={request}
              />
              <hr />
              <GasPayConditionsForm
                request={request}
              />
              <hr/>
              <GasFilesSection
                request={request}
              />
              <hr/>
            </>
        })
      }

      <FlexRow
        justifyContent='space-between'
        padding={8}
      >

        <Button 
          size='lg'
          variant="light"
          onClick={props.onBack}
        >
          <FlexRow
            gap={8}
          >
            <ArrowBack/>
            Volver
          </FlexRow>
          
        </Button>

        <Button 
          size='lg'
          onClick={
            form.validated == none ?
              form.showErrorsState.apply(() => true) :
              props.onNext(form.validated)
          }
        >
          <FlexRow
            gap={8}
          >
            Siguiente
            <ArrowForward/>
          </FlexRow>
        </Button>
      </FlexRow>

      

      <br/>

      <FormErrorsViewer
        form={form}
      />

    </FlexCol>
  </FormReactContext.Provider>
}


const CompanyForm = (
  props: {
    request: RState<BuyRequestDraft>
  }
) => {

  const request = props.request.value

  const fields = RState.destructure(props.request)(
    "buyerCompanyId", 
    "supplyPointId",
    "companyImageUrl"
  ) 

  const [currentUser, userLoading, userError] = useCurrentUser()
  const [company, companyLoading, companyError] = useCompany(request.buyerCompanyId)

  useEffect(
    fields.companyImageUrl?.apply(() => company?.imageUrl) ?? IO.noOp,
    [company?.imageUrl]
  )

  const [
    supplyPointList, 
    supplyPointListLoading,
    supplyPointListError
  ] = useSupplyPointListByProduct(request.type, request.buyerCompanyId)

  const [supplyPoint, supplyPointLoading, supplyPointError] = useSupplyPoint(
    request.buyerCompanyId, 
    request.supplyPointId
  )




  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Punto de Suministro</b>
    </FlexRow>
    
    <FlexRow
      justifyContent='flex-start'
    >

      <CellCol
        weight={1}
        padding={8}
        alignItems='flex-start'
      >
        <Form.Label>Empresa</Form.Label>
        <StringChoiceEditor
          fieldId={gasFormFieldIds.company}
          state={fields.buyerCompanyId}
          options={[
            ["", "--Elegir una Empresa--"],
            ...(currentUser?.companyIdList?.map(id => 
              [id, <CompanyOption id={id} />] as const
            ) ?? [])
          ]}
        />
      </CellCol>

      <CellCol
        weight={1}
        padding={8}
        alignItems='flex-start'
      >

        {company && <>
          <Form.Label>Punto de Suministro</Form.Label>
          <StringChoiceEditor
            fieldId={gasFormFieldIds.supplyPoint}
            state={fields.supplyPointId}
            options={[
              ["", "--Elegir un Punto de Suministro--"],
              ...(supplyPointList?.map(supplyPoint =>
                [supplyPoint.id, supplyPoint.name] as const
              ) ?? [])
            ]}
          />
        </>
        }
      </CellCol>

      <CellCol
        alignItems='flex-start'
        justifyContent='flex-start'
        weight={2}
        style={{ 
          alignSelf: "stretch",
          height: 120,
          paddingLeft: 8,
          paddingTop: 60
        }}
      >
        {
          Maybe.map(supplyPoint)(it => 
            <SupplyPointDescription
              product={request.type}
              supplyPoint={it}
            />
          )
        }
        {
          showIf(company !== none)(
            <p style={{ fontSize: '12' }}>
              (*) Si no encontrás el Punto de Suministro, contactanos
            </p>
          )
        }
      </CellCol>

    </FlexRow>
    <hr/>
  </FlexCol>
}


const CompanyOption = (
  props: { id: Id }
) => {
  const [company, loading, error] = useCompany(props.id)
  return <option value={props.id}>
    {company && company.businessName}
    {loading && "Cargando..."}
    {error && error.message}
  </option>
}

const SupplyPointDescription = (
  props: {
    product: Product
    supplyPoint: SupplyPoint
  }
) => {

  const isGas = props.product == 'gas'
  const isElectricity = props.product == 'electricity'
  const supplyPoint = props.supplyPoint
  const isMultiple = props.supplyPoint?.multiple

  const description = 
    isGas ? <>
      {supplyPoint?.address.street}, {supplyPoint?.address.locality}, {supplyPoint?.address.province}, {supplyPoint?.distributor.name}
    </> :
    (isElectricity ?
      (isMultiple ? <><b>Múltiples Puntos de Suministro</b>
        <br />
        <p className='text text-warning'>Cargar detalle en Documentación del Pedido</p>
      </> :
        <>{supplyPoint?.address?.street}, {supplyPoint?.address?.locality}, {supplyPoint?.address?.province}, {supplyPoint?.type}, NEMO: <b>{supplyPoint?.nemo}</b></>)
      : null)

  return supplyPoint && (isGas || isElectricity) ? <p>{description}</p> : null
}


const GasProductForm = (
  props: {
    request: RState<Draft<GasBuyRequest>>
  }
) => {

  const request = props.request.value

  const fields = RState.destructure(props.request)(
    "mix",
    "product"
  )

  const mix = request.mix

  const [
    supplyPoint, 
    supplyPointLoading, 
    supplyPointError
  ] = useSupplyPoint(request.buyerCompanyId, request.supplyPointId)

  const regions = [
    'NOA - Sistema TGN',
    'NOA - Gas Atacama',
    "Neuquina",
    "Chubut",
    "Santa Cruz",
    "Tierra del Fuego"
  ]

  const gasMixFreeDefault: GasMixFree = {
    type: "free",
    distribution: regions.map(() => 0)
  }

  const gasMixDistributorDefault: GasMixDistributor = {
    type: "distributor",
    distributor: null
  }

  const content = 
    Maybe.map(RState.nonNone(fields.mix))(mix =>
      RState.match(mix)({

        distributor: () => 
        <Col>
          <br />
          <p>
            <b>Distribuidora: </b>{supplyPoint ? supplyPoint.distributor.name : "Elegí una Empresa y un Punto de Suministro"}
          </p>
        </Col>,

        free: mix => {

          const total = nanToNone(
            mix.value.distribution.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0
          )
    
          const fields = RState.destructureAll(mix)

          const color = 
            total === none ? "text text-secondary" :
            total !== 100 ? "text text-danger" :
            "text text-success"

          return <FlexCol
            alignItems='stretch'
          >
            {regions.map((region, number) =>
              <FlexRow
                key={region}
              >
                <CellCol>
                  {region}
                </CellCol>
                <CellCol>
                  <NumberEditor
                    placeholder="0 %"
                    state={RState.applyLens(fields.distribution, Lens.listIndex(number))}
                  />
                </CellCol>
              </FlexRow>
             
            )}
            <FlexRow>
              <b 
                className={color}
                style={{
                  width: 0, flexGrow: 1,
                  textAlign: 'center',
                }}
              >
                Total: 
              </b>
              <b 
                className={color}
                style={{
                  width: 0, flexGrow: 1,
                  justifyContent: "start",
                  paddingLeft: 24
                }}
              >{total ?? "--"} %
              </b>
            </FlexRow>
          </FlexCol>
        }

      })
    )
 

  return <FlexCol
    alignItems='stretch'
  >
    <FlexRow
      justifyContent='center'
    >
      <b>Producto</b>
    </FlexRow>

    <FlexRow
      alignItems='flex-start'
      justifyContent='flex-start'
      //gap={16}
    >

      <CellCol
        weight={1}
        padding={8}
        alignItems='flex-start'
      >
        <Form.Label>Producto</Form.Label>
        <StringChoiceEditor
          state={fields.product}
          options={[
            ["wellhead", "Gas en Boca de Pozo"],
            ["cityGate", "Gas en City Gate"],
            ["plant", "Gas en Planta"]
          ]}
        />
      </CellCol>
      {/*</Row>*/}
      {/*<Row className='justify-content-center'>*/}
      {/*    <b>Mix de Cuencas</b>*/}
      {/*</Row>*/}
      {/*<Row>*/}
      
      <CellCol
        weight={1}
        alignItems='flex-start'
        padding={8}
      >
        {request.product == "wellhead" ? <>
          <Form.Label>Tipo de Mix</Form.Label>
          <StringChoiceEditor
            fieldId={gasFormFieldIds.mix}
            state={{
              value: mix?.type ?? "",
              apply: transform => {
                const value = transform(mix?.type ?? "")
                return matchEnum(value)({
                  free: fields.mix?.apply(() => gasMixFreeDefault),
                  distributor: fields.mix?.apply(() => gasMixDistributorDefault),
                  "": fields.mix?.apply(() => none)
                }) ?? IO.noOp
              }
            }}
            options={[
              ["", "--Elegir Mix de Cuencas--"],
              ["distributor", "Mix de la Distribuidora"],
              ["free", "Mix Libre"]
            ]}
          />
        </> : null}
      </CellCol>

      <CellCol
        weight={2}
        alignItems='stretch'
        padding={8}
      >
        {request.product == "wellhead" ? content : null}
      </CellCol>

    </FlexRow>
    
  </FlexCol>

}

const zip = <A, B>(a: List<A>, b: List<B>): List<[A, B]> => {
  let result: [A, B][] = []
  for (let i = 0; i < a.length; i++) {
    result.push([a[i], b[i]])
  }
  return result
}

const zip2 = <A, B, C>(a: List<A>, b: List<B>, c: List<C>): List<[A, B, C]> => {
  let result: [A, B, C][] = []
  for (let i = 0; i < a.length; i++) {
    result.push([a[i], b[i], c[i]])
  }
  return result
}

export const ConsumptionChart = (
  props: {
    style?: React.CSSProperties
    supplyStartDate: Date
    demandCurve?: List<Maybe<number>>
    cmdCurve?: List<Maybe<number>>
    showStartDate?: boolean
    showBigGraphic?: boolean
  }
) => {

  const startMonthIndex = props.supplyStartDate ? props.supplyStartDate.getMonth() : 10
  const startYear = props.supplyStartDate ? props.supplyStartDate.getFullYear() : 2020

  const monthCount = props.demandCurve?.length ?? 0
  const monthNames = List.new(monthCount)(index => 
    MONTHS[(startMonthIndex + index) % 12] + ' ' + (startYear + Math.floor((startMonthIndex + index) / 12))
  )

  const demandCurve = props.demandCurve ?? []
  const cmdCurve = props.cmdCurve ?? []

  const orderedMonthlyDays = demandCurve.map((value, index) => 
    DAYSPERMONTH[(startMonthIndex + index) % 12]
  )

  const volumeDailyAmount = demandCurve.map((value, index) => 
    (value ?? 0) / orderedMonthlyDays[index]
  )

  const cmdDailyAmount = cmdCurve.map((value, index) => 
    (value ?? 0) * orderedMonthlyDays[index]
  )

  const volumeDayAvg = sum(volumeDailyAmount) / volumeDailyAmount.length ? sum(volumeDailyAmount) / volumeDailyAmount.length : 0

  const cmdDayAvg = sum(cmdDailyAmount) / volumeDailyAmount.length ? sum(cmdDailyAmount) / sum(orderedMonthlyDays) : 0


  // const annualData = zip(monthNames, props.buyType.demandCurve).map(([month, value]) => [month.substring(0, 3), value])

  // const monthlyData = zip2(monthNames, props.buyType.demandCurve, props.buyType.cmdCurve).map(([month, value,cmdValue]) => [month.substring(0, 3), value,cmdValue])
  const data = 
    cmdCurve.length > 0 ? 
      zip2(monthNames, volumeDailyAmount, cmdCurve)
        .map(([month, value, cmdValue]) => [month.substring(0, 3), value, cmdValue ?? 0]) :
      zip(monthNames, volumeDailyAmount)
        .map(([month, value]) => [month.substring(0, 3), value])

  // const data = isAnnual ? annualData : (isMonthly ? monthlyData : [])
  const title = cmdCurve.length > 0 ? 
    [['Mes', 'dam3/mes', 'dam3/día']] : 
    [
      ['Mes', 'dam3/mes'],
      ["Mes", 0 as any]
    ]
  // const monthlyTtile = data.length > 0 ? [['Mes', 'dam3/mes', 'dam3/día']] : [['Mes', 'dam3/mes']].concat([["Mes", 0 as any]])
  // const title = isAnnual ? annualTitle : (isMonthly ? monthlyTtile : [])
  const dataAndTitle = title.concat(data)

  // force relayout TODO: use another chart library
  const counter = useRState(() => 0)

  useEffect(
    () => {
      (async () => {
        for (let i = 0; i < 3; i++) {
          await Async.delayMillis(1000)()
          counter.apply(it => it + 1)?.()
        }
      })()
    },
    []
  )

  return <Card
    style={props.style}
  >
    <Card.Body>
      <FlexCol
        alignItems="stretch"
      >
        <FlexRow>
          <h6 className='text-secondary'>Promedios Diarios <b>[dam3/día]</b></h6>
        </FlexRow>

        <FlexRow
          justifyContent="space-around"
        >
          <p className='text-primary'>Volumen: {volumeDayAvg.toFixed(2)}</p>
          <p className='text-warning'>CMD: {cmdDayAvg.toFixed(2)}</p>
        </FlexRow>

        <div
          style={{
            width: '100%',
          }}
        >
          <Chart
            chartType="LineChart"
            width={'100%'}
            data={dataAndTitle}
            options={chartOptions}
          />

        </div>
      </FlexCol>
    </Card.Body>
  </Card>
}

const chartOptions: ChartWrapperOptions["options"] = {
  height: 400,
  legend: { position: 'none' },
  vAxis: {
    viewWindow: {
      min: 0
    }
  },
  vAxes: {
    0: { title: '(dam3/día)' },
  },
  series: {
    0: { color: '#0d60ff' },
    1: { color: '#ffc106' },
  }
}
