import { Skeleton } from "@material-ui/lab"
import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { Unit, id, none, pipe } from "functional/lib/core"
import MaterialTable, { MaterialTableProps } from "material-table"
import { Alert, Button } from "react-bootstrap"
import { dateToShortFormat } from "../../../Utilities"
import { commentAnswer } from "../../../client/comment"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { StringEditorMultiline } from "../../../components/editors/string"
import { useAppContext, useBuyRequestCollection } from "../../../context/App"
import { useAppAsynchronism } from "../../../context/AppCall"
import { RState, useRState } from "../../../functional/react/RState"
import { dbCommentsGroupCollection, useEntity, usePopulatedEntityList } from "../../../hooks/ConectoHooks"
import { Comment, Id } from "../../../model/Model"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { shallowCopy } from "../../../utils/utils"
import { IdView, buildColumns } from "../components"
import { BuyRequestView, CallError, ConectoDialog, ErrorAlert, SubmitButton, UserView } from "../detail-components"
import { BooleanFilter } from "../filters"
import { softMatch } from "../search"


export const AdminCommentList = () => {

  return <FlexCol
    alignItems="stretch"
    paddingY={16}
    gap={16}
  >
    <AllCommentsTable />
  </FlexCol>
}



const AllCommentsTable = () => {

  const { training } = useAppContext()

  const filter = useRState(
    () => ({
      answered: id<Maybe<boolean>>(none)
    })
  )

  const filters = RState.destructureAll(filter)

  const [allComments, loading, error] = usePopulatedEntityList<Comment>(
    pipe(dbCommentsGroupCollection())(
      //it => it.where("training", "==", training),
      //it => filters.answered.value !== none ? it.where('answered', '==', filters.answered.value) : it
      it => it.orderBy('creationDate', 'desc'),
    )
  )

  const list = pipe(allComments)(
    it => it?.filter(comment => (comment.training ?? false) == training),
    it => filters.answered.value !== none ? it?.filter(comment => comment.answered === filters.answered.value) : it
  )

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    {
      showIf(training)(
        <Alert>
          Solo se muestran las consultas en el <b>SANDBOX</b>, sacar el modo "Training" arriba para ver los pedidos en producción.
        </Alert>
      )
    }

    <FlexCol
      alignItems="flex-start"
      padding={16}
      style={{
        background: "white",
        borderRadius: 16,
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)"
      }}
    >

      <h5>
        Filtros
      </h5>

      <FlexRow
        justifyContent="flex-start"
        gap={8}
      >

        <BooleanFilter
          label={"Contestada"}
          state={filters.answered}
        />

      </FlexRow>

    </FlexCol>

    <CommentTable
      title={`Total ${list?.length ?? "-"}`}
      isLoading={loading}
      data={list}
      options={{
        pageSize: 20
      }}
      columns={[
        columns.id,
        columns.date,
        columns.user,
        columns.buyRequest,
        columns.answered,
        columns.message,
        columns.answer
      ]}
    />
  </FlexCol>
}



export const CommentTable = (
  props: Omit<MaterialTableProps<Comment>, "data"> & {
    data: Maybe<List<Comment>>
  }
) => {

  const dialogState = useRState(() => ({
    open: false,
    comment: id<Maybe<{
      buyRequest: {
        type: BuyRequestType
        id: Id
      }
      id: Id
    }>>(none)
  }))

  const { open, comment } = RState.destructureAll(dialogState)

  return <FlexCol
    alignItems="stretch"
  >
    <MaterialTable
      style={{
        borderRadius: 16,
        overflow: "hidden"
      }}

      {...props}
      options={{
        exportButton: true,
        exportAllData: true,
        columnResizable: true,
        sorting: true,
        ...props.options
        //grouping: true,
        //filtering: true,
      }}
      data={(props.data ?? []) as Comment[]}
      columns={props.columns.map(shallowCopy)}
      onRowClick={(event, data) => {
        const id = data?.id
        if (id !== none) {
          dialogState.apply(() => ({
            open: true,
            comment: {
              buyRequest: {
                type: (data?.product ?? "gas") as BuyRequestType,
                id: data?.buyRequestId ?? ""
              },
              id: id
            }
          }))()
        }
      }}
    />

    <ConectoDialog
      open={open}
      title="Consulta"
      style={{
        width: 800
      }}
    >
      <FlexCol
        style={{ width: "100%" }}
        alignItems="flex-start"
      >
        {
          Maybe.map(comment.value)(comment =>
            <CommentForm
              buyRequest={comment.buyRequest}
              commentId={comment.id}
              onCancel={open.apply(() => false)}
            />
          )
        }
      </FlexCol>
    </ConectoDialog>

  </FlexCol>
}


const useComment = (
  buyRequest: {
    type: BuyRequestType
    id: Id
  },
  commentId: Id
) => {
  const parent = useBuyRequestCollection(buyRequest.type).doc(buyRequest.id)
  const commentReference = parent.collection("comments").doc(commentId)
  return useEntity<Comment>(commentReference as firebase.firestore.DocumentReference<Comment>)
}


const CommentForm = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    commentId: Id
    onCancel?: IO<Unit>
  }
) => {

  const [comment, loading, error] = useComment(props.buyRequest, props.commentId)

  const answer = useRState(() => "")

  const call = useAppAsynchronism(
    (
      args: {
        comment: Comment
        answer: string
      }
    ) =>
      commentAnswer({
        answer: args.answer,
        commentId: args.comment.id,
        answered: true,
        buyRequestId: args.comment.buyRequestId ?? "",
        product: args.comment.product as BuyRequestType,
        training: args.comment.training ?? false,
      })
  )

  return <FlexCol
    style={{
      width: "100%"
    }}
    gap={16}
    alignItems="stretch"
    padding={8}
  >

    <FlexRow>

      <FlexCol
        style={{ width: 0, flexGrow: 1 }}
        gap={8}
        alignItems="flex-start"
      >
        <h6>Usuario</h6>
        <UserView id={comment?.user?.id ?? "-"}/>
      </FlexCol>

      <FlexCol
        style={{ width: 0, flexGrow: 1 }}
        gap={8}
        alignItems="flex-start"
      >
        <h6>Pedido</h6>
        <BuyRequestView 
          buyRequest={{
            type: props.buyRequest.type,
            id: props.buyRequest.id
          }}
        />
      </FlexCol>

    </FlexRow>

    
    

    <h6>Consulta</h6>

    {
      loading ? <Skeleton width={100} /> :
        <p>
          {comment?.comment ?? "-"}
        </p>
    }

    <h6>Respuesta</h6>

    {
      loading ? <Skeleton width={100} /> :
        comment?.answered ?
          <p>
            {comment?.answer ?? "-"}
          </p> :
          <StringEditorMultiline
            state={answer}
          />
    }


    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >

      <Button
        variant="light"
        onClick={props.onCancel}
      >
        Volver
      </Button>

      {Maybe.do(_ => {

        const value = _(comment)

        if (value.answered) _(none)

        return <SubmitButton
          call={call}
          enabled={answer.value.length > 0}
          value={{
            comment: value,
            answer: answer.value
          }}
          label="Contestar"
        />
      })}

    </FlexRow>

    <ErrorAlert
      error={error}
    />

    <CallError
      call={call}
    />

  </FlexCol>
}



export const commentColumns = buildColumns<Comment>()({
  id: {
    title: "Id",
    customFilterAndSearch: (term, data) => softMatch(data.id ?? "", term),
    render: data =>
      <IdView
        id={data.id}
      />,
  },

  date: {
    title: "Fecha",
    defaultSort: "desc",
    customSort: (a, b) => (a.creationDate?.getTime() ?? 0) - (b.creationDate?.getTime() ?? 0),
    render: data => dateToShortFormat(data.creationDate)
  },

  user: {
    title: "Usuario",
    customFilterAndSearch: (term, data) =>
      softMatch(List.filterNotNone([data.user?.firstName, data.user?.lastName]).join(" "), term),
    render: data => <UserView id={data.user?.id} />
  },

  buyRequest: {
    title: "Pedido de Compra",
    customFilterAndSearch: (term, data) => softMatch(data?.buyRequestId ?? "", term),
    render: data => <BuyRequestView
      buyRequest={
        Maybe.do(_ => ({
          type: _(data.product as BuyRequestType),
          id: _(data.buyRequestId),
        }))
      }
    />
  },

  message: {
    title: "Consulta",
    customFilterAndSearch: (term, data) => softMatch(data.comment ?? "", term),
    render: data =>
      <FlexRow
        justifyContent="flex-start"
      >
        {textEllipsis(25)(data.comment ?? "-")}
      </FlexRow>
  },

  answer: {
    title: "Respuesta",
    customFilterAndSearch: (term, data) => softMatch(data.answer ?? "", term),
    render: data =>
      <FlexRow
        justifyContent="flex-start"
      >
        {textEllipsis(25)(data.answer ?? "-")}
      </FlexRow>
  },

  training: {
    title: "Training",
    render: data =>
      data.training ?? false ? "Sanbox" : "Producción"
  },

  answered: {
    title: "Estado",
    render: data => {
      const status = data.answered
      return <FlexRow
        justifyContent="flex-start"
        style={{
          fontWeight: "bold",
          color: status ? "green" : "blue"
        }}
      >
        {status ? "✅ Contestada" : "⏳ Pendiente"}
      </FlexRow>
    }
  },

})

const columns = commentColumns

const textEllipsis =
  (maxChars: number) =>
    (text: string) =>
      text.length > maxChars ? text.slice(0, maxChars) + "..." : text