import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

import { Button } from "react-bootstrap"
import React from "react"
import { useCurrentUser } from "./hooks/ConectoHooks"
import { NotificationsPanel } from "./NotificationsPanel"
import { useNavigate, useLocation } from "react-router-dom"
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import HomeIcon from '@material-ui/icons/Home'
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import { showIf } from "functional/lib/Maybe"
import { Switch } from "@material-ui/core"
import { FlexRow } from "./components/Flexbox"
import { RState, useRState } from "./functional/react/RState"
import { none } from "functional/lib/core"
import { deviceSizes, useDeviceSize } from "./hooks/responsive"
import AdminIcon from '@material-ui/icons/SecurityRounded'
import TrainingIcon from '@material-ui/icons/SchoolRounded'
import { appPaths } from "./pages/navigation"

const webAppPaths = [
  appPaths.marketplace, 
  appPaths.login, 
  appPaths.buyRequestForm, 
  appPaths.admin, 
  appPaths.marketData, 
  appPaths.account,
  "/user",
  "/company",
  "/buy-request"
]

const isAppPath = (path: string): boolean =>
  webAppPaths.some(it => path.startsWith(it))

export const ConectoNavBar = (
  props: {
    training: RState<boolean>
  }
) => {

  const navigate = useNavigate()
  const location = useLocation()

  const [currentUser, loading, error] = useCurrentUser()
  const pathname = location.pathname

  const isAdmin = currentUser?.roleList?.includes('admin') ?? false

  const appPath = isAppPath(pathname)

  const deviceSize = useDeviceSize()
  const small = deviceSize < deviceSizes.lg

  return <Navbar
    bg="white"
    variant="light"
    expand="md"
    sticky="top"
    style={{
      padding: 16,
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    }}
  >
    <Navbar.Brand>
      <Nav.Link onClick={() => navigate("/")}>
        <img
          alt="Image"
          src={require(`../src/landing/assets/images/team/logoConecto.png`)}
          className="img-fluid d-inline"
        />
      </Nav.Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">

      {
          showIf(isAdmin)(
            <>
               <FlexRow
                justifyContent="flex-start"
                gap={4}
               >
                  <TrainingIcon 
                    style={{
                      color: props.training.value ? "black" : "#777777"
                    }}
                  />
                  Training
                  <Switch
                    checked={props.training.value}
                    color="primary"
                    onChange={props.training.apply(it => !it)}
                  />
              </FlexRow>
              <Nav.Link 
                onClick={() => navigate(appPaths.admin)} 
                active={pathname == appPaths.admin}
              >
                <AdminIcon
                  style={{ marginRight: 4 }}
                />
                Admin Panel
              </Nav.Link>
            </>
           
          )
        }

        {showIf(!appPath)(<>
          <Nav.Link onClick={() => navigate("/")} active={pathname == '/'}>Inicio</Nav.Link>
          <Nav.Link onClick={() => navigate(appPaths.buyers)} active={pathname == appPaths.buyers}>Comprar</Nav.Link>
          <Nav.Link onClick={() => navigate(appPaths.sellers)} active={pathname == appPaths.sellers}>Vender</Nav.Link>
          <Nav.Link onClick={() => navigate(appPaths.consultants)} active={pathname == appPaths.consultants}>Consultores</Nav.Link>
          <Nav.Link></Nav.Link>
          {/*<Nav.Link href='/login' className='btn btn-primary'>*/}
          {/*    Iniciar sesión*/}
          {/*</Nav.Link>*/}
          <Button onClick={() => navigate(appPaths.login)}>Iniciar sesión</Button>
        </>)}

        {showIf(appPath && (currentUser !== none))(<>
          <Nav.Link
            active={pathname == appPaths.marketplace}
            onClick={() => navigate(appPaths.marketplace)}
          >
            <HomeIcon color='action' /> Marketplace
          </Nav.Link>
          {/*showIf(currentUser?.roleList?.includes('buyer') ?? false)(
            <Nav.Link 
              onClick={() => navigate(appPaths.marketData)}
              active={pathname == appPaths.marketData}
            >
              <PollOutlinedIcon color='action' /> Informes
            </Nav.Link>
          )*/}
          <Nav.Link 
          >
            <FlexRow gap={4} justifyContent="flex-start" >
              <NotificationsPanel /> Notificaciones
            </FlexRow>
          </Nav.Link>
          <Nav.Link 
            onClick={() => navigate(appPaths.account)}
            active={pathname == appPaths.account}
          >
            <AccountCircleIcon color='action' />   {currentUser?.firstName ?? "Mi Cuenta"}
          </Nav.Link>
        </>)}
      </Nav>
    </Navbar.Collapse>
  </Navbar>

}
