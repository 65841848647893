import { Value } from "@sinclair/typebox/value"
import { BuyRequest, BuyRequestT } from "./model/buyRequest/BuyRequest"
import { Process } from "./model/Process"
import { none } from "functional/lib/core"
import { BuyRequestDraft } from "./model/Model"
import { Draft } from "./model/utils"


export const validateRequestAndProcess = (
  buyRequest: Draft<BuyRequest>,
  process: Draft<Process>
): boolean =>
  validateProcess(process) && validateRequest(buyRequest)

export const validateRequest = (
  buyRequest: Draft<BuyRequest>
): boolean => {
  return requestValidationErrors(buyRequest).length === 0
}

export const validateProcess = (
  process: Draft<Process>
): boolean => 
  processValidationErrors(process).length === 0


export const requestValidationErrors = (
  buyRequest: Draft<BuyRequest>
): string[] => {
  const result = []
  if (buyRequest.buyType === none) { result.push("Selecciona un tipo de compra") }
  // if ((buyRequest.maximumDailyAmount <= 0 && buyRequest.buyType.type == 'annual' || buyRequest.buyType.cmdCurve.lenght) ) {result.push("Selecciona un CMD")}
  
  if (buyRequest.buyerCompanyId === none) { result.push("Selecciona una empresa compradora") }
  // if (0 <= buyRequest.deliverOrPay && buyRequest.deliverOrPay <= 1) result.push("Deliver or Pay tiene que ser entre 0% y 100%")
  
  // if (buyRequest.mix === null) {result.push("Selecciona un mix")}
  
  if (buyRequest.type === "gas") {
    if (buyRequest.feeType === none) { result.push("Selecciona un responsable del pago de la Comisión") }
    if (buyRequest.exchangeRate === none) { result.push("Selecciona una Tasa de Cambio") }
    if ((buyRequest.quality ?? 0) <= 0) { result.push("La calidad debe ser mayor a cero") }
    if (buyRequest.supplyStartDate === none) { result.push("Selecciona una fecha de inicio de suministro") }
    if (buyRequest.supplyType === none) { result.push("Selecciona un tipo de suministro") }
  }

  return result
}

export const processValidationErrors = (
  process: Partial<Process>
): string[] => {
  const result = []
  if (process.openDate === null) { result.push("Selecciona una fecha de apertura") }
  if (process.closeDate === null) { result.push("Selecciona una fecha de cierre") }
  if (process.guestIdList?.length === 0) { result.push("Agrega invitados al proceso") }
  if (process.type === null) { result.push("Selecciona Subasta o Licitación") }
  if ((process.definitionTermDays ?? 0) <= 0) { result.push("El Plazo para definir el Pedido no puede ser 0 días") }
  if (process.defineMinPayTerm && (process.minimumPayTermDays ?? 0) <= 0) result.push("El Plazo de Pago Mínimo no puede ser 0 días")
  if (process.validTermDays === null) { result.push("Selecciona un tipo de suministro") }

  return result
}
