import { List } from "./List"
import { Unit } from "./core"

export type IO<T> = () => T

export const IO = {

  noOp: (): IO<Unit> => () => {},

  pure: <T>(value: T): IO<T> => () => value,

  map: <I>(value: IO<I>) => <O>(f: (value: I) => O): IO<O> => 
    () => f(value()),

  lift: <I, O>(f: (value: I) => O) => (value: IO<I>): IO<O> => 
    IO.map(value)(f),

  bind: <I>(value: IO<I>) => <O>(f: (value: I) => IO<O>): IO<O> => 
    () => f(value())(),

  sequence: <T>(values: List<IO<unknown>>): IO<Unit> => 
    () => {
      values.forEach(value => value())
    },

  forEach: <T>(values: List<T>) => <O>(f: (value: T) => IO<O>): IO<List<O>> =>
    () => values.map(value => f(value)()),

}

