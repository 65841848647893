import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProfessionalForm from "./professionalform";
import Card from "react-bootstrap/Card"

class About extends Component {
  render() {
    return (
      <section className="custom-pynew-1 position-relative">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 text-center">
            <div>
              <h1 className="mt-3">Simplificamos la contratación<br />de energía en Argentina.</h1>
              <h3 className="lead">Hacemos las transacciones más sencillas, transparentes y seguras.</h3>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-6 text-center">
                <br />
                <div className="row justify-content-center">
                  <img
                    src={require('../assets/images/icon/whatshot.png')}
                    style={{ 'max-height': "120px", 'max-width': "120px" }}
                  />
                </div>
                <h2 className='text-secondary'><b>Gas Natural</b></h2>
                <div className="row justify-content-center">
                  <div className="col-10 text-center">
                    <h4 className='text-secondary'>Licitaciones y subastas de contratos anuales,
                      mensuales y spot.<br /></h4>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 text-center">
                <br />
                <div className="row justify-content-center">
                  <img
                    src={require('../assets/images/icon/rayo.png')}
                    style={{ 'max-height': "120px", 'max-width': "120px" }}
                  />
                </div>
                <h2 className='text-secondary'><b>Energía Eléctrica</b></h2>
                <div className="row justify-content-center">
                  <div className="col-10 text-center">
                    <h4 className='text-secondary'>Licitaciones de contratos de MATER, Energía Plus
                      y proyectos renovables y térmicos On-Site.<br /><br /></h4>
                  </div>
                </div>
              </div>
            </div>



            {/*<div className="d-flex flex-wrap justify-content-center">*/}
            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*    <div className="badge-primary-soft rounded p-1">*/}
            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
            {/*        <polyline points="20 6 9 17 4 12" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <p className="mb-0 ml-3">Gas Natural</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*    <div className="badge-primary-soft rounded p-1">*/}
            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
            {/*        <polyline points="20 6 9 17 4 12" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <p className="mb-0 ml-3">Energia Eléctrica en el MATER</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*    <div className="badge-primary-soft rounded p-1">*/}
            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
            {/*        <polyline points="20 6 9 17 4 12" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <p className="mb-0 ml-3">Energía Plus</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*    <div className="badge-primary-soft rounded p-1">*/}
            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
            {/*        <polyline points="20 6 9 17 4 12" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*    <p className="mb-0 ml-3">Energía Electrica OnSite</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*    <Link to="/" className="btn btn-outline-primary mt-5">*/}
            {/*Saber Más*/}
            {/*</Link>*/}
          </div>
        </div>
        <div className="shape-3" style={{ height: '200px', overflow: 'hidden' }}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
            <path d="M-5.00,49.98 C150.00,150.00 349.20,-50.00 505.00,49.98"
              style={{
                stroke: '007bff',
                //'stroke-width': "7", 
                fill: 'none'
              }} />
          </svg>
        </div>
      </section>
    );
  }
}

export default About;