import { Maybe } from "functional/lib/Maybe"
import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { NumberEditor } from "./components/editors/number"
import { RState } from "./functional/react/RState"

export const NumericInput = (
  props: {
    fieldId?: string
    style?: React.CSSProperties
    name?: string
    placeholder: string
    state?: RState<Maybe<number>>
    value?: number
    onValueChanged?: (value: Maybe<number>) => any
    hideLabel?: boolean
  }
) => {

  return <Row
    style={props.style}
  >
    {props.hideLabel || !props.name ? null : <Col xs={6} className="text-center"><Form.Label>{props.name}</Form.Label></Col>}
    <Col xs={props.hideLabel ? 12 : 6}>
      <NumberEditor
        fieldId={props.fieldId}
        placeholder={props.placeholder}
        state={props.state}
        value={props.value}
        onValueChanged={props.onValueChanged}
      />
    </Col>
  </Row>
}



