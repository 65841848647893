import React, { useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { dbTermsAndConditionsCollection } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "./editors/boolean"
import { dateToNiceFormat } from "../Utilities"
import { Id, TermsAndConditions } from "../model/Model"
import { User } from "../model/User"


const NewTermsAndConditions = (
  props: {
    user: User
    lastTerms: TermsAndConditions
  }
) => {
  const [show, setShow] = useState(true)
  const [accepted, setAccepted] = useState(false)

  return <Modal show={show} size={'xl'}>
    <Modal.Body>
      <Row className={'justify-content-center'}>
        <Col xs={6}>
          <h4>Nuevos Términos y Condiciones</h4>
        </Col>
        <Col xs={6} className={'text-right'}>
          <h5 className={'text-secondary'}>Fecha: {props.lastTerms?.date && dateToNiceFormat(props.lastTerms?.date)}</h5>
        </Col>
      </Row>
      <hr />
      <Row className={'justify-content-center'}>
        {props.lastTerms?.pdfLink && <iframe
          src={props.lastTerms?.pdfLink}
          width="85%"
          height="500px"
          frameBorder={0}
          marginWidth={0}
          marginHeight={0}
          scrolling="yes"
          style={{ 'border': '1px solid #CCC', 'borderWidth': '1px', 'marginBottom': '5px', 'maxWidth': '100%' }}
          allowFullScreen>
        </iframe>}
      </Row>
      <hr />
      <Row className={'justify-content-center'}>
        <Col className={'text-center'}>
          <BooleanCheckEditor
            type="checkbox"
            label="Acepto los Términos y Condiciones Generales del Sitio"
            value={accepted}
            onValueChanged={value => setAccepted(value)}
          />
        </Col>
        <Col className={'text-right'}>
          <Button variant={'primary'} disabled={!accepted} onClick={() => addUserToListAndClose(setShow, props.lastTerms, props.user.id)}>Aceptar y salir</Button>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
}

const addUserToListAndClose = (
  setShow: (value: boolean) => void, 
  lastTerms: { id: Id, acceptedUsersList: Id[] }, 
  userId: Id
) => {
  dbTermsAndConditionsCollection().doc(lastTerms.id).update({ acceptedUsersList: lastTerms.acceptedUsersList.concat(userId) })
  return setShow(false)
}

export default NewTermsAndConditions
