import { Form } from "react-bootstrap";
import React from "react";
import { RState } from "../../functional/react/RState";
import { none } from "functional/lib/core";
import { useFieldErrors } from "../../functional/react/form/FormContext"
import { Select } from "@material-ui/core";
import { SelectEditor } from "./select";

export const BooleanSelectEditor = (
  props: {
    style?: React.CSSProperties
    fieldId?: string
    label?: string
    state?: RState<boolean>
    render?: (value: boolean) => React.ReactNode
  }
) => 
  <SelectEditor<boolean>
    state={props.state}
    options={[true, false]}
    render={value => props.render?.(value) ?? (value ? "Sí" : "No")}
  />


export const BooleanCheckEditor = (
  props: {
    fieldId?: string;
    style?: React.CSSProperties;
    type?: "switch" | "checkbox" | "radio";
    label?: string;
    disabled?: boolean;
    state?: RState<boolean>;
    value?: boolean;
    size?: number;
    onValueChanged?: (value: boolean) => void;
  }
) => {

  const state = props.state;

  const errors = useFieldErrors(props.fieldId);

  return <Form.Check
    style={props.style}
    size={props.size}
    isInvalid={errors.length > 0}
    type={props.type}
    label={props.label}
    disabled={props.disabled}
    checked={props.state?.value ?? props.value}
    onChange={state !== none ?
      event => state.apply(() => event.target.checked)() :
      event => props.onValueChanged?.(event.target.checked)} />;
}
