import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { List } from 'functional/lib/List'
import { Maybe } from 'functional/lib/Maybe'
import { id, none, pipe } from 'functional/lib/core'
import MaterialTable, { MaterialTableProps } from 'material-table'
import { useState } from 'react'
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap'
import { FlexCol, FlexRow } from '../../../components/Flexbox'
import { dbCompanyCollection, useBuyerCompanyList, useEntityList, useMockBuyerCompanyList, useMockSellerCompanyList, useSellerCompanyList } from '../../../hooks/ConectoHooks'
import { Company } from '../../../model/Company'
import { Product } from '../../../model/Model'
import { shallowCopy } from '../../../utils/utils'
import { IdView, ProductListView, RoleView, buildColumns } from '../components'
import { useNavigate } from 'react-router'
import { BooleanFilter, ProductFilter, RoleFilter } from '../filters'
import { RState, useRState } from '../../../functional/react/RState'
import { appPaths } from '../../navigation'


export const AllCompanyTables = () => {


  const filter = useRState(
    () => ({
      mock: id<Maybe<boolean>>(none),
      role: "",
      product: ""
    })
  )

  const filters = RState.destructureAll(filter)


  const [list, loading, error] = useEntityList<Company>(
    pipe(dbCompanyCollection())(
      it => Maybe.map(filters.mock.value)(mock => it.where("mockCompany", "==", mock)) ?? it,
      it => filters.role.value !== "" ? it.where("role", "==", filters.role.value) : it,
      it => filters.product.value !== "" ? it.where("productList", "array-contains", filters.product.value) : it
    )
  )
  

  return <FlexCol
    alignItems="stretch"
    gap={8}
    paddingY={16}
  >
    
    <FlexCol
      alignItems="flex-start"
      padding={16}
      style={{
        background: "white",
        borderRadius: 16,
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)"
      }}
    >

      <h5>
        Filtros
      </h5>

      <FlexRow
        justifyContent="flex-start"
        gap={8}
      >

        <RoleFilter
          label="Rol"
          state={filters.role}
        />

        <ProductFilter
          label="Producto"
          state={filters.product}
        />

        <BooleanFilter
          label="Mock"
          state={filters.mock}
        />

      </FlexRow>

    </FlexCol>

    <CompanyTable
      options={{ pageSize: 10 }}
      isLoading={loading}
      title={`Empresas [${list?.length ?? "-"}]`}
      data={list ?? []}
      columns={[
        columns.id,
        columns.imageUrl,
        columns.businessName,
        columns.displayName,
        columns.shortName,
        columns.productList,
        columns.role,
        columns.mockCompany
      ]}
    />

  </FlexCol>
}



export const CompanyTable = (
  props: Omit<MaterialTableProps<Company>, "data"> & {
    data: Maybe<List<Company>>
  }
) => {

  const navigate = useNavigate()

  return <MaterialTable
    style={{
      borderRadius: 16,
      overflow: "hidden"
    }}
    onRowClick={(event, data) => {
      const id = data?.id
      if (id !== none) {
        navigate(appPaths.company(id).detail)
      }
    }}
    {...props}
    data={(props.data ?? []) as Company[]}
    options={{ 
      exportButton: true, 
      exportAllData: true,
      columnResizable: true,
      sorting: true,
      ...props.options,
      //grouping: true,
      //filtering: true,
    }}
    columns={props.columns.map(shallowCopy)}
  />
}

const columns = buildColumns<Company>()({

  id: {
    title: "Id",
    customFilterAndSearch: (term, data) => data.id?.includes(term) ?? false,
    render: data => <IdView id={data.id} />
  },

  imageUrl: {
    title: "Imagen",
    field: "imageUrl",
    render: (rowData: Company) => 
      <img src={rowData.imageUrl} style={{ width: 50, borderRadius: '50%' }} />
  },

  businessName: { title: "Razon Social", field: "businessName" },

  displayName: { title: "Nombre", field: "displayName" },

  cuit: { title: "CUIT", field: "cuit" },

  productList: { 
    title: "Productos", 
    render: data => <ProductListView products={data.productList} />
  },

  role: {
    title: "Rol",
    render: data => <RoleView role={data.role} />
  },

  shortName: { title: "Nombre Corto", field: "shortName" },

  mockCompany: { 
    title: "Mock", 
    render: data => data.mockCompany ? "Si" : "No" 
  },

})




export const SellersCompaniesList = () => {

  const [sellerCompaniesList, sellerCompaniesListLoading, sellerCompaniesListError] = useSellerCompanyList()

  const [filterList, setFilterList] = useState(['gas', 'mater', 'onsite', "thermal"] as List<Product | "">)

  //console.log(filterList)
  const filteredList = 
    sellerCompaniesList?.filter(company => company?.productList?.some(v => filterList.indexOf(v) !== -1)) ?? []

  return <FlexCol
    alignItems="stretch"
  >
    <br />
    <Row className='justify-content-center'>
      <Col>
        <ButtonGroup>
          <Button variant={filterList.includes('gas') ? 'primary' : 'secondary'}
            onClick={() => setFilterList(filteredList => [...filteredList, 'gas'])}>
            {filterList.includes('gas') ? <CheckCircleOutlineOutlinedIcon /> : null}
            Gas Natural
          </Button>
          <Button variant={filterList.includes('mater') ? 'primary' : 'secondary'}
            onClick={() => setFilterList(filteredList => [...filteredList, 'mater'])}>
            {filterList.includes('mater') ? <CheckCircleOutlineOutlinedIcon /> : null}
            MATER
          </Button>
          <Button variant={filterList.includes('onsite') ? 'primary' : 'secondary'}
            onClick={() => setFilterList(filteredList => [...filteredList, 'onsite'])}>
            {filterList.includes('onsite') ? <CheckCircleOutlineOutlinedIcon /> : null}
            Solar FV OnSite
          </Button>
          <Button variant={filterList.includes('thermal') ? 'primary' : 'secondary'}
            onClick={() => setFilterList(filteredList => [...filteredList, 'thermal'])}>
            {filterList.includes('thermal') ? <CheckCircleOutlineOutlinedIcon /> : null}
            Térmico OnSite
          </Button>
        </ButtonGroup>
      </Col>
      <Col xs={3}>
        <Button
          variant={filterList.some(it => ['gas', 'mater', 'onsite', "thermal", ''].includes(it)) ? 'primary' : 'outline-primary'}
          onClick={() => setFilterList(filteredList => [...filteredList, 'gas', 'mater', 'onsite', "thermal", ''])}>
          {filterList.includes('gas' && 'mater' && 'onsite' && "thermal" && '') ?
            <CheckCircleOutlineOutlinedIcon /> : 'Seleccionar'} Todas
        </Button>
        {filterList.length > 0 && <ButtonGroup aria-label="Basic example">
          <Button variant={'outline-danger'}
            onClick={() => setFilterList([])}>
            Borrar Filtros
          </Button>
        </ButtonGroup>}
      </Col>
      <Col xs={3}>
        <ButtonGroup aria-label="Basic example">
          <Button variant={'outline-warning'}
            onClick={() => setFilterList([''])}>
            Empresas Sin Productos Asignados
          </Button>
        </ButtonGroup>
      </Col>


    </Row>
    <br />
    <CompanyTable
      options={{ pageSize: 10 }}
      title={"Empresas Vendedoras[" + filteredList.length + "]"}
      data={filteredList}
      columns={[
        columns.id,
        columns.imageUrl,
        columns.businessName,
        columns.displayName,
        columns.cuit,
        columns.productList,
      ]} 
    />

  </FlexCol>
}

export const AdminMockCompaniesList = () => {


  const [buyerMockCompaniesList, buyerCompaniesListLoading, buyerCompaniesListError] = useMockBuyerCompanyList()
  const [sellerMockCompaniesList, sellerCompaniesListLoading, sellerCompaniesListError] = useMockSellerCompanyList()

  return <FlexCol
    alignItems="stretch"
  >
    <br />
    <CompanyTable
      title="Mock Compradoras"
      data={buyerMockCompaniesList}
      columns={[
        columns.id,
        columns.imageUrl,
        columns.businessName,
        columns.displayName,
        columns.cuit,
        columns.productList,
        columns.shortName,
      ]}
    />
    <br />
    <CompanyTable
      title="Mock Vendedoras"
      data={sellerMockCompaniesList}
      columns={[
        columns.id,
        columns.imageUrl,
        columns.businessName,
        columns.displayName,
        columns.cuit,
        columns.productList,
        columns.shortName,
      ]} 
    />
  </FlexCol>
}

