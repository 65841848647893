import { StaticDecode, Type } from "@sinclair/typebox";
import { ElectricityBuyRequestT } from "./ElectricityBuyRequest"
import { GasBuyRequestT } from "./GasBuyRequest"

export const BuyRequestT = Type.Union([
  GasBuyRequestT,
  ElectricityBuyRequestT
])

export type BuyRequest = StaticDecode<typeof BuyRequestT>

export type BuyRequestType = BuyRequest["type"]
