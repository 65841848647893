import { Maybe } from "functional/lib/Maybe";
import { none } from "functional/lib/core";

export const nanToNone = (value: number): Maybe<number> => isNaN(value) ? none : value

export const shallowCopy = <T extends object>(obj: T): T => ({ ...obj })

export const valueEquals =
  (lhs: unknown, rhs: unknown): boolean => {

    if (lhs === rhs) return true

    if (typeof lhs !== "object" || typeof rhs !== "object") return false

    if (lhs === null || rhs === null) return false

    if (Array.isArray(lhs) && Array.isArray(rhs)) {
      return lhs.length === rhs.length && lhs.every((v, i) => valueEquals(v, rhs[i]))
    }

    const lhsKeys = Object.keys(lhs)
    const rhsKeys = Object.keys(rhs)

    if (lhsKeys.length !== rhsKeys.length) {
      return false
    }

    return lhsKeys.every(key => valueEquals((lhs as any)[key], (rhs as any)[key]))
  }
