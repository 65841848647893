import React from "react"
import { FormErrors } from "./FormErrors"
import { Maybe } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import { List } from "functional/lib/List"


export type FormContext = {
  showErrors: boolean
  errors: FormErrors
}

export const FormReactContext = React.createContext<FormContext>({ 
  showErrors: false,
  errors: {} 
})

export const useFormContext = (): FormContext => React.useContext(FormReactContext)
export const useFormErrors = (): FormErrors => useFormContext().errors

export const useFieldErrors = (fieldId: Maybe<string>): List<string> => {
  const context = useFormContext()
  return context.showErrors && fieldId !== none ? (context.errors[fieldId] ?? []) : []
}

