import { StaticDecode, Type } from "@sinclair/typebox"
import { FileDataT, TimestampT } from "../common"
import { ListT, MaybeT, OptionalTypeTag } from "../utils"
import { BuyRequestBaseFields } from "./BuyRequestBase"

export const ExchangeRateT = Type.Union([
  Type.Literal("BNA divisa"),
  Type.Literal("BNA billete"),
  Type.Literal("CAMMESA"),
])
export type ExchangeRate = StaticDecode<typeof ExchangeRateT>

export const GasBuyTypeAnnualT = Type.Object({
  type: Type.Literal("annual"),
  demandCurve: ListT(Type.Number()),
  cmdCurve: Type.Optional(ListT(Type.Number())),
})
export type GasBuyTypeAnnual = StaticDecode<typeof GasBuyTypeAnnualT>

export const GasBuyTypeMonthlyT = Type.Object({
  type: Type.Literal("monthly"),
  demandCurve: ListT(Type.Number()),
  cmdCurve: Type.Optional(ListT(Type.Number())),
})
export type GasBuyTypeMonthly = StaticDecode<typeof GasBuyTypeMonthlyT>

export const GasBuyTypeSpotT = Type.Object({
  type: Type.Literal("spot"),
  amount: Type.Number(),
  termDays: Type.Number(),
  cmdCurve: Type.Optional(ListT(Type.Number())),
})
export type GasBuyTypeSpot = StaticDecode<typeof GasBuyTypeSpotT>

export const GasBuyTypeT = Type.Union([
  GasBuyTypeAnnualT,
  GasBuyTypeMonthlyT,
  GasBuyTypeSpotT,
])
export type GasBuyType = StaticDecode<typeof GasBuyTypeT>

export const GasMixFreeT = Type.Object({
  type: Type.Literal("free"),
  distribution: ListT(Type.Number())
})
export type GasMixFree = StaticDecode<typeof GasMixFreeT>

export const GasMixDistributorT = Type.Object({
  type: Type.Literal("distributor"),
  distributor: Type.Union([
    Type.Null(),
    Type.Object({
      name: Type.String()
    })
  ])
})
export type GasMixDistributor = StaticDecode<typeof GasMixDistributorT>

export const GasMixT = Type.Union([
  GasMixFreeT,
  GasMixDistributorT
])
export type GasMix = StaticDecode<typeof GasMixT>

export const GasProductT = Type.Union([
  Type.Literal("cityGate"),
  Type.Literal("wellhead"),
  Type.Literal("plant"),
])
export type GasProduct = StaticDecode<typeof GasProductT>

export const GasBuyRequestT = OptionalTypeTag("gas", {

  ...BuyRequestBaseFields,

  acceptsLessVolume: Type.Optional(Type.Boolean()),

  // deprecated?
  discount: MaybeT(
    Type.Object({
      max: Type.Number(),
      min: Type.Number(),
      factor: Type.Number()
    })
  ),

  quality: Type.Number(),

  supplyEndDate: MaybeT(TimestampT),

  exchangeRate: ExchangeRateT,

  exclusivity: Type.Optional(Type.Boolean()),
  
  takeOrPay: MaybeT(Type.Number()),

  maximumDailyAmount: Type.Optional(Type.Number()),

  supplyType: Type.Union([
    Type.Literal("firm"),
    Type.Literal("interruptible")
  ]),

  referencePayTermDays: Type.Number(),

  buyType: GasBuyTypeT,

  mix: MaybeT(GasMixT),

  supplyStartDate: TimestampT,

  product: GasProductT,

  deliverOrPay: Type.Number(),

  // legacy?
  // cmdCurve: Type.Optional(ListT(Type.Number())),

  productType: Type.Optional(Type.String()), //Type.Optional(Type.Literal("gas")),

  // Solo lo tiene CON-GN-01
  multiplePointBusinessNameText: Type.Optional(Type.String()),

  // Solo lo tiene CON-GN-01
  multiPointMessage: Type.Optional(Type.Boolean()),

  // Solo lo tiene CON-GN-01
  jointPurchase: Type.Optional(Type.Boolean()),

  // Solo lo tiene CON-GN-01
  multipleSupplyPointsList: Type.Optional(
    ListT(
      Type.Object({
        name: Type.String(),
        distributor: Type.Object({
          name: Type.String()
        }),
        address: Type.Object({
          locality: Type.String(),
          province: Type.String(),
          street: Type.String(),
        }),
      })
    )
  ),

  // Solo lo tiene CON-GN-01
  multiPointInfoLink: Type.Optional(Type.String()),

  order: Type.Optional(Type.Number()),

  fileList: Type.Optional(ListT(FileDataT))

})

export type GasBuyRequest = StaticDecode<typeof GasBuyRequestT>
