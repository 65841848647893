import { Maybe } from "./Maybe"
import { none } from "./core"

export type List<T> = ReadonlyArray<T>
export type MutableList<T> = Array<T>

export const List = {

  asArray: <T>(list: List<T>): T[] => list as T[],

  new: (length: number) => <T>(init: (index: number) => T): List<T> => {
    let result: T[] = []
    for (let i = 0; i < length; i++) {
      result.push(init(i))
    }
    return result
  },

  map: <T, U>(list: List<T>, f: (value: T) => U): List<U> => 
    list.map(f),

  lift: <T, U>(f: (value: T) => U) =>
    (list: List<T>): List<U> =>
    list.map(f),

  flatMap: 
    <T, U>(f: (value: T) => List<U>) => 
    (list: List<T>): List<U> =>
    list.flatMap(f),

  filter: 
    <T>(predicate: (value: T) => boolean) => 
    (list: List<T>): List<T> =>
    list.filter(predicate),

  filterNotNone:
    <T>(list: List<Maybe<T>>): List<T> =>
    list.filter((it): it is T => it !== none),

  fold:
    <T, U>(reducer: (acc: U, value: T) => U) =>
      (init: U) =>
      (list: List<T>): U =>
      list.reduce(reducer, init),  

  foldRight:
    <T, U>(reducer: (acc: U, next: T) => U) =>
      (init: U) =>
      (list: List<T>): U =>
      list.reduceRight(reducer, init),

  concat:
    <T>(...lists: List<List<T>>): List<T> =>
     lists.flat(),

  flatten:
    <T>(list: List<List<T>>): List<T> =>
    list.flat(),

  sort:
    <T>(compare: (lhs: T, rhs: T) => number) => 
    (list: List<T>): List<T> =>
    list.toSorted(compare),

  reverse:
    <T>(list: List<T>): List<T> =>
    list.toReversed(),

  take:
    (count: number) =>
    <T>(list: List<T>): List<T> =>
    list.slice(0, count),

  drop:
    (count: number) =>
    <T>(list: List<T>): List<T> =>
    list.slice(count),

  scan:
    <T, U>(f: (acc: U, value: T) => U) =>
    (init: U) =>
    (list: List<T>): List<U> => {
      let result: MutableList<U> = []
      let acc = init
      for (let value of list) {
        acc = f(acc, value)
        result.push(acc)
      }
      return result
    },

  associateTo: 
    <K, V>(f: (value: K) => V) =>
    (list: List<K>): Map<K, V> => {
      let result: Map<K, V> = new Map()
      for (let value of list) {
        result.set(value, f(value))
      }
      return result
    },

  associateBy:
    <K, V>(f: (value: K) => V) =>
    (list: List<K>): Map<V, K> => {
      let result: Map<V, K> = new Map()
      for (let value of list) {
        result.set(f(value), value)
      }
      return result
    },

  unique:
    <T>(list: List<T>): List<T> => [...new Set(list)],

}

