import { IO } from "functional/lib/IO"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { RState, useRState } from "../../../functional/react/RState"
import { BuyRequest, BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { CallError, ConectoDialog, ExtraActions, ShowJsonAction, SubmitButton } from "../detail-components"
import { Unit } from "functional/lib/core"
import { NumberEditor } from "../../../components/editors/number"
import { Maybe, showIf } from "functional/lib/Maybe"
import { defaultFeeConditions } from "../../../config"
import { Alert, Button, Spinner } from "react-bootstrap"
import { AppCall, useAppAsynchronism } from "../../../context/AppCall"
import { Id } from "../../../model/Model"
import { buyRequestStatusUpdate, buyRequestUpdate } from "../../../client/buy-request"
import { FeeConditions } from "../../../model/buyRequest/BuyRequestBase"
import { Asynchronism } from "../../../functional/react/Asynchronism"
import { matchPartial } from "functional/lib/match"
import { FeeConditionsDraft, FeeConditionsEditor } from "../company/actions"

export const BuyRequestActions = (
  props: {
    data: BuyRequest
  }
) => {

  return <FlexRow>

    <ExtraActions
      actions={[
        <EditFeeAction
          data={props.data}
        />,
        <ShowJsonAction
          data={props.data}
        />
      ]}
    />

  </FlexRow>
}

const EditFeeAction = (
  props: {
    data: BuyRequest
  }
) => {

  const open = useRState(() => false)

  return <FlexCol>
    <FlexRow
      onClick={open.apply(() => true)}
    > 
      Modificar Comisión
    </FlexRow>

    <ConectoDialog
      open={open}
      title="Modificar Comisión"
      style={{
        width: 400
      }}
    >
      <EditFeeForm
        data={props.data}
        onCancel={open.apply(() => false)}
      />
    </ConectoDialog>

  </FlexCol>
}




const EditFeeForm = (
  props: {
    data: BuyRequest
    onCancel: IO<Unit>
  }
) => {

  const feeConditions = props.data.feeConditions ?? defaultFeeConditions

  const state = useRState<FeeConditionsDraft>(
    () => ({
      max: feeConditions.max,
      min: feeConditions.min,
      percent: feeConditions.factor * 100
    })
  )

  const result = 
    Maybe.do<FeeConditions>(_ => ({
      factor: _(state.value.percent) / 100,
      min: _(state.value.min),
      max: _(state.value.max)
    }))

  const call = useAppAsynchronism(
    (args: {
      buyRequest: {
        type: BuyRequestType
        id: Id
      }
      data: FeeConditions
    }) =>
      buyRequestUpdate({
        buyRequest: args.buyRequest,
        data: {
          feeConditions: args.data
        }
      })
  )

  return <FlexCol
    style={{ 
      width: "100%",
      padding: 10
    }}
    alignItems="stretch"
    gap={16}
  >

    <FeeConditionsEditor
      state={state}
    />

    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >

      <Button
        variant="light"
        onClick={props.onCancel}
      >
        Cancelar
      </Button>

      <SubmitButton
        label="Modificar"
        call={call}
        value={
          Maybe.do(_ => ({
            buyRequest: { type: props.data.type, id: props.data.id ?? "" },
            data: _(result)
          }))
        }
      />

    </FlexRow>

    <CallError
      call={call}
    />

  </FlexCol>
}
