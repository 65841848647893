import React, { Component } from 'react';
import { BuyersHero } from '../../widgets/herosection5'
import { Footer } from "../../widgets/footer"
import { Link } from "react-router-dom"
import Feature from "../../widgets/feature"

export function Buyers() {
  return (
    <div>
      <section className="custom-pb-1 bg-primary position-relative">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-10 col-lg-10">
            <div className="row justify-content-center text-center">
              <h1 className="text-white font-w-5">Comprá mejor en Conecto Energía</h1>
            </div>
            <div>
              <Link to="/form" className="btn btn-lg btn-outline-light mr-1">Creá tu cuenta</Link>&nbsp;&nbsp;
              <Link to="/login" className="btn btn-lg btn-outline-light mr-1">Iniciar sesión</Link>
            </div>
          </div>
        </div>
        <div className="shape-1 bottom" style={{ height: '150px', overflow: 'hidden' }}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
            <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
          </svg>
        </div>
      </section>
      <BuyersHero />
      <div className="container">
        <div className="row align-items-center justify-content-between">
          
          <div className="col-12 col-lg-8">
            <div className>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Accedé a todos los proveedores registrados en forma directa y eficiente.</p>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Publicá tus pedidos y obtené ofertas competitivas que mejorarán tus resultados.</p>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Participá de manera segura y trazable, con nuestro sistema de encriptación de ofertas.</p>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-start">
                  <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Llegá a tu contrato de forma más simple, rápida y efectiva.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="row justify-content-center">
              <div className='col-12'>
                <Link to="/form" className="btn btn-outline-primary block mt-5">Creá tu Cuenta en Conecto Energía</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        {/*feature start*/}
        <Feature />
        {/*feature end*/}
      </section>
      <Footer />
    </div>
  );
}


