
export const removeAccents = (text: string) =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const normalize = (text: string) =>
  removeAccents(text).toLocaleLowerCase()

export const softMatch = (data: string, searchTerm: string): boolean =>
  normalize(data).includes(normalize(searchTerm))

